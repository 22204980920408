import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Footer from 'components/Footer';
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn,
  userSignOut,
} from 'actions/Users';
import { APP_VERSION } from '../constants/globalConstants';

const styles = (theme) => ({
  blackHover: {
    fontSize: '1.25rem',
    '&:hover': {
      color: `${theme.palette.primary.dark}`,
      transition: '0.3s ease-in',
    },
  },
});

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
    };
    this.notificationDOMRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.props.authUser) {
      this.props.history.push('/');
    }
  }

  checkOldToken = () => {
    if (
      localStorage.getItem('token') &&
      localStorage.getItem('tokenExpiredAt') < new Date().getTime()
    ) {
      console.log('checkOldToken clean');
      localStorage.removeItem('user');
      localStorage.removeItem('user_login');
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpiredAt');

      // Effacer auth user et redirect
      this.props.userSignOut();
    }
  };

  render() {
    const classes = this.props.classes;
    const { email, password, modalLegalMentionOpen } = this.state;
    const { showMessage, loader, alertMessage } = this.props;
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link
              className="logo-lg"
              rel="keep-params"
              to="/"
              title="Alert Gasoil">
              <img
                style={{ width: '230px', padding: '10px' }}
                src={require('assets/images/logo-alert-gasoil.svg')}
                alt="Alert Gasoil logo"
                title="alert gasoil"
              />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>
                <IntlMessages id="appModule.signin" />
              </h1>
            </div>

            <div className="app-login-form">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  this.checkOldToken();
                  this.props.showAuthLoader();
                  this.props.userSignIn({ email, password });
                }}>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="appModule.login" />}
                    fullWidth
                    onChange={(event) => {
                      this.checkOldToken();
                      this.setState({ email: event.target.value });
                    }}
                    defaultValue={email}
                    margin="normal"
                    variant="outlined"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password" />}
                    fullWidth
                    onChange={(event) => {
                      this.checkOldToken();
                      this.setState({ password: event.target.value });
                    }}
                    defaultValue={password}
                    margin="normal"
                    variant="outlined"
                    className="mt-1 my-sm-3"
                    autoComplete="new-password"
                  />

                  <div className="mb-3 mt-2 d-flex align-items-center justify-content-between">
                    <Button type="submit" variant="contained" color="primary">
                      <IntlMessages id="appModule.signIn" />
                    </Button>
                  </div>

                  <div className="d-flex justify-content-start">
                    <div className="p-0">
                      <Link
                        rel="keep-params"
                        to="/forgot-password"
                        className={`${classes.blackHover}`}>
                        Mot de passe oublié
                      </Link>
                    </div>
                    <div className="ml-auto p-0 ">
                      <Link
                        rel="keep-params"
                        to="/contact-us"
                        className={`${classes.blackHover}`}>
                        Nous contacter
                      </Link>
                    </div>
                  </div>

                  <Footer />
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ users }) => {
  const { loader, authUser } = users;
  return { loader, authUser };
};

export default connect(mapStateToProps, {
  userSignIn,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn,
  userSignOut,
})(
  withStyles(
    (theme) => ({
      ...styles(theme),
    }),
    { withTheme: true }
  )(SignIn)
);
