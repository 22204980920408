import {
  //? TANK SERIAL
  GET_TANK_SERIES_REQUEST,
  GET_TANK_SERIES_SUCCESS,
  GET_TANK_SERIES_FAILURE,
  UPDATE_TANK_SERIE_REQUEST,
  UPDATE_TANK_SERIE_SUCCESS,
  UPDATE_TANK_SERIE_FAILURE,
  GET_SINGLE_TANK_SERIES_REQUEST,
  GET_SINGLE_TANK_SERIES_SUCCESS,
  GET_SINGLE_TANK_SERIES_FAILURE,
  ADD_TANK_SERIE_REQUEST,
  ADD_TANK_SERIE_SUCCESS,
  ADD_TANK_SERIE_FAILURE,
  //? TANK MODEL
  GET_TANK_MODELS_REQUEST,
  GET_TANK_MODELS_SUCCESS,
  GET_TANK_MODELS_FAILURE,
  LOAD_SINGLE_TANK_MODELS_REQUEST,
  LOAD_SINGLE_TANK_MODELS_SUCCESS,
  LOAD_SINGLE_TANK_MODELS_FAILURE,
  GET_SINGLE_TANK_MODEL_REQUEST,
  GET_SINGLE_TANK_MODEL_SUCCESS,
  GET_SINGLE_TANK_MODEL_FAILURE,
  GET_TANK_MODELS_FOR_ONE_SERIE_REQUEST,
  GET_TANK_MODELS_FOR_ONE_SERIE_SUCCESS,
  GET_TANK_MODELS_FOR_ONE_SERIE_FAILURE,
  ADD_TANK_MODEL_REQUEST,
  ADD_TANK_MODEL_SUCCESS,
  ADD_TANK_MODEL_FAILURE,
  UPDATE_TANK_MODEL_REQUEST,
  UPDATE_TANK_MODEL_SUCCESS,
  UPDATE_TANK_MODEL_FAILURE,
  DELETE_TANK_MODEL_REQUEST,
  DELETE_TANK_MODEL_SUCCESS,
  DELETE_TANK_MODEL_FAILURE,
  CALC_VOLUME_TANK_MODELS_REQUEST,
  CALC_VOLUME_TANK_MODELS_SUCCESS,
  CALC_VOLUME_TANK_MODELS_FAILURE,
  CALC_VOLUME_TANK_MODELS_AGINSTALLER_REQUEST,
  CALC_VOLUME_TANK_MODELS_AGINSTALLER_SUCCESS,
  CALC_VOLUME_TANK_MODELS_AGINSTALLER_FAILURE,
  //? TANK CALIBRATION
  GET_TANK_CALIBRATIONS_REQUEST,
  GET_TANK_CALIBRATIONS_SUCCESS,
  GET_TANK_CALIBRATIONS_FAILURE,
  GET_SINGLE_TANK_CALIBRATION_REQUEST,
  GET_SINGLE_TANK_CALIBRATION_SUCCESS,
  GET_SINGLE_TANK_CALIBRATION_FAILURE,
  GET_TANK_CALIBRATIONS_FOR_ONE_MODEL_REQUEST,
  GET_TANK_CALIBRATIONS_FOR_ONE_MODEL_SUCCESS,
  GET_TANK_CALIBRATIONS_FOR_ONE_MODEL_FAILURE,
  ADD_TANK_CALIBRATION_REQUEST,
  ADD_TANK_CALIBRATION_SUCCESS,
  ADD_TANK_CALIBRATION_FAILURE,
  UPDATE_TANK_CALIBRATION_REQUEST,
  UPDATE_TANK_CALIBRATION_SUCCESS,
  UPDATE_TANK_CALIBRATION_FAILURE,
  //? GAUGE
  GET_RECOMMENDED_GAUGE_TYPES_REQUEST,
  GET_RECOMMENDED_GAUGE_TYPES_SUCCESS,
  GET_RECOMMENDED_GAUGE_TYPES_FAILURE,
  CALC_VMIN_VMAX_GAUGE_REQUEST,
  CALC_VMIN_VMAX_GAUGE_SUCCESS,
  CALC_VMIN_VMAX_GAUGE_FAILURE,
  CALC_DEFAULT_VMIN_GAUGE_REQUEST,
  CALC_DEFAULT_VMIN_GAUGE_SUCCESS,
  CALC_DEFAULT_VMIN_GAUGE_FAILURE,
  GET_GAUGE_TYPES_REQUEST,
  GET_GAUGE_TYPES_SUCCESS,
  GET_GAUGE_TYPES_FAILURE,
  GET_SINGLE_RECOMMENDED_GAUGE_TYPE_REQUEST,
  GET_SINGLE_RECOMMENDED_GAUGE_TYPE_SUCCESS,
  GET_SINGLE_RECOMMENDED_GAUGE_TYPE_FAILURE,
  //? OTHER
  GET_ALL_TANK_SERIE_WITH_ASSOCIATED_MODELS_REQUEST,
  GET_ALL_TANK_SERIE_WITH_ASSOCIATED_MODELS_SUCCESS,
  GET_ALL_TANK_SERIE_WITH_ASSOCIATED_MODELS_FAILURE,
  GET_TANK_SHAPE_TYPES_REQUEST,
  GET_TANK_SHAPE_TYPES_SUCCESS,
  GET_TANK_SHAPE_TYPES_FAILURE,
  GET_TCD_FOR_ONE_TANK_CALIBRATION_REQUEST,
  GET_TCD_FOR_ONE_TANK_CALIBRATION_SUCCESS,
  GET_TCD_FOR_ONE_TANK_CALIBRATION_FAILURE,
  START_CALC_TCD_REQUEST,
  START_CALC_TCD_SUCCESS,
  START_CALC_TCD_FAILURE,
  GET_TANK_BRANDS_REQUEST,
  GET_TANK_BRANDS_SUCCESS,
  GET_TANK_BRANDS_FAILURE,
  GET_TANK_MATERIAL_TYPES_REQUEST,
  GET_TANK_MATERIAL_TYPES_SUCCESS,
  GET_TANK_MATERIAL_TYPES_FAILURE
} from '../constants/ActionTypes';

const INIT_STATE = {
  initialFetchDone: false,
  initialFetchSeriesWithAssociatedModels: false,
  fetching: false,
  error: null,
  singleTankSerie: null,
  tankSeries: [],
  tankModel: null,
  tankModels: [],
  tankCalibration: null,
  tankCalibrations: [],
  gaugeTypes: [],
  calcVolumeModelAginstaller: null,
  calcVolumeModelAdd: null,
  calcVminVmaxGauge: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // GET GAUGE TYPES
    case GET_GAUGE_TYPES_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_GAUGE_TYPES_SUCCESS:
      return { ...state, fetching: false, gaugeTypes: action.gaugeTypes };
    case GET_GAUGE_TYPES_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET TANK SERIES //
    case GET_TANK_SERIES_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_TANK_SERIES_SUCCESS:
      return { ...state, fetching: false, tankSeries: action.tankSeries };
    case GET_TANK_SERIES_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? GET SINGLE TANK SERIE //
    case GET_SINGLE_TANK_SERIES_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_SINGLE_TANK_SERIES_SUCCESS:
      return {
        ...state,
        fetching: false,
        singleTankSerie: action.singleTankSerie
      };
    case GET_SINGLE_TANK_SERIES_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? ADD TANK SERIE //
    case ADD_TANK_SERIE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case ADD_TANK_SERIE_SUCCESS:
      return { ...state, fetching: false, tankSerie: action.tankSerie };
    case ADD_TANK_SERIE_FAILURE:
      return {
        ...state,
        fetching: false,
        tankModel: null,
        error: action.error
      };
    //? UPDATE TANK SERIE //
    case UPDATE_TANK_SERIE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case UPDATE_TANK_SERIE_SUCCESS:
      return { ...state, fetching: false, tankSerie: action };
    case UPDATE_TANK_SERIE_FAILURE:
      return {
        ...state,
        fetching: false,
        tankModel: null,
        error: action.error
      };
    //? GET TANK SHAPE TYPES //
    case GET_TANK_SHAPE_TYPES_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_TANK_SHAPE_TYPES_SUCCESS:
      return { ...state, fetching: false, tankShape: action.tankShape };
    case GET_TANK_SHAPE_TYPES_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET TANK MODELS //
    case GET_TANK_MODELS_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_TANK_MODELS_SUCCESS:
      return { ...state, fetching: false, tankModels: action.tankModels };
    case GET_TANK_MODELS_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? GET SINGLE TANK MODELS //
    case GET_SINGLE_TANK_MODEL_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_SINGLE_TANK_MODEL_SUCCESS:
      return { ...state, fetching: false, tankModel: action.tankModel };
    case GET_SINGLE_TANK_MODEL_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? LOAD SINGLE TANK MODEL IN AN EDIT FORM //
    case LOAD_SINGLE_TANK_MODELS_REQUEST: {
      return {
        ...state,
        initialFetchDone: false,
        fetching: true,
        error: null
      };
    }
    case LOAD_SINGLE_TANK_MODELS_SUCCESS:
      return {
        ...state,
        initialFetchDone: true,
        fetching: false
      };
    case LOAD_SINGLE_TANK_MODELS_FAILURE:
      return {
        ...state,
        initialFetchDone: false,
        fetching: false,
        error: action.error
      };
    //? ADD TANK MODEL //
    case ADD_TANK_MODEL_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case ADD_TANK_MODEL_SUCCESS:
      return { ...state, fetching: false, tankModel: action.tankModel };
    case ADD_TANK_MODEL_FAILURE:
      return {
        ...state,
        fetching: false,
        tankModel: null,
        error: action.error
      };
    //? UPDATE TANK MODEL //
    case UPDATE_TANK_MODEL_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case UPDATE_TANK_MODEL_SUCCESS:
      return { ...state, fetching: false, tankModel: action.tankModel };
    case UPDATE_TANK_MODEL_FAILURE:
      return {
        ...state,
        fetching: false,
        tankModel: null,
        error: action.error
      };
    //? DELETE TANK MODEL //
    case DELETE_TANK_MODEL_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case DELETE_TANK_MODEL_SUCCESS:
      return { ...state, fetching: false };
    case DELETE_TANK_MODEL_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    //? GET TANK MODELS FOR ONE TANK SERIAL //
    case GET_TANK_MODELS_FOR_ONE_SERIE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_TANK_MODELS_FOR_ONE_SERIE_SUCCESS:
      return { ...state, fetching: false, tankModels: action.tankModels };
    case GET_TANK_MODELS_FOR_ONE_SERIE_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? ADD TANK MODEL //
    case ADD_TANK_MODEL_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case ADD_TANK_MODEL_SUCCESS:
      return { ...state, fetching: false, tankModel: action };
    case ADD_TANK_MODEL_FAILURE:
      return {
        ...state,
        fetching: false,
        tankModel: null,
        error: action.error
      };
    //? CALC VOLUME MODEL (ADD) //
    case CALC_VOLUME_TANK_MODELS_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case CALC_VOLUME_TANK_MODELS_SUCCESS:
      return { ...state, fetching: false, calcVolumeModelAdd: action.volume };
    case CALC_VOLUME_TANK_MODELS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    //? CALC VOLUME MODEL AGINSTALLER  //
    case CALC_VOLUME_TANK_MODELS_AGINSTALLER_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case CALC_VOLUME_TANK_MODELS_AGINSTALLER_SUCCESS:
      return {
        ...state,
        fetching: false,
        calcVolumeModelAginstaller: action.volume
      };
    case CALC_VOLUME_TANK_MODELS_AGINSTALLER_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    //? GET TANK CALIBRATIONS //
    case GET_TANK_CALIBRATIONS_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_TANK_CALIBRATIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        tankCalibrations: action.tankCalibrations
      };
    case GET_TANK_CALIBRATIONS_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? CALC VMIN VMAX GAUGE //
    case CALC_VMIN_VMAX_GAUGE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case CALC_VMIN_VMAX_GAUGE_SUCCESS:
      return {
        ...state,
        fetching: false,
        calcVminVmaxGauge: action.calc
      };
    case CALC_VMIN_VMAX_GAUGE_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? CALC DEFAULT VMIN GAUGE //
    case CALC_DEFAULT_VMIN_GAUGE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case CALC_DEFAULT_VMIN_GAUGE_SUCCESS:
      return {
        ...state,
        fetching: false,
        calcDefaultVminGauge: action.calc
      };
    case CALC_DEFAULT_VMIN_GAUGE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET SINGLE TANK CALIBRATION //
    case GET_SINGLE_TANK_CALIBRATION_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_SINGLE_TANK_CALIBRATION_SUCCESS:
      return {
        ...state,
        fetching: false,
        singleTankCalibration: action.tankCalibration
      };
    case GET_SINGLE_TANK_CALIBRATION_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET TANKS CALIBRATIONS FOR ONE TANK MODEL //
    case GET_TANK_CALIBRATIONS_FOR_ONE_MODEL_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_TANK_CALIBRATIONS_FOR_ONE_MODEL_SUCCESS:
      return {
        ...state,
        fetching: false,
        tankCalibrations: action.tankCalibrations
      };
    case GET_TANK_CALIBRATIONS_FOR_ONE_MODEL_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? ADD TANK CALIBRATION //
    case ADD_TANK_CALIBRATION_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case ADD_TANK_CALIBRATION_SUCCESS:
      return {
        ...state,
        fetching: false,
        tankCalibration: action.tankCalibration
      };
    case ADD_TANK_CALIBRATION_FAILURE:
      return {
        ...state,
        fetching: false,
        tankCalibration: null,
        error: action.error
      };
    //? UPDATE TANK CALIBRATION //
    case UPDATE_TANK_CALIBRATION_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case UPDATE_TANK_CALIBRATION_SUCCESS:
      return { ...state, fetching: false, tankCalibration: action };
    case UPDATE_TANK_CALIBRATION_FAILURE:
      return {
        ...state,
        fetching: false,
        tankCalibration: null,
        error: action.error
      };

    //? GET RECOMMENDED GAUGE TYPES //
    case GET_RECOMMENDED_GAUGE_TYPES_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_RECOMMENDED_GAUGE_TYPES_SUCCESS:
      return {
        ...state,
        fetching: false,
        recommendedGaugeTypes: action.recommendedGaugeTypes
      };
    case GET_RECOMMENDED_GAUGE_TYPES_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET SINGLE RECOMMENDED GAUGE TYPE //
    case GET_SINGLE_RECOMMENDED_GAUGE_TYPE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_SINGLE_RECOMMENDED_GAUGE_TYPE_SUCCESS:
      return {
        ...state,
        fetching: false,
        singleRecommendedGaugeType: action.recommendedGaugeType
      };
    case GET_SINGLE_RECOMMENDED_GAUGE_TYPE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET TCD FOR ONE TANK CALIBRATION //
    case GET_TCD_FOR_ONE_TANK_CALIBRATION_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_TCD_FOR_ONE_TANK_CALIBRATION_SUCCESS:
      return {
        ...state,
        fetching: false,
        TCDs: action.TCDs
      };
    case GET_TCD_FOR_ONE_TANK_CALIBRATION_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? START CALC TCD //
    case START_CALC_TCD_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case START_CALC_TCD_SUCCESS:
      return {
        ...state,
        fetching: false,
        TCDs: action.TCDs
      };
    case START_CALC_TCD_FAILURE:
      return { ...state, fetching: false, error: action.error };

    default:
      return state;
    //? GET ALL TANK SERIES WITH TANK MODEL ASSOCIATED //
    case GET_ALL_TANK_SERIE_WITH_ASSOCIATED_MODELS_REQUEST: {
      return {
        ...state,
        initialFetchSeriesWithAssociatedModels: false,
        fetching: true,
        error: null
      };
    }
    case GET_ALL_TANK_SERIE_WITH_ASSOCIATED_MODELS_SUCCESS:
      return {
        ...state,
        initialFetchSeriesWithAssociatedModels: true,
        fetching: false
      };
    case GET_ALL_TANK_SERIE_WITH_ASSOCIATED_MODELS_FAILURE:
      return {
        ...state,
        initialFetchSeriesWithAssociatedModels: false,
        fetching: false,
        error: action.error
      };

    //? GET TANK BRANDS //
    case GET_TANK_BRANDS_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_TANK_BRANDS_SUCCESS:
      return { ...state, fetching: false, tankBrands: action.tankBrands };
    case GET_TANK_BRANDS_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET TANK MATERIAL TYPES //
    case GET_TANK_MATERIAL_TYPES_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_TANK_MATERIAL_TYPES_SUCCESS:
      return { ...state, fetching: false, tankMaterial: action.tankMaterial };
    case GET_TANK_MATERIAL_TYPES_FAILURE:
      return { ...state, fetching: false, error: action.error };
  }
};
