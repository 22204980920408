import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import {
  GET_VEHICLE_ACTIVITY_REQUEST,
  GET_VEHICLE_ACTIVITY_SUCCESS,
  GET_VEHICLE_ACTIVITY_FAILURE,
  GET_VEHICLE_ACTIVITY_DETAILS_REQUEST,
  GET_VEHICLE_ACTIVITY_DETAILS_SUCCESS,
  GET_VEHICLE_ACTIVITY_DETAILS_FAILURE,
  GET_DRIVER_ACTIVITY_REQUEST,
  GET_DRIVER_ACTIVITY_SUCCESS,
  GET_DRIVER_ACTIVITY_FAILURE
} from 'constants/ActionTypes';
import { showMessage } from 'actions/Global';
import { API_URL } from '../constants/globalConstants';
import axios from 'axios';

export function* watchergetVehicleActivities() {
  yield takeEvery(GET_VEHICLE_ACTIVITY_REQUEST, workergetVehicleActivities);
}

export function* watchergetVehicleActivityDetails() {
  yield takeEvery(
    GET_VEHICLE_ACTIVITY_DETAILS_REQUEST,
    workergetVehicleActivityDetails
  );
}

export function* watchergetDriverActivities() {
  yield takeEvery(GET_DRIVER_ACTIVITY_REQUEST, workergetDriverActivities);
}

//? récupère tous les trips pour une période donnée
const getVehicleActivities = async (token, infos) => {
  let url;

  if (infos.truckId) {
    url = `${API_URL}vehicles/${
      infos.truckId
    }/activity/vehicle_activity?period=${encodeURIComponent(
      JSON.stringify({
        start: infos.startDateWithTime,
        end: infos.endDateWithTime
      })
    )}&locations=true&addresses=false&`;
  } else {
    url = `${API_URL}vehicles/vehicle_activity?period=${encodeURIComponent(
      JSON.stringify({
        start: infos.startDateWithTime,
        end: infos.endDateWithTime
      })
    )}&locations=false&addresses=false&customerId=${infos.customerId}&`;

    if (infos.vehicleGroupIds) {
      infos.vehicleGroupIds.map(vehicleGroupId => {
        url += `vehicleGroupId=${vehicleGroupId}&`;
      });
    }
    if (infos.siteIds) {
      infos.siteIds.map(siteId => {
        url += `vehicleGroupId=${siteId}&`;
      });
    }
    if (infos.vehicleIds) {
      infos.vehicleIds.map(vehicleId => {
        url += `vehicleId=${vehicleId}&`;
      });
    }
  }

  if (infos.type) {
    url += `type=${infos.type}&`;
  }

  console.log(url);

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

//? récupère tous les trips pour une période donnée sans donner les locations de chaque points
const getVehicleActivityDetails = async (token, infos) => {
  let url = `${API_URL}vehicles/${
    infos.truckId
  }/report/locations?period=${encodeURIComponent(
    JSON.stringify({
      start: infos.startDateWithTime,
      end: infos.endDateWithTime
    })
  )}&addresses=true`;
  console.log(url);

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

//? récupère tous les trips pour une période donnée
const getDriverActivities = async (token, infos) => {
  let url = `${API_URL}drivers/${
    infos.driverId
  }/activity/vehicle_activity?period=${encodeURIComponent(
    JSON.stringify({
      start: infos.startDateWithTime,
      end: infos.endDateWithTime
    })
  )}&locations=true&addresses=false`;

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

function* workergetVehicleActivities(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getVehicleActivities, token, action.payload);
    const trips = response.data;

    yield put({ type: GET_VEHICLE_ACTIVITY_SUCCESS, trips });
  } catch (error) {
    yield put({ type: GET_VEHICLE_ACTIVITY_FAILURE, error });
  }
}

function* workergetVehicleActivityDetails(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getVehicleActivityDetails,
      token,
      action.payload
    );
    const tripDetails = response.data;

    yield put({ type: GET_VEHICLE_ACTIVITY_DETAILS_SUCCESS, tripDetails });
  } catch (error) {
    yield put({ type: GET_VEHICLE_ACTIVITY_DETAILS_FAILURE, error });
  }
}

function* workergetDriverActivities(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getDriverActivities, token, action.payload);
    const trips = response.data;

    yield put({ type: GET_DRIVER_ACTIVITY_SUCCESS, trips });
  } catch (error) {
    yield put({ type: GET_DRIVER_ACTIVITY_FAILURE, error });
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchergetVehicleActivities),
    fork(watchergetVehicleActivityDetails),
    fork(watchergetDriverActivities)
  ]);
}
