import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  GET_ALL_DRIVER_ECO_PROFILES_REQUEST,
  GET_ALL_DRIVER_ECO_PROFILES_SUCCESS,
  GET_ALL_DRIVER_ECO_PROFILES_FAILURE,
  GET_ALL_VEHICLE_ECO_PROFILES_REQUEST,
  GET_ALL_VEHICLE_ECO_PROFILES_SUCCESS,
  GET_ALL_VEHICLE_ECO_PROFILES_FAILURE,
  GET_SINGLE_ECO_PROFILE_REQUEST,
  GET_SINGLE_ECO_PROFILE_SUCCESS,
  GET_SINGLE_ECO_PROFILE_FAILURE,
  GET_SINGLE_VEHICLE_ECO_PROFILE_REQUEST,
  GET_SINGLE_VEHICLE_ECO_PROFILE_SUCCESS,
  GET_SINGLE_VEHICLE_ECO_PROFILE_FAILURE,
  ADD_ECO_PROFILE_REQUEST,
  ADD_ECO_PROFILE_SUCCESS,
  ADD_ECO_PROFILE_FAILURE,
  ADD_VEHICLE_ECO_PROFILE_REQUEST,
  ADD_VEHICLE_ECO_PROFILE_SUCCESS,
  ADD_VEHICLE_ECO_PROFILE_FAILURE,
  UPDATE_ECO_PROFILE_REQUEST,
  UPDATE_ECO_PROFILE_SUCCESS,
  UPDATE_ECO_PROFILE_FAILURE,
  UPDATE_VEHICLE_ECO_PROFILE_REQUEST,
  UPDATE_VEHICLE_ECO_PROFILE_SUCCESS,
  UPDATE_VEHICLE_ECO_PROFILE_FAILURE,
  GET_ECO_PROFILE_SCORES_REQUEST,
  GET_ECO_PROFILE_SCORES_SUCCESS,
  GET_ECO_PROFILE_SCORES_FAILURE,
  GET_VEHICLE_ECO_PROFILE_SCORES_REQUEST,
  GET_VEHICLE_ECO_PROFILE_SCORES_SUCCESS,
  GET_VEHICLE_ECO_PROFILE_SCORES_FAILURE
} from 'constants/ActionTypes';
import { showMessage } from 'actions/Global';
import { API_URL } from '../constants/globalConstants';
import axios from 'axios';

export function* watcherGetDriverEcoProfiles() {
  yield takeLatest(
    GET_ALL_DRIVER_ECO_PROFILES_REQUEST,
    workerGetDriverEcoProfiles
  );
}

export function* watcherGetVehicleEcoProfiles() {
  yield takeLatest(
    GET_ALL_VEHICLE_ECO_PROFILES_REQUEST,
    workerGetVehicleEcoProfiles
  );
}

export function* watcherGetSingleEcoProfile() {
  yield takeLatest(GET_SINGLE_ECO_PROFILE_REQUEST, workerGetSingleEcoProfile);
}

export function* watcherGetSingleVehicleEcoProfile() {
  yield takeLatest(
    GET_SINGLE_VEHICLE_ECO_PROFILE_REQUEST,
    workerGetSingleVehicleEcoProfile
  );
}

export function* watcherAddEcoProfile() {
  yield takeLatest(ADD_ECO_PROFILE_REQUEST, workerAddEcoProfile);
}

export function* watcherAddVehicleEcoProfile() {
  yield takeLatest(ADD_VEHICLE_ECO_PROFILE_REQUEST, workerAddVehicleEcoProfile);
}

export function* watcherUpdateEcoProfile() {
  yield takeLatest(UPDATE_ECO_PROFILE_REQUEST, workerUpdateEcoProfile);
}

export function* watcherUpdateVehicleEcoProfile() {
  yield takeLatest(
    UPDATE_VEHICLE_ECO_PROFILE_REQUEST,
    workerUpdateVehicleEcoProfile
  );
}

export function* watcherGetEcoProfileScores() {
  yield takeLatest(GET_ECO_PROFILE_SCORES_REQUEST, workerGetEcoProfileScores);
}

export function* watcherGetVehicleEcoProfileScores() {
  yield takeLatest(
    GET_VEHICLE_ECO_PROFILE_SCORES_REQUEST,
    workerGetVehicleEcoProfileScores
  );
}

const getDriverEcoProfiles = async (token, { customerId, offset, limit }) => {
  let url = `${API_URL}driver_eco_profiles?`;
  if (customerId) url += 'customerId=' + customerId + '&';

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

const getVehicleEcoProfiles = async (token, { customerId, offset, limit }) => {
  let url = `${API_URL}vehicle_eco_profiles?`;
  if (customerId) url += 'customerId=' + customerId + '&';

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

const getSingleEcoProfile = async (token, ecoProfileId) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}driver_eco_profiles/` + ecoProfileId
  });
};

const getSingleVehicleEcoProfile = async (token, ecoProfileId) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}vehicle_eco_profiles/` + ecoProfileId
  });
};

const addEcoProfile = async (token, ecoProfile) => {
  let url = `${API_URL}driver_eco_profiles`;
  return axios({
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: {
      name: ecoProfile.name,
      comment: ecoProfile.comment,
      maxBadScore: ecoProfile.maxBadScore,
      minGoodScore: ecoProfile.minGoodScore,
      maxScore: ecoProfile.maxScore,
      overdriveWeight: ecoProfile.overdriveWeight,
      overdriveMin: ecoProfile.overdriveMin,
      overdriveMax: ecoProfile.overdriveMax,
      overspeedWeight: ecoProfile.overspeedWeight,
      overspeedMin: ecoProfile.overspeedMin,
      overspeedMax: ecoProfile.overspeedMax,
      tmavaWeight: ecoProfile.tmavaWeight,
      tmavaMin: ecoProfile.tmavaMin,
      tmavaMax: ecoProfile.tmavaMax,
      urgencyBreakWeight: ecoProfile.urgencyBreakWeight,
      urgencyBreakMin: ecoProfile.urgencyBreakMin,
      urgencyBreakMax: ecoProfile.urgencyBreakMax,
      coastingWeight: ecoProfile.coastingWeight,
      coastingMin: ecoProfile.coastingMin,
      coastingMax: ecoProfile.coastingMax,
      speedStabilityWeight: ecoProfile.speedStabilityWeight,
      speedStabilityMin: ecoProfile.speedStabilityMin,
      speedStabilityMax: ecoProfile.speedStabilityMax,
      driverIds: ecoProfile.driverIds
    },
    url: url
  });
};

const addVehicleEcoProfile = async (token, ecoProfile) => {
  console.log(ecoProfile);

  let url = `${API_URL}vehicle_eco_profiles`;
  return axios({
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: {
      name: ecoProfile.name,
      comment: ecoProfile.comment,
      maxBadScore: ecoProfile.maxBadScore,
      minGoodScore: ecoProfile.minGoodScore,
      maxScore: ecoProfile.maxScore,
      overdriveWeight: ecoProfile.overdriveWeight,
      overdriveMin: ecoProfile.overdriveMin,
      overdriveMax: ecoProfile.overdriveMax,
      overspeedWeight: ecoProfile.overspeedWeight,
      overspeedMin: ecoProfile.overspeedMin,
      overspeedMax: ecoProfile.overspeedMax,
      tmavaWeight: ecoProfile.tmavaWeight,
      tmavaMin: ecoProfile.tmavaMin,
      tmavaMax: ecoProfile.tmavaMax,
      urgencyBreakWeight: ecoProfile.urgencyBreakWeight,
      urgencyBreakMin: ecoProfile.urgencyBreakMin,
      urgencyBreakMax: ecoProfile.urgencyBreakMax,
      coastingWeight: ecoProfile.coastingWeight,
      coastingMin: ecoProfile.coastingMin,
      coastingMax: ecoProfile.coastingMax,
      speedStabilityWeight: ecoProfile.speedStabilityWeight,
      speedStabilityMin: ecoProfile.speedStabilityMin,
      speedStabilityMax: ecoProfile.speedStabilityMax,
      vehicleIds: ecoProfile.vehicleIds
    },
    url: url
  });
};

const updateEcoProfile = async (token, infos) => {
  let url = `${API_URL}driver_eco_profiles/${infos.ecoProfileId}`;
  return axios({
    method: 'put',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: {
      name: infos.name,
      comment: infos.comment,
      maxBadScore: infos.maxBadScore,
      minGoodScore: infos.minGoodScore,
      maxScore: infos.maxScore,
      overdriveWeight: infos.overdriveWeight,
      overdriveMin: infos.overdriveMin,
      overdriveMax: infos.overdriveMax,
      overspeedWeight: infos.overspeedWeight,
      overspeedMin: infos.overspeedMin,
      overspeedMax: infos.overspeedMax,
      tmavaWeight: infos.tmavaWeight,
      tmavaMin: infos.tmavaMin,
      tmavaMax: infos.tmavaMax,
      urgencyBreakWeight: infos.urgencyBreakWeight,
      urgencyBreakMin: infos.urgencyBreakMin,
      urgencyBreakMax: infos.urgencyBreakMax,
      coastingWeight: infos.coastingWeight,
      coastingMin: infos.coastingMin,
      coastingMax: infos.coastingMax,
      speedStabilityWeight: infos.speedStabilityWeight,
      speedStabilityMin: infos.speedStabilityMin,
      speedStabilityMax: infos.speedStabilityMax,
      driverIds: infos.driverIds
    },
    url: url
  });
};

const updateVehicleEcoProfile = async (token, infos) => {
  let url = `${API_URL}vehicle_eco_profiles/${infos.ecoProfileId}`;
  return axios({
    method: 'put',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: {
      name: infos.name,
      comment: infos.comment,
      maxBadScore: infos.maxBadScore,
      minGoodScore: infos.minGoodScore,
      maxScore: infos.maxScore,
      overdriveWeight: infos.overdriveWeight,
      overdriveMin: infos.overdriveMin,
      overdriveMax: infos.overdriveMax,
      overspeedWeight: infos.overspeedWeight,
      overspeedMin: infos.overspeedMin,
      overspeedMax: infos.overspeedMax,
      tmavaWeight: infos.tmavaWeight,
      tmavaMin: infos.tmavaMin,
      tmavaMax: infos.tmavaMax,
      urgencyBreakWeight: infos.urgencyBreakWeight,
      urgencyBreakMin: infos.urgencyBreakMin,
      urgencyBreakMax: infos.urgencyBreakMax,
      coastingWeight: infos.coastingWeight,
      coastingMin: infos.coastingMin,
      coastingMax: infos.coastingMax,
      speedStabilityWeight: infos.speedStabilityWeight,
      speedStabilityMin: infos.speedStabilityMin,
      speedStabilityMax: infos.speedStabilityMax,
      vehicleIds: infos.vehicleIds
    },
    url: url
  });
};

const getEcoProfileScores = async (
  token,
  { ecoProfileId, startDate, endDate }
) => {
  let url = `${API_URL}driver_eco_profiles/${ecoProfileId}/eco-profile-scores?`;
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  }
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

const getVehicleEcoProfileScores = async (
  token,
  { ecoProfileId, startDate, endDate }
) => {
  let url = `${API_URL}vehicle_eco_profiles/${ecoProfileId}/eco-profile-scores?`;
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  }
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

function* workerGetDriverEcoProfiles(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getDriverEcoProfiles, token, action.payload);
    const ecoProfilesList = response.data;

    yield put({ type: GET_ALL_DRIVER_ECO_PROFILES_SUCCESS, ecoProfilesList });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_ALL_DRIVER_ECO_PROFILES_FAILURE, error });
  }
}

function* workerGetVehicleEcoProfiles(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getVehicleEcoProfiles, token, action.payload);
    const ecoProfilesList = response.data;

    console.log('ici', ecoProfilesList);

    yield put({ type: GET_ALL_VEHICLE_ECO_PROFILES_SUCCESS, ecoProfilesList });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_ALL_VEHICLE_ECO_PROFILES_FAILURE, error });
  }
}

function* workerGetSingleEcoProfile(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getSingleEcoProfile, token, action.payload);
    const ecoProfile = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_SINGLE_ECO_PROFILE_SUCCESS, ecoProfile });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_SINGLE_ECO_PROFILE_FAILURE, error });
  }
}

function* workerGetSingleVehicleEcoProfile(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getSingleVehicleEcoProfile,
      token,
      action.payload
    );
    const ecoProfile = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_SINGLE_VEHICLE_ECO_PROFILE_SUCCESS, ecoProfile });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_SINGLE_VEHICLE_ECO_PROFILE_FAILURE, error });
  }
}

function* workerAddEcoProfile(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(addEcoProfile, token, action.payload);
    const ecoProfileAdded = response.data;

    // dispatch a success action to the store with the new eco profile
    yield put({ type: ADD_ECO_PROFILE_SUCCESS, ecoProfileAdded });
    yield put(
      this.props.showMessage({
        title: 'Information',
        message: 'Eco profile created !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center'
      })
    );
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ADD_ECO_PROFILE_FAILURE, error });
    yield put(
      this.props.showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center'
      })
    );
  }
}

function* workerAddVehicleEcoProfile(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(addVehicleEcoProfile, token, action.payload);
    const ecoProfileAdded = response.data;

    // dispatch a success action to the store with the new eco profile
    yield put({ type: ADD_VEHICLE_ECO_PROFILE_SUCCESS, ecoProfileAdded });
    yield put(
      this.props.showMessage({
        title: 'Information',
        message: 'Eco profile created !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center'
      })
    );
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ADD_VEHICLE_ECO_PROFILE_FAILURE, error });
    yield put(
      this.props.showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center'
      })
    );
  }
}

function* workerUpdateEcoProfile(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(updateEcoProfile, token, action.payload);
    const ecoProfileUpdated = response.data;

    // dispatch a success action to the store with the new eco profile
    yield put({ type: UPDATE_ECO_PROFILE_SUCCESS, ecoProfileUpdated });
    yield put(
      this.props.showMessage({
        title: 'Information',
        message: 'Eco profile updated !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center'
      })
    );
  } catch (error) {
    console.log(error);
    // dispatch a failure action to the store with the error
    yield put({ type: UPDATE_ECO_PROFILE_FAILURE, error });
    yield put(
      this.props.showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center'
      })
    );
  }
}

function* workerUpdateVehicleEcoProfile(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(updateVehicleEcoProfile, token, action.payload);
    const ecoProfileUpdated = response.data;

    // dispatch a success action to the store with the new eco profile
    yield put({ type: UPDATE_VEHICLE_ECO_PROFILE_SUCCESS, ecoProfileUpdated });
    yield put(
      this.props.showMessage({
        title: 'Information',
        message: 'Eco profile updated !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center'
      })
    );
  } catch (error) {
    console.log(error);
    // dispatch a failure action to the store with the error
    yield put({ type: UPDATE_VEHICLE_ECO_PROFILE_FAILURE, error });
    yield put(
      this.props.showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center'
      })
    );
  }
}

function* workerGetEcoProfileScores(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getEcoProfileScores, token, action.payload);
    const ecoProfileScores = response.data;

    // dispatch a success action to the store with the eco profile scores
    yield put({ type: GET_ECO_PROFILE_SCORES_SUCCESS, ecoProfileScores });
  } catch (error) {
    console.log(error);
    // dispatch a failure action to the store with the error
    yield put({ type: GET_ECO_PROFILE_SCORES_FAILURE, error });
  }
}

function* workerGetVehicleEcoProfileScores(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getVehicleEcoProfileScores,
      token,
      action.payload
    );
    const ecoProfileScores = response.data;

    // dispatch a success action to the store with the eco profile scores
    yield put({
      type: GET_VEHICLE_ECO_PROFILE_SCORES_SUCCESS,
      ecoProfileScores
    });
  } catch (error) {
    console.log(error);
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLE_ECO_PROFILE_SCORES_FAILURE, error });
  }
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetDriverEcoProfiles),
    fork(watcherGetVehicleEcoProfiles),
    fork(watcherGetSingleEcoProfile),
    fork(watcherGetSingleVehicleEcoProfile),
    fork(watcherAddEcoProfile),
    fork(watcherAddVehicleEcoProfile),
    fork(watcherUpdateEcoProfile),
    fork(watcherUpdateVehicleEcoProfile),
    fork(watcherGetEcoProfileScores),
    fork(watcherGetVehicleEcoProfileScores)
  ]);
}
