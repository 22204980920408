import {
  GET_ALERTS_SINGLE_DRIVER_FAILURE,
  GET_ALERTS_SINGLE_DRIVER_REQUEST,
  GET_ALERTS_SINGLE_DRIVER_SUCCESS,
  GET_ALERTS_SINGLE_VEHICLE_FAILURE,
  GET_ALERTS_SINGLE_VEHICLE_REQUEST,
  GET_ALERTS_SINGLE_VEHICLE_SUCCESS,
  GET_ALERTS_SINGLE_ZONE_FAILURE,
  GET_ALERTS_SINGLE_ZONE_REQUEST,
  GET_ALERTS_SINGLE_ZONE_SUCCESS,
  GET_ALL_ALERTS_FAILURE,
  GET_ALL_ALERTS_REQUEST,
  GET_ALL_ALERTS_SUCCESS
} from '../constants/ActionTypes';

const INIT_STATE = {
  fetching: false,
  alertsListByVehicle: [],
  alertsListByDriver: [],
  alertsListByZone: [],
  error: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //? GET ALL ALERTS //
    case GET_ALL_ALERTS_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_ALL_ALERTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        alertsList: action.alertsList
      };
    case GET_ALL_ALERTS_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET ALERTS FOR SINGLE VEHICLE //
    case GET_ALERTS_SINGLE_VEHICLE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_ALERTS_SINGLE_VEHICLE_SUCCESS:
      return {
        ...state,
        fetching: false,
        alertsListByVehicle: action.alertsList
      };
    case GET_ALERTS_SINGLE_VEHICLE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET ALERTS FOR SINGLE DRIVER //
    case GET_ALERTS_SINGLE_DRIVER_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_ALERTS_SINGLE_DRIVER_SUCCESS:
      return {
        ...state,
        fetching: false,
        alertsListByDriver: action.alertsList
      };
    case GET_ALERTS_SINGLE_DRIVER_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET ALERTS FOR SINGLE ZONE //
    case GET_ALERTS_SINGLE_ZONE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }

    case GET_ALERTS_SINGLE_ZONE_SUCCESS:
      return { ...state, fetching: false, alertsListByZone: action.alertsList };
    case GET_ALERTS_SINGLE_ZONE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    default:
      return state;
  }
};
