import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  GET_AGSUP_ADA_CUSTOMERS_DASHBOARD_REPORT_FAILURE,
  GET_AGSUP_ADA_CUSTOMERS_DASHBOARD_REPORT_REQUEST,
  GET_AGSUP_ADA_CUSTOMERS_DASHBOARD_REPORT_SUCCESS,
  GET_AGSUP_ADA_CUSTOMER_TREE_REQUEST,
  GET_AGSUP_ADA_CUSTOMER_TREE_SUCCESS,
  GET_AGSUP_ADA_CUSTOMER_TREE_FAILURE,
  GET_AGSUP_GENERAL_DASHBOARD_REPORT_FAILURE,
  GET_AGSUP_GENERAL_DASHBOARD_REPORT_REQUEST,
  GET_AGSUP_GENERAL_DASHBOARD_REPORT_SUCCESS,
  GET_AGSUP_DASHBOARD_SINGLE_SITE_REQUEST,
  GET_AGSUP_DASHBOARD_SINGLE_SITE_SUCCESS,
  GET_AGSUP_DASHBOARD_SINGLE_SITE_FAILURE,
  GET_CUSTOMER_DRIVER_ACTIVITY_STATS_REPORT_FAILURE,
  GET_CUSTOMER_DRIVER_ACTIVITY_STATS_REPORT_REQUEST,
  GET_CUSTOMER_DRIVER_ACTIVITY_STATS_REPORT_SUCCESS,
  GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_FAILURE,
  GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_FAILURE,
  GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST,
  GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_SUCCESS,
  GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_REQUEST,
  GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_SUCCESS,
  GET_FUEL_CONSUMPTION_REPORT_FAILURE,
  GET_FUEL_CONSUMPTION_REPORT_MULTIPLE_FAILURE,
  GET_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST,
  GET_FUEL_CONSUMPTION_REPORT_MULTIPLE_SUCCESS,
  GET_FUEL_CONSUMPTION_REPORT_REQUEST,
  GET_FUEL_CONSUMPTION_REPORT_SUCCESS,
  GET_PERMANENT_STOCK_RATE_REPORT_FAILURE,
  GET_PERMANENT_STOCK_RATE_REPORT_REQUEST,
  GET_PERMANENT_STOCK_RATE_REPORT_SUCCESS,
  GET_REFUEL_VEHICLES_REPORT_FAILURE,
  GET_REFUEL_VEHICLES_REPORT_REQUEST,
  GET_REFUEL_VEHICLES_REPORT_SUCCESS,
  GET_THEFT_VEHICLES_REPORT_FAILURE,
  GET_THEFT_VEHICLES_REPORT_REQUEST,
  GET_THEFT_VEHICLES_REPORT_SUCCESS,
  GET_THEFT_REPORT_FAILURE,
  GET_THEFT_REPORT_REQUEST,
  GET_THEFT_REPORT_SUCCESS,
  GET_TMAVA_REPARTITION_REPORT_FAILURE,
  GET_TMAVA_REPARTITION_REPORT_REQUEST,
  GET_TMAVA_REPARTITION_REPORT_SUCCESS,
  GET_TOP_DRIVER_TMAVA_REPORT_FAILURE,
  GET_TOP_DRIVER_TMAVA_REPORT_REQUEST,
  GET_TOP_DRIVER_TMAVA_REPORT_SUCCESS,
  GET_TOP_LOWEST_BATTERY_VEHICLES_REPORT_FAILURE,
  GET_TOP_LOWEST_BATTERY_VEHICLES_REPORT_REQUEST,
  GET_TOP_LOWEST_BATTERY_VEHICLES_REPORT_SUCCESS,
  GET_TOP_LOWEST_FUEL_VEHICLES_REPORT_FAILURE,
  GET_TOP_LOWEST_FUEL_VEHICLES_REPORT_REQUEST,
  GET_TOP_LOWEST_FUEL_VEHICLES_REPORT_SUCCESS,
  GET_TOP_OVERSPEED_VEHICLES_REPORT_FAILURE,
  GET_TOP_OVERSPEED_VEHICLES_REPORT_REQUEST,
  GET_TOP_OVERSPEED_VEHICLES_REPORT_SUCCESS,
  GET_TOP_VEHICLE_TMAVA_REPORT_FAILURE,
  GET_TOP_VEHICLE_TMAVA_REPORT_REQUEST,
  GET_TOP_VEHICLE_TMAVA_REPORT_SUCCESS,
  GET_TOTAL_ACTIVITY_DAYS_REPORT_FAILURE,
  GET_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST,
  GET_TOTAL_ACTIVITY_DAYS_REPORT_SUCCESS,
  GET_TOTAL_DISTANCE_REPORT_FAILURE,
  GET_TOTAL_DISTANCE_REPORT_MULTIPLE_FAILURE,
  GET_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST,
  GET_TOTAL_DISTANCE_REPORT_MULTIPLE_SUCCESS,
  GET_TOTAL_DISTANCE_REPORT_REQUEST,
  GET_TOTAL_DISTANCE_REPORT_SUCCESS,
  GET_TOTAL_FUEL_REPORT_FAILURE,
  GET_TOTAL_FUEL_REPORT_REQUEST,
  GET_TOTAL_FUEL_REPORT_SUCCESS,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_MULTIPLE_FAILURE,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_MULTIPLE_REQUEST,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_MULTIPLE_SUCCESS,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_FAILURE,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REQUEST,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_SUCCESS,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REFERENCE_FAILURE,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REFERENCE_REQUEST,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REFERENCE_SUCCESS,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_DASHBOARD_TREND_FAILURE,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_DASHBOARD_TREND_REQUEST,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_DASHBOARD_TREND_SUCCESS,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_FOR_CUMULATIVE_TMAVA_FAILURE,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_FOR_CUMULATIVE_TMAVA_REQUEST,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_FOR_CUMULATIVE_TMAVA_SUCCESS,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_FAILURE,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_REQUEST,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_SUCCESS,
  GET_VEHICLE_DAILY_REPORT_FAILURE,
  GET_VEHICLE_DAILY_REPORT_REQUEST,
  GET_VEHICLE_DAILY_REPORT_SUCCESS,
  GET_VEHICLE_LIVE_REPORT_FAILURE,
  GET_VEHICLE_LIVE_REPORT_REQUEST,
  GET_VEHICLE_LIVE_REPORT_SUCCESS,
  GET_DRIVER_DAILY_REPORT_MULTIPLE_FAILURE,
  GET_DRIVER_DAILY_REPORT_MULTIPLE_REQUEST,
  GET_DRIVER_DAILY_REPORT_MULTIPLE_SUCCESS,
  GET_DRIVER_DAILY_REPORT_FAILURE,
  GET_DRIVER_DAILY_REPORT_REQUEST,
  GET_DRIVER_DAILY_REPORT_SUCCESS,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_FAILURE,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_REQUEST,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_SUCCESS,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_REFERENCE_FAILURE,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_REFERENCE_REQUEST,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_REFERENCE_SUCCESS,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_MULTIPLE_FAILURE,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_MULTIPLE_REQUEST,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_MULTIPLE_SUCCESS,
  GET_VEQ_TMAVA_STATS_REPORT_FAILURE,
  GET_VEQ_TMAVA_STATS_REPORT_REQUEST,
  GET_VEQ_TMAVA_STATS_REPORT_SUCCESS,
  GET_STABILITY_POINTS_REQUEST,
  GET_STABILITY_POINTS_SUCCESS,
  GET_STABILITY_POINTS_FAILURE,
  GET_TOP_FLOP_REPORT_FAILURE,
  GET_TOP_FLOP_REPORT_REQUEST,
  GET_TOP_FLOP_REPORT_SUCCESS,
  GET_REFUEL_OPTIMIZATION_REPORT_FAILURE,
  GET_REFUEL_OPTIMIZATION_REPORT_REQUEST,
  GET_REFUEL_OPTIMIZATION_REPORT_SUCCESS,
  GET_REFUEL_OPTIMIZATION_BY_VEHICLE_REPORT_FAILURE,
  GET_REFUEL_OPTIMIZATION_BY_VEHICLE_REPORT_REQUEST,
  GET_REFUEL_OPTIMIZATION_BY_VEHICLE_REPORT_SUCCESS,
  GET_ZONE_STAT_REPORT_FAILURE,
  GET_ZONE_STAT_REPORT_REQUEST,
  GET_ZONE_STAT_REPORT_SUCCESS,
  GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_FAILURE,
  GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_REQUEST,
  GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_SUCCESS,
  GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_MULTIPLE_FAILURE,
  GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_MULTIPLE_REQUEST,
  GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_MULTIPLE_SUCCESS
} from 'constants/ActionTypes';
import { API_URL } from 'constants/globalConstants';
import axios from 'axios';
import {
  GET_AGSUP_GENERAL_DASHBOARD_SITES_FAILURE,
  GET_AGSUP_GENERAL_DASHBOARD_SITES_REQUEST,
  GET_AGSUP_GENERAL_DASHBOARD_SITES_SUCCESS
} from '../constants/ActionTypes';

export function* watcherGetFuelConsumptionReport() {
  yield takeLatest(
    GET_FUEL_CONSUMPTION_REPORT_REQUEST,
    workerGetFuelConsumptionReport
  );
}

export function* watcherGetFuelConsumptionReportMultiple() {
  yield takeLatest(
    GET_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST,
    workerGetFuelConsumptionReportMultiple
  );
}

export function* watcherGetTotalFuelReport() {
  yield takeLatest(GET_TOTAL_FUEL_REPORT_REQUEST, workerGetTotalFuelReport);
}

export function* watcherGetTotalDistanceReport() {
  yield takeLatest(
    GET_TOTAL_DISTANCE_REPORT_REQUEST,
    workerGetTotalDistanceReport
  );
}

export function* watcherGetTotalDistanceReportMultiple() {
  yield takeLatest(
    GET_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST,
    workerGetTotalDistanceReportMultiple
  );
}

export function* watcherGetTotalActivityDaysReport() {
  yield takeLatest(
    GET_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST,
    workerGetTotalActivityDaysReport
  );
}

export function* watcherGetVehicleActivityStatsReport() {
  yield takeLatest(
    GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_REQUEST,
    workerGetVehicleActivityStatsReport
  );
}

export function* watcherGetVehicleActivityStatsReportMultiple() {
  yield takeLatest(
    GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST,
    workerGetVehicleActivityStatsReportMultiple
  );
}

export function* watcherGetDriverActivityStatsReport() {
  yield takeLatest(
    GET_CUSTOMER_DRIVER_ACTIVITY_STATS_REPORT_REQUEST,
    workerGetDriverActivityStatsReport
  );
}

export function* watcherGetPermanentStockRateReport() {
  yield takeLatest(
    GET_PERMANENT_STOCK_RATE_REPORT_REQUEST,
    workerGetPermanentStockRateReport
  );
}

export function* watcherGetTopLowestFuelVehiclesReport() {
  yield takeLatest(
    GET_TOP_LOWEST_FUEL_VEHICLES_REPORT_REQUEST,
    workerGetTopLowestFuelVehiclesReport
  );
}

export function* watcherGetTopLowestBatteryVehiclesReport() {
  yield takeLatest(
    GET_TOP_LOWEST_BATTERY_VEHICLES_REPORT_REQUEST,
    workerGetTopLowestBatteryVehiclesReport
  );
}

export function* watcherGetTopOverspeedVehiclesReport() {
  yield takeLatest(
    GET_TOP_OVERSPEED_VEHICLES_REPORT_REQUEST,
    workerGetTopOverspeedVehiclesReport
  );
}

export function* watcherGetVehicleDailyReport() {
  yield takeLatest(
    GET_VEHICLE_DAILY_REPORT_REQUEST,
    workerGetVehicleDailyReport
  );
}

export function* watcherGetVehicleDailyReportMultiple() {
  yield takeLatest(
    GET_VEHICLE_DAILY_REPORT_MULTIPLE_REQUEST,
    workerGetVehicleDailyReportMultiple
  );
}

export function* watcherGetDriverDailyReport() {
  yield takeLatest(GET_DRIVER_DAILY_REPORT_REQUEST, workerGetDriverDailyReport);
}

export function* watcherGetDriverDailyReportMultiple() {
  yield takeLatest(
    GET_DRIVER_DAILY_REPORT_MULTIPLE_REQUEST,
    workerGetDriverDailyReportMultiple
  );
}

export function* watcherGetDriverDailyReportByDriver() {
  yield takeLatest(
    GET_DRIVER_DAILY_REPORT_BY_DRIVER_REQUEST,
    workerGetDriverDailyReportByDriver
  );
}

export function* watcherGetDriverDailyReportByDriverReference() {
  yield takeLatest(
    GET_DRIVER_DAILY_REPORT_BY_DRIVER_REFERENCE_REQUEST,
    workerGetDriverDailyReportByDriverReference
  );
}

export function* watcherGetDriverDailyReportByDriverMultiple() {
  yield takeLatest(
    GET_DRIVER_DAILY_REPORT_BY_DRIVER_MULTIPLE_REQUEST,
    workerGetDriverDailyReportByDriverMultiple
  );
}

export function* watcherGetVehicleDailyReportMultipleDashboardTrend() {
  yield takeLatest(
    GET_VEHICLE_DAILY_REPORT_MULTIPLE_DASHBOARD_TREND_REQUEST,
    workerGetVehicleDailyReportMultipleDashboardTrend
  );
}

export function* watcherGetVehicleDailyReportMultipleForCumulativeTMAVA() {
  yield takeLatest(
    GET_VEHICLE_DAILY_REPORT_MULTIPLE_FOR_CUMULATIVE_TMAVA_REQUEST,
    workerGetVehicleDailyReportMultipleForCumulativeTMAVA
  );
}

export function* watcherGetTopVehicleTMAVAReport() {
  yield takeLatest(
    GET_TOP_VEHICLE_TMAVA_REPORT_REQUEST,
    workerGetTopVehicleTMAVAReport
  );
}

export function* watcherGetTopDriverTMAVAReport() {
  yield takeLatest(
    GET_TOP_DRIVER_TMAVA_REPORT_REQUEST,
    workerGetTopDriverTMAVAReport
  );
}

export function* watcherGetTMAVARepartitionReport() {
  yield takeLatest(
    GET_TMAVA_REPARTITION_REPORT_REQUEST,
    workerGetTMAVARepartitionReport
  );
}

export function* watcherGetVehicleDailyReportByVehicle() {
  yield takeLatest(
    GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REQUEST,
    workerGetVehicleDailyReportByVehicle
  );
}

export function* watcherGetVehicleDailyReportByVehicleReference() {
  yield takeLatest(
    GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REFERENCE_REQUEST,
    workerGetVehicleDailyReportByVehicleReference
  );
}

export function* watcherGetVehicleDailyReportByVehicleMultiple() {
  yield takeLatest(
    GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_MULTIPLE_REQUEST,
    workerGetVehicleDailyReportByVehicleMultiple
  );
}

export function* watcherGetVehicleLiveReport() {
  yield takeLatest(GET_VEHICLE_LIVE_REPORT_REQUEST, workerGetVehicleLiveReport);
}

export function* watcherGeneralDashboardReport() {
  yield takeLatest(
    GET_AGSUP_GENERAL_DASHBOARD_REPORT_REQUEST,
    workerGeneralDashboardReport
  );
}

export function* watcherGeneralDashboardSites() {
  yield takeLatest(
    GET_AGSUP_GENERAL_DASHBOARD_SITES_REQUEST,
    workerGeneralDashboardSites
  );
}

export function* watcherGeneralDashboardVehicles() {
  yield takeLatest(
    GET_AGSUP_DASHBOARD_SINGLE_SITE_REQUEST,
    workerGeneralDashboardVehicles
  );
}

export function* watcherAdaAllCustomersReport() {
  yield takeLatest(
    GET_AGSUP_ADA_CUSTOMERS_DASHBOARD_REPORT_REQUEST,
    workerAdaAllCustomersReport
  );
}

export function* watcherAdaCustomerTreeReport() {
  yield takeLatest(
    GET_AGSUP_ADA_CUSTOMER_TREE_REQUEST,
    workerAdaCustomerTreeReport
  );
}

export function* watcherGetRefuelVehiclesReport() {
  yield takeLatest(
    GET_REFUEL_VEHICLES_REPORT_REQUEST,
    workerGetRefuelVehiclesReport
  );
}

export function* watcherGetTheftVehiclesReport() {
  yield takeLatest(
    GET_THEFT_VEHICLES_REPORT_REQUEST,
    workerGetTheftVehiclesReport
  );
}

export function* watcherGetTheftReport() {
  yield takeLatest(GET_THEFT_REPORT_REQUEST, workerGetTheftReport);
}

export function* watcherGetVeqTmavaStatsReport() {
  yield takeLatest(
    GET_VEQ_TMAVA_STATS_REPORT_REQUEST,
    workerGetVeqTmavaStatsReport
  );
}

export function* watcherGetStabilityPoints() {
  yield takeLatest(GET_STABILITY_POINTS_REQUEST, workerGetStabilityPoints);
}

export function* watcherGetTopFlopReport() {
  yield takeLatest(GET_TOP_FLOP_REPORT_REQUEST, workerGetTopFlopReport);
}

export function* watcherGetRefuelOptimizationReport() {
  yield takeLatest(
    GET_REFUEL_OPTIMIZATION_REPORT_REQUEST,
    workerGetRefuelOptimizationReport
  );
}

export function* watcherGetRefuelOptimizationByVehicleReport() {
  yield takeLatest(
    GET_REFUEL_OPTIMIZATION_BY_VEHICLE_REPORT_REQUEST,
    workerGetRefuelOptimizationByVehicleReport
  );
}

export function* watcherGetZoneStatReport() {
  yield takeLatest(GET_ZONE_STAT_REPORT_REQUEST, workerGetZoneStatReport);
}

export function* watcherGetAverageInitialTankVolumePercentRefuelReport() {
  yield takeLatest(
    GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_REQUEST,
    workerGetAverageInitialTankVolumePercentRefuelReport
  );
}

export function* watcherGetAverageInitialTankVolumePercentRefuelReportMultiple() {
  yield takeLatest(
    GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_MULTIPLE_REQUEST,
    workerGetAverageInitialTankVolumePercentRefuelReportMultiple
  );
}

const getFuelConsumptionReport = async (
  token,
  {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    startDate,
    endDate,
    periods
  }
) => {
  let url = `${API_URL}customer/${customerId}/report/fuel_consumption?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  } else if (periods) {
    url += `&period=${encodeURIComponent(JSON.stringify(periods))}`;
  }
  // console.log('url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getTotalFuelReport = async (
  token,
  { customerId, vehicleGroupIds, siteIds, vehicleIds, startDate, endDate }
) => {
  let url = `${API_URL}customer/${customerId}/report/total_fuel?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  }
  // console.log('url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getTotalDistanceReport = async (
  token,
  {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    startDate,
    endDate,
    periods
  }
) => {
  let url = `${API_URL}customer/${customerId}/report/total_distance?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  } else if (periods) {
    url += `&period=${encodeURIComponent(JSON.stringify(periods))}`;
  }
  // console.log('url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getTotalActivityDaysReport = async (
  token,
  { customerId, vehicleGroupIds, siteIds, vehicleIds, startDate, endDate }
) => {
  let url = `${API_URL}customer/${customerId}/report/total_activity_days?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  }
  // console.log('url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getVehicleActivityStatsReport = async (
  token,
  {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    startDate,
    endDate,
    periods
  }
) => {
  let url = `${API_URL}customer/${customerId}/report/vehicle_stats?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  } else if (periods) {
    url += `&period=${encodeURIComponent(JSON.stringify(periods))}`;
  }
  // console.log('url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getDriverActivityStatsReport = async (
  token,
  {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    driverIds,
    startDate,
    endDate
  }
) => {
  let url = `${API_URL}customer/${customerId}/report/driver_stats?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (driverIds) {
    driverIds.map(driverId => {
      url += `driverId=${driverId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  }
  console.log('url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getPermanentStockRateReport = async (
  token,
  { customerId, vehicleGroupIds, siteIds, vehicleIds, startDate, endDate }
) => {
  let url = `${API_URL}customer/${customerId}/report/permanent_stock_rate_stats?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  }
  // console.log('url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getTopLowestFuelVehiclesReport = async (
  token,
  { customerId, vehicleGroupIds, siteIds, vehicleIds, startDate, endDate }
) => {
  let url = `${API_URL}customer/${customerId}/report/lowest_fuel?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  }
  // console.log('url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getTopLowestBatteryVehiclesReport = async (
  token,
  {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    startDate,
    endDate,
    threshold
  }
) => {
  let url = `${API_URL}customer/${customerId}/report/lowest_battery?`;
  if (threshold) {
    url += `threshold=${threshold}&`;
  }
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  }

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getTopOverspeedVehiclesReport = async (
  token,
  {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    startDate,
    endDate,
    threshold
  }
) => {
  let url = `${API_URL}customer/${customerId}/report/overspeed?`;
  if (threshold) {
    url += `threshold=${threshold}&`;
  }
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  }
  // console.log('url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getVehicleDailyReport = async (
  token,
  {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    startDate,
    endDate,
    periods
  }
) => {
  let url = `${API_URL}customer/${customerId}/report/vehicle_daily_report?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  } else if (periods) {
    url += `&period=${encodeURIComponent(JSON.stringify(periods))}`;
  }
  console.log('->url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getDriverDailyReport = async (
  token,
  {
    customerId,
    driverGroupIds,
    siteIds,
    driverIds,
    startDate,
    endDate,
    periods
  }
) => {
  let url = `${API_URL}customer/${customerId}/report/driver_daily_report?`;
  if (driverGroupIds) {
    driverGroupIds.map(driverGroupId => {
      url += `driverGroupId=${driverGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `driverGroupId=${siteId}&`;
    });
  }
  if (driverIds) {
    driverIds.map(driverId => {
      url += `driverId=${driverId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  } else if (periods) {
    url += `&period=${encodeURIComponent(JSON.stringify(periods))}`;
  }
  console.log('->url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getDriverDailyReportByDriver = async (
  token,
  {
    customerId,
    driverGroupIds,
    siteIds,
    driverIds,
    startDate,
    endDate,
    periods
  }
) => {
  let url = `${API_URL}customer/${customerId}/report/driver_daily_report_by_driver?`;
  if (driverGroupIds) {
    driverGroupIds.map(driverGroupId => {
      url += `driverGroupId=${driverGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `driverGroupId=${siteId}&`;
    });
  }
  if (driverIds) {
    driverIds.map(driverId => {
      url += `driverId=${driverId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  } else if (periods) {
    url += `&period=${encodeURIComponent(JSON.stringify(periods))}`;
  }
  console.log('->url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getTopVehicleTMAVAReport = async (
  token,
  {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    startDate,
    endDate,
    periods
  }
) => {
  let url = `${API_URL}customer/${customerId}/report/top_vehicle_tmava_report?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  } else if (periods) {
    url += `&period=${encodeURIComponent(JSON.stringify(periods))}`;
  }
  console.log('->url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getTopDriverTMAVAReport = async (
  token,
  {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    startDate,
    endDate,
    periods
  }
) => {
  let url = `${API_URL}customer/${customerId}/report/top_driver_tmava_report?`;
  /*if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }*/
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  } else if (periods) {
    url += `&period=${encodeURIComponent(JSON.stringify(periods))}`;
  }
  console.log('->url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getTMAVARepartitionReport = async (
  token,
  {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    startDate,
    endDate,
    periods
  }
) => {
  let url = `${API_URL}customer/${customerId}/report/tmava_repartition?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  } else if (periods) {
    url += `&period=${encodeURIComponent(JSON.stringify(periods))}`;
  }
  console.log('->url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getVehicleDailyReportByVehicle = async (
  token,
  {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    startDate,
    endDate,
    periods,
    reference
  }
) => {
  let url = `${API_URL}customer/${customerId}/report/vehicle_daily_report_by_vehicle?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}&`;
  } else if (periods) {
    url += `period=${encodeURIComponent(JSON.stringify(periods))}&`;
  }
  if (reference) {
    url += `reference=${reference}&`;
  }
  console.log('->url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getVehicleLiveReport = async (
  token,
  { customerId, vehicleGroupIds, siteIds, vehicleIds }
) => {
  let url = `${API_URL}customer/${customerId}/report/vehicle_live?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }

  console.log('->url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getGeneralDashboardReport = async (token, infos) => {
  let url = `${API_URL}dashboard/main/customers?`;

  if (infos.startDate && infos.endDate) {
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: infos.startDate,
        end: infos.endDate
      })
    )}&`;
  }

  if (infos.adaType) {
    url += `adaType=${infos.adaType.name}&`;
  }

  if (infos.noOccurence) {
    url += 'noOccurence=true&';
  }

  console.log('URL', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url: url
  });
};

const getGeneralDashboardSites = async (token, infos) => {
  let url = `${API_URL}dashboard/main/customers/${infos.customerId}?`;

  if (infos.startDate && infos.endDate) {
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: infos.startDate,
        end: infos.endDate
      })
    )}&`;
  }

  if (infos.adaType) {
    url += `adaType=${infos.adaType.name}&`;
  }

  if (infos.noOccurence) {
    url += 'noOccurence=true&';
  }

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url: url
  });
};

const getGeneralDashboardVehicles = async (token, infos) => {
  let url = `${API_URL}dashboard/main/customers/${infos.customerId}/groups/${infos.groupId}?`;

  if (infos.startDate && infos.endDate) {
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: infos.startDate,
        end: infos.endDate
      })
    )}&`;
  }

  if (infos.adaType) {
    url += `adaType=${infos.adaType.name}&`;
  }

  if (infos.noOccurence) {
    url += 'noOccurence=true&';
  }

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url: url
  });
};

const getAdaAllCustomersReport = async (token, infos) => {
  let url = `${API_URL}dashboard/ada/customers?period=${encodeURIComponent(
    JSON.stringify({
      start: infos.startDate,
      end: infos.endDate
    })
  )}&`;

  if (infos.adaType) {
    url += `adaType=${infos.adaType.name}&`;
  }

  if (infos.noOccurence) {
    url += 'noOccurence=true&';
  }

  console.log(url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url: url
  });
};

const getAdaCustomerTreeReport = async (token, infos) => {
  let url = `${API_URL}dashboard/ada/customers/${
    infos.customerId
  }?period=${encodeURIComponent(
    JSON.stringify({
      start: infos.dates.startDate,
      end: infos.dates.endDate
    })
  )}&`;

  if (infos.adaType) {
    url += `adaType=${infos.adaType.name}&`;
  }

  if (infos.noOccurence) {
    url += 'noOccurence=true&';
  }

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url: url
  });
};

const getRefuelVehiclesReport = async (
  token,
  {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    startDate,
    endDate,
    periods
  }
) => {
  let url = `${API_URL}customer/${customerId}/report/refuel_vehicles?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  } else if (periods) {
    url += `&period=${encodeURIComponent(JSON.stringify(periods))}`;
  }
  console.log('->url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getTheftVehiclesReport = async (
  token,
  { customerId, vehicleIds, startDate, endDate, periods }
) => {
  let url = `${API_URL}customer/${customerId}/report/theft_vehicles?`;
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  } else if (periods) {
    url += `&period=${encodeURIComponent(JSON.stringify(periods))}`;
  }
  console.log('->url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getTheftReport = async (
  token,
  { customerId, vehicleGroupIds, siteIds, vehicleIds, startDate, endDate }
) => {
  let url = `${API_URL}customer/${customerId}/report/theft_report?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  }

  console.log('->url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getVeqTmavaStatsReport = async (
  token,
  { customerId, vehicleGroupIds, siteIds, vehicleIds, startDate, endDate }
) => {
  let url = `${API_URL}customer/${customerId}/report/veq_tmava_stats?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  }

  console.log('->url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getStabilityPoints = async (token, infos) => {
  console.log('Saga get stab points ', infos);

  let url = null;

  url = `${API_URL}stability_points?vehicleId=${
    infos.vehicleId
  }&period=${encodeURIComponent(
    JSON.stringify({
      start: infos.startDate,
      end: infos.endDate
    })
  )}`;

  console.log(url);
  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url: url
  });
};

const getTopFlopReport = async (
  token,
  {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    startDate,
    endDate,
    periods
  }
) => {
  let url = `${API_URL}customer/${customerId}/report/top_flop_report?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  } else if (periods) {
    url += `&period=${encodeURIComponent(JSON.stringify(periods))}`;
  }
  // console.log('url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getRefuelOptimizationReport = async (
  token,
  {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    startDate,
    endDate,
    periods
  }
) => {
  let url = `${API_URL}customer/${customerId}/report/refuel_optimization_report?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  } else if (periods) {
    url += `&period=${encodeURIComponent(JSON.stringify(periods))}`;
  }
  // console.log('url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getRefuelOptimizationByVehicleReport = async (
  token,
  {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    startDate,
    endDate,
    periods
  }
) => {
  let url = `${API_URL}customer/${customerId}/report/refuel_optimization_by_vehicle_report?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  } else if (periods) {
    url += `&period=${encodeURIComponent(JSON.stringify(periods))}`;
  }
  console.log('url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getZoneStatReport = async (
  token,
  {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    startDate,
    endDate,
    periods
  }
) => {
  let url = `${API_URL}customer/${customerId}/report/zone_stat_report?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  } else if (periods) {
    url += `&period=${encodeURIComponent(JSON.stringify(periods))}`;
  }
  // console.log('url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

const getAverageInitialTankVolumePercentRefuelReport = async (
  token,
  {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    startDate,
    endDate,
    periods
  }
) => {
  let url = `${API_URL}customer/${customerId}/report/average_initial_tank_volume_refuel?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += `vehicleGroupId=${vehicleGroupId}&`;
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += `vehicleGroupId=${siteId}&`;
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += `vehicleId=${vehicleId}&`;
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  } else if (periods) {
    url += `&period=${encodeURIComponent(JSON.stringify(periods))}`;
  }
  // console.log('url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url
  });
};

function* workerGetFuelConsumptionReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getFuelConsumptionReport,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_FUEL_CONSUMPTION_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_FUEL_CONSUMPTION_REPORT_FAILURE, error });
  }
}

function* workerGetFuelConsumptionReportMultiple(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getFuelConsumptionReport,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_FUEL_CONSUMPTION_REPORT_MULTIPLE_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_FUEL_CONSUMPTION_REPORT_MULTIPLE_FAILURE, error });
  }
}

function* workerGetTotalFuelReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getTotalFuelReport, token, action.payload);
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_TOTAL_FUEL_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TOTAL_FUEL_REPORT_FAILURE, error });
  }
}

function* workerGetTotalDistanceReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getTotalDistanceReport, token, action.payload);
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_TOTAL_DISTANCE_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TOTAL_DISTANCE_REPORT_FAILURE, error });
  }
}

function* workerGetTotalDistanceReportMultiple(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getTotalDistanceReport, token, action.payload);
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_TOTAL_DISTANCE_REPORT_MULTIPLE_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TOTAL_DISTANCE_REPORT_MULTIPLE_FAILURE, error });
  }
}

function* workerGetTotalActivityDaysReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getTotalActivityDaysReport,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_TOTAL_ACTIVITY_DAYS_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TOTAL_ACTIVITY_DAYS_REPORT_FAILURE, error });
  }
}

function* workerGetVehicleActivityStatsReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getVehicleActivityStatsReport,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_SUCCESS,
      report
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_FAILURE,
      error
    });
  }
}

function* workerGetVehicleActivityStatsReportMultiple(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getVehicleActivityStatsReport,
      token,
      action.payload
    );

    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_SUCCESS,
      report
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_FAILURE,
      error
    });
  }
}

function* workerGetDriverActivityStatsReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getDriverActivityStatsReport,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: GET_CUSTOMER_DRIVER_ACTIVITY_STATS_REPORT_SUCCESS,
      report
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_CUSTOMER_DRIVER_ACTIVITY_STATS_REPORT_FAILURE,
      error
    });
  }
}

function* workerGetPermanentStockRateReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getPermanentStockRateReport,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_PERMANENT_STOCK_RATE_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_PERMANENT_STOCK_RATE_REPORT_FAILURE, error });
  }
}

function* workerGetTopLowestFuelVehiclesReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getTopLowestFuelVehiclesReport,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_TOP_LOWEST_FUEL_VEHICLES_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TOP_LOWEST_FUEL_VEHICLES_REPORT_FAILURE, error });
  }
}

function* workerGetTopLowestBatteryVehiclesReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getTopLowestBatteryVehiclesReport,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_TOP_LOWEST_BATTERY_VEHICLES_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TOP_LOWEST_BATTERY_VEHICLES_REPORT_FAILURE, error });
  }
}

function* workerGetTopOverspeedVehiclesReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getTopOverspeedVehiclesReport,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_TOP_OVERSPEED_VEHICLES_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TOP_OVERSPEED_VEHICLES_REPORT_FAILURE, error });
  }
}

function* workerGetVehicleDailyReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getVehicleDailyReport, token, action.payload);
    const report = response.data;
    console.log('REPORT', report);

    // dispatch a success action to the store with the new report
    yield put({ type: GET_VEHICLE_DAILY_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLE_DAILY_REPORT_FAILURE, error });
  }
}

function* workerGetVehicleDailyReportMultiple(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getVehicleDailyReport, token, action.payload);
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_VEHICLE_DAILY_REPORT_MULTIPLE_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLE_DAILY_REPORT_MULTIPLE_FAILURE, error });
  }
}

function* workerGetVehicleDailyReportMultipleDashboardTrend(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getVehicleDailyReport, token, action.payload);
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: GET_VEHICLE_DAILY_REPORT_MULTIPLE_DASHBOARD_TREND_SUCCESS,
      report
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_VEHICLE_DAILY_REPORT_MULTIPLE_DASHBOARD_TREND_FAILURE,
      error
    });
  }
}

function* workerGetVehicleDailyReportMultipleForCumulativeTMAVA(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getVehicleDailyReport, token, action.payload);
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: GET_VEHICLE_DAILY_REPORT_MULTIPLE_FOR_CUMULATIVE_TMAVA_SUCCESS,
      report
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_VEHICLE_DAILY_REPORT_MULTIPLE_FOR_CUMULATIVE_TMAVA_FAILURE,
      error
    });
  }
}

function* workerGetDriverDailyReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getDriverDailyReport, token, action.payload);
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_DRIVER_DAILY_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DRIVER_DAILY_REPORT_FAILURE, error });
  }
}

function* workerGetDriverDailyReportMultiple(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getDriverDailyReport, token, action.payload);
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_DRIVER_DAILY_REPORT_MULTIPLE_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DRIVER_DAILY_REPORT_MULTIPLE_FAILURE, error });
  }
}

function* workerGetDriverDailyReportByDriver(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getDriverDailyReportByDriver,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_DRIVER_DAILY_REPORT_BY_DRIVER_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DRIVER_DAILY_REPORT_BY_DRIVER_FAILURE, error });
  }
}

function* workerGetDriverDailyReportByDriverReference(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getDriverDailyReportByDriver,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: GET_DRIVER_DAILY_REPORT_BY_DRIVER_REFERENCE_SUCCESS,
      report
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_DRIVER_DAILY_REPORT_BY_DRIVER_REFERENCE_FAILURE,
      error
    });
  }
}

function* workerGetDriverDailyReportByDriverMultiple(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getDriverDailyReportByDriver,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: GET_DRIVER_DAILY_REPORT_BY_DRIVER_MULTIPLE_SUCCESS,
      report
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_DRIVER_DAILY_REPORT_BY_DRIVER_MULTIPLE_FAILURE,
      error
    });
  }
}

function* workerGetTopVehicleTMAVAReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getTopVehicleTMAVAReport,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_TOP_VEHICLE_TMAVA_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TOP_VEHICLE_TMAVA_REPORT_FAILURE, error });
  }
}

function* workerGetTopDriverTMAVAReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getTopDriverTMAVAReport, token, action.payload);
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_TOP_DRIVER_TMAVA_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TOP_DRIVER_TMAVA_REPORT_FAILURE, error });
  }
}

function* workerGetTMAVARepartitionReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getTMAVARepartitionReport,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_TMAVA_REPARTITION_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TMAVA_REPARTITION_REPORT_FAILURE, error });
  }
}

function* workerGetVehicleDailyReportByVehicle(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getVehicleDailyReportByVehicle,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_FAILURE, error });
  }
}

function* workerGetVehicleDailyReportByVehicleReference(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getVehicleDailyReportByVehicle,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REFERENCE_SUCCESS,
      report
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REFERENCE_FAILURE,
      error
    });
  }
}

function* workerGetVehicleDailyReportByVehicleMultiple(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getVehicleDailyReportByVehicle,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_MULTIPLE_SUCCESS,
      report
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_MULTIPLE_FAILURE,
      error
    });
  }
}

function* workerGetVehicleLiveReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getVehicleLiveReport, token, action.payload);
    const report = response.data;
    // dispatch a success action to the store with the new report
    yield put({ type: GET_VEHICLE_LIVE_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLE_LIVE_REPORT_FAILURE, error });
  }
}

function* workerGeneralDashboardReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getGeneralDashboardReport,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_AGSUP_GENERAL_DASHBOARD_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_AGSUP_GENERAL_DASHBOARD_REPORT_FAILURE, error });
  }
}

function* workerGeneralDashboardSites(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getGeneralDashboardSites,
      token,
      action.payload
    );
    const sites = response.data;

    // dispatch a success action to the store with the new sites
    yield put({ type: GET_AGSUP_GENERAL_DASHBOARD_SITES_SUCCESS, sites });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_AGSUP_GENERAL_DASHBOARD_SITES_FAILURE, error });
  }
}

function* workerGeneralDashboardVehicles(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getGeneralDashboardVehicles,
      token,
      action.payload
    );
    const vehiclesCount = response.data;

    // dispatch a success action to the store with the new sites
    yield put({ type: GET_AGSUP_DASHBOARD_SINGLE_SITE_SUCCESS, vehiclesCount });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_AGSUP_DASHBOARD_SINGLE_SITE_FAILURE, error });
  }
}

function* workerAdaAllCustomersReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getAdaAllCustomersReport,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: GET_AGSUP_ADA_CUSTOMERS_DASHBOARD_REPORT_SUCCESS,
      report
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_AGSUP_ADA_CUSTOMERS_DASHBOARD_REPORT_FAILURE,
      error
    });
  }
}

function* workerAdaCustomerTreeReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getAdaCustomerTreeReport,
      token,
      action.payload
    );
    const customerTree = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: GET_AGSUP_ADA_CUSTOMER_TREE_SUCCESS,
      customerTree
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_AGSUP_ADA_CUSTOMER_TREE_FAILURE,
      error
    });
  }
}

function* workerGetRefuelVehiclesReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getRefuelVehiclesReport, token, action.payload);
    const report = response.data;
    // dispatch a success action to the store with the new report
    yield put({ type: GET_REFUEL_VEHICLES_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_REFUEL_VEHICLES_REPORT_FAILURE, error });
  }
}

function* workerGetTheftVehiclesReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getTheftVehiclesReport, token, action.payload);
    const report = response.data;
    // dispatch a success action to the store with the new report
    yield put({ type: GET_THEFT_VEHICLES_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_THEFT_VEHICLES_REPORT_FAILURE, error });
  }
}

function* workerGetTheftReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getTheftReport, token, action.payload);
    const report = response.data;
    // dispatch a success action to the store with the new report
    yield put({ type: GET_THEFT_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_THEFT_REPORT_FAILURE, error });
  }
}

function* workerGetVeqTmavaStatsReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getVeqTmavaStatsReport, token, action.payload);
    const report = response.data;
    // dispatch a success action to the store with the new report
    yield put({ type: GET_VEQ_TMAVA_STATS_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEQ_TMAVA_STATS_REPORT_FAILURE, error });
  }
}

function* workerGetStabilityPoints(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getStabilityPoints, token, action.payload);
    const stabilityPoints = response.data;
    // dispatch a success action to the store with the new stabilityPoints
    yield put({ type: GET_STABILITY_POINTS_SUCCESS, stabilityPoints });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_STABILITY_POINTS_FAILURE, error });
  }
}

function* workerGetTopFlopReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getTopFlopReport, token, action.payload);
    const topFlopReport = response.data;
    // dispatch a success action to the store with the new topFlopReport
    yield put({ type: GET_TOP_FLOP_REPORT_SUCCESS, topFlopReport });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TOP_FLOP_REPORT_FAILURE, error });
  }
}

function* workerGetRefuelOptimizationReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getRefuelOptimizationReport,
      token,
      action.payload
    );
    const refuelOptimizationReport = response.data;
    // dispatch a success action to the store with the new refuelOptimizationReport
    yield put({
      type: GET_REFUEL_OPTIMIZATION_REPORT_SUCCESS,
      refuelOptimizationReport
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_REFUEL_OPTIMIZATION_REPORT_FAILURE, error });
  }
}

function* workerGetRefuelOptimizationByVehicleReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getRefuelOptimizationByVehicleReport,
      token,
      action.payload
    );
    const refuelOptimizationByVehicleReport = response.data;
    // dispatch a success action to the store with the new refuelOptimizationByVehicleReport
    yield put({
      type: GET_REFUEL_OPTIMIZATION_BY_VEHICLE_REPORT_SUCCESS,
      refuelOptimizationByVehicleReport
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_REFUEL_OPTIMIZATION_BY_VEHICLE_REPORT_FAILURE,
      error
    });
  }
}

function* workerGetZoneStatReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getZoneStatReport, token, action.payload);
    const zoneStatReport = response.data;
    // dispatch a success action to the store with the new zoneStatReport
    yield put({
      type: GET_ZONE_STAT_REPORT_SUCCESS,
      zoneStatReport
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_ZONE_STAT_REPORT_FAILURE, error });
  }
}

function* workerGetAverageInitialTankVolumePercentRefuelReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getAverageInitialTankVolumePercentRefuelReport,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_SUCCESS,
      report
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_FAILURE,
      error
    });
  }
}

function* workerGetAverageInitialTankVolumePercentRefuelReportMultiple(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getAverageInitialTankVolumePercentRefuelReport,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_MULTIPLE_SUCCESS,
      report
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_MULTIPLE_FAILURE,
      error
    });
  }
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetFuelConsumptionReport),
    fork(watcherGetFuelConsumptionReportMultiple),
    fork(watcherGetTotalFuelReport),
    fork(watcherGetTotalDistanceReport),
    fork(watcherGetTotalDistanceReportMultiple),
    fork(watcherGetTotalActivityDaysReport),
    fork(watcherGetVehicleActivityStatsReport),
    fork(watcherGetVehicleActivityStatsReportMultiple),
    fork(watcherGetDriverActivityStatsReport),
    fork(watcherGetPermanentStockRateReport),
    fork(watcherGetTopLowestFuelVehiclesReport),
    fork(watcherGetTopLowestBatteryVehiclesReport),
    fork(watcherGetTopOverspeedVehiclesReport),
    fork(watcherGetVehicleDailyReport),
    fork(watcherGetVehicleDailyReportMultiple),
    fork(watcherGetVehicleDailyReportMultipleDashboardTrend),
    fork(watcherGetVehicleDailyReportMultipleForCumulativeTMAVA),
    fork(watcherGetTopVehicleTMAVAReport),
    fork(watcherGetTopDriverTMAVAReport),
    fork(watcherGetTMAVARepartitionReport),
    fork(watcherGetVehicleDailyReportByVehicle),
    fork(watcherGetVehicleDailyReportByVehicleReference),
    fork(watcherGetVehicleDailyReportByVehicleMultiple),
    fork(watcherGetVehicleLiveReport),
    fork(watcherGeneralDashboardReport),
    fork(watcherGeneralDashboardSites),
    fork(watcherGeneralDashboardVehicles),
    fork(watcherAdaAllCustomersReport),
    fork(watcherAdaCustomerTreeReport),
    fork(watcherGetRefuelVehiclesReport),
    fork(watcherGetTheftVehiclesReport),
    fork(watcherGetTheftReport),
    fork(watcherGetDriverDailyReport),
    fork(watcherGetDriverDailyReportMultiple),
    fork(watcherGetDriverDailyReportByDriver),
    fork(watcherGetDriverDailyReportByDriverReference),
    fork(watcherGetDriverDailyReportByDriverMultiple),
    fork(watcherGetVeqTmavaStatsReport),
    fork(watcherGetStabilityPoints),
    fork(watcherGetTopFlopReport),
    fork(watcherGetRefuelOptimizationReport),
    fork(watcherGetRefuelOptimizationByVehicleReport),
    fork(watcherGetZoneStatReport),
    fork(watcherGetAverageInitialTankVolumePercentRefuelReport),
    fork(watcherGetAverageInitialTankVolumePercentRefuelReportMultiple)
  ]);
}
