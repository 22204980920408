import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import LegalMention from 'components/LegalMention/index';
import IntlMessages from 'util/IntlMessages';
import { APP_VERSION } from 'constants/globalConstants';

const styles = theme => ({
  footer: {
    alignItems: 'baseline',
    justifyContent: 'center',
    display: 'flex',
    fontSize: '1rem',
    position: 'absolute',
    bottom: '1rem',
    left: 0,
    right: 0,
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  link: {
    '&:hover': {
      color: theme.palette.primary.light,
      textDecoration: 'underline',
      cursor: 'pointer',
      transition: '0.3s ease-in'
    }
  },
  modal: {
    width: '50%',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalLegalMentionOpen: false
    };
    this.notificationDOMRef = React.createRef();
  }

  handleModalOpen = () => {
    this.setState({ modalLegalMentionOpen: true });
  };

  handleModalClose = () => {
    this.setState({ modalLegalMentionOpen: false });
  };

  render() {
    const classes = this.props.classes;
    const { modalLegalMentionOpen } = this.state;
    return (
      <>
        <footer className={`${classes.footer} d-flex justify-content-center`}>
          <p
            onClick={this.handleModalOpen}
            className={`${classes.link} mr-4 p-0 m-0`}>
            Mentions légales
          </p>
          <span>
            {`© 2019 `}
            <a
              href="https://www.alertgasoil.com/"
              target="_blank"
              className={`p-0 ${classes.link}`}>
              alertgasoil™
            </a>
            {` - Version: ${APP_VERSION}`}
          </span>
        </footer>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={modalLegalMentionOpen}
          onClose={this.handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}>
          <Fade in={modalLegalMentionOpen}>
            <LegalMention props={this.props} />
          </Fade>
        </Modal>
      </>
    );
  }
}

export default withStyles(
  theme => ({
    ...styles(theme)
  }),
  { withTheme: true }
)(Footer);
