import React from 'react';

const CardBox = ({heading, headingIcon, headingTitleStyle, children, styleName, cardStyle, childrenStyle, headerOutside, headerStyle}) => {
  return (
    <div className={`${styleName}`}>
      {headerOutside &&
      <div className={`jr-entry-header ${headerStyle}`}>
        <h3 className={`entry-heading ${headingTitleStyle}`}>{heading} {headingIcon}</h3>
        {children.length > 1 && <div className="entry-description">{children[0]}</div>}
      </div>
      }

      <div className={`jr-card ${cardStyle}`}>
        {!headerOutside &&
        ( heading &&
          <div className={`jr-card-header ${headerStyle}`}>
            <h3 className={`card-heading ${headingTitleStyle}`}>{heading} {headingIcon}</h3>
            {children.length > 1 && <div className="sub-heading">{children[0]}</div>}
          </div>
        )}
        <div className={`jr-card-body ${childrenStyle}`}>
          {children.length > 1 ? children[1] : children}
        </div>
      </div>
    </div>
  )
};

export default CardBox;

CardBox.defaultProps = {
  cardStyle: '',
  headingStyle:'',
  headerStyle:'',
  childrenStyle: '',
  styleName: 'col-lg-6 col-sm-12'
};