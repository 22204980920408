import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  ADD_GROUP_TYPE_FAILURE,
  ADD_GROUP_TYPE_REQUEST,
  ADD_GROUP_TYPE_SUCCESS,
  ADD_GROUP_TYPE_AND_GROUP_REQUEST,
  ADD_GROUP_TYPE_AND_GROUP_SUCCESS,
  ADD_GROUP_TYPE_AND_GROUP_FAILURE,
  ADD_OPTIONAL_GROUPS_FAILURE,
  ADD_OPTIONAL_GROUPS_REQUEST,
  ADD_OPTIONAL_GROUPS_SUCCESS,
  DELETE_GROUP_TYPE_FAILURE,
  DELETE_GROUP_TYPE_REQUEST,
  DELETE_GROUP_TYPE_SUCCESS,
  DELETE_OPTIONAL_GROUPS_FAILURE,
  DELETE_OPTIONAL_GROUPS_REQUEST,
  DELETE_OPTIONAL_GROUPS_SUCCESS,
  EDIT_GROUP_TYPE_FAILURE,
  EDIT_GROUP_TYPE_REQUEST,
  EDIT_GROUP_TYPE_SUCCESS,
  GET_GROUP_LEVELS_FAILURE,
  GET_GROUP_LEVELS_REQUEST,
  GET_GROUP_LEVELS_SUCCESS,
  GET_GROUP_LEVELS_FROM_CUSTOMER_FAILURE,
  GET_GROUP_LEVELS_FROM_CUSTOMER_REQUEST,
  GET_GROUP_LEVELS_FROM_CUSTOMER_SUCCESS,
  GET_GROUP_TYPES_FAILURE,
  GET_GROUP_TYPES_REQUEST,
  GET_GROUP_TYPES_SUCCESS,
  GET_GROUPS_BY_LEVEL_FAILURE,
  GET_GROUPS_BY_LEVEL_REQUEST,
  GET_GROUPS_BY_LEVEL_SUCCESS,
  GET_GROUPS_FAILURE,
  GET_GROUPS_REQUEST,
  GET_GROUPS_SUCCESS,
  GET_DEEPEST_GROUPS_REQUEST,
  GET_DEEPEST_GROUPS_SUCCESS,
  GET_DEEPEST_GROUPS_FAILURE,
  GET_GEOGRAPHIC_GROUPS_FILTER_FAILURE,
  GET_GEOGRAPHIC_GROUPS_FILTER_REQUEST,
  GET_GEOGRAPHIC_GROUPS_FILTER_SUCCESS,
  GET_NO_GEOGRAPHIC_GROUPS_FILTER_FAILURE,
  GET_NO_GEOGRAPHIC_GROUPS_FILTER_REQUEST,
  GET_NO_GEOGRAPHIC_GROUPS_FILTER_SUCCESS,
  GET_OPTIONAL_GROUPS_FAILURE,
  ADD_GROUP_REQUEST,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILURE,
  EDIT_GROUP_REQUEST,
  EDIT_GROUP_SUCCESS,
  EDIT_GROUP_FAILURE,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
  GET_TREE_AREA_GROUPS_REQUEST,
  GET_TREE_AREA_GROUPS_SUCCESS,
  GET_TREE_AREA_GROUPS_FAILURE,
  GET_OPTIONAL_GROUPS_REQUEST,
  GET_OPTIONAL_GROUPS_SUCCESS,
  GET_PRESELECTED_GEOGRAPHIC_GROUPS_BARFILTER_FAILURE,
  GET_PRESELECTED_GEOGRAPHIC_GROUPS_BARFILTER_REQUEST,
  GET_PRESELECTED_GEOGRAPHIC_GROUPS_BARFILTER_SUCCESS,
  GET_PRESELECTED_NO_GEOGRAPHIC_GROUPS_BARFILTER_FAILURE,
  GET_PRESELECTED_NO_GEOGRAPHIC_GROUPS_BARFILTER_REQUEST,
  GET_PRESELECTED_NO_GEOGRAPHIC_GROUPS_BARFILTER_SUCCESS,
  GET_SINGLE_GROUP_REQUEST,
  GET_SINGLE_GROUP_SUCCESS,
  GET_SINGLE_GROUP_FAILURE,
} from 'constants/ActionTypes';
import { showMessage } from 'actions/Global';
import { API_URL } from '../constants/globalConstants';
import axios from 'axios';

////////? GET TREE AREA GROUPS ///////////
export function* watcherGetTreeAreaGroups() {
  yield takeLatest(GET_TREE_AREA_GROUPS_REQUEST, workerGetTreeAreaGroups);
}

function* workerGetTreeAreaGroups(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getTreeAreaGroups, token, action.payload);
    const treeAreaGroups = response.data;

    yield put({ type: GET_TREE_AREA_GROUPS_SUCCESS, treeAreaGroups });
  } catch (error) {
    yield put({ type: GET_TREE_AREA_GROUPS_FAILURE, error });
  }
}

const getTreeAreaGroups = async (token, customerId) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}groups/by_customer?customerId=${customerId}`,
  });
};

////////? GET GROUP TYPES ///////////
export function* watcherGetGroupTypes() {
  yield takeLatest(GET_GROUP_TYPES_REQUEST, workerGetGroupTypes);
}

function* workerGetGroupTypes(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getGroupTypes, token, action.payload);
    const groupTypes = response.data;

    console.log('groupTypes', groupTypes);

    yield put({ type: GET_GROUP_TYPES_SUCCESS, groupTypes });
  } catch (error) {
    yield put({ type: GET_GROUP_TYPES_FAILURE, error });
  }
}

const getGroupTypes = async (token, payload) => {
  let url = `${API_URL}group_types?customerId=${payload.customerId}`;

  if (payload.groupTypeFlag) url += `&groupTypeFlag=${payload.groupTypeFlag}`;
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: url,
  });
};

////////? GET DEEPEST GROUPS SINGLE CUSTOMER ///////////
export function* watcherGetDeepestGroupsSingleCustomer() {
  yield takeLatest(
    GET_DEEPEST_GROUPS_REQUEST,
    workerGetDeepestGroupsSingleCustomer
  );
}

function* workerGetDeepestGroupsSingleCustomer(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getDeepestGroups, token, action.payload);
    const deepestGroups = response.data;

    yield put({ type: GET_DEEPEST_GROUPS_SUCCESS, deepestGroups });
  } catch (error) {
    yield put({ type: GET_DEEPEST_GROUPS_FAILURE, error });
  }
}

const getDeepestGroups = async (token, customerId) => {
  let url = `${API_URL}groups/deepest_groups?customer_id=${customerId}`;

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: url,
  });
};

////////? ADD GROUP TYPE ///////////
export function* watcherAddGroupType() {
  yield takeLatest(ADD_GROUP_TYPE_REQUEST, workerAddGroupType);
}

function* workerAddGroupType(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(addGroupType, token, action.payload);
    const addedGroup = response.data;

    yield put({ type: ADD_GROUP_TYPE_SUCCESS, addedGroup });
  } catch (error) {
    yield put({ type: ADD_GROUP_TYPE_FAILURE, error });
  }
}

const addGroupType = async (token, group) => {
  return axios({
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: {
      customerId: group.customer_id,
      name: group.group_type,
      flag: group.flag,
    },
    url: `${API_URL}group_types`,
  });
};

////////? EDIT GROUP TYPE ///////////
export function* watcherEditGroupType() {
  yield takeLatest(EDIT_GROUP_TYPE_REQUEST, workerEditGroupType);
}

function* workerEditGroupType(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(editGroupType, token, action.payload);
    const editedGroup = response.data;

    yield put({ type: EDIT_GROUP_TYPE_SUCCESS, editedGroup });
  } catch (error) {
    yield put({ type: EDIT_GROUP_TYPE_FAILURE, error });
  }
}

const editGroupType = async (token, group) => {
  return axios({
    method: 'put',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: {
      customerId: group.customer_id,
      name: group.group_type,
      flag: group.flag,
    },
    url: `${API_URL}group_types/${group.id}`,
  });
};

////////? DELETE GROUP TYPE ///////////
export function* watcherDeleteGroupType() {
  yield takeLatest(DELETE_GROUP_TYPE_REQUEST, workerDeleteGroupType);
}

function* workerDeleteGroupType(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(deleteGroupType, token, action.payload);
    const deletedGroup = response.data;

    yield put({ type: DELETE_GROUP_TYPE_SUCCESS, deletedGroup });
  } catch (error) {
    yield put({ type: DELETE_GROUP_TYPE_FAILURE, error });
  }
}

const deleteGroupType = async (token, groupId) => {
  return axios({
    method: 'delete',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}group_types/${groupId}`,
  });
};

////////? ADD GROUP TYPE AND GROUP ///////////
export function* watcherAddGroupTypeAndGroup() {
  yield takeLatest(
    ADD_GROUP_TYPE_AND_GROUP_REQUEST,
    workerAddGroupTypeAndGroup
  );
}

function* workerAddGroupTypeAndGroup(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(addGroupTypeAndGroup, token, action.payload);
    const addedGroupTypeAndGroup = response.data;

    yield put({
      type: ADD_GROUP_TYPE_AND_GROUP_SUCCESS,
      addedGroupTypeAndGroup,
    });
  } catch (error) {
    yield put({ type: ADD_GROUP_TYPE_AND_GROUP_FAILURE, error });
  }
}

const addGroupTypeAndGroup = async (token, payload) => {
  return axios({
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: {
      groupType: {
        customerId: payload.customer_id,
        name: payload.group_type_name,
        flag: payload.group_type_flag,
      },
      groups: payload.groups,
    },
    url: `${API_URL}groups/group_type_and_groups`,
  });
};

////////? GET GROUP LEVELS ///////////
export function* watcherGetGroupLevels() {
  yield takeLatest(GET_GROUP_LEVELS_REQUEST, workerGetGroupLevels);
}

function* workerGetGroupLevels(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getGroupLevels, token, action.payload);
    const groupLevels = response.data;

    yield put({ type: GET_GROUP_LEVELS_SUCCESS, groupLevels });
  } catch (error) {
    yield put({ type: GET_GROUP_LEVELS_FAILURE, error });
  }
}

const getGroupLevels = async (token) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}global/group_levels`,
  });
};

////////? GET GROUP LEVELS FROM CUSTOMER ///////////
export function* watcherGetGroupLevelsFromCustomer() {
  yield takeLatest(
    GET_GROUP_LEVELS_FROM_CUSTOMER_REQUEST,
    workerGetGroupLevelsFromCustomer
  );
}

function* workerGetGroupLevelsFromCustomer(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getGroupLevelsFromCustomer,
      token,
      action.payload
    );
    const groupLevelsFromCustomer = response.data;

    yield put({
      type: GET_GROUP_LEVELS_FROM_CUSTOMER_SUCCESS,
      groupLevelsFromCustomer,
    });
  } catch (error) {
    yield put({ type: GET_GROUP_LEVELS_FROM_CUSTOMER_FAILURE, error });
  }
}

const getGroupLevelsFromCustomer = async (token, payload) => {
  let url = `${API_URL}groups/group_levels?customerId=${payload.customerId}`;
  if (payload.startDate && payload.endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: payload.startDate,
        end: payload.endDate,
      })
    )}`;
  }
  console.log('url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url,
  });
};

//////? GET GROUPS ///////////
export function* watcherGetGroups() {
  yield takeLatest(GET_GROUPS_REQUEST, workerGetGroups);
}

export function* watcherGetGeographicGroupsFilter() {
  yield takeLatest(
    GET_GEOGRAPHIC_GROUPS_FILTER_REQUEST,
    workerGetGeographicGroupsFilter
  );
}

export function* watcherGetNoGeographicGroupsFilter() {
  yield takeLatest(
    GET_NO_GEOGRAPHIC_GROUPS_FILTER_REQUEST,
    workerGetNoGeographicGroupsFilter
  );
}

function* workerGetGroups(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getGroups, token, action.payload);
    const groups = response.data;

    yield put({ type: GET_GROUPS_SUCCESS, groups });
  } catch (error) {
    yield put({ type: GET_GROUPS_FAILURE, error });
  }
}

function* workerGetGeographicGroupsFilter(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getGroups, token, action.payload);
    const groups = response.data;

    yield put({ type: GET_GEOGRAPHIC_GROUPS_FILTER_SUCCESS, groups });
  } catch (error) {
    yield put({ type: GET_GEOGRAPHIC_GROUPS_FILTER_FAILURE, error });
  }
}

function* workerGetNoGeographicGroupsFilter(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getGroups, token, action.payload);
    const groups = response.data;

    yield put({ type: GET_NO_GEOGRAPHIC_GROUPS_FILTER_SUCCESS, groups });
  } catch (error) {
    yield put({ type: GET_NO_GEOGRAPHIC_GROUPS_FILTER_FAILURE, error });
  }
}

const getGroups = async (token, payload) => {
  console.log('getGroups childrenGroups', payload.childrenGroups);
  let url = `${API_URL}groups?customer_id=${payload.customerId}`;
  if (payload.groupTypeId) url += `&group_type_id=${payload.groupTypeId}`;
  if (payload.groupTypeFlag) url += `&group_type_flag=${payload.groupTypeFlag}`;
  if (payload.groupIds) {
    payload.groupIds.map((groupId) => {
      url += '&groupId=' + groupId + '&';
    });
  }
  if (payload.childrenGroups)
    url += `&children_groups=${payload.childrenGroups}`;

  console.log(url);
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: url,
  });
};

//////? GET GROUPS BY LEVEL ///////////
export function* watcherGetGroupsByLevel() {
  yield takeLatest(GET_GROUPS_BY_LEVEL_REQUEST, workerGetGroupsByLevel);
}

function* workerGetGroupsByLevel(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getGroupsByLevel, token, action.payload);
    const groupsByLevel = response.data;

    yield put({ type: GET_GROUPS_BY_LEVEL_SUCCESS, groupsByLevel });
  } catch (error) {
    yield put({ type: GET_GROUPS_BY_LEVEL_FAILURE, error });
  }
}

const getGroupsByLevel = async (token, payload) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}groups/group_by_level?customerId=${payload.customerId}&level=${payload.level}`,
  });
};

////////? ADD GROUP ///////////
export function* watcherAddGroup() {
  yield takeLatest(ADD_GROUP_REQUEST, workerAddGroup);
}

function* workerAddGroup(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(addGroup, token, action.payload);
    const addedGroup = response.data;

    yield put({ type: ADD_GROUP_SUCCESS, addedGroup });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Zone created !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center',
      })
    );
  } catch (error) {
    yield put({ type: ADD_GROUP_FAILURE, error });
    yield put(
      showMessage({
        title: 'Information',
        message: 'A problem occured during the request, please retry.',
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center',
      })
    );
  }
}

const addGroup = async (token, group) => {
  return axios({
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: {
      groupTypeId: group.group_type_id,
      level: group.group_level,
      name: group.group_name,
      groupParentId: group.parent_group,
      address: group.group_address ? group.group_address : null,
    },
    url: `${API_URL}groups`,
  });
};

////////? GET SINGLE GROUP ///////////
export function* watcherGetSingleGroup() {
  yield takeLatest(GET_SINGLE_GROUP_REQUEST, workerGetSingleGroup);
}

function* workerGetSingleGroup(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getSingleGroup, token, action.payload);
    const group = response.data;

    yield put({ type: GET_SINGLE_GROUP_SUCCESS, group });
  } catch (error) {
    yield put({ type: GET_SINGLE_GROUP_FAILURE, error });
  }
}

const getSingleGroup = async (token, id) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}groups/${id}`,
  });
};

////////? EDIT GROUP ///////////
export function* watcherEditGroup() {
  yield takeLatest(EDIT_GROUP_REQUEST, workerEditGroup);
}

function* workerEditGroup(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(editGroup, token, action.payload);
    const editedGroup = response.data;

    yield put({ type: EDIT_GROUP_SUCCESS, editedGroup });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Group settings updated',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center',
      })
    );
  } catch (error) {
    yield put({ type: EDIT_GROUP_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center',
      })
    );
  }
}

const editGroup = async (token, group) => {
  return axios({
    method: 'put',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: {
      groupTypeId: group.group_type_id,
      level: group.group_level,
      name: group.group_name,
      settings: {
        // SAV_PRIORITY_GAUGE: customer.priority_gauge,
        // SAV_SIM_DYSFUNCTION: customer.sim_dysfunction,
        // SAV_PERMANENT_PLUS_CONNECTION: customer.permanent_plus_connection,
        // SAV_DISCONNECTED_CASE_UNDER_BATTERY_CUT:
        //   customer.disconnected_case_under_battery_cut,
        // SAV_PACKAGE_WITHOUT_DATA_FRAME: customer.package_without_dataframe,
        // SAV_RELAY_FUSE: customer.relay_fuse,
        // SAV_DISCONNECT_CASE: customer.disconnected_case,
        // SAV_PTO: customer.pto,
        // SAV_D8_ACTIVATION: customer.d8_activation,
        // SAV_TACOGRAPH: customer.tacograph,
        // SAV_BUZZER: customer.buzzer,
        // SAV_SRE_STONERIDGE_PASSAGE: customer.sre_stoneridge_passage,
        // SAV_FMS_CONNECTION: customer.fms_connection,
        // SAV_AGTACH: customer.ag_tach,
        // SAV_MANO_CONTACT_CONNECTION: customer.mano_contact_connection,
        // SAV_GPS_LOST: customer.gps_lost,
        // SAV_ALTERNATOR_BATTERY: customer.alternator_battery,
        // SAV_TANKS_DIMENSIONS_PLUG: customer.tanks_dimensions_plug,
        // SAV_GAUGE_RMA: customer.gauge_sav,
        // SAV_TACHY_RMA_LOSS: customer.loss_or_rma_tachy,
        // SAV_RMA_BOX: customer.case_rma,
        // SAV_GPS_LOSS_RMA: customer.gps_loss_or_rma,
        // SAV_DISCONNECTED_CASE_ON_OFF: customer.disconnected_case_off,
        // SAV_DISCONNECTED_CASE_ON_ON: customer.disconnected_case_on,

        ALERT_TMAVA_BEGIN_HOUR: group.tmava_begin_hour,
        ALERT_TMAVA_END_HOUR: group.tmava_end_hour,
        ALERT_TMAVA_TRIGGER_THRESHOLD: group.tmava_display_threshold,
        ALERT_TMAVA_DAYS:
          typeof group.tmava_days === 'object'
            ? JSON.stringify(group.tmava_days).replace(/[\\"]+/g, '"')
            : group.tmava_days,
        ALERT_TMAVAT_BEGIN_HOUR: group.tmavat_begin_hour,
        ALERT_TMAVAT_END_HOUR: group.tmavat_end_hour,
        ALERT_TMAVAT_TRIGGER_THRESHOLD: group.tmavat_display_threshold,
        ALERT_TMAVAT_DAYS:
          typeof group.tmavat_days === 'object'
            ? JSON.stringify(group.tmavat_days).replace(/[\\"]+/g, '"')
            : group.tmavat_days,
        ALERT_SERVER_THEFT_BEGIN_HOUR: group.server_theft_begin_hour,
        ALERT_SERVER_THEFT_END_HOUR: group.server_theft_end_hour,
        ALERT_SERVER_THEFT_TRIGGER_THRESHOLD:
          group.server_theft_display_threshold,
        ALERT_SERVER_THEFT_DAYS:
          typeof group.server_theft_days === 'object'
            ? JSON.stringify(group.server_theft_days).replace(/[\\"]+/g, '"')
            : group.server_theft_days,
        ALERT_EMBEDDED_THEFT_BEGIN_HOUR: group.embedded_theft_begin_hour,
        ALERT_EMBEDDED_THEFT_END_HOUR: group.embedded_theft_end_hour,
        ALERT_EMBEDDED_THEFT_TRIGGER_THRESHOLD:
          group.embedded_theft_display_threshold,
        ALERT_EMBEDDED_THEFT_DAYS:
          typeof group.embedded_theft_days === 'object'
            ? JSON.stringify(group.embedded_theft_days).replace(/[\\"]+/g, '"')
            : group.embedded_theft_days,
        ALERT_LOW_BATTERY_BEGIN_HOUR: group.low_battery_begin_hour,
        ALERT_LOW_BATTERY_END_HOUR: group.low_battery_end_hour,
        ALERT_LOW_BATTERY_TRIGGER_THRESHOLD:
          group.low_battery_display_threshold,
        ALERT_LOW_BATTERY_DAYS:
          typeof group.low_battery_days === 'object'
            ? JSON.stringify(group.low_battery_days).replace(/[\\"]+/g, '"')
            : group.low_battery_days,
        EVENT_LOW_TANK_STOCK_BEGIN_HOUR: group.almost_low_tank_stock_begin_hour,
        EVENT_LOW_TANK_STOCK_END_HOUR: group.almost_low_tank_stock_end_hour,
        EVENT_LOW_TANK_STOCK_TRIGGER_THRESHOLD:
          group.almost_low_tank_stock_display_threshold,
        EVENT_LOW_TANK_STOCK_DAYS:
          typeof group.almost_low_tank_stock_days === 'object'
            ? JSON.stringify(group.almost_low_tank_stock_days).replace(
                /[\\"]+/g,
                '"'
              )
            : group.almost_low_tank_stock_days,
        EVENT_VERY_LOW_TANK_STOCK_BEGIN_HOUR: group.low_tank_stock_begin_hour,
        EVENT_VERY_LOW_TANK_STOCK_END_HOUR: group.low_tank_stock_end_hour,
        EVENT_VERY_LOW_TANK_STOCK_TRIGGER_THRESHOLD:
          group.low_tank_stock_display_threshold,
        EVENT_VERY_LOW_TANK_STOCK_DAYS:
          typeof group.low_tank_stock_days === 'object'
            ? JSON.stringify(group.low_tank_stock_days).replace(/[\\"]+/g, '"')
            : group.low_tank_stock_days,
        ALERT_ZONE_IN_LIST_ZONE_ENABLED:
          group.zones_alerts.zone_entry.length > 0,
        ALERT_ZONE_IN_LIST_ZONE:
          group.zones_alerts.zone_entry.length > 0
            ? group.zones_alerts.zone_entry.join(';')
            : '',
        ALERT_ZONE_OUT_LIST_ZONE_ENABLED:
          group.zones_alerts.zone_exit.length > 0,
        ALERT_ZONE_OUT_LIST_ZONE:
          group.zones_alerts.zone_exit.length > 0
            ? group.zones_alerts.zone_exit.join(';')
            : '',
        ALERT_ZONE_ATTENDANCE_LIST_ZONE_ENABLED:
          group.zones_alerts.zone_presence_time.length > 0,
        ALERT_ZONE_ATTENDANCE_LIST_ZONE:
          group.zones_alerts.zone_presence_time.length > 0
            ? group.zones_alerts.zone_presence_time.join(';')
            : '',
        ALERT_ZONE_SPEED_ENABLED: group.zones_alerts.zone_speed.length > 0,
        ALERT_ZONE_SPEED_LIST_ZONE:
          group.zones_alerts.zone_speed.length > 0
            ? group.zones_alerts.zone_speed.join(';')
            : '',
        TMAVA_COEFF: group.tmava_coef,
        TMAVAT_COEFF: group.tmavat_coef,
        VEQ_TMAVA_DAYS_PER_YEAR: group.tmava_veq,
        TEQ_COMBUSTION: group.combustion_teq,
        FULL_TANK_THRESHOLD: group.full_tank_threshold,
        REFERENCE_THRESHOLD_CONSUMPTION: group.reference_threshold_consumption,
        TRAINED_CLIENT: group.trained_customer,
        PAGE_ACCESS_DEPLOYMENT_MONITORING: group.follow_deployment_access,
      },
    },
    url: `${API_URL}groups/${group.group_id}`,
  });
};

////////? DELETE GROUP ///////////
export function* watcherDeleteGroup() {
  yield takeLatest(DELETE_GROUP_REQUEST, workerDeleteGroup);
}

function* workerDeleteGroup(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(deleteGroup, token, action.payload);
    const deletedGroup = action.payload;

    yield put({ type: DELETE_GROUP_SUCCESS, deletedGroup });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Zone deleted !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center',
      })
    );
  } catch (error) {
    yield put({ type: DELETE_GROUP_FAILURE, error });
    if (error.response.data.message === 'The group has children groups') {
      yield put(
        showMessage({
          title: 'Error',
          message:
            "You can't delete a group which contain vehicles or children's groups",
          messageType: 'danger',
          duration: 5000,
          position: 'bottom-center',
        })
      );
    }
  }
}

const deleteGroup = async (token, groupId) => {
  return axios({
    method: 'delete',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}groups/${groupId}`,
  });
};

////////? GET OPTIONAL GROUPS ///////////
export function* watcherGetOptionalGroup() {
  yield takeLatest(GET_OPTIONAL_GROUPS_REQUEST, workerGetOptionalGroup);
}

function* workerGetOptionalGroup(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getOptionalGroup, token, action.payload);
    const gettingGroups = response.data.map((vehicle) => {
      return [vehicle['id'], vehicle['name'], vehicle['vehicleGroupType']];
    });

    yield put({ type: GET_OPTIONAL_GROUPS_SUCCESS, gettingGroups });
  } catch (error) {
    yield put({ type: GET_OPTIONAL_GROUPS_FAILURE, error });
  }
}

const getOptionalGroup = async (token, customerId) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}vehicle_groups?customerId=${customerId}`,
  });
};

////////? ADD OPTIONAL GROUP ///////////
export function* watcherAddOptionalGroup() {
  yield takeLatest(ADD_OPTIONAL_GROUPS_REQUEST, workerAddOptionalGroup);
}

function* workerAddOptionalGroup(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(addOptionalGroup, token, action.payload);
    const addedGroup = response.data;

    yield put({ type: ADD_OPTIONAL_GROUPS_SUCCESS, addedGroup });
  } catch (error) {
    yield put({ type: ADD_OPTIONAL_GROUPS_FAILURE, error });
  }
}

const addOptionalGroup = async (token, tankSerieId) => {
  // return axios({
  //   method: 'get',
  //   headers: {
  //     Authorization: 'Bearer ' + token
  //   },
  //   url: `${API_URL}tank_serials/${tankSerieId}`
  // });
};

////////? DELETE OPTIONAL GROUP ///////////
export function* watcherDeleteOptionalGroup() {
  yield takeLatest(DELETE_OPTIONAL_GROUPS_REQUEST, workerDeleteOptionalGroup);
}

function* workerDeleteOptionalGroup(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(deleteOptionalGroup, token, action.payload);
    const deletedGroup = response.data;

    yield put({ type: DELETE_OPTIONAL_GROUPS_SUCCESS, deletedGroup });
  } catch (error) {
    yield put({ type: DELETE_OPTIONAL_GROUPS_FAILURE, error });
  }
}

const deleteOptionalGroup = async (token, tankSerieId) => {
  // return axios({
  //   method: 'get',
  //   headers: {
  //     Authorization: 'Bearer ' + token
  //   },
  //   url: `${API_URL}tank_serials/${tankSerieId}`
  // });
};

////////? Preselect geographic groups ///////////
export function* watcherGetPreselectedGeographicGroupsBarFilter() {
  yield takeLatest(
    GET_PRESELECTED_GEOGRAPHIC_GROUPS_BARFILTER_REQUEST,
    workerGetPreselectedGeographicGroupsBarFilter
  );
}

function* workerGetPreselectedGeographicGroupsBarFilter(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getGroups, token, action.payload);
    const preSelectedGeographicGroupsBarFilter = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({
      type: GET_PRESELECTED_GEOGRAPHIC_GROUPS_BARFILTER_SUCCESS,
      preSelectedGeographicGroupsBarFilter: preSelectedGeographicGroupsBarFilter,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_PRESELECTED_GEOGRAPHIC_GROUPS_BARFILTER_FAILURE,
      error,
    });
  }
}

////////? Preselect no geographic groups ///////////
export function* watcherGetPreselectedNoGeographicGroupsBarFilter() {
  yield takeLatest(
    GET_PRESELECTED_NO_GEOGRAPHIC_GROUPS_BARFILTER_REQUEST,
    workerGetPreselectedNoGeographicGroupsBarFilter
  );
}

function* workerGetPreselectedNoGeographicGroupsBarFilter(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getGroups, token, action.payload);
    const preSelectedNoGeographicGroupsBarFilter = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({
      type: GET_PRESELECTED_NO_GEOGRAPHIC_GROUPS_BARFILTER_SUCCESS,
      preSelectedNoGeographicGroupsBarFilter: preSelectedNoGeographicGroupsBarFilter,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_PRESELECTED_NO_GEOGRAPHIC_GROUPS_BARFILTER_FAILURE,
      error,
    });
  }
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetGroupTypes),
    fork(watcherAddGroupType),
    fork(watcherEditGroupType),
    fork(watcherDeleteGroupType),
    fork(watcherAddGroupTypeAndGroup),
    fork(watcherGetGroupLevels),
    fork(watcherGetTreeAreaGroups),
    fork(watcherGetGroups),
    fork(watcherGetDeepestGroupsSingleCustomer),
    fork(watcherGetGeographicGroupsFilter),
    fork(watcherGetNoGeographicGroupsFilter),
    fork(watcherGetGroupsByLevel),
    fork(watcherGetGroupLevelsFromCustomer),
    fork(watcherAddGroup),
    fork(watcherEditGroup),
    fork(watcherDeleteGroup),
    fork(watcherGetOptionalGroup),
    fork(watcherGetPreselectedGeographicGroupsBarFilter),
    fork(watcherGetPreselectedNoGeographicGroupsBarFilter),
    fork(watcherGetSingleGroup),
  ]);
}
