import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import 'assets/vendors/style';
import defaultTheme from './themes/defaultTheme';
import AppLocale from '../lngProvider';

import MainApp from 'app/index';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Loading from '../app/routes/loading';
import ForgotPassword from './ForgotPassword';
import ChangePassword from './ChangePassword';
// import LegalMention from './LegalMention';
import ContactUs from './ContactUs';
import { setInitUrl, userSignOut } from 'actions/Users';
import RTL from 'util/RTL';
import asyncComponent from 'util/asyncComponent';
import MessageNotification from '../components/MessageNotification';

const RestrictedRoute = ({
  component: Component,
  authUser,
  userSignOut,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (authUser !== null && authUser.tokenExpiredAt > new Date().getTime()) {
        return <Component {...props} />;
      } else if (authUser !== 0 || !localStorage.getItem('user_login')) {
        console.log(
          'disconnect',
          authUser,
          ' and ',
          localStorage.getItem('user_login')
        );
        localStorage.removeItem('user');
        localStorage.removeItem('user_login');
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiredAt');

        // Effacer auth user et redirect
        userSignOut();

        return (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        );
      } else {
        return <Loading />;
      }
    }}
  />
);

class App extends Component {
  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }

  render() {
    const {
      match,
      location,
      authUser,
      initURL,
      locale,
      isDirectionRTL,
    } = this.props;
    //? Par défaut, la lamgue de l'app sera celle de la langue du navigateur
    let currentAppLocale = AppLocale[locale.locale];
    if (location.pathname === '/') {
      if (!authUser) {
        return <Redirect to={'/signin'} />;
      } else if (
        initURL === '' ||
        initURL === '/' ||
        initURL === '/signin' ||
        initURL === '/forgot-password' ||
        initURL === '/reset-password' ||
        initURL === '/contact-us'
      ) {
        return <Redirect to={'/home'} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }
    //? Si le user est connecté, on switch sur la langue de préférence du user
    if (authUser) {
      currentAppLocale = AppLocale[authUser.languageCode];
    }
    const applyTheme = createMuiTheme(defaultTheme);

    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }

    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <RTL>
              <div className="app-main">
                <Switch>
                  <Route path="/signin" component={SignIn} />
                  <Route path="/signup" component={SignUp} />
                  <Route path="/reset-password" component={ChangePassword} />

                  <Route path="/forgot-password" component={ForgotPassword} />
                  <Route path="/contact-us" component={ContactUs} />
                  <RestrictedRoute
                    path={`${match.url}`}
                    authUser={authUser}
                    component={MainApp}
                    userSignOut={userSignOut}
                  />
                  <Route
                    component={asyncComponent(() =>
                      import('components/Error404')
                    )}
                  />
                </Switch>
                <MessageNotification />
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ settings, users, global }) => {
  const { sideNavColor, locale, isDirectionRTL } = settings;
  const { authUser, initURL } = users;
  return {
    sideNavColor,
    locale,
    isDirectionRTL,
    authUser,
    initURL,
  };
};

export default connect(mapStateToProps, { setInitUrl, userSignOut })(App);
