import {
  GET_ALL_DRIVERS_REQUEST,
  GET_SINGLE_DRIVER_REQUEST,
  GET_DRIVER_GROUP_REQUEST,
  ADD_DRIVER_REQUEST,
  UPDATE_DRIVER_REQUEST,
  DELETE_DRIVER_REQUEST,
  INITIAL_FETCH_SINGLE_DRIVER_REQUEST,
  GET_DRIVER_TMAVA_TOP_REPORT_REQUEST,
  GET_DRIVER_FUEL_CONSUMPTION_REPORT_REQUEST,
  GET_DRIVER_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST,
  GET_DRIVER_TOTAL_FUEL_REPORT_REQUEST,
  GET_DRIVER_TOTAL_DISTANCE_REPORT_REQUEST,
  GET_DRIVER_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST,
  GET_DRIVER_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST,
  GET_DRIVER_ACTIVITY_STATS_REPORT_REQUEST,
  GET_DRIVER_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST,
  GET_DRIVER_ACTIVITIES_BY_DRIVER_REQUEST,
  GET_DRIVER_ACTIVITIES_REQUEST,
  GET_PRESELECTED_DRIVER_BARFILTER_REQUEST,
  GET_DRIVERS_FILTER_REQUEST
} from '../constants/ActionTypes';

export const getDrivers = ({
  customerId,
  vehicleGroupId,
  vehicleId,
  offset,
  limit
}) => {
  return {
    type: GET_ALL_DRIVERS_REQUEST,
    payload: { customerId, vehicleGroupId, vehicleId, offset, limit }
  };
};

export const getSingleDriver = driverId => {
  return {
    type: GET_SINGLE_DRIVER_REQUEST,
    payload: driverId
  };
};

//? get le driver à partir de son ID et la liste des éco profiles disponibles pour ce driver
export const initialFetchSingleDriver = driverId => {
  return {
    type: INITIAL_FETCH_SINGLE_DRIVER_REQUEST,
    payload: driverId
  };
};

//? Get la liste des groupes de driver pour un customer précis
export const getDriverGroups = customerId => {
  return {
    type: GET_DRIVER_GROUP_REQUEST,
    payload: customerId
  };
};

//? Add un single driver
export const addDriver = info => {
  return {
    type: ADD_DRIVER_REQUEST,
    payload: info
  };
};

//? Update un single driver
export const updateDriver = info => {
  return {
    type: UPDATE_DRIVER_REQUEST,
    payload: info
  };
};

//? Delete un single driver
export const deleteDriver = driverId => {
  return {
    type: DELETE_DRIVER_REQUEST,
    payload: driverId
  };
};

export const getDriverTmavaTopReport = ({ driverId, startDate, endDate }) => {
  return {
    type: GET_DRIVER_TMAVA_TOP_REPORT_REQUEST,
    payload: { driverId, startDate, endDate }
  };
};

export const getDriverFuelConsumptionReport = ({
  driverId,
  startDate,
  endDate
}) => {
  return {
    type: GET_DRIVER_FUEL_CONSUMPTION_REPORT_REQUEST,
    payload: { driverId, startDate, endDate }
  };
};

export const getDriverFuelConsumptionReportMultiple = ({
  driverId,
  periods
}) => {
  return {
    type: GET_DRIVER_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST,
    payload: { driverId, periods }
  };
};

export const getDriverTotalFuelReport = ({ driverId, startDate, endDate }) => {
  return {
    type: GET_DRIVER_TOTAL_FUEL_REPORT_REQUEST,
    payload: { driverId, startDate, endDate }
  };
};

export const getDriverTotalDistanceReport = ({
  driverId,
  startDate,
  endDate
}) => {
  return {
    type: GET_DRIVER_TOTAL_DISTANCE_REPORT_REQUEST,
    payload: { driverId, startDate, endDate }
  };
};

export const getDriverTotalDistanceReportMultiple = ({ driverId, periods }) => {
  return {
    type: GET_DRIVER_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST,
    payload: { driverId, periods }
  };
};

export const getDriverTotalActivityDaysReport = ({
  driverId,
  startDate,
  endDate
}) => {
  return {
    type: GET_DRIVER_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST,
    payload: { driverId, startDate, endDate }
  };
};

export const getDriverActivityStatsReport = ({
  driverId,
  startDate,
  endDate
}) => {
  return {
    type: GET_DRIVER_ACTIVITY_STATS_REPORT_REQUEST,
    payload: { driverId, startDate, endDate }
  };
};

export const getDriverActivityStatsReportMultiple = ({ driverId, periods }) => {
  return {
    type: GET_DRIVER_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST,
    payload: { driverId, periods }
  };
};

export const getDriverActivitiesByDriver = ({
  driverId,
  startDate,
  endDate
}) => {
  return {
    type: GET_DRIVER_ACTIVITIES_BY_DRIVER_REQUEST,
    payload: { driverId, startDate, endDate }
  };
};

export const getDriverActivities = infos => {
  return {
    type: GET_DRIVER_ACTIVITIES_REQUEST,
    payload: infos
  };
};

export const getPreSelectedDriverBarFilter = ({ customerId, driverIds }) => {
  return {
    type: GET_PRESELECTED_DRIVER_BARFILTER_REQUEST,
    payload: { customerId, driverIds }
  };
};

export const getDriversFilter = ({
  customerId,
  driverIds,
  offset,
  limit,
  pattern
}) => {
  return {
    type: GET_DRIVERS_FILTER_REQUEST,
    payload: {
      customerId,
      driverIds,
      offset,
      limit,
      pattern
    }
  };
};
