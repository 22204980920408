// export const API_URL = 'http://localhost:8080/VepsiAPI/api/';
// export const WEBSOCKET_URL = 'ws://localhost:8080/VepsiAPI/websocket';
//export const API_URL = 'https://vepsi-api.alertgasoil.com/VepsiAPI/api/';  //? Back de prod
export const API_URL =
  document.domain === 'vepsi.alertgasoil.com'
    ? 'https://vepsi-api.alertgasoil.com/VepsiAPI/api/'
    : 'https://vepsi-api-dev.alertgasoil.com/VepsiAPI/api/'; //? Back de dev
export const WEBSOCKET_URL =
  document.domain === 'vepsi.alertgasoil.com'
    ? 'wss://vepsi-api.alertgasoil.com/websocket'
    : 'wss://vepsi-api-dev.alertgasoil.com/websocket';
export const APP_VERSION = '0.0.5';
