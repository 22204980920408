import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import ReactDOM from 'react-dom';
import startMarker from '../../../assets/images/start-icon.png';
import endMarker from '../../../assets/images/end-icon.png';
import tmavaMarker from '../../../assets/images/tmava-icon.png';
import tmavatMarker from '../../../assets/images/tmavat-icon.png';
import parkingMarker from '../../../assets/images/parking-icon.png';
import idlingMarker from '../../../assets/images/idling-icon.png';
import { getVehicleActivities } from '../../../actions/Trips';
import { getVehicleDailyReportByVehicle } from '../../../actions/Reports';
import {
  closeConsumptionVehicleModal,
  openConsumptionVehicleModal
} from '../../../actions/Global';

const styles = theme => ({
  mapWrapper: {
    height: '100%',
    width: '100%'
  }
});

class MapWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapInstance: null,
      markersStartEndTrip: [] //? Marker de début et de fin d'un trip spécifique
    };
    this.polylineList = []; //? Liste des polylines actuellement sur la map

    this.MAX_ZINDEX = 10000;
    this.mapRef = React.createRef();
  }

  componentDidMount() {
    this.props.getVehicleActivities({
      startDateWithTime: moment()
        .subtract(24, 'hours')
        .valueOf(),
      endDateWithTime: moment().valueOf(),
      truckId: this.props.vehicle.id
    });

    if (!window.google) {
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://maps.google.com/maps/api/js?key=AIzaSyC8yVaot1hkKAeQ2XtD05Z6CRMCWmCX65Y&libraries=drawing`;
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
      // Below is important.
      //We cannot access google.maps until it's finished loading
      s.addEventListener('load', e => {
        this.onScriptLoad();
      });
    } else {
      this.onScriptLoad();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.mapInstance &&
      prevProps.tripsList !== this.props.tripsList
    ) {
      console.log('======================');
      console.log('COUCOUCUOCUOCUOOCUOUC');
      console.log('======================');
      this.loadActivityOnMap(
        this.props.tripsList.activities,
        this.state.mapInstance
      );
    }
  }

  loadActivityOnMap = (tripsList, mapInstance) => {
    if (this.polylineList) {
      this.deleteAllPolylines();
    }
    let polylineList = [];
    let path = [];
    let bounds = new window.google.maps.LatLngBounds();
    let boundLocation = null;
    tripsList.map((trip, index) => {
      if (trip.firstLocation) {
        switch (trip.type) {
          case 'TRIP':
            path = [];
            trip.locations &&
              trip.locations.map((tripLocations, index) => {
                if (tripLocations.location) {
                  path.push({
                    lat: tripLocations.location.latitude,
                    lng: tripLocations.location.longitude
                  });
                  let tripBound = new window.google.maps.LatLng(
                    tripLocations.location.latitude,
                    tripLocations.location.longitude
                  );
                  bounds.extend(tripBound);
                }
              });

            if (path.length > 0) {
              let newPolyline = new window.google.maps.Polyline({
                id: index,
                path: path,
                strokeColor: '#3DADDF',
                strokeOpacity: 1.0,
                strokeWeight: 2,
                icons: [
                  {
                    icon: {
                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW
                    },
                    repeat: '50px'
                  }
                ],
                map: mapInstance
              });
              newPolyline.addListener('mouseover', e => {
                newPolyline.setOptions({
                  strokeColor: '#CD4B46',
                  strokeOpacity: 1.0,
                  strokeWeight: 2
                });
              });
              newPolyline.addListener('mouseout', e => {
                //? S'il n'y a pas d'event sélectionné, alors on reset la couleur de l'event hover

                newPolyline.setOptions({
                  strokeColor: '#3DADDF',
                  strokeOpacity: 1.0,
                  strokeWeight: 2
                });
              });
              polylineList.push(newPolyline);
            }

            break;
          case 'TMAVA':
            if (
              trip.firstLocation &&
              trip.firstLocation.latitude &&
              trip.firstLocation.longitude
            ) {
              var newMarker = new window.google.maps.Marker({
                position: {
                  lat: trip.firstLocation.latitude,
                  lng: trip.firstLocation.longitude
                },
                // label: label,
                icon: tmavaMarker,
                id: index,
                map: mapInstance
              });
              newMarker.addListener('click', e => {
                mapInstance.setCenter(newMarker.getPosition());
                mapInstance.setZoom(15);
                //mapInstance.panBy(-300, 0);
                newMarker.setOptions({
                  zIndex: ++this.MAX_ZINDEX
                });
              });

              let tmavaMarkerBound = new window.google.maps.LatLng(
                trip.firstLocation.latitude,
                trip.firstLocation.longitude
              );
              bounds.extend(tmavaMarkerBound);
              polylineList.push(newMarker);
            }

            break;
          case 'TMAVAT':
            if (
              trip.firstLocation &&
              trip.firstLocation.latitude &&
              trip.firstLocation.longitude
            ) {
              var newMarker = new window.google.maps.Marker({
                position: {
                  lat: trip.firstLocation.latitude,
                  lng: trip.firstLocation.longitude
                },
                // label: label,
                icon: tmavatMarker,
                id: index,
                map: mapInstance
              });
              newMarker.addListener('click', e => {
                mapInstance.setCenter(newMarker.getPosition());
                mapInstance.setZoom(15);
                //mapInstance.panBy(-300, 0);
                newMarker.setOptions({
                  zIndex: ++this.MAX_ZINDEX
                });
              });

              let tmavatBound = new window.google.maps.LatLng(
                trip.firstLocation.latitude,
                trip.firstLocation.longitude
              );
              bounds.extend(tmavatBound);
              polylineList.push(newMarker);
            }
            break;
          case 'STOPPED':
            if (
              trip.firstLocation &&
              trip.firstLocation.latitude &&
              trip.firstLocation.longitude
            ) {
              var newMarker = new window.google.maps.Marker({
                position: {
                  lat: trip.firstLocation.latitude,
                  lng: trip.firstLocation.longitude
                },
                // label: label,
                icon: parkingMarker,
                id: index,
                map: mapInstance
              });
              newMarker.addListener('click', e => {
                mapInstance.setCenter(newMarker.getPosition());
                mapInstance.setZoom(15);
                //mapInstance.panBy(-300, 0);
                newMarker.setOptions({
                  zIndex: ++this.MAX_ZINDEX
                });
              });

              let parkingMarkerBound = new window.google.maps.LatLng(
                trip.firstLocation.latitude,
                trip.firstLocation.longitude
              );
              bounds.extend(parkingMarkerBound);
              polylineList.push(newMarker);
            }
            break;
          case 'IDLING':
            if (
              trip.firstLocation &&
              trip.firstLocation.latitude &&
              trip.firstLocation.longitude
            ) {
              var newMarker = new window.google.maps.Marker({
                position: {
                  lat: trip.firstLocation.latitude,
                  lng: trip.firstLocation.longitude
                },
                // label: label,
                icon: idlingMarker,
                id: index,
                map: mapInstance
              });
              newMarker.addListener('click', e => {
                mapInstance.setCenter(newMarker.getPosition());
                mapInstance.setZoom(15);
                //mapInstance.panBy(-300, 0);
                newMarker.setOptions({
                  zIndex: ++this.MAX_ZINDEX
                });
              });

              let idlingMarkerBound = new window.google.maps.LatLng(
                trip.firstLocation.latitude,
                trip.firstLocation.longitude
              );
              bounds.extend(idlingMarkerBound);
              polylineList.push(newMarker);
            }
            break;

          default:
            break;
        }
      }
    });

    if (!bounds.isEmpty()) {
      mapInstance.fitBounds(bounds);
      //mapInstance.panBy(-300, 0);
    }

    this.polylineList = polylineList;
  };

  deleteAllPolylines() {
    //? On supprime tous les listener existants
    this.polylineList.map(polyline => {
      window.google.maps.event.clearInstanceListeners(polyline);
      polyline.setMap(null);
    });

    //? Si il y a des markers de début et de fin de trip, on les delete
    if (
      this.state.markersStartEndTrip &&
      this.state.markersStartEndTrip.length > 0
    ) {
      this.state.markersStartEndTrip.map((marker, index) => {
        window.google.maps.event.clearInstanceListeners(marker);
        marker.setMap(null);
      });
    }
  }

  onScriptLoad() {
    const map = new window.google.maps.Map(this.mapRef.current, {
      center: {
        lat: 46.5286,
        lng: 2.4389
      },
      zoom: 5,
      fullscreenControl: false,
      disableDefaultUI: true,
      streetViewControl: false,
      streetViewControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_CENTER
      },
      mapTypeControl: false,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
        mapTypeIds: [
          window.google.maps.MapTypeId.ROADMAP,
          window.google.maps.MapTypeId.SATELLITE
        ]
      },
      zoomControl: false,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_CENTER
      },
      gestureHandling: 'greedy',
      controlSize: 28,
      clickableIcons: false,
      styles: [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }]
        }
      ]
    });
    this.setState({ mapInstance: map });
  }

  render() {
    const classes = this.props.classes;

    return (
      <div
        ref={this.mapRef}
        className={classes.mapWrapper}
        width="100%"
        height="100%"
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.trips.fetching,
    tripsList: state.trips.trips
  };
};

const mapDispatchToProps = dispatch => ({
  getVehicleActivities: params => dispatch(getVehicleActivities(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MapWidget));
