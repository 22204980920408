export const colorStyle = theme => ({
  colorPrimary: {
    color: theme.palette.primary.main
  },
  colorPrimaryLight: {
    color: theme.palette.primary.light
  },
  colorSecondary: {
    color: theme.palette.secondary.main
  },
  colorAccent: {
    color: theme.palette.accent.main
  },
  colorInformation: {
    color: theme.status.information
  },
  colorSuccess: {
    color: theme.status.success
  },
  colorWarn: {
    color: theme.status.warn
  },
  colorDanger: {
    color: theme.status.danger
  }
});

export const bgColorStyle = theme => ({
  bgColorPrimary: {
    backgroundColor: theme.palette.primary.main
  },
  bgColorPrimaryLight: {
    backgroundColor: theme.palette.primary.light
  },
  bgColorSecondary: {
    backgroundColor: theme.palette.secondary.main
  },
  bgColorAccent: {
    backgroundColor: theme.palette.accent.main
  },
  bgColorInformation: {
    backgroundColor: theme.status.information
  },
  bgColorSuccess: {
    backgroundColor: theme.status.success
  },
  bgColorWarn: {
    backgroundColor: theme.status.warn
  },
  bgColorDanger: {
    backgroundColor: theme.status.danger
  }
});

export const borderColorStyle = theme => ({
  borderColorPrimary: {
    borderColor: theme.palette.primary.main
  },
  borderColorPrimaryLight: {
    borderColor: theme.palette.primary.light
  },
  borderColorSecondary: {
    borderColor: theme.palette.secondary.main
  },
  borderColorAccent: {
    borderColor: theme.palette.accent.main
  },
  borderColorInformation: {
    borderColor: theme.status.information
  },
  borderColorSuccess: {
    borderColor: theme.status.success
  },
  borderColorWarn: {
    borderColor: theme.status.warn
  },
  borderColorDanger: {
    borderColor: theme.status.danger
  }
});

export const fillColorStyle = theme => ({
  fillColorPrimary: {
    fill: theme.palette.primary.main
  },
  fillColorPrimaryLight: {
    fill: theme.palette.primary.light
  },
  fillColorSecondary: {
    fill: theme.palette.secondary.main
  },
  fillColorAccent: {
    fill: theme.palette.accent.main
  },
  fillColorInformation: {
    fill: theme.status.information
  },
  fillColorSuccess: {
    fill: theme.status.success
  },
  fillColorWarn: {
    fill: theme.status.warn
  },
  fillColorDanger: {
    fill: theme.status.danger
  }
});

//? design Buttons altermatifs
export const designButtons = theme => ({
  designButtonTransparent: {
    width: '100%',
    height: '3.4rem',
    backgroundColor: 'transparent',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: theme.palette.accent.main,
    border: '1px solid' + theme.palette.accent.main,
    borderRadius: '50px',
    margin: '0',
    padding: '0',
    transition: '0.25s',

    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.accent.main
    }
  },

  designButtonFilled: {
    width: '100%',
    height: '3.4rem',
    backgroundColor: theme.palette.accent.main,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'white',
    border: '1px solid' + theme.palette.accent.main,
    borderRadius: '50px',
    margin: '0',
    padding: '0',
    transition: '0.25s',

    '&:hover': {
      color: theme.palette.accent.main,
      backgroundColor: 'transparent'
    }
  },

  buttonAddManagement: {
    textTransform: 'none',
    color: theme.palette.primary.light,
    borderColor: theme.palette.secondary.main,
    backgroundColor: 'white',
    //padding: '0.4rem 1.5rem 1rem 1.5rem',
    borderWeight: 5,
    height: '3rem',
    '& svg': {
      '& path': {
        fill: theme.palette.primary.light
      }
    },
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.light,
      '& svg': {
        '& path': {
          fill: 'white'
        }
      }
    }
  }
});
