import {
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAILURE,
  GET_SINGLE_CUSTOMER_REQUEST,
  GET_SINGLE_CUSTOMER_SUCCESS,
  GET_SINGLE_CUSTOMER_FAILURE,
  GET_PRESELECTED_CUSTOMER_BARFILTER_REQUEST,
  GET_PRESELECTED_CUSTOMER_BARFILTER_SUCCESS,
  GET_PRESELECTED_CUSTOMER_BARFILTER_FAILURE,
  GET_CUSTOMERS_FILTER_REQUEST,
  GET_CUSTOMERS_FILTER_SUCCESS,
  GET_CUSTOMERS_FILTER_FAILURE,
  GET_CUSTOMER_STATUS_LIST_REQUEST,
  GET_CUSTOMER_STATUS_LIST_SUCCESS,
  GET_CUSTOMER_STATUS_LIST_FAILURE,
  GET_CUSTOMER_STATS_REPORT_REQUEST,
  GET_CUSTOMER_STATS_REPORT_SUCCESS,
  GET_CUSTOMER_STATS_REPORT_FAILURE,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE
} from 'constants/ActionTypes';

const INIT_STATE = {
  fetching: false,
  customers: [],
  customer: null,
  preSelectedCustomerBarFilter: null,
  customerSelectedSites: [],
  statusList: [],
  updatedCustomer: null,
  groupLevels: [],
  error: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //? GET CUSTOMERS //
    case GET_CUSTOMERS_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_CUSTOMERS_SUCCESS:
      return { ...state, fetching: false, customers: action.customers };
    case GET_CUSTOMERS_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? GET SINGLE CUSTOMER //
    case GET_SINGLE_CUSTOMER_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_SINGLE_CUSTOMER_SUCCESS:
      return { ...state, fetching: false, customer: action.customer };
    case GET_SINGLE_CUSTOMER_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET PRE SELECTED CUSTOMER IN BAR FILTER //
    case GET_PRESELECTED_CUSTOMER_BARFILTER_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_PRESELECTED_CUSTOMER_BARFILTER_SUCCESS:
      return {
        ...state,
        fetching: false,
        preSelectedCustomerBarFilter: action.preSelectedCustomer
      };
    case GET_PRESELECTED_CUSTOMER_BARFILTER_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? UPDATE SINGLE CUSTOMER //
    case UPDATE_CUSTOMER_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case UPDATE_CUSTOMER_SUCCESS:
      return { ...state, fetching: false, updatedCustomer: action.customer };
    case UPDATE_CUSTOMER_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET CUSTOMERS FILTER //
    case GET_CUSTOMERS_FILTER_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_CUSTOMERS_FILTER_SUCCESS:
      return { ...state, fetching: false, customersFilter: action.customers };
    case GET_CUSTOMERS_FILTER_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET CUSTOMER STATUS LIST //
    case GET_CUSTOMER_STATUS_LIST_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_CUSTOMER_STATUS_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        statusList: action.customerStatusList
      };
    case GET_CUSTOMER_STATUS_LIST_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET CUSTOMER STATS REPORT//
    case GET_CUSTOMER_STATS_REPORT_REQUEST: {
      return {
        ...state,
        statsReportFetching: true,
        error: null
      };
    }
    case GET_CUSTOMER_STATS_REPORT_SUCCESS:
      return {
        ...state,
        statsReportFetching: false,
        statsReport: action.customerStatsReport
      };
    case GET_CUSTOMER_STATS_REPORT_FAILURE:
      return { ...state, statsReportFetching: false, error: action.error };

    default:
      return state;
  }
};
