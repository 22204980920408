//import appLocaleData from 'react-intl/locale-data/en';
import enMessages from '../locales/en_EN.json';

const EnLang = {
  messages: {
    ...enMessages
  },
  locale: 'en-EN'
  //data: appLocaleData
};
export default EnLang;
