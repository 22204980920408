import React from 'react';
import { withTheme } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { NavLink } from 'react-router-dom';
import { userSignOut } from 'actions/Users';
import IntlMessages from 'util/IntlMessages';
import IcomoonReact from 'icomoon-react';
import iconSet from 'styles/iconFont/selection.json';

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div
        className="user-profile d-flex flex-row align-items-center"
        // style={{ marginRight: '2rem' }}
      >
        <IcomoonReact
          iconSet={iconSet}
          color={this.props.theme.palette.primary.light}
          size={16}
          icon="account_outline"
          className="mr-1"
        />
        <div className="user-detail">
          <p className="user-name" onClick={this.handleClick}>
            Mon compte
            {/* {`${this.props.authUser.firstName} ${this.props.authUser.lastName}`} */}
            <i
              className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"
              style={{ color: this.props.theme.palette.primary.light }}
            />
          </p>
        </div>
        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0
            }
          }}>
          <MenuItem onClick={this.handleRequestClose}>
            <i
              className="zmdi zmdi-account zmdi-hc-fw mr-2"
              style={{ color: this.props.theme.palette.primary.light }}
            />
            <IntlMessages id="popup.profile" />
          </MenuItem>
          <NavLink
            rel="keep-params"
            to="/my-account"
            className="remove-link-style">
            <MenuItem
              onClick={() => {
                this.handleRequestClose();
              }}>
              <i
                className="zmdi zmdi-settings zmdi-hc-fw mr-2"
                style={{ color: this.props.theme.palette.primary.light }}
              />
              <IntlMessages id="popup.setting" />
            </MenuItem>
          </NavLink>
          <NavLink
            rel="keep-params"
            to="/my-account"
            target="_blank"
            className="remove-link-style">
            <MenuItem
              onClick={() => {
                this.handleRequestClose();
              }}>
              <i
                className="zmdi zmdi-help zmdi-hc-fw mr-2"
                style={{ color: this.props.theme.palette.primary.light }}
              />
              <IntlMessages id="popup.help" />
            </MenuItem>
          </NavLink>
          <MenuItem
            onClick={() => {
              this.handleRequestClose();
              this.props.userSignOut();
            }}>
            <i
              className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"
              style={{ color: this.props.theme.palette.primary.light }}
            />

            <IntlMessages id="popup.logout" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, users }) => {
  const { locale } = settings;
  const { authUser } = users;
  return { locale, authUser };
};
export default withTheme(connect(mapStateToProps, { userSignOut })(UserInfo));
