import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
  getDriverActivitiesByVehicle,
  getVehicleFuelReport,
  getVehicleFuelReportByDriver,
} from '../../../actions/Vehicles';
import {
  getDriverActivities,
  getVehicleActivities,
} from '../../../actions/Trips';
import { getDriverActivitiesByDriver } from '../../../actions/Drivers';
import {
  getAlertSingleDriver,
  getAlertSingleVehicle,
} from '../../../actions/Alerts';
import SimpleTimelineChart from '../SimpleTimelineChart';
import SimpleComposedChart from '../SimpleComposedChart';
import * as moment from 'moment';
import numberSeparator from 'number-separator';
import CircularProgress from '../../CircularProgress';

const stringWidth = require('string-width');

const styles = (theme) => ({
  mapWrapper: {
    width: '100%',
    marginTop: '2rem',
    border: '1px solid #CACED5',
    boxShadow: '0px 2px 6px #0000000A',
  },
  cardStyle: {
    border: '1px solid #CACED5',
    padding: '1.5rem',
    marginBottom: 0,
    borderRadius: 0,
    boxShadow: '0px 2px 6px #0000000A',
    height: '100%',
  },
  cardContent: {
    position: 'relative',
    borderTop: 'solid 1px #ebeef1',
  },
  chartStyle: {
    width: '100%',
  },
  buttonDays: {
    fontSize: '1.1rem',
    fontWeight: 500,
    height: '2.6rem',
    border: '1px solid' + theme.palette.accent.main,
    borderRadius: 50,
    color: theme.palette.accent.main,
    backgroundColor: 'white',
  },
  customTootip: {
    margin: '0px',
    padding: '10px',
    backgroundColor: 'rgb(255, 255, 255)',
    border: '1px solid rgb(204, 204, 204)',
    whiteSpace: 'nowrap',
  },
  wrapper: {
    height: '20rem',
  },
  wrapperInner: {
    width: '100%',
    zIndex: '9999999999',
  },
  hoverAction: {
    transition: theme.transitions.create(['color'], {
      duration: theme.transitions.duration.complex,
    }),
    '&:hover': {
      cursor: 'pointer',
      color: `${theme.palette.primary.dark} !important`,
    },
  },
  monitoringButton: {
    height: '2.6rem',
    textTransform: 'none',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.light,
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: '50px',
    fontSize: '1.1rem',
    '& svg': {
      '& path': {
        fill: theme.palette.primary.light,
      },
    },

    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.light,
      '& svg': {
        '& path': {
          fill: 'white',
        },
      },
    },
  },
});

class MonitoringWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.getVehicleFuelReport({
      startDate: moment().subtract(24, 'hours').valueOf(),
      endDate: moment().valueOf(),
      vehicleId: this.props.vehicle.id,
    });

    this.props.getDriverActivitiesByVehicle({
      startDate: moment().subtract(24, 'hours').valueOf(),
      endDate: moment().valueOf(),
      vehicleId: this.props.vehicle.id,
    });

    this.props.getVehicleActivities({
      startDateWithTime: moment().subtract(24, 'hours').valueOf(),
      endDateWithTime: moment().valueOf(),
      truckId: this.props.vehicle.id,
    });
  }

  render() {
    const classes = this.props.classes;
    let maxWidthLabel = 60;
    let lastEntityNames = [];
    let nbDifferentEntities = 0;

    let data = [];
    let dataCpt = 0;
    let ticks = [];
    let data2 = [];
    let data2Cpt = 0;
    let data3 = [];
    let dataKeys2 = [
      { key: 'WORK', color: '#FFBA5E' },
      { key: 'DRIVING', color: '#B2CD73' },
      { key: 'BREAK_REST', color: '#5CB2F7' },
    ];

    if (
      !this.props.driverActivitiesFetching &&
      !this.props.fuelReportFetching &&
      this.props.fuelReport
    ) {
      let max = 0;
      for (let idx in this.props.fuelReport.deviceData) {
        if (this.props.fuelReport.deviceData[idx].tankFuelLiters) {
          if (max < this.props.fuelReport.deviceData[idx].tankFuelLiters['1'])
            max = this.props.fuelReport.deviceData[idx].tankFuelLiters['1'];
          if (max < this.props.fuelReport.deviceData[idx].tankFuelLiters['2'])
            max = this.props.fuelReport.deviceData[idx].tankFuelLiters['2'];
        }
      }

      for (let idx in this.props.fuelReport.deviceData) {
        let deviceData = this.props.fuelReport.deviceData[idx];

        const date = moment(deviceData.dateTime);
        if (date.hour() % 2 == 0 && date.minute() == 0)
          ticks.push(deviceData.dateTime);

        data.push({
          name: deviceData.dateTime,
          'Réservoir 1':
            deviceData.tankFuelLiters && deviceData.tankFuelLiters['1']
              ? deviceData.tankFuelLiters['1']
              : null,
          'Réservoir 2':
            deviceData.tankFuelLiters && deviceData.tankFuelLiters['2']
              ? deviceData.tankFuelLiters['2']
              : null,
          'Volume total':
            deviceData.tankFuelLiters && deviceData.tankFuelLiters['1']
              ? deviceData.tankFuelLiters['1']
              : 0 + deviceData.tankFuelLiters && deviceData.tankFuelLiters['2']
              ? deviceData.tankFuelLiters['2']
              : 0,
          Tension:
            deviceData.mapAttributeData && deviceData.mapAttributeData.BATTERY
              ? (max / 190) * parseFloat(deviceData.mapAttributeData.BATTERY)
              : 0,
          Moteur: deviceData.calculatedEngine ? max / 4 : null,
          Contact:
            deviceData.mapAttributeData &&
            deviceData.mapAttributeData.IGNITION == 'true'
              ? max / 5
              : null,
          Vitesse: deviceData.calculatedSpeed
            ? (max / 800) * deviceData.calculatedSpeed
            : 0,
          PDF: deviceData.calculatedPto ? max / 6 : null,
          max: max,
        });
        dataCpt++;

        if (this.props.driverActivities) {
          let found = false;
          for (let idx in this.props.driverActivities.activities) {
            if (
              this.props.driverActivities.activities[idx].startDateTime <=
                deviceData.dateTime &&
              this.props.driverActivities.activities[idx].endDateTime >=
                deviceData.dateTime
            ) {
              let obj = {
                name: deviceData.dateTime,
              };

              obj[this.props.driverActivities.activities[idx].workstate] = this
                .props.driverActivities.activities[idx].driver.firstName
                ? this.props.driverActivities.activities[idx].driver.firstName +
                  ' ' +
                  this.props.driverActivities.activities[idx].driver.lastName
                : this.props.driverActivities.activities[idx].driver.driverCode;

              if (obj[this.props.driverActivities.activities[idx].workstate]) {
                let width =
                  stringWidth(
                    obj[this.props.driverActivities.activities[idx].workstate]
                  ) *
                    8 +
                  20;
                if (maxWidthLabel < width) maxWidthLabel = width;

                if (
                  lastEntityNames.indexOf(
                    obj[this.props.driverActivities.activities[idx].workstate]
                  ) == -1
                ) {
                  nbDifferentEntities++;
                  lastEntityNames.push(
                    obj[this.props.driverActivities.activities[idx].workstate]
                  );
                }
              }

              data2.push(obj);
              data3[data2Cpt] = data[data2Cpt];
              if (this.props.selectedDrivers && this.props.selectedDrivers[0]) {
                data3[data2Cpt].driverName = this.props.selectedDrivers[0]
                  .firstName
                  ? this.props.selectedDrivers[0].firstName +
                    ' ' +
                    this.props.selectedDrivers[0].lastName
                  : this.props.selectedDrivers[0].driverCode;
                data3[data2Cpt].vehicleName =
                  obj[this.props.driverActivities.activities[idx].workstate];
              } else if (
                this.props.selectedVehicles &&
                this.props.selectedVehicles[0]
              ) {
                data3[data2Cpt].vehicleName =
                  this.props.selectedVehicles[0] &&
                  this.props.selectedVehicles[0].plate
                    ? this.props.selectedVehicles[0].plate
                    : this.props.selectedVehicles[0].vin;
                data3[data2Cpt].driverName =
                  obj[this.props.driverActivities.activities[idx].workstate];
              }
              data3[
                data2Cpt
              ].workstate = this.props.driverActivities.activities[
                idx
              ].workstate;
              data2Cpt++;

              let color;
              for (let idx2 in dataKeys2) {
                if (
                  dataKeys2[idx2].key ===
                  this.props.driverActivities.activities[idx].workstate
                ) {
                  color = dataKeys2[idx2].color;
                  break;
                }
              }
              data[data2.length - 1].activity = {
                state: this.props.driverActivities.activities[idx].workstate,
                startDateTime: this.props.driverActivities.activities[idx]
                  .startDateTime,
                endDateTime: this.props.driverActivities.activities[idx]
                  .endDateTime,
                entity:
                  obj[this.props.driverActivities.activities[idx].workstate],
                color: color,
              };

              if (
                this.state[
                  obj[this.props.driverActivities.activities[idx].workstate]
                ]
              ) {
                delete data[data2.length - 1]['Réservoir 1'];
                delete data[data2.length - 1]['Réservoir 2'];
                delete data[data2.length - 1].Tension;
                delete data[data2.length - 1].Moteur;
                delete data[data2.length - 1].Contact;
                delete data[data2.length - 1].Vitesse;
                delete data[data2.length - 1].PDF;
                delete data[data2.length - 1].max;
              }
              found = true;
              break;
            }
          }
          if (!found) {
            data2.push({
              name: deviceData.dateTime,
            });
            data3[data2Cpt] = data[data2Cpt];
            if (this.props.selectedDrivers && this.props.selectedDrivers[0]) {
              data3[data2Cpt].driverName = this.props.selectedDrivers[0]
                .firstName
                ? this.props.selectedDrivers[0].firstName +
                  ' ' +
                  this.props.selectedDrivers[0].lastName
                : this.props.selectedDrivers[0].driverCode;
            } else if (
              this.props.selectedVehicles &&
              this.props.selectedVehicles[0]
            ) {
              data3[data2Cpt].vehicleName =
                this.props.selectedVehicles[0] &&
                this.props.selectedVehicles[0].plate
                  ? this.props.selectedVehicles[0].plate
                  : this.props.selectedVehicles[0].vin;
            }
            data2Cpt++;
          }
        }
      }
    }

    return (
      <>
        {this.props.fuelReportFetching ? (
          <div className={`w-100 loader-view p-3`}>
            <CircularProgress />
          </div>
        ) : data.length > 0 ? (
          <>
            {this.props.driverActivities &&
            this.props.driverActivities.activities.length > 0 ? (
              <SimpleTimelineChart
                height={19 + nbDifferentEntities * 9 + '%'}
                YAxisWidth={maxWidthLabel}
                data={data2}
                dataKeys={dataKeys2}
                YAxisOnClick={(e) => {
                  let obj = {};
                  obj[e.value] = !this.state[e.value];
                  this.setState(obj);
                }}
                formatYAxis={(tickItem) => {
                  if (this.state[tickItem]) return '- ' + tickItem;
                  else return tickItem;
                }}
                customTooTip={({ active, payload, label }) => {}}
                legend={false}
                verticalLegend={false}
                syncId={'monitoringDetailGraph'}
              />
            ) : null}
            <SimpleComposedChart
              height={
                this.props.driverActivities &&
                this.props.driverActivities.activities.length > 0
                  ? 100 - (19 + nbDifferentEntities * 9) + '%'
                  : '100%'
              }
              YAxisWidth={maxWidthLabel}
              formatXAxis={(tickItem) => {
                return moment(tickItem).format('HH:mm');
              }}
              ticks={ticks}
              legend={true}
              data={data}
              onClick={this.onClick}
              onRef={(ref) => {
                this.chartRef = ref;
              }}
              dataKeys={[
                {
                  key: 'Réservoir 1',
                  stroke: '#FFC207',
                  chart: 'Line',
                },
                {
                  key: 'Réservoir 2',
                  stroke: '#FF9B37',
                  chart: 'Line',
                },
                {
                  key: 'Volume total',
                  stroke: '#3DADE0',
                  chart: 'Line',
                },
                { key: 'Tension', stroke: '#80CAC3', chart: 'Line' },
                { key: 'Moteur', stroke: '#EB5579', chart: 'Line' },
                { key: 'Contact', stroke: '#87C443', chart: 'Line' },
                { key: 'Vitesse', stroke: '#454E64', chart: 'Line' },
                { key: 'PDF', stroke: '#454E64', chart: 'Line' },
              ]}
              customTooTip={({ active, payload, label }) => {
                if (active && payload) {
                  return (
                    <div
                      className={`${classes.customTootip}`}
                      style={payload[0] ? { color: payload[0].color } : {}}>
                      <p style={{ color: 'black' }} className="label">
                        {moment(label).format('HH:mm')}
                      </p>
                      {payload.map((data, index) => {
                        if (data.value) {
                          switch (data.dataKey) {
                            case 'Tension':
                              data.value =
                                (data.value / data.payload.max) * 190;
                              break;
                            case 'Moteur':
                              if (data.value > 0) data.value = 'allumé';
                              else data.value = 'éteint';
                              break;
                            case 'Contact':
                              if (data.value > 0) data.value = 'allumé';
                              else data.value = 'éteint';
                              break;
                            case 'Vitesse':
                              data.value =
                                (data.value / data.payload.max) * 400;
                              break;
                            case 'PDF':
                              if (data.value > 0) data.value = 'allumée';
                              else data.value = 'éteinte';
                              break;
                          }
                          return (
                            <p
                              key={index}
                              style={{ color: data.color }}
                              className="intro">
                              {data.dataKey}:{' '}
                              {data.value.toFixed
                                ? numberSeparator(data.value.toFixed(2))
                                : data.value}
                            </p>
                          );
                        }
                      })}
                      {payload && payload[0] && payload[0].payload.activity ? (
                        <div
                          className="intro"
                          style={{
                            color: payload[0].payload.activity.color,
                          }}>
                          {payload[0].payload.activity.entity}:{' '}
                          {payload[0].payload.activity.state} (
                          {moment(
                            payload[0].payload.activity.startDateTime
                          ).format('HH:mm')}{' '}
                          -{' '}
                          {moment(
                            payload[0].payload.activity.endDateTime
                          ).format('HH:mm')}
                          )
                        </div>
                      ) : null}
                    </div>
                  );
                }
                return null;
              }}
              syncId={'monitoringDetailGraph'}
            />
          </>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ({ global, vehicles, trips, drivers, alerts }) => {
  return {
    fuelReportFetching: vehicles.fuelReportFetching,
    fuelReport: vehicles.fuelReport,
    driverActivitiesFetching:
      vehicles.driverActivitiesFetching || drivers.driverActivitiesFetching,
    driverActivities: vehicles.driverActivities || drivers.driverActivities,
    selectedVehicles: global.selectedVehicles,
    selectedDrivers: global.selectedDrivers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getVehicleFuelReport: (param) => dispatch(getVehicleFuelReport(param)),
  getVehicleFuelReportByDriver: (param) =>
    dispatch(getVehicleFuelReportByDriver(param)),
  getDriverActivitiesByVehicle: (param) =>
    dispatch(getDriverActivitiesByVehicle(param)),
  getVehicleActivities: (param) => dispatch(getVehicleActivities(param)),
  getDriverActivitiesByDriver: (param) =>
    dispatch(getDriverActivitiesByDriver(param)),
  getDriverActivities: (param) => dispatch(getDriverActivities(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withStyles(
      (theme) => ({
        ...styles(theme),
      }),
      { withTheme: true }
    )(MonitoringWidget)
  )
);
