import React, { Component } from 'react';
import {
  BarChart,
  Bar,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Cell
} from 'recharts';
//import data from './data-linechart';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100
  }
];
const COLORS = ['#76E6AE', '#67DDFE', '#FFC81F', '#FF7E1A'];

class SimpleBarChart extends Component {
  render() {
    return (
      <ResponsiveContainer
        id={
          this.props.chartForWidget
            ? this.props.chartForWidget
            : 'chart-container'
        }
        width="100%"
        height={this.props.height ? this.props.height : '100%'}>
        <BarChart
          layout={'vertical'}
          data={this.props.data ? this.props.data : data}
          margin={{
            top: 10,
            right: this.props.chartForWidget === 'tmava-vs-driver' ? 30 : 0,
            left: 0,
            bottom: 10
          }}
          syncId={this.props.syncId}>
          <CartesianGrid strokeDasharray="3 3" />

          <XAxis
            padding={{ top: 20, right: 0, left: 0, bottom: 20 }}
            type={'number'}
            orientation={'bottom'}
          />
          <YAxis
            padding={{ top: 20, right: 0, left: 0, bottom: 20 }}
            type={'category'}
            orientation={'left'}
            dataKey={'name'}
            width={
              this.props.chartForWidget === 'repartition-consumption'
                ? 45
                : this.props.chartForWidget === 'tmava-vs-driver'
                ? 50
                : 60
            }
          />

          {this.props.customTooTip ? (
            <Tooltip
              content={this.props.customTooTip}
              wrapperStyle={{ zIndex: 99999999 }}
            />
          ) : (
            <Tooltip wrapperStyle={{ zIndex: 99999999 }} />
          )}
          {this.props.dataKeys
            ? this.props.dataKeys.map((dataKey, index) => {
                if (dataKey instanceof Object) {
                  return (
                    <Bar
                      dataKey={dataKey.key}
                      fill={dataKey.color}
                      stackId="1"
                      barSize={100}></Bar>
                  );
                } else
                  return (
                    <Bar dataKey={dataKey} fill="#67DDFE" barSize={25}>
                      {(this.props.data ? this.props.data : data).map(
                        (entry, index) => {
                          const color = entry.color ? entry.color : '#76E6AE';
                          return <Cell fill={color} />;
                        }
                      )}
                    </Bar>
                  );
              })
            : null}

          {!this.props.dataKeys ? (
            <Bar
              type="monotone"
              dataKey="uv"
              stackId="1"
              stroke="#76E6AE"
              fill="url(#color1)"
            />
          ) : null}
          {!this.props.dataKeys ? (
            <Bar
              type="monotone"
              dataKey="pv"
              stackId="1"
              stroke="#67DDFE"
              fill="url(#color2)"
            />
          ) : null}
          {!this.props.dataKeys ? (
            <Bar
              type="monotone"
              dataKey="amt"
              stackId="1"
              stroke="#FFC81F"
              fill="url(#color3)"
            />
          ) : null}

          {this.props.legend ? (
            this.props.verticalLegend ? (
              <Legend
                iconType="square"
                layout="vertical"
                verticalAlign="middle"
                align={'right'}
                wrapperStyle={{ right: 0 }}
              />
            ) : (
              <Legend iconType="square" layout="horizontal" align="center" />
            )
          ) : null}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default SimpleBarChart;
