import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import {
  GET_TANK_SERIES_REQUEST,
  GET_TANK_SERIES_SUCCESS,
  GET_TANK_SERIES_FAILURE,
  UPDATE_TANK_SERIE_REQUEST,
  UPDATE_TANK_SERIE_SUCCESS,
  UPDATE_TANK_SERIE_FAILURE,
  GET_SINGLE_TANK_SERIES_REQUEST,
  GET_SINGLE_TANK_SERIES_SUCCESS,
  GET_SINGLE_TANK_SERIES_FAILURE,
  ADD_TANK_SERIE_REQUEST,
  ADD_TANK_SERIE_SUCCESS,
  ADD_TANK_SERIE_FAILURE,
  GET_TANK_SHAPE_TYPES_REQUEST,
  GET_TANK_SHAPE_TYPES_SUCCESS,
  GET_TANK_SHAPE_TYPES_FAILURE,
  GET_TANK_MODELS_REQUEST,
  GET_TANK_MODELS_SUCCESS,
  GET_TANK_MODELS_FAILURE,
  GET_SINGLE_TANK_MODEL_REQUEST,
  GET_SINGLE_TANK_MODEL_SUCCESS,
  GET_SINGLE_TANK_MODEL_FAILURE,
  LOAD_SINGLE_TANK_MODELS_REQUEST,
  LOAD_SINGLE_TANK_MODELS_SUCCESS,
  LOAD_SINGLE_TANK_MODELS_FAILURE,
  GET_TANK_MODELS_FOR_ONE_SERIE_REQUEST,
  GET_TANK_MODELS_FOR_ONE_SERIE_SUCCESS,
  GET_TANK_MODELS_FOR_ONE_SERIE_FAILURE,
  ADD_TANK_MODEL_REQUEST,
  ADD_TANK_MODEL_SUCCESS,
  ADD_TANK_MODEL_FAILURE,
  UPDATE_TANK_MODEL_REQUEST,
  UPDATE_TANK_MODEL_SUCCESS,
  UPDATE_TANK_MODEL_FAILURE,
  DELETE_TANK_MODEL_REQUEST,
  DELETE_TANK_MODEL_SUCCESS,
  DELETE_TANK_MODEL_FAILURE,
  CALC_VOLUME_TANK_MODELS_REQUEST,
  CALC_VOLUME_TANK_MODELS_SUCCESS,
  CALC_VOLUME_TANK_MODELS_FAILURE,
  CALC_VOLUME_TANK_MODELS_AGINSTALLER_REQUEST,
  CALC_VOLUME_TANK_MODELS_AGINSTALLER_SUCCESS,
  CALC_VOLUME_TANK_MODELS_AGINSTALLER_FAILURE,
  GET_TANK_CALIBRATIONS_REQUEST,
  GET_TANK_CALIBRATIONS_SUCCESS,
  GET_TANK_CALIBRATIONS_FAILURE,
  CALC_VMIN_VMAX_GAUGE_REQUEST,
  CALC_VMIN_VMAX_GAUGE_SUCCESS,
  CALC_VMIN_VMAX_GAUGE_FAILURE,
  CALC_DEFAULT_VMIN_GAUGE_REQUEST,
  CALC_DEFAULT_VMIN_GAUGE_SUCCESS,
  CALC_DEFAULT_VMIN_GAUGE_FAILURE,
  GET_SINGLE_TANK_CALIBRATION_REQUEST,
  GET_SINGLE_TANK_CALIBRATION_SUCCESS,
  GET_SINGLE_TANK_CALIBRATION_FAILURE,
  GET_TANK_CALIBRATIONS_FOR_ONE_MODEL_REQUEST,
  GET_TANK_CALIBRATIONS_FOR_ONE_MODEL_SUCCESS,
  GET_TANK_CALIBRATIONS_FOR_ONE_MODEL_FAILURE,
  ADD_TANK_CALIBRATION_REQUEST,
  ADD_TANK_CALIBRATION_SUCCESS,
  ADD_TANK_CALIBRATION_FAILURE,
  UPDATE_TANK_CALIBRATION_REQUEST,
  UPDATE_TANK_CALIBRATION_SUCCESS,
  UPDATE_TANK_CALIBRATION_FAILURE,
  GET_RECOMMENDED_GAUGE_TYPES_REQUEST,
  GET_RECOMMENDED_GAUGE_TYPES_SUCCESS,
  GET_RECOMMENDED_GAUGE_TYPES_FAILURE,
  GET_GAUGE_TYPES_REQUEST,
  GET_GAUGE_TYPES_SUCCESS,
  GET_GAUGE_TYPES_FAILURE,
  GET_SINGLE_RECOMMENDED_GAUGE_TYPE_REQUEST,
  GET_SINGLE_RECOMMENDED_GAUGE_TYPE_SUCCESS,
  GET_SINGLE_RECOMMENDED_GAUGE_TYPE_FAILURE,
  GET_TCD_FOR_ONE_TANK_CALIBRATION_REQUEST,
  GET_TCD_FOR_ONE_TANK_CALIBRATION_SUCCESS,
  GET_TCD_FOR_ONE_TANK_CALIBRATION_FAILURE,
  START_CALC_TCD_REQUEST,
  START_CALC_TCD_SUCCESS,
  START_CALC_TCD_FAILURE,
  GET_ALL_TANK_SERIE_WITH_ASSOCIATED_MODELS_REQUEST,
  GET_ALL_TANK_SERIE_WITH_ASSOCIATED_MODELS_SUCCESS,
  GET_ALL_TANK_SERIE_WITH_ASSOCIATED_MODELS_FAILURE,
  GET_TANK_BRANDS_REQUEST,
  GET_TANK_BRANDS_SUCCESS,
  GET_TANK_BRANDS_FAILURE,
  GET_TANK_MATERIAL_TYPES_REQUEST,
  GET_TANK_MATERIAL_TYPES_SUCCESS,
  GET_TANK_MATERIAL_TYPES_FAILURE,
} from 'constants/ActionTypes';
import { showMessage } from 'actions/Global';
import { API_URL } from 'constants/globalConstants';
import axios from 'axios';

export function* watcherGetGaugeTypes() {
  yield takeLatest(GET_GAUGE_TYPES_REQUEST, workerGetGaugeTypes);
}

export function* watcherGetAllTankSeries() {
  yield takeLatest(GET_TANK_SERIES_REQUEST, workerGetAllTankSeries);
}

export function* watcherGetSingleTankSerie() {
  yield takeLatest(GET_SINGLE_TANK_SERIES_REQUEST, workerGetSingleTankSerie);
}

export function* watcherAddTankSerie() {
  yield takeLatest(ADD_TANK_SERIE_REQUEST, workerAddTankSerie);
}

export function* watcherUpdateTankSerie() {
  yield takeLatest(UPDATE_TANK_SERIE_REQUEST, workerUpdateTankSerie);
}

export function* watcherGetTankShapeTypes() {
  yield takeLatest(GET_TANK_SHAPE_TYPES_REQUEST, workerGetTankShapeTypes);
}

export function* watcherGetAllTankModels() {
  yield takeLatest(GET_TANK_MODELS_REQUEST, workerGetAllTankModels);
}

export function* watcherGetSingleTankModel() {
  yield takeLatest(GET_SINGLE_TANK_MODEL_REQUEST, workerGetSingleTankModel);
}

export function* watcherLoadSingleTankModelForm() {
  yield takeLatest(
    LOAD_SINGLE_TANK_MODELS_REQUEST,
    workerLoadSingleTankModelForm
  );
}

export function* watcherGetTankModelsForOneSerial() {
  yield takeLatest(
    GET_TANK_MODELS_FOR_ONE_SERIE_REQUEST,
    workerGetTankModelsForOneSerial
  );
}

export function* watcherAddTankModel() {
  yield takeLatest(ADD_TANK_MODEL_REQUEST, workerAddTankModel);
}

export function* watcherUpdateTankModel() {
  yield takeLatest(UPDATE_TANK_MODEL_REQUEST, workerUpdateTankModel);
}

export function* watcherDeleteTankModel() {
  yield takeLatest(DELETE_TANK_MODEL_REQUEST, workerDeleteTankModel);
}

export function* watcherCalcVolumeTankModel() {
  yield takeLatest(CALC_VOLUME_TANK_MODELS_REQUEST, workerCalcVolumeTankModel);
}

export function* watcherCalcVolumeTankModelAginstaller() {
  yield takeLatest(
    CALC_VOLUME_TANK_MODELS_AGINSTALLER_REQUEST,
    workerCalcVolumeTankModelAginstaller
  );
}

export function* watcherGetAllTankCalibrations() {
  yield takeLatest(GET_TANK_CALIBRATIONS_REQUEST, workerGetAllTankCalibrations);
}

export function* watcherGetSingleTankCalibration() {
  yield takeLatest(
    GET_SINGLE_TANK_CALIBRATION_REQUEST,
    workerGetSingleTankCalibration
  );
}

export function* watcherCalcVminVmaxGauge() {
  yield takeLatest(CALC_VMIN_VMAX_GAUGE_REQUEST, workerCalcVminVmaxGauge);
}

export function* watcherCalcDefaultVminGauge() {
  yield takeLatest(CALC_DEFAULT_VMIN_GAUGE_REQUEST, workerCalcDefaultVminGauge);
}

export function* watcherGetTankCalibrationsForOneModel() {
  yield takeLatest(
    GET_TANK_CALIBRATIONS_FOR_ONE_MODEL_REQUEST,
    workerGetTankCalibrationsForOneModel
  );
}

export function* watcherAddTankCalibration() {
  yield takeLatest(ADD_TANK_CALIBRATION_REQUEST, workerAddTankCalibration);
}

export function* watcherUpdateTankCalibration() {
  yield takeLatest(
    UPDATE_TANK_CALIBRATION_REQUEST,
    workerUpdateTankCalibration
  );
}

export function* watcherGetRecommendedGaugeTypes() {
  yield takeLatest(
    GET_RECOMMENDED_GAUGE_TYPES_REQUEST,
    workerGetRecommendedGaugeTypes
  );
}

export function* watcherGetSingleRecommendedGaugeType() {
  yield takeLatest(
    GET_SINGLE_RECOMMENDED_GAUGE_TYPE_REQUEST,
    workerGetSingleRecommendedGaugeType
  );
}

export function* watcherGetTCDForOneTankCalibration() {
  yield takeLatest(
    GET_TCD_FOR_ONE_TANK_CALIBRATION_REQUEST,
    workerGetTCDForOneTankCalibration
  );
}

export function* watcherStartCalcTCD() {
  yield takeLatest(START_CALC_TCD_REQUEST, workerStartCalcTCD);
}

export function* watcherGetTankSeriesWithAssociatedModels() {
  yield takeLatest(
    GET_ALL_TANK_SERIE_WITH_ASSOCIATED_MODELS_REQUEST,
    workerGetTankSeriesWithAssociatedModels
  );
}

export function* watcherGetAllTankBrands() {
  yield takeLatest(GET_TANK_BRANDS_REQUEST, workerGetAllTankBrands);
}

export function* watcherGetTankMaterialTypes() {
  yield takeLatest(GET_TANK_MATERIAL_TYPES_REQUEST, workerGetTankMaterialTypes);
}

const getAllTankSeries = async (token) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}tank_serials`,
  });
};

const getSingleTankSerie = async (token, tankSerieId) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}tank_serials/${tankSerieId}`,
  });
};

const addTankSerie = async (token, tankSerial) => {
  console.log('tankSerial', tankSerial);

  return axios({
    method: 'post',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    data: {
      addref: tankSerial.add_ref,
      topCornerRadius: tankSerial.top_radius_corner,
      bottomCornerRadius: tankSerial.bottom_radius_corner,
      comment: tankSerial.comment,
      width: tankSerial.width,
      height: tankSerial.height,
      tankShape: tankSerial.tank_shape,
      isTest: tankSerial.test_serial,
      tankBrands: tankSerial.tank_brands,
      materialEnum: tankSerial.material,
      gaugeLength: tankSerial.gauge_length,
      gaugeRadius: tankSerial.gauge_radius,
      images: tankSerial.images ? tankSerial.images : null,
    },
    url: `${API_URL}tank_serials`,
  });
};

const updateTankSerie = async (token, tankSerial) => {
  console.log('SAGA', tankSerial);

  return axios({
    method: 'put',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    data: {
      addref: tankSerial.add_ref,
      topCornerRadius: tankSerial.top_radius_corner,
      bottomCornerRadius: tankSerial.bottom_radius_corner,
      comment: tankSerial.comment,
      width: tankSerial.width,
      height: tankSerial.height,
      tankShape: tankSerial.tank_shape,
      isTest: tankSerial.test_serial,
      tankBrands: tankSerial.tank_brands,
      materialEnum: tankSerial.material,
      gaugeLength: tankSerial.gauge_length,
      gaugeRadius: tankSerial.gauge_radius,
      images: tankSerial.images,
      imageIdsToDelete: tankSerial.imageIdsToDelete,
    },
    url: `${API_URL}tank_serials/${tankSerial.id}`,
  });
};

const getAllTankModels = async (token) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}tank_models`,
  });
};

const getSingleTankModel = async (token, tankModelId) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}tank_models/${tankModelId}`,
  });
};

const getTankModelsForOneSerial = async (token, tankSerialId) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}tank_models?tankSerialId=${tankSerialId}`,
  });
};

const getTankShapeTypes = async (token) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}global/tank_shape_types`,
  });
};

const addTankModel = async (token, tankModel) => {
  console.log('=======SAGA=============');
  console.log(tankModel);
  console.log('====================================');
  return axios({
    method: 'post',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    data: {
      length: tankModel.length_add,
      width: tankModel.width_add,
      height: tankModel.height_add,
      capacity: tankModel.volume_add,
      comment: tankModel.comment,
      topCornerRadius: tankModel.top_radius_corner_add,
      bottomCornerRadius: tankModel.bottom_radius_corner_add,
      tankSerialId: tankModel.tank_serial.id,
      addref: tankModel.add_ref_add,
      isTest: tankModel.test_model,
      volumeAgInstaller: tankModel.volume_aginstaller,
      lengthAgInstaller: tankModel.length_aginstaller,
      // vmin_offset: tankModel.vmin_offset,  // Non implémenté côté API
      // gauge_tilt: tankModel.gauge_tilt     // Non implémenté côté API
    },
    url: `${API_URL}tank_models`,
  });
};

const updateTankModel = async (token, tankModel) => {
  console.log('======SAGGGAGAGAA======');
  console.log(tankModel);
  console.log('====================================');
  return axios({
    method: 'put',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    data: {
      length: tankModel.length_add,
      width: tankModel.width_add,
      height: tankModel.height_add,
      capacity: tankModel.volume_add,
      comment: tankModel.comment,
      topCornerRadius: tankModel.top_radius_corner,
      bottomCornerRadius: tankModel.bottom_radius_corner,
      tankSerialId: tankModel.tankSerialId,
      addref: tankModel.add_ref_add,
      isTest: tankModel.test_model,
      volumeAgInstaller: tankModel.volume_aginstaller,
      lengthAgInstaller: tankModel.length_aginstaller,
      // vmin_offset: tankModel.vmin_offset,  // Non implémenté côté API
      // gauge_tilt: tankModel.gauge_tilt     // Non implémenté côté API
    },
    url: `${API_URL}tank_models/${tankModel.id}`,
  });
};

const deleteTankModel = async (token, idTankModel) => {
  return axios({
    method: 'delete',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}tank_models/${idTankModel}`,
  });
};

//? tank_models/tank_volume?tankShapeType=D_TANK&tankMeasuredLength=12&tankMeasuredWidth=42&tankMeasuredHeight=57&bottomCornerRadius=75&topCornerRadius=45
const calcVolumeTankModel = async (token, infos) => {
  console.log('//SAGA//');
  console.log(infos);
  let url = '';
  switch (infos.shape) {
    case 'RECTANGULAR':
    case 'RECTANGULAR_WITH_STEP':
      url = `${API_URL}tank_models/tank_volume?tankShapeType=${infos.shape}&tankMeasuredLength=${infos.length}&tankMeasuredWidth=${infos.width}&tankMeasuredHeight=${infos.height}&bottomCornerRadius=${infos.bottomCornerRadius}&topCornerRadius=${infos.topCornerRadius}`;
      break;
    case 'D_TANK':
    case 'D_TANK_WITH_STEP':
      url = `${API_URL}tank_models/tank_volume?tankShapeType=${infos.shape}&tankMeasuredLength=${infos.length}&tankMeasuredWidth=${infos.width}&tankMeasuredHeight=${infos.height}&bottomCornerRadius=${infos.bottomCornerRadius}`;
      break;
    case 'CYLINDRICAL':
    case 'CYLINDRICAL_WITH_STEP':
      url = `${API_URL}tank_models/tank_volume?tankShapeType=${infos.shape}&tankMeasuredLength=${infos.length}&tankMeasuredWidth=${infos.width}&tankMeasuredHeight=${infos.height}`;
      break;
    default:
      break;
  }

  if (url !== '') {
    return axios({
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      url: url,
    });
  }
  return null;
};

const getAllTankCalibrations = async (token) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}tank_calibrations`,
  });
};

const getSingleTankCalibration = async (token, tankCalibrationId) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}tank_calibrations/${tankCalibrationId}`,
  });
};

const calcVminVmaxGauge = async (token, infos) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}tank_calibrations/tank_vmin_vmax?gaugeLength=${infos.gaugeLength}&vminOffset=${infos.vminOffset}`,
  });
};

const calcDefaultVminGauge = async (token, infos) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}tank_calibrations/tank_vmin_vmax?gaugeLength=${infos.gaugeLength}&vminOffset=0`,
  });
};

const getTankCalibrationsForOneModel = async (token, tankModelId) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}tank_calibrations?model_id=${tankModelId}`,
  });
};

const addTankCalibration = async (token, tankCalibration) => {
  console.log('====SAGA====');
  console.log(tankCalibration);

  return axios({
    method: 'post',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    data: {
      gaugeType: tankCalibration.gauge_type,
      gaugeVminOffset: tankCalibration.vmin_offset
        ? tankCalibration.vmin_offset
        : 0,
      //gaugeVmin: tankCalibration.vmin,
      //gaugeVmax: tankCalibration.vmax,
      gaugeLength: tankCalibration.gauge_length,
      gaugeRadius: tankCalibration.gauge_radius,
      gaugeTilt: tankCalibration.gauge_tilt,
      preconized: tankCalibration.is_preconised,
      tankModelId: tankCalibration.tank_model.id,
      vehicleId: tankCalibration.vehicle_id,
      tankMeasuredWidth: tankCalibration.width_model,
      tankMeasuredHeight: tankCalibration.height_model,
      tankMeasuredLength: tankCalibration.length_model,
      tankMeasuredCapacity: tankCalibration.volume_model,
      comment: tankCalibration.comment_tank,
    },
    url: `${API_URL}tank_calibrations`,
  });
};

const updateTankCalibration = async (token, tankCalibration) => {
  console.log('====SAGA====', tankCalibration);

  return axios({
    method: 'put',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    data: {
      gaugeType: tankCalibration.gauge_type,
      gaugeVminOffset: tankCalibration.vmin_offset,
      gaugeLength: tankCalibration.gauge_length,
      gaugeRadius: tankCalibration.gauge_radius,
      gaugeTilt: tankCalibration.gauge_tilt,
      preconized: tankCalibration.is_preconised,
      tankModelId: tankCalibration.tank_model.id,
      tankMeasuredWidth: tankCalibration.width_model,
      tankMeasuredHeight: tankCalibration.height_model,
      tankMeasuredLength: tankCalibration.length_model,
      tankMeasuredCapacity: tankCalibration.volume_model,
      comment: tankCalibration.comment_tank,
    },
    url: `${API_URL}tank_calibrations/${tankCalibration.tank_id}`,
  });
};

const getRecommendedGaugeTypes = async (token, tankModel) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: {},
    url:
      `${API_URL}recommended_gauge_types` +
      (tankModel != null ? '?model_id=' + tankModel : ''),
  });
};

const getSingleRecommendedGaugeType = async (token, recommendedGaugeTypeId) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}recommended_gauge_types/${recommendedGaugeTypeId}`,
  });
};

const getTCDForOneTankCalibration = async (token, tankCalibrationId) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}tank_calibrations/${tankCalibrationId}/tank_calibration_data`,
  });
};

const startCalcTCD = async (token, infos) => {
  console.log(
    `${API_URL}tank_calibrations/${infos.tankCalibrationId}/calculate?gauge_length=${infos.gaugeLength}&gauge_radius=${infos.gaugeRadius}&gauge_tilt=${infos.gaugeTilt}&gauge_vmin_offset=${infos.gaugeVminOffset}`
  );
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}tank_calibrations/${infos.tankCalibrationId}/calculate?gauge_length=${infos.gaugeLength}&gauge_radius=${infos.gaugeRadius}&gauge_tilt=${infos.gaugeTilt}&gauge_vmin_offset=${infos.gaugeVminOffset}`,
  });
};

const getAllTankBrands = async (token) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}global/tank_brands`,
  });
};

const getTankMaterialTypes = async (token) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}global/tank_material_types`,
  });
};

function* workerGetAllTankSeries() {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getAllTankSeries, token);
    const tankSeries = response.data;

    // dispatch a success action to the store with the tank series
    yield put({ type: GET_TANK_SERIES_SUCCESS, tankSeries });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TANK_SERIES_FAILURE, error });
  }
}

function* workerGetSingleTankSerie(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getSingleTankSerie, token, action.payload);
    const singleTankSerie = response.data;

    // dispatch a success action to the store with the tank series
    yield put({ type: GET_SINGLE_TANK_SERIES_SUCCESS, singleTankSerie });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_SINGLE_TANK_SERIES_FAILURE, error });
  }
}

function* workerAddTankSerie(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(addTankSerie, token, action.payload);
    const tankSerie = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: ADD_TANK_SERIE_SUCCESS, tankSerie });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Tank serial created !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center',
      })
    );
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ADD_TANK_SERIE_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center',
      })
    );
  }
}

function* workerUpdateTankSerie(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(updateTankSerie, token, action.payload);
    const tankSerie = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: UPDATE_TANK_SERIE_SUCCESS, tankSerie });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Tank serial updated !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center',
      })
    );
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: UPDATE_TANK_SERIE_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center',
      })
    );
  }
}

function* workerGetTankShapeTypes() {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getTankShapeTypes, token);
    const tankShape = response.data;

    // dispatch a success action to the store with the tank series
    yield put({ type: GET_TANK_SHAPE_TYPES_SUCCESS, tankShape });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TANK_SHAPE_TYPES_FAILURE, error });
  }
}

function* workerGetAllTankModels() {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getAllTankModels, token);
    const tankModels = response.data;

    // dispatch a success action to the store with the tank models
    yield put({ type: GET_TANK_MODELS_SUCCESS, tankModels });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TANK_MODELS_FAILURE, error });
  }
}

function* workerGetSingleTankModel(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getSingleTankModel, token, action.payload);
    const tankModel = response.data;

    // dispatch a success action to the store with the tank models
    yield put({ type: GET_SINGLE_TANK_MODEL_SUCCESS, tankModel });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_SINGLE_TANK_MODEL_FAILURE, error });
  }
}

export function* workerLoadSingleTankModelForm(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    //? On get le tank Model a partir de son id
    const response = yield call(getSingleTankModel, token, action.payload);
    const tankModel = response.data;
    //? On envoi à Redux la réponse pour qu'il peuple le store
    yield put({ type: GET_SINGLE_TANK_MODEL_SUCCESS, tankModel });
    //? On appel l'action qui permet de récupérer un tankserial à partir de son id
    yield put({
      type: GET_SINGLE_TANK_SERIES_REQUEST,
      payload: tankModel.tankSerialId,
    });
    const res = yield take([
      GET_SINGLE_TANK_SERIES_SUCCESS,
      GET_SINGLE_TANK_SERIES_FAILURE,
    ]);
    if (res !== null) {
      yield put({ type: LOAD_SINGLE_TANK_MODELS_SUCCESS });
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: LOAD_SINGLE_TANK_MODELS_FAILURE, error });
  }
}

function* workerGetTankModelsForOneSerial(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getTankModelsForOneSerial,
      token,
      action.payload
    );
    const tankModels = response.data;

    // dispatch a success action to the store with the tank models
    yield put({ type: GET_TANK_MODELS_FOR_ONE_SERIE_SUCCESS, tankModels });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TANK_MODELS_FOR_ONE_SERIE_FAILURE, error });
  }
}

function* workerAddTankModel(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(addTankModel, token, action.payload);
    const tankModel = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: ADD_TANK_MODEL_SUCCESS, tankModel });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Tank model created !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center',
      })
    );
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ADD_TANK_MODEL_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center',
      })
    );
  }
}

function* workerUpdateTankModel(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(updateTankModel, token, action.payload);
    const tankModel = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: UPDATE_TANK_MODEL_SUCCESS, tankModel });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Tank model updated !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center',
      })
    );
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: UPDATE_TANK_MODEL_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center',
      })
    );
  }
}

function* workerDeleteTankModel(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(deleteTankModel, token, action.payload);
    const tankModel = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: DELETE_TANK_MODEL_SUCCESS, tankModel });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: DELETE_TANK_MODEL_FAILURE, error });
  }
}

function* workerCalcVolumeTankModel(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(calcVolumeTankModel, token, action.payload);
    const volume = response.data;

    console.log('====================================');
    console.log(volume);
    console.log('====================================');

    // dispatch a success action to the store with the new vehicle
    yield put({ type: CALC_VOLUME_TANK_MODELS_SUCCESS, volume });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: CALC_VOLUME_TANK_MODELS_FAILURE, error });
  }
}

function* workerCalcVolumeTankModelAginstaller(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(calcVolumeTankModel, token, action.payload);
    const volume = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: CALC_VOLUME_TANK_MODELS_AGINSTALLER_SUCCESS, volume });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: CALC_VOLUME_TANK_MODELS_AGINSTALLER_FAILURE, error });
  }
}

function* workerGetAllTankCalibrations() {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    console.log();
    const response = yield call(getAllTankCalibrations, token);
    const tankCalibrations = response.data;

    console.log(tankCalibrations);

    // dispatch a success action to the store with the tank calibrations
    yield put({ type: GET_TANK_CALIBRATIONS_SUCCESS, tankCalibrations });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TANK_CALIBRATIONS_FAILURE, error });
  }
}

function* workerGetSingleTankCalibration(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getSingleTankCalibration,
      token,
      action.payload
    );
    const tankCalibration = response.data;

    // dispatch a success action to the store with the tank models
    yield put({ type: GET_SINGLE_TANK_CALIBRATION_SUCCESS, tankCalibration });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_SINGLE_TANK_CALIBRATION_FAILURE, error });
  }
}

function* workerCalcVminVmaxGauge(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(calcVminVmaxGauge, token, action.payload);
    const calc = response.data;

    // dispatch a success action to the store with the tank models
    yield put({ type: CALC_VMIN_VMAX_GAUGE_SUCCESS, calc });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: CALC_VMIN_VMAX_GAUGE_FAILURE, error });
  }
}

function* workerCalcDefaultVminGauge(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(calcDefaultVminGauge, token, action.payload);
    const calc = response.data;

    // dispatch a success action to the store with the tank models
    yield put({ type: CALC_DEFAULT_VMIN_GAUGE_SUCCESS, calc });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: CALC_DEFAULT_VMIN_GAUGE_FAILURE, error });
  }
}

function* workerGetTankCalibrationsForOneModel(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getTankCalibrationsForOneModel,
      token,
      action.payload
    );
    const tankCalibrations = response.data;

    // dispatch a success action to the store with the tank calibrations
    yield put({
      type: GET_TANK_CALIBRATIONS_FOR_ONE_MODEL_SUCCESS,
      tankCalibrations,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TANK_CALIBRATIONS_FOR_ONE_MODEL_FAILURE, error });
  }
}

function* workerAddTankCalibration(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(addTankCalibration, token, action.payload);
    const tankCalibration = response.data;

    console.log(tankCalibration);

    // dispatch a success action to the store with the new vehicle
    yield put({ type: ADD_TANK_CALIBRATION_SUCCESS, tankCalibration });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Tank created !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center',
      })
    );
  } catch (error) {
    console.log(error);
    // dispatch a failure action to the store with the error
    yield put({ type: ADD_TANK_CALIBRATION_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center',
      })
    );
  }
}

function* workerUpdateTankCalibration(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(updateTankCalibration, token, action.payload);
    const tankCalibration = response.data;

    console.log(tankCalibration);

    // dispatch a success action to the store with the new vehicle
    yield put({ type: UPDATE_TANK_CALIBRATION_SUCCESS, tankCalibration });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Tank updated !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center',
      })
    );
  } catch (error) {
    console.log(error);
    // dispatch a failure action to the store with the error
    yield put({ type: UPDATE_TANK_CALIBRATION_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center',
      })
    );
  }
}

function* workerGetRecommendedGaugeTypes(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getRecommendedGaugeTypes,
      token,
      action.payload
    );
    const recommendedGaugeTypes = response.data;

    // dispatch a success action to the store with the tank calibrations
    yield put({
      type: GET_RECOMMENDED_GAUGE_TYPES_SUCCESS,
      recommendedGaugeTypes,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_RECOMMENDED_GAUGE_TYPES_FAILURE, error });
  }
}

function* workerGetSingleRecommendedGaugeType(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getSingleRecommendedGaugeType,
      token,
      action.payload
    );
    const recommendedGaugeType = response.data;

    // dispatch a success action to the store with the tank models
    yield put({
      type: GET_SINGLE_RECOMMENDED_GAUGE_TYPE_SUCCESS,
      recommendedGaugeType,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_SINGLE_RECOMMENDED_GAUGE_TYPE_FAILURE, error });
  }
}

function* workerGetTCDForOneTankCalibration(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getTCDForOneTankCalibration,
      token,
      action.payload
    );
    const TCDs = response.data;

    // dispatch a success action to the store with the tank models
    yield put({ type: GET_TCD_FOR_ONE_TANK_CALIBRATION_SUCCESS, TCDs });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TCD_FOR_ONE_TANK_CALIBRATION_FAILURE, error });
  }
}

function* workerStartCalcTCD(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(startCalcTCD, token, action.payload);
    const TCDs = response.data;

    // dispatch a success action to the store with the tank models
    yield put({ type: START_CALC_TCD_SUCCESS, TCDs });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: START_CALC_TCD_FAILURE, error });
  }
}

function* workerGetTankSeriesWithAssociatedModels(action) {
  console.log('executeWorkersToGet');
  yield all([
    put({ type: GET_TANK_SERIES_REQUEST }),
    put({
      type: GET_TANK_MODELS_FOR_ONE_SERIE_REQUEST,
      payload: action.payload,
    }),
  ]);
  console.log('all request perform');
  const response = yield all([
    take([GET_TANK_SERIES_SUCCESS, GET_TANK_SERIES_FAILURE]),
    take([
      GET_TANK_MODELS_FOR_ONE_SERIE_SUCCESS,
      GET_TANK_MODELS_FOR_ONE_SERIE_FAILURE,
    ]),
  ]);
  console.log('all response perform');
  if (response != null) {
    yield put({ type: GET_ALL_TANK_SERIE_WITH_ASSOCIATED_MODELS_SUCCESS });
  }
}

const getGaugeTypes = async (token) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}global/gauge_types`,
  });
};

function* workerGetGaugeTypes(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);

  try {
    const response = yield call(getGaugeTypes, token);
    const gaugeTypes = response.data;
    yield put({ type: GET_GAUGE_TYPES_SUCCESS, gaugeTypes });
  } catch (error) {
    yield put({ type: GET_GAUGE_TYPES_FAILURE, error });
  }
}

function* workerGetAllTankBrands() {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getAllTankBrands, token);
    const tankBrands = response.data;

    // dispatch a success action to the store with the tank models
    yield put({ type: GET_TANK_BRANDS_SUCCESS, tankBrands });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TANK_BRANDS_FAILURE, error });
  }
}

function* workerGetTankMaterialTypes() {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getTankMaterialTypes, token);
    const tankMaterial = response.data;

    // dispatch a success action to the store with the tank series
    yield put({ type: GET_TANK_MATERIAL_TYPES_SUCCESS, tankMaterial });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TANK_MATERIAL_TYPES_FAILURE, error });
  }
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetGaugeTypes),
    fork(watcherAddTankSerie),
    fork(watcherUpdateTankSerie),
    fork(watcherGetAllTankSeries),
    fork(watcherGetSingleTankSerie),
    fork(watcherGetTankShapeTypes),
    fork(watcherGetAllTankModels),
    fork(watcherGetSingleTankModel),
    fork(watcherLoadSingleTankModelForm),
    fork(watcherGetTankModelsForOneSerial),
    fork(watcherAddTankModel),
    fork(watcherUpdateTankModel),
    fork(watcherCalcVolumeTankModel),
    fork(watcherCalcVolumeTankModelAginstaller),
    fork(watcherGetAllTankCalibrations),
    fork(watcherGetSingleTankCalibration),
    fork(watcherCalcVminVmaxGauge),
    fork(watcherCalcDefaultVminGauge),
    fork(watcherGetTankCalibrationsForOneModel),
    fork(watcherAddTankCalibration),
    fork(watcherUpdateTankCalibration),
    fork(watcherGetRecommendedGaugeTypes),
    fork(watcherGetSingleRecommendedGaugeType),
    fork(watcherGetTCDForOneTankCalibration),
    fork(watcherStartCalcTCD),
    fork(watcherGetTankSeriesWithAssociatedModels),
    fork(watcherGetAllTankBrands),
    fork(watcherGetTankMaterialTypes),
    fork(watcherDeleteTankModel),
  ]);
}
