import {
  GET_VEHICLE_ACTIVITY_REQUEST,
  GET_VEHICLE_ACTIVITY_SUCCESS,
  GET_VEHICLE_ACTIVITY_FAILURE,
  GET_VEHICLE_ACTIVITY_DETAILS_REQUEST,
  GET_VEHICLE_ACTIVITY_DETAILS_SUCCESS,
  GET_VEHICLE_ACTIVITY_DETAILS_FAILURE,
  GET_DRIVER_ACTIVITY_REQUEST,
  GET_DRIVER_ACTIVITY_SUCCESS,
  GET_DRIVER_ACTIVITY_FAILURE
} from '../constants/ActionTypes';

const INIT_STATE = {
  fetching: false,
  trips: [],
  tripDetails: {},
  error: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //? GET ALL TRIPS FOR A SPECIFIC PERIOD //
    case GET_VEHICLE_ACTIVITY_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_VEHICLE_ACTIVITY_SUCCESS:
      return { ...state, fetching: false, trips: action.trips };
    case GET_VEHICLE_ACTIVITY_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? GET TRIP DETAILS FOR SPECIFIC TRIP //
    case GET_VEHICLE_ACTIVITY_DETAILS_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_VEHICLE_ACTIVITY_DETAILS_SUCCESS:
      return { ...state, fetching: false, tripDetails: action.tripDetails };
    case GET_VEHICLE_ACTIVITY_DETAILS_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? GET ALL DRIVER TRIPS FOR A SPECIFIC PERIOD //
    case GET_DRIVER_ACTIVITY_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_DRIVER_ACTIVITY_SUCCESS:
      return { ...state, fetching: false, trips: action.trips };
    case GET_DRIVER_ACTIVITY_FAILURE:
      return { ...state, fetching: false, error: action.error };
    default:
      return state;
  }
};
