import {
  GET_AGSUP_ADA_CUSTOMERS_DASHBOARD_REPORT_REQUEST,
  GET_AGSUP_ADA_CUSTOMER_TREE_REQUEST,
  GET_AGSUP_GENERAL_DASHBOARD_REPORT_REQUEST,
  GET_AGSUP_GENERAL_DASHBOARD_SITES_REQUEST,
  GET_AGSUP_DASHBOARD_SINGLE_SITE_REQUEST,
  GET_CUSTOMER_DRIVER_ACTIVITY_STATS_REPORT_REQUEST,
  GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST,
  GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_REQUEST,
  GET_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST,
  GET_FUEL_CONSUMPTION_REPORT_REQUEST,
  GET_PERMANENT_STOCK_RATE_REPORT_REQUEST,
  GET_REFUEL_VEHICLES_REPORT_REQUEST,
  GET_THEFT_VEHICLES_REPORT_REQUEST,
  GET_THEFT_REPORT_REQUEST,
  GET_TMAVA_REPARTITION_REPORT_REQUEST,
  GET_TOP_DRIVER_TMAVA_REPORT_REQUEST,
  GET_TOP_LOWEST_BATTERY_VEHICLES_REPORT_REQUEST,
  GET_TOP_LOWEST_FUEL_VEHICLES_REPORT_REQUEST,
  GET_TOP_OVERSPEED_VEHICLES_REPORT_REQUEST,
  GET_TOP_VEHICLE_TMAVA_REPORT_REQUEST,
  GET_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST,
  GET_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST,
  GET_TOTAL_DISTANCE_REPORT_REQUEST,
  GET_TOTAL_FUEL_REPORT_REQUEST,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_MULTIPLE_REQUEST,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REQUEST,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REFERENCE_REQUEST,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_DASHBOARD_TREND_REQUEST,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_FOR_CUMULATIVE_TMAVA_REQUEST,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_REQUEST,
  GET_VEHICLE_DAILY_REPORT_REQUEST,
  GET_VEHICLE_LIVE_REPORT_REQUEST,
  GET_DRIVER_DAILY_REPORT_MULTIPLE_REQUEST,
  GET_DRIVER_DAILY_REPORT_REQUEST,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_REQUEST,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_REFERENCE_REQUEST,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_MULTIPLE_REQUEST,
  GET_VEQ_TMAVA_STATS_REPORT_REQUEST,
  GET_STABILITY_POINTS_REQUEST,
  GET_TOP_FLOP_REPORT_REQUEST,
  GET_REFUEL_OPTIMIZATION_REPORT_REQUEST,
  GET_REFUEL_OPTIMIZATION_BY_VEHICLE_REPORT_REQUEST,
  GET_ZONE_STAT_REPORT_REQUEST,
  GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_REQUEST,
  GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_MULTIPLE_REQUEST
} from '../constants/ActionTypes';

export const getFuelConsumptionReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_FUEL_CONSUMPTION_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate
    }
  };
};

export const getFuelConsumptionReportMultiple = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  periods
}) => {
  return {
    type: GET_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      periods
    }
  };
};

export const getTotalFuelReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_TOTAL_FUEL_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate
    }
  };
};

export const getTotalDistanceReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_TOTAL_DISTANCE_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate
    }
  };
};

export const getTotalDistanceReportMultiple = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  periods
}) => {
  return {
    type: GET_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      periods
    }
  };
};

export const getTotalActivityDaysReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate
    }
  };
};

export const getVehicleActivityStatsReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate
    }
  };
};

export const getVehicleActivityStatsReportMultiple = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  periods
}) => {
  return {
    type: GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      periods
    }
  };
};

export const getDriverActivityStatsReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  driverIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_CUSTOMER_DRIVER_ACTIVITY_STATS_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      driverIds,
      startDate,
      endDate
    }
  };
};

export const getPermanentStockRateReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_PERMANENT_STOCK_RATE_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate
    }
  };
};

export const getTopLowestFuelVehiclesReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_TOP_LOWEST_FUEL_VEHICLES_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate
    }
  };
};

export const getTopLowestBatteryVehiclesReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate,
  threshold
}) => {
  return {
    type: GET_TOP_LOWEST_BATTERY_VEHICLES_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate,
      threshold
    }
  };
};

export const getTopOverspeedVehiclesReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate,
  threshold
}) => {
  return {
    type: GET_TOP_OVERSPEED_VEHICLES_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate,
      threshold
    }
  };
};

export const getVehicleDailyReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_VEHICLE_DAILY_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate
    }
  };
};

export const getVehicleDailyReportMultiple = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  periods
}) => {
  return {
    type: GET_VEHICLE_DAILY_REPORT_MULTIPLE_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      periods
    }
  };
};

export const getVehicleDailyReportMultipleDashboardTrend = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  periods,
  startDate,
  endDate
}) => {
  return {
    type: GET_VEHICLE_DAILY_REPORT_MULTIPLE_DASHBOARD_TREND_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      periods,
      startDate,
      endDate
    }
  };
};

export const getVehicleDailyReportMultipleForCumulativeTMAVA = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  periods
}) => {
  return {
    type: GET_VEHICLE_DAILY_REPORT_MULTIPLE_FOR_CUMULATIVE_TMAVA_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      periods
    }
  };
};

export const getTopVehicleTMAVAReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_TOP_VEHICLE_TMAVA_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate
    }
  };
};

export const getTopDriverTMAVAReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_TOP_DRIVER_TMAVA_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate
    }
  };
};

export const getTMAVARepartitionReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_TMAVA_REPARTITION_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate
    }
  };
};

export const getVehicleDailyReportByVehicle = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate
    }
  };
};

export const getVehicleDailyReportByVehicleReference = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REFERENCE_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate,
      reference: true
    }
  };
};

export const getVehicleDailyReportByVehicleMultiple = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  periods
}) => {
  return {
    type: GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_MULTIPLE_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      periods
    }
  };
};

export const getVehicleLiveReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds
}) => {
  return {
    type: GET_VEHICLE_LIVE_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds
    }
  };
};

export const getAgsupGeneralDashboardReport = ({
  startDate,
  endDate,
  adaType,
  noOccurence
}) => {
  return {
    type: GET_AGSUP_GENERAL_DASHBOARD_REPORT_REQUEST,
    payload: { startDate, endDate, adaType, noOccurence }
  };
};

export const getAgsupGeneralDashboardSites = infos => {
  return {
    type: GET_AGSUP_GENERAL_DASHBOARD_SITES_REQUEST,
    payload: infos
  };
};

export const getAgsupGeneralDashboardVehicles = infos => {
  return {
    type: GET_AGSUP_DASHBOARD_SINGLE_SITE_REQUEST,
    payload: infos
  };
};

export const getAgsupAdaAllCustomersReport = ({
  startDate,
  endDate,
  adaType,
  noOccurence
}) => {
  return {
    type: GET_AGSUP_ADA_CUSTOMERS_DASHBOARD_REPORT_REQUEST,
    payload: { startDate, endDate, adaType, noOccurence }
  };
};

export const getAdaDashboardCustomerTree = infos => {
  return {
    type: GET_AGSUP_ADA_CUSTOMER_TREE_REQUEST,
    payload: infos
  };
};

export const getRefuelVehiclesReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_REFUEL_VEHICLES_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate
    }
  };
};

export const getTheftVehiclesReport = ({
  customerId,
  vehicleIds,
  startDate,
  endDate,
  periods
}) => {
  return {
    type: GET_THEFT_VEHICLES_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleIds,
      startDate,
      endDate,
      periods
    }
  };
};

export const getTheftReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_THEFT_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate
    }
  };
};

export const getDriverDailyReport = ({
  customerId,
  driverGroupIds,
  siteIds,
  driverIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_DRIVER_DAILY_REPORT_REQUEST,
    payload: {
      customerId,
      driverGroupIds,
      siteIds,
      driverIds,
      startDate,
      endDate
    }
  };
};

export const getDriverDailyReportMultiple = ({
  customerId,
  driverGroupIds,
  siteIds,
  driverIds,
  periods
}) => {
  return {
    type: GET_DRIVER_DAILY_REPORT_MULTIPLE_REQUEST,
    payload: {
      customerId,
      driverGroupIds,
      siteIds,
      driverIds,
      periods
    }
  };
};

export const getDriverDailyReportByDriver = ({
  customerId,
  driverGroupIds,
  siteIds,
  driverIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_DRIVER_DAILY_REPORT_BY_DRIVER_REQUEST,
    payload: {
      customerId,
      driverGroupIds,
      siteIds,
      driverIds,
      startDate,
      endDate
    }
  };
};

export const getDriverDailyReportByDriverReference = ({
  customerId,
  driverGroupIds,
  siteIds,
  driverIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_DRIVER_DAILY_REPORT_BY_DRIVER_REFERENCE_REQUEST,
    payload: {
      customerId,
      driverGroupIds,
      siteIds,
      driverIds,
      startDate,
      endDate,
      reference: true
    }
  };
};

export const getDriverDailyReportByDriverMultiple = ({
  customerId,
  driverGroupIds,
  siteIds,
  driverIds,
  periods
}) => {
  return {
    type: GET_DRIVER_DAILY_REPORT_BY_DRIVER_MULTIPLE_REQUEST,
    payload: {
      customerId,
      driverGroupIds,
      siteIds,
      driverIds,
      periods
    }
  };
};

export const getVeqTmavaStatsReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_VEQ_TMAVA_STATS_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate
    }
  };
};

export const getStabilityPoints = infos => {
  console.log('getStabilityPoints', infos);
  return {
    type: GET_STABILITY_POINTS_REQUEST,
    payload: infos
  };
};

export const getTopFlopReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  console.log('getTopFlopReport', {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    startDate,
    endDate
  });
  return {
    type: GET_TOP_FLOP_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate
    }
  };
};

export const getRefuelOptimizationReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  console.log('getRefuelOptimizationReport', {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    startDate,
    endDate
  });
  return {
    type: GET_REFUEL_OPTIMIZATION_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate
    }
  };
};

export const getRefuelOptimizationByVehicleReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  console.log('getRefuelOptimizationByVehicleReport', {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    startDate,
    endDate
  });
  return {
    type: GET_REFUEL_OPTIMIZATION_BY_VEHICLE_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate
    }
  };
};

export const getZoneStatReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  console.log('getZoneStatReport', {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    startDate,
    endDate
  });
  return {
    type: GET_ZONE_STAT_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate
    }
  };
};

export const getAverageInitialTankVolumePercentRefuelReport = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  startDate,
  endDate
}) => {
  return {
    type: GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      startDate,
      endDate
    }
  };
};

export const getAverageInitialTankVolumePercentRefuelReportMultiple = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  periods
}) => {
  return {
    type: GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_MULTIPLE_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      periods
    }
  };
};
