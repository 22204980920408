import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import {
  GET_ALL_ADA_REQUEST,
  GET_ALL_ADA_SUCCESS,
  GET_ALL_ADA_FAILURE,
  GET_AGSUP_ADA_SINGLE_GROUP_REQUEST,
  GET_AGSUP_ADA_SINGLE_GROUP_SUCCESS,
  GET_AGSUP_ADA_SINGLE_GROUP_FAILURE,
  GET_ADA_SINGLE_VEHICLE_BY_DATE_REQUEST,
  GET_ADA_SINGLE_VEHICLE_BY_DATE_SUCCESS,
  GET_ADA_SINGLE_VEHICLE_BY_DATE_FAILURE,
  GET_ADA_TYPE_ENUM_REQUEST,
  GET_ADA_TYPE_ENUM_SUCCESS,
  GET_ADA_TYPE_ENUM_FAILURE,
  UPDATE_ADA_REQUEST,
  UPDATE_ADA_SUCCESS,
  UPDATE_ADA_FAILURE,
  CREATE_ADA_REQUEST,
  CREATE_ADA_SUCCESS,
  CREATE_ADA_FAILURE,
  GET_SAV_COUNT_BY_ADA_REQUEST,
  GET_SAV_COUNT_BY_ADA_SUCCESS,
  GET_SAV_COUNT_BY_ADA_FAILURE
} from 'constants/ActionTypes';
import { showMessage } from 'actions/Global';
import { API_URL } from '../constants/globalConstants';
import axios from 'axios';

////////////! GET ALL ADA FOR SINGLE VEHICLE //////////////////

export function* watcherGetAllADAByVehicle() {
  yield takeEvery(GET_ALL_ADA_REQUEST, workerGetAllADAByVehicle);
}

const getAllADAByVehicle = async (token, { vehicleId, startDate, endDate }) => {
  console.log('======SAGA======');
  console.log(vehicleId, startDate, endDate);
  console.log('======================');
  let url = `${API_URL}ada?`;

  if (vehicleId) url += 'vehicle_id=' + vehicleId + '&';
  if (startDate && endDate)
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

function* workerGetAllADAByVehicle(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getAllADAByVehicle, token, action.payload);
    const ADAList = response.data;

    yield put({ type: GET_ALL_ADA_SUCCESS, ADAList });
  } catch (error) {
    yield put({ type: GET_ALL_ADA_FAILURE, error });
  }
}

////////////! GET ADA VEHICLES FOR ONE SINGLE GROUP //////////////////

export function* watcherAdaSingleGroup() {
  yield takeLatest(GET_AGSUP_ADA_SINGLE_GROUP_REQUEST, workerAdaSingleGroup);
}

const getAdaSingleGroup = async (token, infos) => {
  console.log('Saga get ada ', infos);

  let url = `${API_URL}dashboard/ada/customers/${infos.customerId}/groups/${
    infos.groupId
  }?period=${encodeURIComponent(
    JSON.stringify({
      start: infos.dates.startDate,
      end: infos.dates.endDate
    })
  )}`;

  if (infos.adaType) {
    url += `&adaType=${infos.adaType.name}`;
  }

  if (infos.noOccurence) {
    url += `&noOccurence=true`;
  }

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url: url
  });
};

function* workerAdaSingleGroup(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getAdaSingleGroup, token, action.payload);
    const adaSingleGroup = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: GET_AGSUP_ADA_SINGLE_GROUP_SUCCESS,
      adaSingleGroup
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_AGSUP_ADA_SINGLE_GROUP_FAILURE,
      error
    });
  }
}

////////////! GET ADA TYPE ENUM //////////////////

export function* watcherGetAdaTypeEnum() {
  yield takeLatest(GET_ADA_TYPE_ENUM_REQUEST, workerGetAdaTypeEnum);
}

const getGetAdaTypeEnum = async token => {
  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url: `${API_URL}global/ada_type_name`
  });
};

function* workerGetAdaTypeEnum(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getGetAdaTypeEnum, token);
    const adaTypeEnum = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: GET_ADA_TYPE_ENUM_SUCCESS,
      adaTypeEnum
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_ADA_TYPE_ENUM_FAILURE,
      error
    });
  }
}

////////////! GET ADA SINGLE VEHICLE BY DATE //////////////////

export function* watcherAdaSingleVehicleByDate() {
  yield takeLatest(
    GET_ADA_SINGLE_VEHICLE_BY_DATE_REQUEST,
    workerAdaSingleVehicleByDate
  );
}

const getAdaSingleVehicleByDate = async (token, infos) => {
  console.log(infos);
  let url = `${API_URL}dashboard/ada/customers/${infos.customerId}/vehicle/${infos.vehicleId}?`;

  if (infos.startDate && infos.endDate) {
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: infos.startDate,
        end: infos.endDate
      })
    )}&`;
  }

  if (infos.adaType) {
    url += `adaType=${infos.adaType.name}&`;
  }

  if (infos.noOccurence) {
    url += `&noOccurence=true`;
  }

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url: url
  });
};

function* workerAdaSingleVehicleByDate(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getAdaSingleVehicleByDate,
      token,
      action.payload
    );
    const adaSingleVehicle = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: GET_ADA_SINGLE_VEHICLE_BY_DATE_SUCCESS,
      adaSingleVehicle
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_ADA_SINGLE_VEHICLE_BY_DATE_FAILURE,
      error
    });
  }
}

////////////! UPDATE ADA //////////////////

export function* watcherUpdateAda() {
  yield takeLatest(UPDATE_ADA_REQUEST, workerUpdateAda);
}

const updateAda = async (token, infos) => {
  console.log(infos);
  return axios({
    method: 'put',
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      validated: infos.type === 'validated' ? infos.value : infos.ada.validated,
      deleted: infos.type === 'delete' ? infos.value : infos.ada.deleted,
      refConsumption:
        infos.type === 'ref' ? infos.value : infos.ada.refConsumption,
      comment: infos.ada.comment ? infos.ada.comment : null
    },
    url: `${API_URL}ada/${infos.ada.id}`
  });
};

function* workerUpdateAda(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(updateAda, token, action.payload);
    const adaUpdated = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: UPDATE_ADA_SUCCESS,
      adaUpdated
    });
    if (action.payload.ada.comment) {
      yield put(
        showMessage({
          title: 'Information',
          message: 'Comment added !',
          messageType: 'success',
          duration: 2000,
          position: 'bottom-center'
        })
      );
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: UPDATE_ADA_FAILURE,
      error
    });
  }
}

////////////! CREATE ADA //////////////////

export function* watcherCreateAda() {
  yield takeLatest(CREATE_ADA_REQUEST, workerCreateAda);
}

const createAda = async (token, infos) => {
  console.log(infos);
  if (infos.tankIndex) {
    return axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        vehicleId: infos.vehicleId,
        type: infos.adaType,
        tankIndex: infos.tankIndex,
        day: infos.day,
        comment: ''
      },
      url: `${API_URL}ada`
    });
  } else
    return axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        vehicleId: infos.vehicleId,
        type: infos.adaType,
        day: infos.day,
        comment: ''
      },
      url: `${API_URL}ada`
    });
};

function* workerCreateAda(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(createAda, token, action.payload);
    const adaCreated = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: CREATE_ADA_SUCCESS,
      adaCreated
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: CREATE_ADA_FAILURE,
      error
    });
  }
}

////////////! GET SAV COUNT FILTER BY ADA //////////////////

export function* watcherGetSavByAda() {
  yield takeLatest(GET_SAV_COUNT_BY_ADA_REQUEST, workerGetSavByAda);
}

const getSavByAda = async (token, infos) => {
  console.log(infos);
  let url = `${API_URL}sav/count_by_sav_type?`;

  if (infos.groupId) {
    url += `groupId=${infos.groupId}&`;
  }

  url += `customerId=${infos.customerId}&period=${encodeURIComponent(
    JSON.stringify({
      start: infos.period.startDate,
      end: infos.period.endDate
    })
  )}`;

  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`
    },
    url: url
  });
};

function* workerGetSavByAda(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getSavByAda, token, action.payload);
    const savFilterByAda = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: GET_SAV_COUNT_BY_ADA_SUCCESS,
      savFilterByAda
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_SAV_COUNT_BY_ADA_FAILURE,
      error
    });
  }
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetAllADAByVehicle),
    fork(watcherAdaSingleGroup),
    fork(watcherAdaSingleVehicleByDate),
    fork(watcherGetAdaTypeEnum),
    fork(watcherUpdateAda),
    fork(watcherCreateAda),
    fork(watcherGetSavByAda)
  ]);
}
