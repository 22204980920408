import {
  all,
  take,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import {
  WEBSOCKET_SET_TOKEN_REQUEST,
  WEBSOCKET_SET_TOKEN_SUCCESS,
  WEBSOCKET_SET_TOKEN_FAILURE,
  WEBSOCKET_SUBSCRIBE_EVENTS_REQUEST,
  WEBSOCKET_SUBSCRIBE_EVENTS_SUCCESS,
  WEBSOCKET_SUBSCRIBE_EVENTS_FAILURE,
  WEBSOCKET_UNSUBSCRIBE_EVENTS_REQUEST,
  WEBSOCKET_UNSUBSCRIBE_EVENTS_SUCCESS,
  WEBSOCKET_UNSUBSCRIBE_EVENTS_FAILURE,
  WEBSOCKET_CONNECTED,
  WEBSOCKET_NEW_LOCATION,
  WEBSOCKET_NEW_ADDRESS,
  WEBSOCKET_NEW_DATA,
  WEBSOCKET_NEW_DRIVER_DATA,
  WEBSOCKET_NEW_ACTIVITY_DATA,
  WEBSOCKET_NEW_ALERT_DATA,
  WEBSOCKET_10SEC_DATA
} from 'constants/ActionTypes';
import { showMessage } from 'actions/Global';
import { API_URL, WEBSOCKET_URL } from '../constants/globalConstants';
import { eventChannel, END } from 'redux-saga';

let ws;

let iv;
let tempWebsocketLocationData = {};
let tempWebsocketAddressData = {};
let tempWebsocketVehicleData = {};
let tempWebsocketDriverActivityData = {};
let tempWebsocketVehicleActivityData = {};
let tempWebsocketAlertData = {};

function countdown(seconds) {
  let secs = 0;
  return eventChannel(emitter => {
    if (iv) clearInterval(iv);
    iv = setInterval(() => {
      secs++;
      if (secs >= seconds) {
        emitter(secs);
        secs = 0;
      }
    }, 1000);
    // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(iv);
    };
  });
}

export function* watcherWebsocket10s() {
  const channel = yield call(countdown, 10);
  yield takeEvery(channel, function*(secs) {
    if (
      Object.keys(tempWebsocketLocationData).length > 0 ||
      Object.keys(tempWebsocketAddressData).length > 0 ||
      Object.keys(tempWebsocketVehicleData).length > 0 ||
      Object.keys(tempWebsocketDriverActivityData).length > 0 ||
      Object.keys(tempWebsocketVehicleActivityData).length > 0 ||
      Object.keys(tempWebsocketAlertData).length > 0
    ) {
      yield put({
        type: WEBSOCKET_10SEC_DATA,
        currentWebsocketLocationData: tempWebsocketLocationData,
        currentWebsocketAddressData: tempWebsocketAddressData,
        currentWebsocketVehicleData: tempWebsocketVehicleData,
        currentWebsocketDriverActivityData: tempWebsocketDriverActivityData,
        currentWebsocketVehicleActivityData: tempWebsocketVehicleActivityData,
        currentWebsocketAlertData: tempWebsocketAlertData
      });
      tempWebsocketLocationData = {};
      tempWebsocketAddressData = {};
      tempWebsocketVehicleData = {};
      tempWebsocketDriverActivityData = {};
      tempWebsocketVehicleActivityData = {};
      tempWebsocketAlertData = {};
    }
  });
}

export function* watcherWebsocketSetToken() {
  yield takeLatest(WEBSOCKET_SET_TOKEN_REQUEST, workerWebsocketSetToken);
}

export function* watcherWebsocketSubscribeEvents() {
  yield takeLatest(
    WEBSOCKET_SUBSCRIBE_EVENTS_REQUEST,
    workerWebsocketSubscribeEvents
  );
}

export function* watcherWebsocketUnsubscribeEvents() {
  yield takeLatest(
    WEBSOCKET_UNSUBSCRIBE_EVENTS_REQUEST,
    workerWebsocketUnsubscribeEvents
  );
}

function* workerWebsocketSetToken() {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(websocketSetToken, token);
    //const vehicles = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: WEBSOCKET_SET_TOKEN_SUCCESS });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: WEBSOCKET_SET_TOKEN_FAILURE, error });
  }
}

function* workerWebsocketSubscribeEvents(action) {
  try {
    const response = yield call(websocketSubscribeEvents, action.payload);
    //const vehicles = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: WEBSOCKET_SUBSCRIBE_EVENTS_SUCCESS });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: WEBSOCKET_SUBSCRIBE_EVENTS_FAILURE, error });
  }
}

function* workerWebsocketUnsubscribeEvents() {
  try {
    const response = yield call(websocketUnsubscribeEvents);
    //const vehicles = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: WEBSOCKET_UNSUBSCRIBE_EVENTS_SUCCESS });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: WEBSOCKET_UNSUBSCRIBE_EVENTS_FAILURE, error });
  }
}

function websocketInit() {
  return eventChannel(emitter => {
    ws = new WebSocket(WEBSOCKET_URL);
    ws.onopen = () => {
      return emitter({ type: WEBSOCKET_CONNECTED });
    };
    ws.onclose = function(e) {
      console.log(
        'Socket is closed. Reconnect will be attempted in 1 second.',
        e.reason
      );
      setTimeout(function() {
        websocketInit();
      }, 1000);
    };
    ws.onerror = error => {
      console.log('WebSocket error ');
      console.dir(error);
      ws.close();
    };
    ws.onmessage = e => {
      let msg = null;
      try {
        msg = JSON.parse(e.data);
      } catch (e) {
        console.error(`Error parsing : ${e.data}`);
      }
      if (msg) {
        const channel = msg.type;
        switch (channel) {
          case 'NEW_LOCATION':
            //console.log('NEW_LOCATION', msg.location);
            tempWebsocketLocationData[msg.location.vehicle.id] = msg.location;
            // return emitter({
            //   type: WEBSOCKET_NEW_LOCATION,
            //   location: msg.location
            // });
            break;
          case 'NEW_ADDRESS':
            //console.log('NEW_ADDRESS', msg, msg.address, msg.vehicle);
            tempWebsocketAddressData[msg.address.vehicle.id] = msg.address;
            // return emitter({
            //   type: WEBSOCKET_NEW_ADDRESS,
            //   address: msg.address
            // });
            break;
          case 'NEW_DATA':
            //console.log('NEW_DATA', msg.data);
            tempWebsocketVehicleData[msg.data.vehicle.id] = msg.data;
            //return emitter({ type: WEBSOCKET_NEW_DATA, data: msg.data });
            break;
          case 'NEW_DRIVER_DATA':
            //console.log('NEW_DRIVER_DATA', msg.data);
            tempWebsocketDriverActivityData[msg.data.driver.id] = msg.data;
            // return emitter({ type: WEBSOCKET_NEW_DRIVER_DATA, data: msg.data });
            break;
          case 'NEW_ACTIVITY_DATA':
            //console.log('NEW_ACTIVITY_DATA', msg.activity);
            //tempWebsocketVehicleActivityData.push(msg.data);
            tempWebsocketVehicleActivityData[msg.data.driver.id] = msg.activity;
            //return emitter({ type: WEBSOCKET_NEW_ACTIVITY_DATA, data: msg.data });
            break;
          case 'NEW_ALERT':
            //console.log('NEW_ALERT', msg.alert);
            tempWebsocketAlertData[msg.alert.vehicle.id] = msg.alert;
            //return emitter({ type: WEBSOCKET_NEW_ALERT_DATA, alert: msg.alert });
            break;
          default:
          // nothing to do
        }
      }
    };

    ws.close = () => {
      console.log('WebSocket closed ');
    };
    // unsubscribe function
    return () => {
      console.log('Socket off');
    };
  });
}

function websocketSetToken(token) {
  if (ws && token) {
    ws.send('{ "request": "SET_TOKEN", "token": "' + token + '" }');
  }
}

function websocketSubscribeEvents({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds
}) {
  if (ws) {
    let request = '{ "request": "SUBSCRIBE_VEHICLE_EVENTS"';
    if (customerId) request += ', "customerId": ' + customerId;
    if (vehicleGroupIds)
      request += ', "vehicleGroupIds": "' + vehicleGroupIds + '"';
    if (siteIds) request += ', "siteIds": "' + siteIds + '"';
    if (vehicleIds) request += ', "vehicleIds": "' + vehicleIds + '"';

    request += '}';

    ws.send(request);
  }
}

function websocketUnsubscribeEvents() {
  if (ws) {
    ws.send('{ "request": "UNSUBSCRIBE_VEHICLE_EVENTS" }');
  }
}

export default function* rootSaga() {
  yield all([
    fork(watcherWebsocketSetToken),
    fork(watcherWebsocketSubscribeEvents),
    fork(watcherWebsocketUnsubscribeEvents),
    fork(watcherWebsocket10s)
  ]);

  const channel = yield call(websocketInit);
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}
