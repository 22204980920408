import {
  FORGOT_PASSWORD_FAILURE,
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  UPDATE_PREFERENCES_DASHBOARD_FAILURE,
  UPDATE_PREFERENCES_DASHBOARD_REQUEST,
  UPDATE_PREFERENCES_DASHBOARD_SUCCESS,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  CHANGE_PASSWORD_NO_TOKEN_REQUEST,
  CHANGE_PASSWORD_NO_TOKEN_SUCCESS,
  CHANGE_PASSWORD_NO_TOKEN_FAILURE,
  GET_USERS_LIST_REQUEST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAILURE,
  INITIAL_FETCH_ENUM_UPDATE_USER_REQUEST,
  INITIAL_FETCH_ENUM_UPDATE_USER_SUCCESS,
  INITIAL_FETCH_ENUM_UPDATE_USER_FAILURE,
  GET_CUSTOMER_PROFILES_USER_REQUEST,
  GET_CUSTOMER_PROFILES_USER_SUCCESS,
  GET_CUSTOMER_PROFILES_USER_FAILURE,
  GET_CURRENCIES_ENUM_REQUEST,
  GET_CURRENCIES_ENUM_SUCCESS,
  GET_CURRENCIES_ENUM_FAILURE,
  GET_DEFAULT_PAGE_ENUM_REQUEST,
  GET_DEFAULT_PAGE_ENUM_SUCCESS,
  GET_DEFAULT_PAGE_ENUM_FAILURE,
  GET_LANGUAGES_ENUM_REQUEST,
  GET_LANGUAGES_ENUM_SUCCESS,
  GET_LANGUAGES_ENUM_FAILURE,
} from '../constants/ActionTypes';

const INIT_STATE = {
  loader: false,
  initURL: '',
  authUser: 0,
  error_code: null,
  preferencesDashboard: null,
  preferencesDashboardFetching: false,

  fetching_update_user: false,
  fetching_create_user: false,
  fetching_delete_user: false,
  fetching_get_user: false,
  usersFetching: null,
  usersList: null,
  userGet: null,
  updatedUser: null,
  createdUser: null,
  updatingPassword: null,
  updatedPassword: null,
  currenciesList: null,
  initialFetchingAddUserDone: null,
  profilesListFetching: null,
  profilesList: null,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: '/home',
        loader: false,
      };
    }

    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        messageType: '',
        showMessage: false,
        loader: false,
      };
    }

    case SIGNIN_GOOGLE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SIGNIN_FACEBOOK_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SIGNIN_TWITTER_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SIGNIN_GITHUB_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    //? PREFERENCES DASHBOARD //
    case UPDATE_PREFERENCES_DASHBOARD_REQUEST: {
      return {
        ...state,
        preferencesDashboardFetching: true,
        error: null,
      };
    }
    case UPDATE_PREFERENCES_DASHBOARD_SUCCESS:
      return {
        ...state,
        preferencesDashboardFetching: false,
        preferencesDashboard: action.preferencesDashboard,
      };
    case UPDATE_PREFERENCES_DASHBOARD_FAILURE:
      return {
        ...state,
        preferencesDashboardFetching: false,
        error: action.error,
      };
    //? FORGOT_PASSWORD_FAILURE
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        error_code: action.error,
      };
    //? GET SINGLE USER //
    case GET_USER_REQUEST: {
      return {
        ...state,
        fetching_get_user: true,
        error: null,
      };
    }
    case GET_USER_SUCCESS:
      let resGetUser = {
        ...state,
        fetching_get_user: false,
        userGet: action.user,
      };
      if (
        localStorage.getItem('user_login') === action.user.login &&
        !state.authUser
      ) {
        resGetUser['authUser'] = action.user;
        resGetUser['authUser'].token = localStorage.getItem('token');
        resGetUser['authUser'].tokenExpiredAt = parseInt(
          localStorage.getItem('tokenExpiredAt')
        );
      }
      return resGetUser;
    case GET_USER_FAILURE:
      let res = {
        ...state,
        fetching_get_user: false,
        userGet: null,
        error: action.error,
      };
      if (
        localStorage.getItem('user_login') === action.login &&
        action.error.response &&
        action.error.response.status === 401
      ) {
        res['authUser'] = null;
        res['initURL'] = '/signin';
      }
      return res;

    //? CREATE USER //
    case CREATE_USER_REQUEST: {
      return {
        ...state,
        fetching_create_user: true,
        error: null,
      };
    }
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        fetching_create_user: false,
        createdUser: action.user,
      };
    case CREATE_USER_FAILURE:
      return {
        ...state,
        fetching_create_user: false,
        createdUser: null,
        error: action.error,
      };

    //? UPDATE USER //
    case UPDATE_USER_REQUEST: {
      return {
        ...state,
        fetching_update_user: true,
        error: null,
      };
    }
    case UPDATE_USER_SUCCESS:
      let resUpdateUser = {
        ...state,
        fetching_update_user: false,
        updatedUser: action.user,
      };
      if (
        action.user &&
        state.authUser &&
        state.authUser.login == action.user.login
      ) {
        let authUserUpdated = Object.assign(state.authUser, action.user);
        resUpdateUser['authUser'] = authUserUpdated;
      }

      return resUpdateUser;
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        fetching_update_user: false,
        updatedUser: null,
        error: action.error,
      };

    //? DELETE USER //
    case DELETE_USER_REQUEST: {
      return {
        ...state,
        fetching_delete_user: true,
        error: null,
      };
    }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        fetching_delete_user: false,
        createdUser: action.user,
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        fetching_delete_user: false,
        error: action.error,
      };

    //? GET USERS LIST //
    case GET_USERS_LIST_REQUEST: {
      return {
        ...state,
        usersFetching: true,
        error: null,
      };
    }
    case GET_USERS_LIST_SUCCESS:
      return { ...state, usersFetching: false, usersList: action };
    case GET_USERS_LIST_FAILURE:
      return {
        ...state,
        usersFetching: false,
        error: action.error,
      };

    //? UPDATE USER PASSWORD NO MAIL TOKEN //
    case CHANGE_PASSWORD_NO_TOKEN_REQUEST: {
      return {
        ...state,
        updatingPassword: true,
        error: null,
      };
    }
    case CHANGE_PASSWORD_NO_TOKEN_SUCCESS:
      return { ...state, updatingPassword: false, updatedPassword: action };
    case CHANGE_PASSWORD_NO_TOKEN_FAILURE:
      return {
        ...state,
        updatingPassword: false,
        updatedPassword: null,
        error: action.error,
      };

    //? GET CURRENCIES ENUM //
    case GET_CURRENCIES_ENUM_REQUEST: {
      return {
        ...state,
        currenciesFetching: true,
        error: null,
      };
    }
    case GET_CURRENCIES_ENUM_SUCCESS:
      return {
        ...state,
        currenciesFetching: false,
        currenciesList: action.currenciesList,
      };
    case GET_CURRENCIES_ENUM_FAILURE:
      return {
        ...state,
        currenciesFetching: false,
        error: action.error,
      };

    //? GET LANGUAGES ENUM //
    case GET_LANGUAGES_ENUM_REQUEST: {
      return {
        ...state,
        languagesFetching: true,
        error: null,
      };
    }
    case GET_LANGUAGES_ENUM_SUCCESS:
      return {
        ...state,
        languagesFetching: false,
        languagesList: action.languagesList,
      };
    case GET_LANGUAGES_ENUM_FAILURE:
      return {
        ...state,
        languagesFetching: false,
        error: action.error,
      };

    //? GET DEFAULT PAGE ENUM //
    case GET_DEFAULT_PAGE_ENUM_REQUEST: {
      return {
        ...state,
        defaultPagesFetching: true,
        error: null,
      };
    }
    case GET_DEFAULT_PAGE_ENUM_SUCCESS:
      return {
        ...state,
        defaultPagesFetching: false,
        defaultPagesList: action.defaultPagesList,
      };
    case GET_DEFAULT_PAGE_ENUM_FAILURE:
      return {
        ...state,
        defaultPagesFetching: false,
        error: action.error,
      };

    //? GET CUSTOMER USER PROFILES //
    case GET_CUSTOMER_PROFILES_USER_REQUEST: {
      return {
        ...state,
        profilesListFetching: true,
        error: null,
      };
    }
    case GET_CUSTOMER_PROFILES_USER_SUCCESS:
      return {
        ...state,
        profilesListFetching: false,
        profilesList: action.profilesList,
      };
    case GET_CUSTOMER_PROFILES_USER_FAILURE:
      return {
        ...state,
        profilesListFetching: false,
        error: action.error,
      };

    //? INITIAL FETCH ADD USER //
    case INITIAL_FETCH_ENUM_UPDATE_USER_REQUEST: {
      return {
        ...state,
        initialFetchingAddUserDone: false,
        error: null,
      };
    }
    case INITIAL_FETCH_ENUM_UPDATE_USER_SUCCESS:
      return {
        ...state,
        initialFetchingAddUserDone: true,
        updatedPassword: action,
      };
    case INITIAL_FETCH_ENUM_UPDATE_USER_FAILURE:
      return {
        ...state,
        initialFetchingAddUserDone: true,
        error: action.error,
      };
    default:
      return state;
  }
};
