import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Footer from 'components/Footer';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { injectIntl } from 'react-intl';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { TextField } from 'formik-material-ui';
import Select from 'react-select';
import { selectAutocompleteStyle } from 'util/reactSelectStyles';

import * as Yup from 'yup';

import { colorStyle, bgColorStyle, designButtons } from 'util/themeColorClass';
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn,
  sendContact,
} from 'actions/Users';
const ValidatorSchema = Yup.object().shape({
  email: Yup.string().email(),
  message: Yup.string().required('A Message is required'),
});

const styles = (theme) => ({
  blackHover: {
    '&:hover': {
      color: `${theme.palette.primary.dark}`,
      transition: '0.3s ease-in',
    },
  },
  legalMention: {
    fontSize: '1rem',
    position: 'absolute',
    bottom: '1rem',
    left: 0,
    right: 0,
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  rootMessageInput: {
    height: 'auto',
    minHeight: '10rem',
  },
  notchedOutlineMessageInput: {
    borderRadius: 20,
  },
  textArea: {
    height: '8rem',
  },
  textAreaRadius: {
    borderRadius: 10,
  },
});

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //
    };
  }

  validatorSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        this.props
          ? this.props.intl.formatMessage({
              id: 'form.control.email.format',
            })
          : 'The format is incorrect'
      )
      .required(
        this.props
          ? this.props.intl.formatMessage({
              id: 'form.control.email.required',
            })
          : 'The email is required'
      ),
    subject: Yup.string().required(
      this.props
        ? this.props.intl.formatMessage({ id: 'form.control.required' })
        : 'This field is required'
    ),
    message: Yup.string().required(
      this.props
        ? this.props.intl.formatMessage({ id: 'form.control.required' })
        : 'This field is required'
    ),
  });

  render() {
    const classes = this.props.classes;
    const subjects = [
      this.props.intl.formatMessage({
        id: 'form.contactUs.subject1',
      }),
      this.props.intl.formatMessage({
        id: 'form.contactUs.subject2',
      }),
      this.props.intl.formatMessage({
        id: 'form.contactUs.subject3',
      }),
    ];
    const { showMessage, loader } = this.props;
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link
              className="logo-lg"
              rel="keep-params"
              to="/"
              title="Alert Gasoil">
              <img
                style={{ width: '230px', padding: '10px' }}
                src={require('assets/images/logo-alert-gasoil.svg')}
                alt="Alert Gasoil logo"
                title="alert gasoil"
              />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>
                <IntlMessages id="form.contactUs.title" />
              </h1>
            </div>

            <div className="app-login-form">
              <Formik
                initialValues={{
                  email: '',
                  subject: '',
                  message: '',
                }}
                enableReinitialize
                validationSchema={this.validatorSchema}
                onSubmit={(values, actions) => {
                  this.props.sendContact({
                    email: values.email,
                    subject: values.subject,
                    message: values.message,
                  });
                  actions.setSubmitting(false);
                  actions.resetForm();
                }}
                render={({
                  errors,
                  status,
                  touched,
                  setFieldValue,
                  submitForm,
                  isSubmitting,
                  values,
                  isValid,
                  handleChange,
                  handleSubmit,
                }) => (
                  <Form>
                    <FormControl component="fieldset" className={`mt-3 w-100`}>
                      <FormLabel component="legend">
                        <IntlMessages id="appModule.email" />
                      </FormLabel>
                      <Field
                        name="email"
                        type="text"
                        component={TextField}
                        onChange={handleChange}
                        value={values.email}
                        className="mt-2"
                        fullWidth
                        variant="outlined"
                      />
                    </FormControl>

                    <FormControl
                      className={`mt-1 my-sm-3 w-100`}
                      variant="outlined">
                      <FormLabel component="legend">
                        <IntlMessages id="form.contactUs.subject" />
                      </FormLabel>
                      <Select
                        name="subject"
                        placeholder=""
                        className="mt-2"
                        styles={selectAutocompleteStyle}
                        isClearable={false}
                        isSearchable={true}
                        isMulti={false}
                        options={subjects.map((status) => {
                          return { id: status, name: status };
                        })}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        controlShouldRenderValue={true}
                        defaultValue={''}
                        onChange={(selectedOption) => {
                          handleChange('subject')(selectedOption);
                        }}
                        value={values.subject}
                        menuPortalTarget={document.body}
                      />
                      <ErrorMessage name="subject" />
                    </FormControl>

                    <FormControl component="fieldset" className={`w-100`}>
                      <FormLabel component="legend">
                        <IntlMessages id="form.contactUs.message" />
                      </FormLabel>
                      <Field
                        name="message"
                        type="text"
                        component={TextField}
                        onChange={(selectedOption) => {
                          handleChange('message')(selectedOption);
                        }}
                        value={values.message}
                        className={`mt-2 w-100`}
                        fullWidth
                        variant="outlined"
                        multiline={true}
                        rows="4"
                        InputProps={{
                          classes: {
                            root: classes.textArea,
                            notchedOutline: classes.textAreaRadius,
                          },
                        }}
                      />
                    </FormControl>
                    <div className="mt-4 d-flex align-items-center justify-content-between">
                      <Link rel="keep-params" to="/signin">
                        <button className="signin-back-button">
                          <IntlMessages id="appModule.back" />
                        </button>
                      </Link>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!isValid}
                        onClick={handleSubmit}>
                        <IntlMessages id="appModule.send" />
                      </Button>
                    </div>
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({ users, global }) => {
  const { loader, authUser } = users;
  return { loader, authUser };
};

export default connect(mapStateToProps, {
  sendContact,
  userSignIn,
  hideMessage,
  showAuthLoader,
})(
  withStyles(
    (theme) => ({
      ...styles(theme),
      ...colorStyle(theme),
      ...bgColorStyle(theme),
      ...designButtons(theme),
    }),
    { withTheme: true }
  )(injectIntl(ContactUs))
);
