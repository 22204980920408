import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
//? ICONS
import IcomoonReact from 'icomoon-react';
import iconSet from 'styles/iconFont/selection.json';
import { Scrollbars } from 'react-custom-scrollbars';

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, 'li');
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains('open'))
          ) {
            menuLi[j].classList.remove('open');
          }
        }
        this.classList.toggle('open');
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {}
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        'matches',
        'webkitMatchesSelector',
        'mozMatchesSelector',
        'msMatchesSelector',
        'oMatchesSelector'
      ].some(function(fn) {
        if (typeof document.body[fn] == 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  render() {
    return (
      <ul className="nav-menu">
        <Scrollbars
          style={{ height: this.props.sidebarHeight }}
          renderTrackHorizontal={props => (
            <div
              {...props}
              style={{ display: 'none' }}
              className="track-horizontal"
            />
          )}>
          {/* <li className="nav-header">
            <IntlMessages id="sidebar.main" />
          </li> */}

          {/* Dashboard */}
          <li className="menu">
            <NavLink rel="keep-params" to="/home">
              <span>
                <IcomoonReact
                  iconSet={iconSet}
                  size={18}
                  icon="dashboard"
                  color="white"
                />
              </span>
              <span className="nav-text jr-font-weight-medium">
                <IntlMessages id="sidebar.text.home" />
              </span>
            </NavLink>
          </li>

          {/* Statistics */}
          <li className="menu collapse-box">
            <Button>
              <span>
                <IcomoonReact
                  iconSet={iconSet}
                  size={18}
                  icon="trend_up"
                  color="white"
                />
              </span>
              <span className="nav-text jr-font-weight-medium">
                <IntlMessages id="sidebar.text.stats" />
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/vehicles-stats">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.stats.vehicles" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/drivers-stats">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.stats.drivers" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          {/* Eco driving */}
          <li className="menu collapse-box">
            <Button>
              <span>
                <IcomoonReact
                  iconSet={iconSet}
                  size={18}
                  icon="ecodriving"
                  color="white"
                />
              </span>
              <span className="nav-text jr-font-weight-medium">
                <IntlMessages id="sidebar.text.ecodriving" />
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/eco-driving-driver">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.ecodrivingreportsDriver" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/eco-driving-vehicle">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.ecodrivingreportsVehicle" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-parc/eco-profiles-management-driver">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.settings.ecoProfilesDriver" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-parc/eco-profiles-management-vehicle">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.settings.ecoProfilesVehicle" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          {/* Live Map */}
          <li className="menu">
            <NavLink rel="keep-params" to="/map">
              <span>
                <IcomoonReact
                  iconSet={iconSet}
                  size={18}
                  icon="map_marker_radius"
                  color="white"
                />
              </span>
              <span className="nav-text jr-font-weight-medium">
                <IntlMessages id="sidebar.text.map" />
              </span>
            </NavLink>
          </li>

          {/* <li className="menu collapse-box">
          <Button>
            <span>
              <IcomoonReact
                iconSet={iconSet}
                size={18}
                icon="map_marker_radius"
                color="white"
              />
            </span>
            <span className="nav-text jr-font-weight-medium">
              <IntlMessages id="sidebar.text.map" />
            </span>
          </Button>
          <ul className="sub-menu">
            <li>
              <NavLink className="prepend-icon" rel="keep-params" to="/map/live">
                <span className="nav-text jr-font-weight-light">
                  <IntlMessages id="sidebar.text.map.live" />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon" rel="keep-params" to="/monitoring">
                <span className="nav-text jr-font-weight-light">
                  <IntlMessages id="sidebar.text.map.monitoring" />
                </span>
              </NavLink>
            </li>
          </ul>
        </li> */}

          {/* Monitoring */}
          <li className="menu">
            <NavLink rel="keep-params" to="/monitoring">
              <span>
                <IcomoonReact
                  iconSet={iconSet}
                  size={18}
                  icon="laptop"
                  color="white"
                />
              </span>
              <span className="nav-text jr-font-weight-medium">
                <IntlMessages id="sidebar.text.monitoring" />
              </span>
            </NavLink>
          </li>

          {/* Alert Center */}
          {/* <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-alert-triangle zmdi-hc-fw" />
              <span className="nav-text jr-font-weight-medium">
                <IntlMessages id="sidebar.text.alertscenter" />
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" rel="keep-params" to="/alerts-map">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.alertscenter.alertsmap" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" rel="keep-params" to="/alerts-stats">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.alertscenter.statsalerts" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li> */}

          {/* Centre d'économies */}
          <li className="menu collapse-box">
            <Button>
              <span>
                <IcomoonReact
                  iconSet={iconSet}
                  size={18}
                  icon="rocket"
                  color="white"
                />
              </span>
              <span className="nav-text jr-font-weight-medium">
                <IntlMessages id="sidebar.text.savingsCenter" />
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/savings-center/dashboard">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="page.dashboard" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/savings-center/roi">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.savingsCenter.roi" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/savings-center/administrative-gains">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.savingsCenter.administrative" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/savings-center/thefts">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.savingsCenter.thefts" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/savings-center/tmava">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="map.status.vehicle.tmava" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/savings-center/tmavat">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="map.status.vehicle.tmavat" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/savings-center/refuels-optimisation">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.savingsCenter.refuelsOptimisation" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          {/* Centre de rapport */}
          <li className="menu collapse-box">
            <Button>
              <span>
                <IcomoonReact
                  iconSet={iconSet}
                  size={18}
                  icon="book"
                  color="white"
                />
              </span>
              <span className="nav-text jr-font-weight-medium">
                <IntlMessages id="sidebar.text.reportsCenter" />
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/reports-center/dashboard">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="page.dashboard" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/reports-center/subscription">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.reportsCenter.subscription" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          {/* Suivi de la flotte */}
          <li className="menu collapse-box">
            <Button>
              <span>
                <IcomoonReact
                  iconSet={iconSet}
                  size={18}
                  icon="vehicle_group"
                  color="white"
                />
              </span>
              <span className="nav-text jr-font-weight-medium">
                <IntlMessages id="sidebar.text.fleetMonitoring" />
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" rel="keep-params" to="/sav">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.sav" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          {/* Settings */}
          <li className="menu collapse-box">
            <Button>
              <span>
                <IcomoonReact
                  iconSet={iconSet}
                  size={18}
                  icon="action"
                  color="white"
                />
              </span>
              <span className="nav-text jr-font-weight-medium">
                <IntlMessages id="sidebar.text.settings" />
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/settings/edit-vehicle-groups">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.settings.vehiclesgroup" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/settings/edit-driver-groups">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.settings.driversgroup" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/settings/refuel-import">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.settings.refuelImport" />
                  </span>
                </NavLink>
              </li>
              {/* <li>
              <NavLink className="prepend-icon" rel="keep-params" to="/settings/vehicles">
                <span className="nav-text jr-font-weight-light">
                  <IntlMessages id="sidebar.text.settings.vehicles" />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon" rel="keep-params" to="/settings/vehicles-group">
                <span className="nav-text jr-font-weight-light">
                  <IntlMessages id="sidebar.text.settings.vehiclesgroup" />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon" rel="keep-params" to="/settings/drivers">
                <span className="nav-text jr-font-weight-light">
                  <IntlMessages id="sidebar.text.settings.drivers" />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon" rel="keep-params" to="/settings/drivers-group">
                <span className="nav-text jr-font-weight-light">
                  <IntlMessages id="sidebar.text.settings.driversgroup" />
                </span>
              </NavLink>
            </li> */}
            </ul>
          </li>

          {/* AG sup */}
          <li className="menu collapse-box">
            <Button>
              <span>
                <IcomoonReact
                  iconSet={iconSet}
                  size={18}
                  icon="screen"
                  color="white"
                />
              </span>
              <span className="nav-text jr-font-weight-medium">
                <IntlMessages id="sidebar.text.agsup" />
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-sup/dashboard">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.agsup.dashboard" />
                  </span>
                </NavLink>
              </li>
              {/* <li>
              <NavLink className="prepend-icon" rel="keep-params" to="/ag-sup/request">
                <span className="nav-text jr-font-weight-light">
                  <IntlMessages id="sidebar.text.agsup.request" />
                </span>
              </NavLink>
            </li> */}
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-sup/ada">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.agsup.ada" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-sup/ada-vehicle">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.agsup.adapervehicle" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-sup/monitoring">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.agsup.monitoring" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-sup/monitoring-detail">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.agsup.monitoringdetail" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-sup/monitoring-events/refuel">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.agsup.monitoringevent.refuel" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-sup/monitoring-events/theft">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.agsup.monitoringevent.theft" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-sup/monitoring-events/tankage">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.agsup.monitoringevent.tankage" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-sup/treatment-requests">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.agsup.treatmentRequests" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          {/* AG Parc */}
          <li className="menu collapse-box">
            <Button>
              <span>
                <IcomoonReact
                  iconSet={iconSet}
                  size={18}
                  icon="parking"
                  color="white"
                />
              </span>
              <span className="nav-text jr-font-weight-medium">
                <IntlMessages id="sidebar.text.agparc" />
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-parc/users-management">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.settings.agparc.users_settings" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-parc/customers-management">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.settings.agparc.customers_settings" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-parc/vehicles-management">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.settings.agparc.vehicles_settings" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-parc/drivers-management">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.settings.agparc.drivers_settings" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-parc/package-management">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.settings.agparc.package_settings" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-parc/modems-management">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.settings.agparc.modems_settings" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-parc/sim-management">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.settings.agparc.sims_settings" />
                  </span>
                </NavLink>
              </li>
              {/* <li>
              <NavLink className="prepend-icon" rel="keep-params" to="/ag-parc/users-management">
                <span className="nav-text jr-font-weight-light">
                  <IntlMessages id="sidebar.text.settings.agparc.users_settings" />
                </span>
              </NavLink>
            </li> */}
            </ul>
          </li>

          {/* AG Tank REF */}
          <li className="menu collapse-box">
            <Button>
              <span>
                <IcomoonReact
                  iconSet={iconSet}
                  size={18}
                  icon="tank_mini"
                  color="white"
                />
              </span>
              <span className="nav-text jr-font-weight-medium">
                <IntlMessages id="sidebar.text.agtankref" />
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-tank-ref/tank-series-management">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.agtankref.seriemanagement" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-tank-ref/tank-models-management">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.agtankref.modelmanagement" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  rel="keep-params"
                  to="/ag-tank-ref/tank-management">
                  <span className="nav-text jr-font-weight-light">
                    <IntlMessages id="sidebar.text.agtankref.tankcalibration" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          {/* AG Tach */}
          <li className="menu">
            <a href="https://tach.alertgasoil.com/do/info" target="_blank">
              <span>
                <IcomoonReact
                  iconSet={iconSet}
                  size={18}
                  icon="tachy"
                  color="white"
                />
              </span>
              <span className="nav-text jr-font-weight-medium">
                <IntlMessages id="sidebar.text.agtach" />
              </span>
            </a>
          </li>

          {/* Contact Us */}
          {/* <li className="menu">
            <NavLink rel="keep-params" to="/contact">
              <i className="zmdi zmdi-email zmdi-hc-fw" />
              <span className="nav-text jr-font-weight-medium">
                <IntlMessages id="sidebar.text.contact" />
              </span>
            </NavLink>
          </li> */}
        </Scrollbars>
      </ul>
    );
  }
}

export default withRouter(SidenavContent);
