import { all } from 'redux-saga/effects';
import vehicleSagas from './Vehicles';
import customerSagas from './Customers';
import driverSaga from './Drivers';
import tankSagas from './Tanks';
import zoneSagas from './Zones';
import websocketSagas from './Websocket';
import tripsSagas from './Trips';
import alertsSagas from './Alerts';
import reportsSagas from './Reports';
import userSagas from './Users';
import ecoProfileSaga from './EcoProfiles';
import groupsSaga from './Groups';
import packagesSaga from './Packages';
import refuelsSaga from './Refuels';
import vehicleDailyReportsSaga from './VehicleDailyReports';
import adaSaga from './Ada';
import theftSaga from './Thefts';
import globalSaga from './Global';

export default function* rootSaga(getState) {
  yield all([
    vehicleSagas(),
    customerSagas(),
    tankSagas(),
    websocketSagas(),
    driverSaga(),
    zoneSagas(),
    tripsSagas(),
    alertsSagas(),
    reportsSagas(),
    userSagas(),
    ecoProfileSaga(),
    groupsSaga(),
    packagesSaga(),
    refuelsSaga(),
    vehicleDailyReportsSaga(),
    adaSaga(),
    theftSaga(),
    globalSaga()
  ]);
}
