import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  ADD_VEHICLE_FAILURE,
  ADD_VEHICLE_REQUEST,
  ADD_VEHICLE_SUCCESS,
  GET_ALL_DATA_TO_ADD_VEHICLE_REQUEST,
  GET_ALL_DATA_TO_ADD_VEHICLE_SUCCESS,
  GET_ALL_DATA_TO_UPDATE_VEHICLE_FAILURE,
  GET_ALL_DATA_TO_UPDATE_VEHICLE_REQUEST,
  GET_ALL_DATA_TO_UPDATE_VEHICLE_SUCCESS,
  GET_ALL_VEHICLE_SITES_FILTER_FAILURE,
  GET_ALL_VEHICLE_SITES_FILTER_REQUEST,
  GET_ALL_VEHICLE_SITES_FILTER_SUCCESS,
  GET_ALL_VEHICLES_FAILURE,
  GET_ALL_VEHICLES_REQUEST,
  GET_ALL_VEHICLES_SUCCESS,
  GET_ALL_ZONES_FAILURE,
  GET_ALL_ZONES_REQUEST,
  GET_ALL_ZONES_SUCCESS,
  GET_CUSTOMERS_FAILURE,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  GET_DRIVER_ACTIVITIES_BY_VEHICLE_FAILURE,
  GET_DRIVER_ACTIVITIES_BY_VEHICLE_REQUEST,
  GET_DRIVER_ACTIVITIES_BY_VEHICLE_SUCCESS,
  GET_ENGINE_DETECTION_TYPE_FAILURE,
  GET_ENGINE_DETECTION_TYPE_REQUEST,
  GET_ENGINE_DETECTION_TYPE_SUCCESS,
  GET_ENUM_CALIB_FAILURE,
  GET_ENUM_CALIB_REQUEST,
  GET_ENUM_CALIB_SUCCESS,
  GET_GAUGE_TYPES_FAILURE,
  GET_GAUGE_TYPES_REQUEST,
  GET_GAUGE_TYPES_SUCCESS,
  GET_PTO_TYPE_ENUM_FAILURE,
  GET_PTO_TYPE_ENUM_REQUEST,
  GET_PTO_TYPE_ENUM_SUCCESS,
  GET_SINGLE_VEHICLE_FAILURE,
  GET_SINGLE_VEHICLE_REQUEST,
  GET_SINGLE_VEHICLE_SUCCESS,
  GET_PRESELECTED_VEHICLES_BARFILTER_REQUEST,
  GET_PRESELECTED_VEHICLES_BARFILTER_SUCCESS,
  GET_PRESELECTED_VEHICLES_BARFILTER_FAILURE,
  GET_TACHY_TYPE_FAILURE,
  GET_TACHY_TYPE_REQUEST,
  GET_TACHY_TYPE_SUCCESS,
  GET_RDL_TYPE_FAILURE,
  GET_RDL_TYPE_REQUEST,
  GET_RDL_TYPE_SUCCESS,
  GET_VEHICLE_ACTIVITY_STATS_REPORT_FAILURE,
  GET_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_FAILURE,
  GET_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST,
  GET_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_SUCCESS,
  GET_VEHICLE_ACTIVITY_STATS_REPORT_REQUEST,
  GET_VEHICLE_ACTIVITY_STATS_REPORT_SUCCESS,
  GET_VEHICLE_BRAND_FAILURE,
  GET_VEHICLE_BRAND_REQUEST,
  GET_VEHICLE_BRAND_SUCCESS,
  GET_VEHICLE_CATEGORY_FAILURE,
  GET_VEHICLE_CATEGORY_REQUEST,
  GET_VEHICLE_CATEGORY_SUCCESS,
  GET_VEHICLE_FUEL_CONSUMPTION_REPORT_FAILURE,
  GET_VEHICLE_FUEL_CONSUMPTION_REPORT_MULTIPLE_FAILURE,
  GET_VEHICLE_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST,
  GET_VEHICLE_FUEL_CONSUMPTION_REPORT_MULTIPLE_SUCCESS,
  GET_VEHICLE_FUEL_CONSUMPTION_REPORT_REQUEST,
  GET_VEHICLE_FUEL_CONSUMPTION_REPORT_SUCCESS,
  GET_VEHICLE_FUEL_REPORT_FAILURE,
  GET_VEHICLE_FUEL_REPORT_REQUEST,
  GET_VEHICLE_FUEL_REPORT_SUCCESS,
  GET_VEHICLE_FUEL_REPORT_BY_DRIVER_FAILURE,
  GET_VEHICLE_FUEL_REPORT_BY_DRIVER_REQUEST,
  GET_VEHICLE_FUEL_REPORT_BY_DRIVER_SUCCESS,
  GET_VEHICLE_GROUP_TYPES_FAILURE,
  GET_VEHICLE_GROUP_TYPES_REQUEST,
  GET_VEHICLE_GROUP_TYPES_SUCCESS,
  GET_VEHICLE_GROUPS_FILTER_FAILURE,
  GET_VEHICLE_GROUPS_FILTER_REQUEST,
  GET_VEHICLE_GROUPS_FILTER_SUCCESS,
  GET_VEHICLE_MODEL_FAILURE,
  GET_VEHICLE_MODEL_REQUEST,
  GET_VEHICLE_MODEL_SUCCESS,
  GET_VEHICLE_STATUS_FAILURE,
  GET_VEHICLE_STATUS_REQUEST,
  GET_VEHICLE_STATUS_SUCCESS,
  GET_VEHICLE_TMAVA_TOP_REPORT_FAILURE,
  GET_VEHICLE_TMAVA_TOP_REPORT_REQUEST,
  GET_VEHICLE_TMAVA_TOP_REPORT_SUCCESS,
  GET_VEHICLE_TOTAL_ACTIVITY_DAYS_REPORT_FAILURE,
  GET_VEHICLE_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST,
  GET_VEHICLE_TOTAL_ACTIVITY_DAYS_REPORT_SUCCESS,
  GET_VEHICLE_TOTAL_DISTANCE_REPORT_FAILURE,
  GET_VEHICLE_TOTAL_DISTANCE_REPORT_MULTIPLE_FAILURE,
  GET_VEHICLE_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST,
  GET_VEHICLE_TOTAL_DISTANCE_REPORT_MULTIPLE_SUCCESS,
  GET_VEHICLE_TOTAL_DISTANCE_REPORT_REQUEST,
  GET_VEHICLE_TOTAL_DISTANCE_REPORT_SUCCESS,
  GET_VEHICLE_TOTAL_FUEL_REPORT_FAILURE,
  GET_VEHICLE_TOTAL_FUEL_REPORT_REQUEST,
  GET_VEHICLE_TOTAL_FUEL_REPORT_SUCCESS,
  GET_VEHICLE_TYPE_FAILURE,
  GET_VEHICLE_TYPE_REQUEST,
  GET_VEHICLE_TYPE_SUCCESS,
  GET_VEHICLES_FILTER_FAILURE,
  GET_VEHICLES_FILTER_REQUEST,
  GET_VEHICLES_FILTER_SUCCESS,
  GET_VEHICLES_HEADER_FAILURE,
  GET_VEHICLES_HEADER_REQUEST,
  GET_VEHICLES_HEADER_SUCCESS,
  UPDATE_VEHICLE_FAILURE,
  UPDATE_VEHICLE_REQUEST,
  UPDATE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAILURE,
  DELETE_VEHICLE_REQUEST,
  DELETE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FORM_MAP_REQUEST,
  UPDATE_VEHICLE_FORM_MAP_SUCCESS,
  UPDATE_VEHICLE_FORM_MAP_FAILURE,
  GET_DEEPEST_GROUPS_REQUEST,
  GET_DEEPEST_GROUPS_SUCCESS,
  GET_DEEPEST_GROUPS_FAILURE,
  GET_ENERGY_ENUM_REQUEST,
  GET_ENERGY_ENUM_SUCCESS,
  GET_ENERGY_ENUM_FAILURE,
  GET_PACKAGE_HISTORIC_REQUEST,
  GET_PACKAGE_HISTORIC_FAILURE,
  GET_PACKAGE_HISTORIC_SUCCESS,
  DELETE_DRIVER_REQUEST,
  DELETE_DRIVER_SUCCESS,
  DELETE_DRIVER_FAILURE,
  CHECK_VEHICLE_DATA_IMPORT_REQUEST,
  CHECK_VEHICLE_DATA_IMPORT_SUCCESS,
  CHECK_VEHICLE_DATA_IMPORT_FAILURE,
} from 'constants/ActionTypes';
import { showMessage } from 'actions/Global';
import { API_URL } from '../constants/globalConstants';
import axios from 'axios';

export function* watcherGetAllDataToUpdateVehicle() {
  yield takeLatest(
    GET_ALL_DATA_TO_UPDATE_VEHICLE_REQUEST,
    executeWorkersToUpdate
  );
}

export function* executeWorkersToUpdate(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  const { payload } = action;

  try {
    const response = yield call(getSingleVehicle, token, payload.vehicleId);
    const vehicle = response.data;
    yield put({ type: GET_SINGLE_VEHICLE_SUCCESS, vehicle });
    const customerId = vehicle.customerId;
    const brandId = vehicle.brand && vehicle.brand.id;
    yield all([
      put({ type: GET_TACHY_TYPE_REQUEST }),
      put({ type: GET_RDL_TYPE_REQUEST }),
      put({ type: GET_VEHICLE_BRAND_REQUEST }),
      put({ type: GET_ENGINE_DETECTION_TYPE_REQUEST }),
      put({ type: GET_VEHICLE_CATEGORY_REQUEST }),
      put({ type: GET_VEHICLE_TYPE_REQUEST }),
      put({ type: GET_CUSTOMERS_REQUEST, payload: { customerId } }),
      put({ type: GET_GAUGE_TYPES_REQUEST }),
      put({ type: GET_ENUM_CALIB_REQUEST }),
      put({ type: GET_PTO_TYPE_ENUM_REQUEST }),
      put({ type: GET_VEHICLE_STATUS_REQUEST }),
      put({ type: GET_ALL_ZONES_REQUEST }),
      put({ type: GET_VEHICLE_MODEL_REQUEST, payload: brandId }),
      put({ type: GET_DEEPEST_GROUPS_REQUEST, payload: customerId }),
      put({ type: GET_ENERGY_ENUM_REQUEST }),
      put({ type: GET_PACKAGE_HISTORIC_REQUEST, payload: payload.vehicleId }),
    ]);
    const res = yield all([
      take([GET_TACHY_TYPE_SUCCESS, GET_TACHY_TYPE_FAILURE]),
      take([GET_RDL_TYPE_SUCCESS, GET_RDL_TYPE_FAILURE]),
      take([GET_VEHICLE_BRAND_SUCCESS, GET_VEHICLE_BRAND_FAILURE]),
      take([
        GET_ENGINE_DETECTION_TYPE_SUCCESS,
        GET_ENGINE_DETECTION_TYPE_FAILURE,
      ]),
      take([GET_VEHICLE_CATEGORY_SUCCESS, GET_VEHICLE_CATEGORY_FAILURE]),
      take([GET_VEHICLE_TYPE_SUCCESS, GET_VEHICLE_TYPE_FAILURE]),
      take([GET_CUSTOMERS_SUCCESS, GET_CUSTOMERS_FAILURE]),
      take([GET_GAUGE_TYPES_SUCCESS, GET_GAUGE_TYPES_FAILURE]),
      take([GET_ENUM_CALIB_SUCCESS, GET_ENUM_CALIB_FAILURE]),
      take([GET_PTO_TYPE_ENUM_SUCCESS, GET_PTO_TYPE_ENUM_FAILURE]),
      take([GET_VEHICLE_STATUS_SUCCESS, GET_VEHICLE_STATUS_FAILURE]),
      take([GET_ALL_ZONES_SUCCESS, GET_ALL_ZONES_FAILURE]),
      take([GET_VEHICLE_MODEL_SUCCESS, GET_VEHICLE_MODEL_FAILURE]),
      take([GET_DEEPEST_GROUPS_SUCCESS, GET_DEEPEST_GROUPS_FAILURE]),
      take([GET_ENERGY_ENUM_SUCCESS, GET_ENERGY_ENUM_FAILURE]),
      take([GET_PACKAGE_HISTORIC_SUCCESS, GET_PACKAGE_HISTORIC_FAILURE]),
    ]);
    if (res != null) {
      yield put({ type: GET_ALL_DATA_TO_UPDATE_VEHICLE_SUCCESS });
    }
  } catch (error) {
    yield put({ type: GET_ALL_DATA_TO_UPDATE_VEHICLE_FAILURE, error });
  }
}

export function* watcherGetAllDataToAddVehicle() {
  yield takeLatest(GET_ALL_DATA_TO_ADD_VEHICLE_REQUEST, executeWorkersToAdd);
}

export function* executeWorkersToAdd() {
  yield all([
    put({ type: GET_TACHY_TYPE_REQUEST }),
    put({ type: GET_RDL_TYPE_REQUEST }),
    put({ type: GET_VEHICLE_BRAND_REQUEST }),
    put({ type: GET_ENGINE_DETECTION_TYPE_REQUEST }),
    put({ type: GET_VEHICLE_CATEGORY_REQUEST }),
    put({ type: GET_VEHICLE_TYPE_REQUEST }),
    put({ type: GET_CUSTOMERS_REQUEST, payload: {} }),
    put({ type: GET_GAUGE_TYPES_REQUEST }),
    put({ type: GET_ENUM_CALIB_REQUEST }),
    put({ type: GET_PTO_TYPE_ENUM_REQUEST }),
    put({ type: GET_VEHICLE_STATUS_REQUEST }),
    put({ type: GET_ENERGY_ENUM_REQUEST }),
  ]);
  const response = yield all([
    take([GET_TACHY_TYPE_SUCCESS, GET_TACHY_TYPE_FAILURE]),
    take([GET_RDL_TYPE_SUCCESS, GET_RDL_TYPE_FAILURE]),
    take([GET_VEHICLE_BRAND_SUCCESS, GET_VEHICLE_BRAND_FAILURE]),
    take([
      GET_ENGINE_DETECTION_TYPE_SUCCESS,
      GET_ENGINE_DETECTION_TYPE_FAILURE,
    ]),
    take([GET_VEHICLE_CATEGORY_SUCCESS, GET_VEHICLE_CATEGORY_FAILURE]),
    take([GET_VEHICLE_TYPE_SUCCESS, GET_VEHICLE_TYPE_FAILURE]),
    take([GET_CUSTOMERS_SUCCESS, GET_CUSTOMERS_FAILURE]),
    take([GET_GAUGE_TYPES_SUCCESS, GET_GAUGE_TYPES_FAILURE]),
    take([GET_ENUM_CALIB_SUCCESS, GET_ENUM_CALIB_FAILURE]),
    take([GET_PTO_TYPE_ENUM_SUCCESS, GET_PTO_TYPE_ENUM_FAILURE]),
    take([GET_VEHICLE_STATUS_SUCCESS, GET_VEHICLE_STATUS_FAILURE]),
    take([GET_ENERGY_ENUM_SUCCESS, GET_ENERGY_ENUM_FAILURE]),
  ]);
  if (response != null) {
    yield put({ type: GET_ALL_DATA_TO_ADD_VEHICLE_SUCCESS });
  }
}

export function* watcherGetAllVehicles() {
  yield takeLatest(GET_ALL_VEHICLES_REQUEST, workerGetAllVehicles);
}

export function* watcherGetVehiclesFilter() {
  yield takeLatest(GET_VEHICLES_FILTER_REQUEST, workerGetVehiclesFilter);
}

export function* watcherGetVehiclesHeader() {
  yield takeLatest(GET_VEHICLES_HEADER_REQUEST, workerGetVehiclesHeader);
}

export function* watcherGetSingleVehicle() {
  yield takeLatest(GET_SINGLE_VEHICLE_REQUEST, workerGetSingleVehicle);
}

export function* watcherGetPreselectedVehiclesBarFilter() {
  yield takeLatest(
    GET_PRESELECTED_VEHICLES_BARFILTER_REQUEST,
    workerGetPreselectedVehiclesBarFilter
  );
}

export function* watcherAddVehicle() {
  yield takeLatest(ADD_VEHICLE_REQUEST, workerAddVehicle);
}

export function* watcherUpdateVehicle() {
  yield takeLatest(UPDATE_VEHICLE_REQUEST, workerUpdateVehicle);
}

export function* watcherDeleteVehicle() {
  yield takeLatest(DELETE_VEHICLE_REQUEST, workerDeleteVehicle);
}

export function* watcherUpdateVehicleFormMap() {
  yield takeLatest(UPDATE_VEHICLE_FORM_MAP_REQUEST, workerUpdateVehicleFormMap);
}

export function* watcherGetVehicleBrand() {
  yield takeEvery(GET_VEHICLE_BRAND_REQUEST, workerGetVehicleBrand);
}

export function* watcherGetVehicleModel() {
  yield takeEvery(GET_VEHICLE_MODEL_REQUEST, workerGetVehicleModel);
}

export function* watcherGetVehicleType() {
  yield takeEvery(GET_VEHICLE_TYPE_REQUEST, workerGetVehicleType);
}

export function* watcherGetTachyType() {
  yield takeEvery(GET_TACHY_TYPE_REQUEST, workerGetTachyType);
}

export function* watcherGetRDLType() {
  yield takeEvery(GET_RDL_TYPE_REQUEST, workerGetRDLType);
}

export function* watcherGetVehicleCategory() {
  yield takeEvery(GET_VEHICLE_CATEGORY_REQUEST, workerGetVehicleCategory);
}

export function* watcherGetEngineDetectionType() {
  yield takeEvery(
    GET_ENGINE_DETECTION_TYPE_REQUEST,
    workerGetEngineDetectionType
  );
}

export function* watcherGetAllVehicleSitesFilter() {
  yield takeLatest(
    GET_ALL_VEHICLE_SITES_FILTER_REQUEST,
    workerGetAllVehicleSitesFilter
  );
}

export function* watcherGetVehicleGroupsFilter() {
  yield takeLatest(
    GET_VEHICLE_GROUPS_FILTER_REQUEST,
    workerGetVehicleGroupsFilter
  );
}

export function* watcherGetEnumCalibration() {
  yield takeLatest(GET_ENUM_CALIB_REQUEST, workerGetEnumCalibration);
}

export function* watcherGetVehicleStatus() {
  yield takeLatest(GET_VEHICLE_STATUS_REQUEST, workerGetVehicleStatus);
}

export function* watcherGetVehicleGroupTypes() {
  yield takeLatest(GET_VEHICLE_GROUP_TYPES_REQUEST, workerGetVehicleGroupTypes);
}

export function* watcherGetVehicleFuelReport() {
  yield takeLatest(GET_VEHICLE_FUEL_REPORT_REQUEST, workerGetVehicleFuelReport);
}

export function* watcherGetVehicleFuelReportByDriver() {
  yield takeLatest(
    GET_VEHICLE_FUEL_REPORT_BY_DRIVER_REQUEST,
    workerGetVehicleFuelReportByDriver
  );
}

export function* watcherGetVehicleTmavaTopReport() {
  yield takeLatest(
    GET_VEHICLE_TMAVA_TOP_REPORT_REQUEST,
    workerGetVehicleTmavaTopReport
  );
}

export function* watcherGetVehicleFuelConsumptionReport() {
  yield takeLatest(
    GET_VEHICLE_FUEL_CONSUMPTION_REPORT_REQUEST,
    workerGetVehicleFuelConsumptionReport
  );
}

export function* watcherGetVehicleFuelConsumptionReportMultiple() {
  yield takeLatest(
    GET_VEHICLE_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST,
    workerGetVehicleFuelConsumptionReportMultiple
  );
}

export function* watcherGetVehicleTotalFuelReport() {
  yield takeLatest(
    GET_VEHICLE_TOTAL_FUEL_REPORT_REQUEST,
    workerGetVehicleTotalFuelReport
  );
}

export function* watcherGetVehicleTotalDistanceReport() {
  yield takeLatest(
    GET_VEHICLE_TOTAL_DISTANCE_REPORT_REQUEST,
    workerGetVehicleTotalDistanceReport
  );
}

export function* watcherGetVehicleTotalDistanceReportMultiple() {
  yield takeLatest(
    GET_VEHICLE_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST,
    workerGetVehicleTotalDistanceReportMultiple
  );
}

export function* watcherGetVehicleTotalActivityDaysReport() {
  yield takeLatest(
    GET_VEHICLE_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST,
    workerGetVehicleTotalActivityDaysReport
  );
}

export function* watcherGetVehicleActivityStatsReport() {
  yield takeLatest(
    GET_VEHICLE_ACTIVITY_STATS_REPORT_REQUEST,
    workerGetVehicleActivityStatsReport
  );
}

export function* watcherGetVehicleActivityStatsReportMultiple() {
  yield takeLatest(
    GET_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST,
    workerGetVehicleActivityStatsReportMultiple
  );
}

export function* watcherGetPtoTypes() {
  yield takeLatest(GET_PTO_TYPE_ENUM_REQUEST, workerGetPtoTypes);
}

export function* watcherGetEnergyEnum() {
  yield takeLatest(GET_ENERGY_ENUM_REQUEST, workerGetEnergyEnum);
}

export function* watcherGetDriverActivitiesByVehicle() {
  yield takeLatest(
    GET_DRIVER_ACTIVITIES_BY_VEHICLE_REQUEST,
    workerGetDriverActivitiesByVehicle
  );
}

export function* watcherCheckVehicleDataImport() {
  yield takeLatest(
    CHECK_VEHICLE_DATA_IMPORT_REQUEST,
    workerCheckVehicleDataImport
  );
}

const getAllVehicles = async (
  token,
  {
    customerId,
    vehicleGroupIds,
    siteIds,
    vehicleIds,
    packageIsActive,
    calibrationType,
    offset,
    limit,
    pattern,
    lightModel,
  }
) => {
  let url = `${API_URL}vehicles`;
  if (lightModel) url += '/map?';
  else url += '?';
  if (customerId) url += 'customerId=' + customerId + '&';
  if (vehicleGroupIds) {
    vehicleGroupIds.map((vehicleGroupId) => {
      url += 'vehicleGroupId=' + vehicleGroupId + '&';
    });
  }
  if (siteIds) {
    siteIds.map((siteId) => {
      url += 'vehicleGroupId=' + siteId + '&';
    });
  }
  if (vehicleIds) {
    vehicleIds.map((vehicleId) => {
      url += 'vehicleId=' + vehicleId + '&';
    });
  }
  if (calibrationType) {
    url += 'calibrationType=' + calibrationType + '&';
  }
  if (packageIsActive) {
    url += 'packageIsActive=' + packageIsActive + '&';
  }
  if (offset) url += 'offset=' + offset + '&';
  if (limit) url += 'limit=' + limit + '&';
  if (pattern) url += 'pattern=' + pattern;
  console.log(url);
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: url,
  });
};

const getSingleVehicle = async (token, idVehicle) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}vehicles/` + idVehicle,
  });
};

const addVehicle = async (token, vehicle) => {
  console.log('=====SAGA ADD VEHICLE=====');
  console.log(vehicle);
  console.log('======================');

  let obj = {
    customerId: vehicle.customer,
    vin: vehicle.vin,
    plate: vehicle.plate,
    vehicleModelId: vehicle.vehicle_model_id,
    status: vehicle.vehicle_status,
    category: vehicle.category,
    type: vehicle.vehicle_type,
    energy: vehicle.energy,
    tachyType: vehicle.tachy_type,
    comment: vehicle.comment ? vehicle.comment : '',
    manufactureYear: vehicle.manufacture_year
      ? vehicle.manufacture_year.year
        ? vehicle.manufacture_year.year()
        : vehicle.manufacture_year
      : null,
    geographicVehicleGroupId: vehicle.geographic_group,
    optionalVehicleGroupIds: vehicle.optional_groups,
    parcName: vehicle.park_name,
    activePackage: vehicle.package_is_active,
    tankCalibrationIds: vehicle.tankIds,
    indexKm: vehicle.km_index,
    dateIndexKm: vehicle.km_index_date
      ? vehicle.km_index_date.year
        ? vehicle.km_index_date.valueOf()
        : vehicle.km_index_date
      : null,
    calibration: vehicle.calibration,
    calibrationDate: vehicle.calibration_date
      ? vehicle.calibration_date.year
        ? vehicle.calibration_date.valueOf()
        : vehicle.calibration_date
      : null,

    settings: {
      TACHY_ENTERED_IN_MATRIX: vehicle.tachy_entered_matrix,
      TACHY_CONNECTED_DURING_INSTALLATION: vehicle.tachy_entered_during_install,
      //DETECTED_TACHY: vehicle.tachy_detected,
      ENGINE_DETECTION: vehicle.engine_detection,
      PTO_ENTERED_IN_MATRIX: vehicle.pto_entered_matrix,
      PTO_CONNECTED_DURING_INSTALLATION: vehicle.pto_connected_during_install,
      //DETECTED_PTO: vehicle.auto_pto_detected,
      BUZZER_ENTERED_IN_MATRIX: vehicle.buzzer_in_matrix,
      BUZZER_CONNECTED_DURING_INSTALLATION:
        vehicle.buzzer_connected_during_install,
      TANK_REMOVAL: vehicle.tank_removal,
      FMS_ENTERED_IN_MATRIX: vehicle.fms_in_matrix,
      FMS_CONNECTED_DURING_INSTALLATION: vehicle.fms_connected_during_install,
      //FMS_DETECTED: vehicle.fms_detected_automatically,
      PLUS_PERMANENT_CONNECTED: vehicle.permanent_plus,
      ADR: vehicle.adr,
      RDL: vehicle.rdl_equipment,
      RDL_PROVIDER: vehicle.rdl_provider,
      // IMEI: vehicle.imei ? vehicle.imei.number : null,

      EVENT_TMAVA_ENABLED: vehicle.tmava_event,
      EVENT_TMAVA_BEGIN_HOUR: vehicle.tmava_event_begin_hour,
      EVENT_TMAVA_END_HOUR: vehicle.tmava_event_end_hour,
      EVENT_TMAVA_TRIGGER_THRESHOLD: vehicle.tmava_event_send_threshold,
      // EVENT_TMAVA_NOTIF_CHANNEL: null, //! TODO
      EVENT_TMAVA_DAYS:
        typeof vehicle.tmava_event_days === 'object'
          ? JSON.stringify(vehicle.tmava_event_days).replace(/[\\"]+/g, '"')
          : vehicle.tmava_event_days,
      EVENT_TMAVA_LIST_ZONE_ENABLED: vehicle.tmava_event_zone,
      EVENT_TMAVA_LIST_ZONE: vehicle.tmava_event_zones
        ? vehicle.tmava_event_zones.join(';')
        : [],

      EVENT_TMAVAT_ENABLED: vehicle.tmavat_event,
      EVENT_TMAVAT_BEGIN_HOUR: vehicle.tmavat_event_begin_hour,
      EVENT_TMAVAT_END_HOUR: vehicle.tmavat_event_end_hour,
      EVENT_TMAVAT_TRIGGER_THRESHOLD: vehicle.tmavat_event_send_threshold,
      // EVENT_TMAVAT_NOTIF_CHANNEL: null, //! TODO
      EVENT_TMAVAT_DAYS:
        typeof vehicle.tmavat_event_days === 'object'
          ? JSON.stringify(vehicle.tmavat_event_days).replace(/[\\"]+/g, '"')
          : vehicle.tmavat_event_days,
      EVENT_TMAVAT_LIST_ZONE_ENABLED: vehicle.tmavat_event_zone, //! TODO
      EVENT_TMAVAT_LIST_ZONE: vehicle.tmavat_event_zones
        ? vehicle.tmavat_event_zones.join(';')
        : [],

      EVENT_SERVER_THEFT_ENABLED: vehicle.server_thief_event,
      EVENT_SERVER_THEFT_BEGIN_HOUR: vehicle.server_thief_event_begin_hour,
      EVENT_SERVER_THEFT_END_HOUR: vehicle.server_thief_event_end_hour,
      EVENT_SERVER_THEFT_TRIGGER_THRESHOLD:
        vehicle.server_thief_event_send_threshold,
      // EVENT_SERVER_THEFT_NOTIF_CHANNEL: null, //! TODO
      EVENT_SERVER_THEFT_DAYS:
        typeof vehicle.server_thief_event_days === 'object'
          ? JSON.stringify(vehicle.server_thief_event_days).replace(
              /[\\"]+/g,
              '"'
            )
          : vehicle.server_thief_event_days,
      EVENT_SERVER_THEFT_LIST_ZONE_ENABLED: vehicle.server_thief_event_zone,
      EVENT_SERVER_THEFT_LIST_ZONE: vehicle.server_thief_event_zones
        ? vehicle.server_thief_event_zones.join(';')
        : [],

      EVENT_EMBEDDED_THEFT_ENABLED: vehicle.embedded_thief_event,
      EVENT_EMBEDDED_THEFT_BEGIN_HOUR: vehicle.embedded_thief_event_begin_hour,
      EVENT_EMBEDDED_THEFT_END_HOUR: vehicle.embedded_thief_event_end_hour,
      EVENT_EMBEDDED_THEFT_TRIGGER_THRESHOLD:
        vehicle.embedded_thief_event_send_threshold,
      // EVENT_EMBEDDED_THEFT_NOTIF_CHANNEL: null, //! TODO
      EVENT_EMBEDDED_THEFT_DAYS:
        typeof vehicle.embedded_thief_event_days === 'object'
          ? JSON.stringify(vehicle.embedded_thief_event_days).replace(
              /[\\"]+/g,
              '"'
            )
          : vehicle.embedded_thief_event_days,
      EVENT_EMBEDDED_THEFT_LIST_ZONE_ENABLED: vehicle.embedded_thief_event_zone,
      EVENT_EMBEDDED_THEFT_LIST_ZONE: vehicle.embedded_thief_event_zones
        ? vehicle.embedded_thief_event_zones.join(';')
        : [],

      EVENT_LOW_BATTERY_ENABLED: vehicle.low_battery_event,
      EVENT_LOW_BATTERY_BEGIN_HOUR: vehicle.low_battery_event_begin_hour,
      EVENT_LOW_BATTERY_END_HOUR: vehicle.low_battery_event_end_hour,
      EVENT_LOW_BATTERY_TRIGGER_THRESHOLD: vehicle.low_battery_event_send_threshold
        ? vehicle.low_battery_event_send_threshold
        : 20,
      // EVENT_LOW_BATTERY_NOTIF_CHANNEL: null, //! TODO
      EVENT_LOW_BATTERY_DAYS:
        typeof vehicle.low_battery_event_days === 'object'
          ? JSON.stringify(vehicle.low_battery_event_days).replace(
              /[\\"]+/g,
              '"'
            )
          : vehicle.low_battery_event_days,
      EVENT_LOW_BATTERY_LIST_ZONE_ENABLED: vehicle.low_battery_event_zone,
      EVENT_LOW_BATTERY_LIST_ZONE: vehicle.low_battery_event_zones
        ? vehicle.low_battery_event_zones.join(';')
        : [],

      ALERT_TMAVA_ENABLED: vehicle.tmava_alert,
      ALERT_TMAVA_BEGIN_HOUR: vehicle.tmava_alert_begin_hour,
      ALERT_TMAVA_END_HOUR: vehicle.tmava_alert_end_hour,
      ALERT_TMAVA_TRIGGER_THRESHOLD: vehicle.tmava_alert_send_threshold,
      // ALERT_TMAVA_NOTIF_CHANNEL: null, //! TODO
      ALERT_TMAVA_DAYS:
        typeof vehicle.tmava_alert_days === 'object'
          ? JSON.stringify(vehicle.tmava_alert_days).replace(/[\\"]+/g, '"')
          : vehicle.tmava_alert_days,
      ALERT_TMAVA_LIST_ZONE_ENABLED: vehicle.tmava_alert_zone,
      ALERT_TMAVA_LIST_ZONE: vehicle.tmava_alert_zones
        ? vehicle.tmava_alert_zones.join(';')
        : [],

      ALERT_TMAVAT_ENABLED: vehicle.tmavat_alert,
      ALERT_TMAVAT_BEGIN_HOUR: vehicle.tmavat_alert_begin_hour,
      ALERT_TMAVAT_END_HOUR: vehicle.tmavat_alert_end_hour,
      ALERT_TMAVAT_TRIGGER_THRESHOLD: vehicle.tmavat_alert_send_threshold,
      // ALERT_TMAVAT_NOTIF_CHANNEL: null, //! TODO
      ALERT_TMAVAT_DAYS:
        typeof vehicle.tmavat_alert_days === 'object'
          ? JSON.stringify(vehicle.tmavat_alert_days).replace(/[\\"]+/g, '"')
          : vehicle.tmavat_alert_days,
      ALERT_TMAVAT_ENABLE_ZONE: vehicle.tmavat_alert_zone, //! TODO
      ALERT_TMAVAT_LIST_ZONE: vehicle.tmavat_alert_zones
        ? vehicle.tmavat_alert_zones.join(';')
        : [],

      ALERT_SERVER_THEFT_ENABLED: vehicle.server_thief_alert,
      ALERT_SERVER_THEFT_BEGIN_HOUR: vehicle.server_thief_alert_begin_hour,
      ALERT_SERVER_THEFT_END_HOUR: vehicle.server_thief_alert_end_hour,
      ALERT_SERVER_THEFT_TRIGGER_THRESHOLD:
        vehicle.server_thief_alert_send_threshold,
      // ALERT_SERVER_THEFT_NOTIF_CHANNEL: null, //! TODO
      ALERT_SERVER_THEFT_DAYS:
        typeof vehicle.server_thief_alert_days === 'object'
          ? JSON.stringify(vehicle.server_thief_alert_days).replace(
              /[\\"]+/g,
              '"'
            )
          : vehicle.server_thief_alert_days,
      ALERT_SERVER_THEFT_LIST_ZONE_ENABLED: vehicle.server_thief_alert_zone,
      ALERT_SERVER_THEFT_LIST_ZONE: vehicle.server_thief_alert_zones
        ? vehicle.server_thief_alert_zones.join(';')
        : [],

      ALERT_EMBEDDED_THEFT_ENABLED: vehicle.embedded_thief_alert,
      ALERT_EMBEDDED_THEFT_BEGIN_HOUR: vehicle.embedded_thief_alert_begin_hour,
      ALERT_EMBEDDED_THEFT_END_HOUR: vehicle.embedded_thief_alert_end_hour,
      ALERT_EMBEDDED_THEFT_TRIGGER_THRESHOLD:
        vehicle.embedded_thief_alert_send_threshold,
      // ALERT_EMBEDDED_THEFT_NOTIF_CHANNEL: null, //! TODO
      ALERT_EMBEDDED_THEFT_DAYS:
        typeof vehicle.embedded_thief_alert_days === 'object'
          ? JSON.stringify(vehicle.embedded_thief_alert_days).replace(
              /[\\"]+/g,
              '"'
            )
          : vehicle.embedded_thief_alert_days,
      ALERT_EMBEDDED_THEFT_LIST_ZONE_ENABLED: vehicle.embedded_thief_alert_zone,
      ALERT_EMBEDDED_THEFT_LIST_ZONE: vehicle.embedded_thief_alert_zones
        ? vehicle.embedded_thief_alert_zones.join(';')
        : [],

      ALERT_LOW_BATTERY_ENABLED: vehicle.low_battery_alert,
      ALERT_LOW_BATTERY_BEGIN_HOUR: vehicle.low_battery_alert_begin_hour,
      ALERT_LOW_BATTERY_END_HOUR: vehicle.low_battery_alert_end_hour,
      ALERT_LOW_BATTERY_TRIGGER_THRESHOLD:
        vehicle.low_battery_alert_send_threshold,
      // ALERT_LOW_BATTERY_NOTIF_CHANNEL: null, //! TODO
      ALERT_LOW_BATTERY_DAYS:
        typeof vehicle.low_battery_alert_days === 'object'
          ? JSON.stringify(vehicle.low_battery_alert_days).replace(
              /[\\"]+/g,
              '"'
            )
          : vehicle.low_battery_alert_days,
      ALERT_LOW_BATTERY_LIST_ZONE_ENABLED: vehicle.low_battery_alert_zone,
      ALERT_LOW_BATTERY_LIST_ZONE: vehicle.low_battery_event_zones
        ? vehicle.low_battery_event_zones.join(';')
        : [],

      EVENT_LOW_TANK_STOCK_ENABLED: vehicle.almost_low_tank_stock_event,
      EVENT_LOW_TANK_STOCK_BEGIN_HOUR:
        vehicle.almost_low_tank_stock_event_begin_hour,
      EVENT_LOW_TANK_STOCK_END_HOUR:
        vehicle.almost_low_tank_stock_event_end_hour,
      EVENT_LOW_TANK_STOCK_TRIGGER_THRESHOLD:
        vehicle.almost_low_tank_stock_event_send_threshold,
      // EVENT_LOW_TANK_STOCK_NOTIF_CHANNEL: null, //! TODO
      EVENT_LOW_TANK_STOCK_DAYS:
        typeof vehicle.almost_low_tank_stock_event_days === 'object'
          ? JSON.stringify(vehicle.almost_low_tank_stock_event_days).replace(
              /[\\"]+/g,
              '"'
            )
          : vehicle.almost_low_tank_stock_event_days,
      EVENT_LOW_TANK_STOCK_LIST_ZONE_ENABLED:
        vehicle.almost_low_tank_stock_event_zone,
      EVENT_LOW_TANK_STOCK_LIST_ZONE: vehicle.almost_low_tank_stock_event_zones
        ? vehicle.almost_low_tank_stock_event_zones.join(';')
        : [],

      EVENT_VERY_LOW_TANK_STOCK_ENABLED: vehicle.low_tank_stock_event,
      EVENT_VERY_LOW_TANK_STOCK_BEGIN_HOUR:
        vehicle.low_tank_stock_event_begin_hour,
      EVENT_VERY_LOW_TANK_STOCK_END_HOUR: vehicle.low_tank_stock_event_end_hour,
      EVENT_VERY_LOW_TANK_STOCK_TRIGGER_THRESHOLD: vehicle.low_tank_stock_event_send_threshold
        ? vehicle.low_tank_stock_event_send_threshold
        : 6,
      // EVENT_VERY_LOW_TANK_STOCK_NOTIF_CHANNEL: null, //! TODO
      EVENT_VERY_LOW_TANK_STOCK_DAYS:
        typeof vehicle.low_tank_stock_event_days === 'object'
          ? JSON.stringify(vehicle.low_tank_stock_event_days).replace(
              /[\\"]+/g,
              '"'
            )
          : vehicle.low_tank_stock_event_days,
      EVENT_VERY_LOW_TANK_STOCK_LIST_ZONE_ENABLED:
        vehicle.low_tank_stock_event_zone,
      EVENT_VERY_LOW_TANK_STOCK_LIST_ZONE: vehicle.low_tank_stock_notifications_zones
        ? vehicle.low_tank_stock_notifications_zones.join(';')
        : [],

      TMAVA_COEFF: vehicle.tmava_coef,
      TMAVAT_COEFF: vehicle.tmavat_coef,
      VEQ_TMAVA_DAYS_PER_YEAR: vehicle.tmava_veq,
      TEQ_COMBUSTION: vehicle.combustion_teq,
      FULL_TANK_THRESHOLD: vehicle.full_tank_threshold,
      REFERENCE_THRESHOLD_CONSUMPTION: vehicle.reference_threshold_consumption,
      OVERSPEED: vehicle.overspeed,
      MINIMUM_DISTANCE_PER_MONTH: vehicle.minimum_distance,
    },
  };

  console.log(obj);

  return axios({
    method: 'post',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    data: obj,
    url: `${API_URL}vehicles`,
  });
};

const updateVehicle = async (token, vehicle) => {
  console.log('====================================');
  console.log(vehicle);
  console.log('====================================');

  let obj = {
    customerId: vehicle.customer,
    vin: vehicle.vin,
    plate: vehicle.plate,
    vehicleModelId: vehicle.vehicle_model_id,
    status: vehicle.vehicle_status,
    category: vehicle.category,
    type: vehicle.vehicle_type,
    energy: 'FUEL',
    comment: vehicle.comment ? vehicle.comment : '',
    manufactureYear: vehicle.manufacture_year
      ? vehicle.manufacture_year.year
        ? vehicle.manufacture_year.year()
        : vehicle.manufacture_year
      : null,
    geographicVehicleGroupId: vehicle.geographic_group,
    optionalVehicleGroupIds: vehicle.optional_groups,
    parcName: vehicle.park_name,
    activePackage: vehicle.package_is_active,
    tankCalibrationIds: vehicle.tankIds,
    indexKm: vehicle.km_index,
    dateIndexKm: vehicle.km_index_date
      ? vehicle.km_index_date.year
        ? vehicle.km_index_date.valueOf()
        : vehicle.km_index_date
      : null,
    calibration: vehicle.calibration,
    calibrationDate: vehicle.calibration_date
      ? vehicle.calibration_date.year
        ? vehicle.calibration_date.valueOf()
        : vehicle.calibration_date
      : null,

    settings: {
      TACHY_ENTERED_IN_MATRIX: vehicle.tachy_entered_matrix,
      TACHY_CONNECTED_DURING_INSTALLATION: vehicle.tachy_entered_during_install,
      //DETECTED_TACHY: vehicle.tachy_detected,
      ENGINE_DETECTION: vehicle.engine_detection,
      PTO_ENTERED_IN_MATRIX: vehicle.pto_entered_matrix,
      PTO_CONNECTED_DURING_INSTALLATION: vehicle.pto_connected_during_install,
      //DETECTED_PTO: vehicle.auto_pto_detected,
      BUZZER_ENTERED_IN_MATRIX: vehicle.buzzer_in_matrix,
      BUZZER_CONNECTED_DURING_INSTALLATION:
        vehicle.buzzer_connected_during_install,
      TANK_REMOVAL: vehicle.tank_removal,
      FMS_ENTERED_IN_MATRIX: vehicle.fms_in_matrix,
      FMS_CONNECTED_DURING_INSTALLATION: vehicle.fms_connected_during_install,
      //FMS_DETECTED: vehicle.fms_detected_automatically,
      PLUS_PERMANENT_CONNECTED: vehicle.permanent_plus,
      ADR: vehicle.adr,
      RDL: vehicle.rdl_equipment,
      RDL_PROVIDER: vehicle.rdl_provider,
      // IMEI: vehicle.imei ? vehicle.imei.number : null,

      EVENT_TMAVA_ENABLED: vehicle.tmava_event,
      EVENT_TMAVA_BEGIN_HOUR: vehicle.tmava_event_begin_hour,
      EVENT_TMAVA_END_HOUR: vehicle.tmava_event_end_hour,
      EVENT_TMAVA_TRIGGER_THRESHOLD: vehicle.tmava_event_send_threshold,
      // EVENT_TMAVA_NOTIF_CHANNEL: null, //! TODO
      EVENT_TMAVA_DAYS:
        typeof vehicle.tmava_event_days === 'object'
          ? JSON.stringify(vehicle.tmava_event_days).replace(/[\\"]+/g, '"')
          : vehicle.tmava_event_days,
      EVENT_TMAVA_LIST_ZONE_ENABLED: vehicle.tmava_event_zone,
      // EVENT_TMAVA_LIST_ZONE: vehicle.tmava_event_zones.join(
      //   ';'
      // ), //! TODO

      EVENT_TMAVAT_ENABLED: vehicle.tmavat_event,
      EVENT_TMAVAT_BEGIN_HOUR: vehicle.tmavat_event_begin_hour,
      EVENT_TMAVAT_END_HOUR: vehicle.tmavat_event_end_hour,
      EVENT_TMAVAT_TRIGGER_THRESHOLD: vehicle.tmavat_event_send_threshold,
      // EVENT_TMAVAT_NOTIF_CHANNEL: null, //! TODO
      EVENT_TMAVAT_DAYS:
        typeof vehicle.tmavat_event_days === 'object'
          ? JSON.stringify(vehicle.tmavat_event_days).replace(/[\\"]+/g, '"')
          : vehicle.tmavat_event_days,
      EVENT_TMAVAT_LIST_ZONE_ENABLED: vehicle.tmavat_event_zone,
      // EVENT_TMAVAT_LIST_ZONE: vehicle.tmavat_event_zones.join(
      //   ';'
      // ), //! TODO

      EVENT_SERVER_THEFT_ENABLED: vehicle.server_thief_event,
      EVENT_SERVER_THEFT_BEGIN_HOUR: vehicle.server_thief_event_begin_hour,
      EVENT_SERVER_THEFT_END_HOUR: vehicle.server_thief_event_end_hour,
      EVENT_SERVER_THEFT_TRIGGER_THRESHOLD:
        vehicle.server_thief_event_send_threshold,
      // EVENT_SERVER_THEFT_NOTIF_CHANNEL: null, //! TODO
      EVENT_SERVER_THEFT_DAYS:
        typeof vehicle.server_thief_event_days === 'object'
          ? JSON.stringify(vehicle.server_thief_event_days).replace(
              /[\\"]+/g,
              '"'
            )
          : vehicle.server_thief_event_days,
      EVENT_SERVER_THEFT_LIST_ZONE_ENABLED: vehicle.server_thief_event_zone,
      // EVENT_SERVER_THEFT_LIST_ZONE: vehicle.server_thief_event_zones.join(
      //   ';'
      // ), //! TODO

      EVENT_EMBEDDED_THEFT_ENABLED: vehicle.embedded_thief_event,
      EVENT_EMBEDDED_THEFT_BEGIN_HOUR: vehicle.embedded_thief_event_begin_hour,
      EVENT_EMBEDDED_THEFT_END_HOUR: vehicle.embedded_thief_event_end_hour,
      EVENT_EMBEDDED_THEFT_TRIGGER_THRESHOLD:
        vehicle.embedded_thief_event_send_threshold,
      // EVENT_EMBEDDED_THEFT_NOTIF_CHANNEL: null, //! TODO
      EVENT_EMBEDDED_THEFT_DAYS:
        typeof vehicle.embedded_thief_event_days === 'object'
          ? JSON.stringify(vehicle.embedded_thief_event_days).replace(
              /[\\"]+/g,
              '"'
            )
          : vehicle.embedded_thief_event_days,
      EVENT_EMBEDDED_THEFT_LIST_ZONE_ENABLED: vehicle.embedded_thief_event_zone,
      // EVENT_EMBEDDED_THEFT_LIST_ZONE: vehicle.embedded_thief_event_zones.join(
      //   ';'
      // ), //! TODO

      EVENT_LOW_BATTERY_ENABLED: vehicle.low_battery_event,
      EVENT_LOW_BATTERY_BEGIN_HOUR: vehicle.low_battery_event_begin_hour,
      EVENT_LOW_BATTERY_END_HOUR: vehicle.low_battery_event_end_hour,
      EVENT_LOW_BATTERY_TRIGGER_THRESHOLD:
        vehicle.low_battery_event_send_threshold,
      // EVENT_LOW_BATTERY_NOTIF_CHANNEL: null, //! TODO
      EVENT_LOW_BATTERY_DAYS:
        typeof vehicle.low_battery_event_days === 'object'
          ? JSON.stringify(vehicle.low_battery_event_days).replace(
              /[\\"]+/g,
              '"'
            )
          : vehicle.low_battery_event_days,
      EVENT_LOW_BATTERY_LIST_ZONE_ENABLED: vehicle.low_battery_event_zone,
      // EVENT_LOW_BATTERY_LIST_ZONE: vehicle.low_battery_event_zones.join(
      //   ';'
      // ), //! TODO

      EVENT_LOW_TANK_STOCK_ENABLED: vehicle.low_tank_stock_event,
      EVENT_LOW_TANK_STOCK_BEGIN_HOUR: vehicle.low_tank_stock_event_begin_hour,
      EVENT_LOW_TANK_STOCK_END_HOUR: vehicle.low_tank_stock_event_end_hour,
      EVENT_LOW_TANK_STOCK_TRIGGER_THRESHOLD:
        vehicle.low_tank_stock_event_send_threshold,
      // EVENT_LOW_TANK_STOCK_NOTIF_CHANNEL: null, //! TODO
      EVENT_LOW_TANK_STOCK_DAYS:
        typeof vehicle.low_tank_stock_event_days === 'object'
          ? JSON.stringify(vehicle.low_tank_stock_event_days).replace(
              /[\\"]+/g,
              '"'
            )
          : vehicle.low_tank_stock_event_days,
      EVENT_LOW_TANK_STOCK_LIST_ZONE_ENABLED: vehicle.low_tank_stock_event_zone,
      // EVENT_LOW_TANK_STOCK_LIST_ZONE: vehicle.low_tank_stock_event_zones.join(
      //   ';'
      // ), //! TODO

      EVENT_VERY_LOW_TANK_STOCK_ENABLED: vehicle.very_low_tank_stock_event,
      EVENT_VERY_LOW_TANK_STOCK_BEGIN_HOUR:
        vehicle.very_low_tank_stock_event_begin_hour,
      EVENT_VERY_LOW_TANK_STOCK_END_HOUR:
        vehicle.very_low_tank_stock_event_end_hour,
      EVENT_VERY_LOW_TANK_STOCK_TRIGGER_THRESHOLD:
        vehicle.very_low_tank_stock_event_send_threshold,
      // EVENT_VERY_LOW_TANK_STOCK_NOTIF_CHANNEL: null, //! TODO
      EVENT_VERY_LOW_TANK_STOCK_DAYS:
        typeof vehicle.very_low_tank_stock_event_days === 'object'
          ? JSON.stringify(vehicle.very_low_tank_stock_event_days).replace(
              /[\\"]+/g,
              '"'
            )
          : vehicle.very_low_tank_stock_event_days,
      EVENT_VERY_LOW_TANK_STOCK_LIST_ZONE_ENABLED:
        vehicle.very_low_tank_stock_event_zone,
      // EVENT_VERY_LOW_TANK_STOCK_LIST_ZONE: vehicle.very_low_tank_stock_notifications_zones.join(
      //   ';'
      // ), //! TODO
      ALERT_ZONE_IN_LIST_ZONE_ENABLED:
        vehicle.zones_alerts.zone_entry.length > 0,
      ALERT_ZONE_IN_LIST_ZONE:
        vehicle.zones_alerts.zone_entry.length > 0
          ? vehicle.zones_alerts.zone_entry.join(';')
          : '',
      ALERT_ZONE_OUT_LIST_ZONE_ENABLED:
        vehicle.zones_alerts.zone_exit.length > 0,
      ALERT_ZONE_OUT_LIST_ZONE:
        vehicle.zones_alerts.zone_exit.length > 0
          ? vehicle.zones_alerts.zone_exit.join(';')
          : '',
      ALERT_ZONE_ATTENDANCE_LIST_ZONE_ENABLED:
        vehicle.zones_alerts.zone_presence_time.length > 0,
      ALERT_ZONE_ATTENDANCE_LIST_ZONE:
        vehicle.zones_alerts.zone_presence_time.length > 0
          ? vehicle.zones_alerts.zone_presence_time.join(';')
          : '',
      ALERT_ZONE_SPEED_ENABLED: vehicle.zones_alerts.zone_speed.length > 0,
      ALERT_ZONE_SPEED_LIST_ZONE:
        vehicle.zones_alerts.zone_speed.length > 0
          ? vehicle.zones_alerts.zone_speed.join(';')
          : '',
      TMAVA_COEFF: vehicle.tmava_coef,
      TMAVAT_COEFF: vehicle.tmavat_coef,
      VEQ_TMAVA_DAYS_PER_YEAR: vehicle.tmava_veq,
      TEQ_COMBUSTION: vehicle.combustion_teq,
      FULL_TANK_THRESHOLD: vehicle.full_tank_threshold,
      REFERENCE_THRESHOLD_CONSUMPTION: vehicle.reference_threshold_consumption,
      OVERSPEED: vehicle.overspeed,
      MINIMUM_DISTANCE_PER_MONTH: vehicle.minimum_distance,
    },
  };
  console.log(obj);

  return axios({
    method: 'put',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    data: obj,
    url: `${API_URL}vehicles/${vehicle.id}`,
  });
};

const deleteVehicle = async (token, vehicleId) => {
  let url = `${API_URL}vehicles/${vehicleId}`;
  console.log('======================');
  console.log(vehicleId);
  console.log('======================');
  return axios({
    method: 'delete',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: url,
  });
};

const updateVehicleFormMap = async (token, vehicle) => {
  console.log('======================');
  console.log(vehicle);
  console.log('======================');
  return axios({
    method: 'put',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    data: {
      vehicleModelId: vehicle.vehicle_model_id,
      category: vehicle.category,
      type: vehicle.vehicle_type,
      geographicVehicleGroupId: vehicle.geographic_group,
      optionalVehicleGroupIds: vehicle.optional_groups,
      parcName: vehicle.park_name,
      activePackage: vehicle.package_is_active,
      manufactureYear:
        vehicle.manufacture_year && vehicle.manufacture_year.year
          ? vehicle.manufacture_year.year()
          : null,
    },
    url: `${API_URL}vehicles/${vehicle.id}`,
  });
};

const getVehicleBrand = async (token) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}vehicle_brands`,
  });
};

const getVehicleModel = async (token, brand) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}vehicle_models?vehicleBrandId=` + brand,
  });
};

const getVehicleType = async (token) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}global/vehicle_types`,
  });
};

const getTachyType = async (token) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}global/tachy_types`,
  });
};

const getRDLType = async (token) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}global/vehicle_rdl`,
  });
};

const getEngineDetectionType = async (token) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}global/vehicle_setting_engine_detection`,
  });
};

const getVehicleCategory = async (token) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}global/vehicle_categories`,
  });
};

const getEnumCalibration = async (token) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}global/calibration_modes`,
  });
};

const getVehicleStatus = async (token) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}global/vehicle_status`,
  });
};

const getAllVehicleSites = async (
  token,
  { customerId, offset, limit, pattern }
) => {
  let url = `${API_URL}sites?`;
  if (customerId) url += 'customerId=' + customerId + '&';
  if (offset) url += 'offset=' + offset + '&';
  if (limit) url += 'limit=' + limit + '&';
  if (pattern) url += 'pattern=' + pattern;
  console.log('url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: url,
  });
};

const getVehicleGroups = async (
  token,
  { customerId, vehicleGroupType, offset, limit, pattern }
) => {
  let url = `${API_URL}vehicle_groups?`;
  if (customerId) url += 'customerId=' + customerId + '&';
  if (vehicleGroupType) url += 'vehicleGroupType=' + vehicleGroupType + '&';
  if (offset) url += 'offset=' + offset + '&';
  if (limit) url += 'limit=' + limit + '&';
  if (pattern) url += 'pattern=' + pattern;
  console.log('url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: url,
  });
};

const getVehicleGroupTypes = async (token, {}) => {
  let url = `${API_URL}global/vehicle_group_types?`;
  console.log('url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: url,
  });
};

const getVehicleFuelReport = async (
  token,
  { vehicleId, startDate, endDate }
) => {
  let url = `${API_URL}vehicles/${vehicleId}/report/detailed?&period=${encodeURIComponent(
    JSON.stringify({
      start: startDate,
      end: endDate,
    })
  )}`;
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: url,
  });
};

const getVehicleFuelReportByDriver = async (
  token,
  { driverId, startDate, endDate }
) => {
  let url = `${API_URL}drivers/${driverId}/report/detailed?&period=${encodeURIComponent(
    JSON.stringify({
      start: startDate,
      end: endDate,
    })
  )}`;
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: url,
  });
};

const getVehicleTmavaTopReport = async (
  token,
  { vehicleId, startDate, endDate }
) => {
  let url = `${API_URL}vehicles/${vehicleId}/report/top_tmava?&period=${encodeURIComponent(
    JSON.stringify({
      start: startDate,
      end: endDate,
    })
  )}`;

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: url,
  });
};

const getVehicleFuelConsumptionReport = async (
  token,
  { vehicleId, startDate, endDate, periods }
) => {
  let url = `${API_URL}vehicles/${vehicleId}/report/fuel_consumption?`;
  if (startDate && endDate) {
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate,
      })
    )}`;
  } else if (periods) {
    url += `period=${encodeURIComponent(JSON.stringify(periods))}`;
  }

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: url,
  });
};

const getVehicleTotalFuelReport = async (
  token,
  { vehicleId, startDate, endDate }
) => {
  let url = `${API_URL}vehicles/${vehicleId}/report/total_fuel?&period=${encodeURIComponent(
    JSON.stringify({
      start: startDate,
      end: endDate,
    })
  )}`;

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: url,
  });
};

const getVehicleTotalDistanceReport = async (
  token,
  { vehicleId, startDate, endDate, periods }
) => {
  let url = `${API_URL}vehicles/${vehicleId}/report/total_distance?`;
  if (startDate && endDate) {
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate,
      })
    )}`;
  } else if (periods) {
    url += `period=${encodeURIComponent(JSON.stringify(periods))}`;
  }

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: url,
  });
};

const getVehicleTotalActivityDaysReport = async (
  token,
  { vehicleId, startDate, endDate }
) => {
  let url = `${API_URL}vehicles/${vehicleId}/report/total_activity_days?&period=${encodeURIComponent(
    JSON.stringify({
      start: startDate,
      end: endDate,
    })
  )}`;

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: url,
  });
};

const getVehicleActivityStatsReport = async (
  token,
  { vehicleId, startDate, endDate, periods }
) => {
  let url = `${API_URL}vehicles/${vehicleId}/report/vehicle_stats?`;
  if (startDate && endDate) {
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate,
      })
    )}`;
  } else if (periods) {
    url += `period=${encodeURIComponent(JSON.stringify(periods))}`;
  }

  //console.log('url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: url,
  });
};

const getPtoTypes = async (token) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}global/pto_type_name`,
  });
};

const getenergyEnum = async (token) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}global/vehicle_energy_types`,
  });
};

const getDriverActivitiesByVehicle = async (
  token,
  { vehicleId, startDate, endDate }
) => {
  let url = `${API_URL}vehicles/${vehicleId}/activity/driver_activity_by_vehicle?`;
  if (startDate && endDate)
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate,
      })
    )}`;
  console.log('url', url);
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: url,
  });
};

const checkVehicleDataImport = async (token, { customerId, vehicle }) => {
  let url = `${API_URL}vehicles/import_check?customerId=${customerId}`;
  console.log('url', url);

  return axios({
    method: 'put',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    data: {
      customer: vehicle.customer,
      plate: vehicle.plate,
      vin: vehicle.vin,
      geographicVehicleGroup: vehicle.geographicVehicleGroup,
      parcName: vehicle.parcName,
      status: vehicle.status,
      type: vehicle.type,
      category: vehicle.category,
      tachyType: vehicle.tachyType,
      vehicleModel: vehicle.vehicleModel,
      vehicleBrand: vehicle.vehicleBrand,
      manufactureYear: vehicle.manufactureYear,
      energy: vehicle.energy,
      settings: vehicle.settings,
      indexKm: vehicle.indexKm,
      indexKmDate: vehicle.indexKmDate,
      calibration: vehicle.calibration,
      calibrationDate: vehicle.calibrationDate,
      comment: vehicle.comment,
    },
    url: url,
  });
};

function* workerGetAllVehicles(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getAllVehicles, token, action.payload);
    const vehicles = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_ALL_VEHICLES_SUCCESS, vehicles });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_ALL_VEHICLES_FAILURE, error });
  }
}

function* workerGetVehiclesFilter(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getAllVehicles, token, action.payload);
    const vehicles = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_VEHICLES_FILTER_SUCCESS, vehicles });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLES_FILTER_FAILURE, error });
  }
}

function* workerGetVehiclesHeader(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getAllVehicles, token, action.payload);
    const vehicles = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_VEHICLES_HEADER_SUCCESS, vehicles });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLES_HEADER_FAILURE, error });
  }
}

function* workerGetSingleVehicle(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getSingleVehicle, token, action.payload);
    const vehicle = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_SINGLE_VEHICLE_SUCCESS, vehicle });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_SINGLE_VEHICLE_FAILURE, error });
  }
}

function* workerGetPreselectedVehiclesBarFilter(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getAllVehicles, token, action.payload);
    const preselectedVehicles = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({
      type: GET_PRESELECTED_VEHICLES_BARFILTER_SUCCESS,
      preselectedVehicles,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_PRESELECTED_VEHICLES_BARFILTER_FAILURE, error });
  }
}

function* workerAddVehicle(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(addVehicle, token, action.payload);
    const vehicle = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: ADD_VEHICLE_SUCCESS, vehicle });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Vehicle created !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center',
      })
    );
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ADD_VEHICLE_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center',
      })
    );
  }
}

function* workerUpdateVehicle(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(updateVehicle, token, action.payload);
    const vehicle = response.data;
    console.log(response);

    // dispatch a success action to the store with the new vehicle
    yield put({ type: UPDATE_VEHICLE_SUCCESS, vehicle });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Vehicle updated !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center',
      })
    );
  } catch (error) {
    console.log(error);
    // dispatch a failure action to the store with the error
    yield put({ type: UPDATE_VEHICLE_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center',
      })
    );
  }
}

function* workerDeleteVehicle(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(deleteVehicle, token, action.payload);
    const deletedVehicle = action.payload;

    yield put({ type: DELETE_VEHICLE_SUCCESS, deletedVehicle });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Vehicle deleted !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center',
      })
    );
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: DELETE_VEHICLE_FAILURE, error });
    //yield put(showMessage(error.message, 'danger'));
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center',
      })
    );
  }
}

function* workerUpdateVehicleFormMap(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(updateVehicleFormMap, token, action.payload);
    const vehicle = response.data;
    console.log(response);

    // dispatch a success action to the store with the new vehicle
    yield put({ type: UPDATE_VEHICLE_FORM_MAP_SUCCESS, vehicle });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Vehicle updated !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center',
      })
    );
  } catch (error) {
    console.log(error);
    // dispatch a failure action to the store with the error
    yield put({ type: UPDATE_VEHICLE_FORM_MAP_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center',
      })
    );
  }
}

function* workerGetVehicleBrand() {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getVehicleBrand, token);
    const vehicleBrand = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_VEHICLE_BRAND_SUCCESS, vehicleBrand });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLE_BRAND_FAILURE, error });
  }
}

function* workerGetVehicleModel(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getVehicleModel, token, action.payload);
    const vehicleModel = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_VEHICLE_MODEL_SUCCESS, vehicleModel });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLE_MODEL_FAILURE, error });
  }
}

function* workerGetVehicleType() {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getVehicleType, token);
    const vehicleType = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_VEHICLE_TYPE_SUCCESS, vehicleType });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLE_TYPE_FAILURE, error });
  }
}

function* workerGetTachyType() {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getTachyType, token);
    const tachyType = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_TACHY_TYPE_SUCCESS, tachyType });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TACHY_TYPE_FAILURE, error });
  }
}

function* workerGetRDLType() {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getRDLType, token);
    const RDLType = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_RDL_TYPE_SUCCESS, RDLType });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_RDL_TYPE_FAILURE, error });
  }
}

function* workerGetEngineDetectionType() {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getEngineDetectionType, token);
    const engineDetectionType = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_ENGINE_DETECTION_TYPE_SUCCESS, engineDetectionType });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_ENGINE_DETECTION_TYPE_FAILURE, error });
  }
}

function* workerGetVehicleCategory() {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getVehicleCategory, token);
    const vehicleCategory = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_VEHICLE_CATEGORY_SUCCESS, vehicleCategory });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLE_CATEGORY_FAILURE, error });
  }
}

function* workerGetAllVehicleSitesFilter(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getAllVehicleSites, token, action.payload);
    const sitesFilter = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_ALL_VEHICLE_SITES_FILTER_SUCCESS, sitesFilter });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_ALL_VEHICLE_SITES_FILTER_FAILURE, error });
  }
}

function* workerGetVehicleGroupsFilter(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getVehicleGroups, token, action.payload);
    const groupsFilter = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_VEHICLE_GROUPS_FILTER_SUCCESS, groupsFilter });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLE_GROUPS_FILTER_FAILURE, error });
  }
}

function* workerGetVehicleGroupTypes(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getVehicleGroupTypes, token, action.payload);
    const groupTypes = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_VEHICLE_GROUP_TYPES_SUCCESS, groupTypes });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLE_GROUP_TYPES_FAILURE, error });
  }
}

function* workerGetEnumCalibration(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getEnumCalibration, token, action.payload);
    const calibrationList = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_ENUM_CALIB_SUCCESS, calibrationList });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_ENUM_CALIB_FAILURE, error });
  }
}

function* workerGetVehicleStatus(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getVehicleStatus, token, action.payload);
    const statusList = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_VEHICLE_STATUS_SUCCESS, statusList });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLE_STATUS_FAILURE, error });
  }
}

function* workerGetVehicleFuelReport(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getVehicleFuelReport, token, action.payload);
    const fuelReport = response.data;
    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_VEHICLE_FUEL_REPORT_SUCCESS, fuelReport });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLE_FUEL_REPORT_FAILURE, error });
  }
}

function* workerGetVehicleFuelReportByDriver(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getVehicleFuelReportByDriver,
      token,
      action.payload
    );
    const fuelReport = response.data;
    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_VEHICLE_FUEL_REPORT_BY_DRIVER_SUCCESS, fuelReport });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLE_FUEL_REPORT_BY_DRIVER_FAILURE, error });
  }
}

function* workerGetVehicleTmavaTopReport(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getVehicleTmavaTopReport,
      token,
      action.payload
    );
    const tmavaTopReport = response.data;
    // dispatch a success action to the store
    yield put({ type: GET_VEHICLE_TMAVA_TOP_REPORT_SUCCESS, tmavaTopReport });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLE_TMAVA_TOP_REPORT_FAILURE, error });
  }
}

function* workerGetVehicleFuelConsumptionReport(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getVehicleFuelConsumptionReport,
      token,
      action.payload
    );
    const fuelConsumptionReport = response.data;
    // dispatch a success action to the store
    yield put({
      type: GET_VEHICLE_FUEL_CONSUMPTION_REPORT_SUCCESS,
      fuelConsumptionReport,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLE_FUEL_CONSUMPTION_REPORT_FAILURE, error });
  }
}

function* workerGetVehicleFuelConsumptionReportMultiple(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getVehicleFuelConsumptionReport,
      token,
      action.payload
    );
    const fuelConsumptionReportMultiple = response.data;
    // dispatch a success action to the store
    yield put({
      type: GET_VEHICLE_FUEL_CONSUMPTION_REPORT_MULTIPLE_SUCCESS,
      fuelConsumptionReportMultiple,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_VEHICLE_FUEL_CONSUMPTION_REPORT_MULTIPLE_FAILURE,
      error,
    });
  }
}

function* workerGetVehicleTotalFuelReport(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getVehicleTotalFuelReport,
      token,
      action.payload
    );
    const totalFuelReport = response.data;
    // dispatch a success action to the store
    yield put({ type: GET_VEHICLE_TOTAL_FUEL_REPORT_SUCCESS, totalFuelReport });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLE_TOTAL_FUEL_REPORT_FAILURE, error });
  }
}

function* workerGetVehicleTotalDistanceReport(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getVehicleTotalDistanceReport,
      token,
      action.payload
    );
    const totalDistanceReport = response.data;
    // dispatch a success action to the store
    yield put({
      type: GET_VEHICLE_TOTAL_DISTANCE_REPORT_SUCCESS,
      totalDistanceReport,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLE_TOTAL_DISTANCE_REPORT_FAILURE, error });
  }
}

function* workerGetVehicleTotalDistanceReportMultiple(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getVehicleTotalDistanceReport,
      token,
      action.payload
    );
    const totalDistanceReportMultiple = response.data;
    // dispatch a success action to the store
    yield put({
      type: GET_VEHICLE_TOTAL_DISTANCE_REPORT_MULTIPLE_SUCCESS,
      totalDistanceReportMultiple,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_VEHICLE_TOTAL_DISTANCE_REPORT_MULTIPLE_FAILURE,
      error,
    });
  }
}

function* workerGetVehicleTotalActivityDaysReport(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getVehicleTotalActivityDaysReport,
      token,
      action.payload
    );
    const totalActivityDaysReport = response.data;
    // dispatch a success action to the store
    yield put({
      type: GET_VEHICLE_TOTAL_ACTIVITY_DAYS_REPORT_SUCCESS,
      totalActivityDaysReport,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLE_TOTAL_ACTIVITY_DAYS_REPORT_FAILURE, error });
  }
}

function* workerGetVehicleActivityStatsReport(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getVehicleActivityStatsReport,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_VEHICLE_ACTIVITY_STATS_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_VEHICLE_ACTIVITY_STATS_REPORT_FAILURE, error });
  }
}

function* workerGetVehicleActivityStatsReportMultiple(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getVehicleActivityStatsReport,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: GET_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_SUCCESS,
      report,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_FAILURE,
      error,
    });
  }
}

function* workerGetPtoTypes() {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getPtoTypes, token);
    const ptoTypeList = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_PTO_TYPE_ENUM_SUCCESS, ptoTypeList });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_PTO_TYPE_ENUM_FAILURE, error });
  }
}

function* workerGetEnergyEnum() {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getenergyEnum, token);
    const energyList = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: GET_ENERGY_ENUM_SUCCESS, energyList });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_ENERGY_ENUM_FAILURE, error });
  }
}

function* workerGetDriverActivitiesByVehicle(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getDriverActivitiesByVehicle,
      token,
      action.payload
    );
    const driverActivities = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({
      type: GET_DRIVER_ACTIVITIES_BY_VEHICLE_SUCCESS,
      driverActivities,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DRIVER_ACTIVITIES_BY_VEHICLE_FAILURE, error });
  }
}

function* workerCheckVehicleDataImport(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(checkVehicleDataImport, token, action.payload);
    const checkVehicleData = response.data;

    // dispatch a success action
    yield put({
      type: CHECK_VEHICLE_DATA_IMPORT_SUCCESS,
      checkVehicleData,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: CHECK_VEHICLE_DATA_IMPORT_FAILURE, error });
  }
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetAllDataToAddVehicle),
    fork(watcherGetAllDataToUpdateVehicle),
    fork(watcherGetAllVehicles),
    fork(watcherGetVehiclesFilter),
    fork(watcherGetVehiclesHeader),
    fork(watcherGetSingleVehicle),
    fork(watcherGetPreselectedVehiclesBarFilter),
    fork(watcherAddVehicle),
    fork(watcherUpdateVehicle),
    fork(watcherDeleteVehicle),
    fork(watcherUpdateVehicleFormMap),
    fork(watcherGetVehicleBrand),
    fork(watcherGetVehicleModel),
    fork(watcherGetVehicleType),
    fork(watcherGetTachyType),
    fork(watcherGetRDLType),
    fork(watcherGetEnumCalibration),
    fork(watcherGetVehicleStatus),
    fork(watcherGetVehicleCategory),
    fork(watcherGetEngineDetectionType),
    fork(watcherGetAllVehicleSitesFilter),
    fork(watcherGetVehicleGroupsFilter),
    fork(watcherGetVehicleGroupsFilter),
    fork(watcherGetVehicleGroupTypes),
    fork(watcherGetVehicleFuelReport),
    fork(watcherGetVehicleTmavaTopReport),
    fork(watcherGetVehicleFuelConsumptionReport),
    fork(watcherGetVehicleFuelConsumptionReportMultiple),
    fork(watcherGetVehicleTotalFuelReport),
    fork(watcherGetVehicleTotalDistanceReport),
    fork(watcherGetVehicleTotalDistanceReportMultiple),
    fork(watcherGetVehicleTotalActivityDaysReport),
    fork(watcherGetVehicleActivityStatsReport),
    fork(watcherGetVehicleActivityStatsReportMultiple),
    fork(watcherGetPtoTypes),
    fork(watcherGetEnergyEnum),
    fork(watcherGetDriverActivitiesByVehicle),
    fork(watcherGetVehicleFuelReportByDriver),
    fork(watcherCheckVehicleDataImport),
  ]);
}
