import {
  GET_SIM_LIST_REQUEST,
  GET_SIM_LIST_SUCCESS,
  GET_SIM_LIST_FAILURE,
  GET_SINGLE_SIM_REQUEST,
  GET_SINGLE_SIM_SUCCESS,
  GET_SINGLE_SIM_FAILURE,
  ADD_SIM_REQUEST,
  ADD_SIM_SUCCESS,
  ADD_SIM_FAILURE,
  UPDATE_SIM_REQUEST,
  UPDATE_SIM_SUCCESS,
  UPDATE_SIM_FAILURE,
  CHECK_SIM_DATA_IMPORT_REQUEST,
  CHECK_SIM_DATA_IMPORT_SUCCESS,
  CHECK_SIM_DATA_IMPORT_FAILURE,
  GET_MOBILE_OPERATORS_REQUEST,
  GET_MOBILE_OPERATORS_SUCCESS,
  GET_MOBILE_OPERATORS_FAILURE,
  GET_PACKAGES_LIST_REQUEST,
  GET_PACKAGES_LIST_SUCCESS,
  GET_PACKAGES_LIST_FAILURE,
  GET_PACKAGE_HISTORIC_REQUEST,
  GET_PACKAGE_HISTORIC_SUCCESS,
  GET_PACKAGE_HISTORIC_FAILURE,
  GET_SINGLE_PACKAGE_REQUEST,
  GET_SINGLE_PACKAGE_SUCCESS,
  GET_SINGLE_PACKAGE_FAILURE,
  ADD_PACKAGE_REQUEST,
  ADD_PACKAGE_SUCCESS,
  ADD_PACKAGE_FAILURE,
  UPDATE_PACKAGE_REQUEST,
  UPDATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_FAILURE,
  GET_MODEM_LIST_REQUEST,
  GET_MODEM_LIST_SUCCESS,
  GET_MODEM_LIST_FAILURE,
  GET_SINGLE_MODEM_REQUEST,
  GET_SINGLE_MODEM_SUCCESS,
  GET_SINGLE_MODEM_FAILURE,
  ADD_MODEM_REQUEST,
  ADD_MODEM_SUCCESS,
  ADD_MODEM_FAILURE,
  UPDATE_MODEM_REQUEST,
  UPDATE_MODEM_SUCCESS,
  UPDATE_MODEM_FAILURE,
  CHECK_MODEM_DATA_IMPORT_REQUEST,
  CHECK_MODEM_DATA_IMPORT_SUCCESS,
  CHECK_MODEM_DATA_IMPORT_FAILURE,
  GET_NO_ASSOCIATED_VEHICLE_REQUEST,
  GET_NO_ASSOCIATED_VEHICLE_SUCCESS,
  GET_NO_ASSOCIATED_VEHICLE_FAILURE,
  GET_NO_ASSOCIATED_DEVICE_REQUEST,
  GET_NO_ASSOCIATED_DEVICE_SUCCESS,
  GET_NO_ASSOCIATED_DEVICE_FAILURE,
  GET_NO_ASSOCIATED_SIM_REQUEST,
  GET_NO_ASSOCIATED_SIM_SUCCESS,
  GET_NO_ASSOCIATED_SIM_FAILURE,
  INITIAL_FETCH_PACKAGE_REQUEST,
  INITIAL_FETCH_PACKAGE_SUCCESS,
  INITIAL_FETCH_PACKAGE_FAILURE,
  GET_DEVICE_STATUS_REQUEST,
  GET_DEVICE_STATUS_SUCCESS,
  GET_DEVICE_STATUS_FAILURE,
  GET_DEVICE_TYPE_NAME_REQUEST,
  GET_DEVICE_TYPE_NAME_SUCCESS,
  GET_DEVICE_TYPE_NAME_FAILURE,
  GET_SIM_DEVICE_TYPE_REQUEST,
  GET_SIM_DEVICE_TYPE_SUCCESS,
  GET_SIM_DEVICE_TYPE_FAILURE,
  DELETE_PACKAGE_REQUEST,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_FAILURE,
  DELETE_SIM_REQUEST,
  DELETE_SIM_SUCCESS,
  DELETE_SIM_FAILURE,
  DELETE_DEVICE_REQUEST,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAILURE
} from '../constants/ActionTypes';

const INIT_STATE = {
  fetching: null,
  fetchUpdatingSim: null,
  fetchingAddSim: null,
  fetchingAddModem: null,
  fetchUpdatingModel: null,
  simList: null,
  singleSim: null,
  mobileOperators: null,
  packageList: null,
  historicPackageList: null,
  singlePackage: null,
  selectedPackage: null,
  deletedPackage: null,
  selectedSim: null,
  deletedSim: null,
  selectedDevice: null,
  deletedDevice: null,
  initialFetchDone: false,
  notAssociatedVehicles: null,
  notAssociatedDevices: null,
  notAssociatedSims: [],
  modemList: null,
  singleModem: null,
  deviceStatus: null,
  deviceTypeName: null,
  simDeviceType: null,
  error: null,
  checkSimDataFetching: false,
  checkSimData: null,
  checkModemDataFetching: false,
  checkModemData: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //? GET SIM LIST FOR 1 CUSTOMER //
    case GET_SIM_LIST_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_SIM_LIST_SUCCESS:
      return { ...state, fetching: false, simList: action.simList };
    case GET_SIM_LIST_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET SINGLE SIM //
    case GET_SINGLE_SIM_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_SINGLE_SIM_SUCCESS:
      return { ...state, fetching: false, singleSim: action.singleSim };
    case GET_SINGLE_SIM_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? ADD SIM //
    case ADD_SIM_REQUEST: {
      return {
        ...state,
        fetchingAddSim: true,
        error: null
      };
    }
    case ADD_SIM_SUCCESS:
      return { ...state, fetchingAddSim: false, simAdded: action.simAdded };
    case ADD_SIM_FAILURE:
      return { ...state, fetchingAddSim: false, error: action.error };

    //? UPDATE SIM //
    case UPDATE_SIM_REQUEST: {
      return {
        ...state,
        fetchUpdatingSim: true,
        error: null
      };
    }
    case UPDATE_SIM_SUCCESS:
      return {
        ...state,
        fetchUpdatingSim: false,
        simUpdated: action.simUpdated
      };
    case UPDATE_SIM_FAILURE:
      return { ...state, fetchUpdatingSim: false, error: action.error };

    //? DELETE SIM//
    case DELETE_SIM_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null,
        selectedSim: action.payload
      };
    }
    case DELETE_SIM_SUCCESS:
      return {
        ...state,
        fetching: false,
        deletedSim: action.deletedSim
      };
    case DELETE_SIM_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? CHECK SIM DATA IMPORT //
    case CHECK_SIM_DATA_IMPORT_REQUEST: {
      return {
        ...state,
        checkSimDataFetching: true,
        error: null
      };
    }
    case CHECK_SIM_DATA_IMPORT_SUCCESS:
      return {
        ...state,
        checkSimDataFetching: false,
        checkSimData: action.checkSimData
      };
    case CHECK_SIM_DATA_IMPORT_FAILURE:
      return {
        ...state,
        checkSimDataFetching: false,
        error: action.error
      };

    //? GET MOBILE OPERATORS //
    case GET_MOBILE_OPERATORS_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_MOBILE_OPERATORS_SUCCESS:
      return {
        ...state,
        fetching: false,
        mobileOperators: action.mobileOperators
      };
    case GET_MOBILE_OPERATORS_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET PACKAGE LIST //
    case GET_PACKAGES_LIST_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_PACKAGES_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        packageList: action.packageList
      };
    case GET_PACKAGES_LIST_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET SINGLE PACKAGE//
    case GET_SINGLE_PACKAGE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_SINGLE_PACKAGE_SUCCESS:
      return {
        ...state,
        fetching: false,
        singlePackage: action.singlePackage
      };
    case GET_SINGLE_PACKAGE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET PACKAGE HISTORIC//
    case GET_PACKAGE_HISTORIC_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_PACKAGE_HISTORIC_SUCCESS:
      return {
        ...state,
        fetching: false,
        historicPackageList: action.historicPackageList
      };
    case GET_PACKAGE_HISTORIC_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? ADD PACKAGE//
    case ADD_PACKAGE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case ADD_PACKAGE_SUCCESS:
      return {
        ...state,
        fetching: false,
        addedPackage: action.addedPackage
      };
    case ADD_PACKAGE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? UPDATE PACKAGE//
    case UPDATE_PACKAGE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case UPDATE_PACKAGE_SUCCESS:
      return {
        ...state,
        fetching: false,
        updatedPackage: action.updatedPackage
      };
    case UPDATE_PACKAGE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? DELETE PACKAGE//
    case DELETE_PACKAGE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null,
        selectedPackage: action.payload
      };
    }
    case DELETE_PACKAGE_SUCCESS:
      return {
        ...state,
        fetching: false,
        deletedPackage: action.deletedPackage
      };
    case DELETE_PACKAGE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET NO ASSOCIATED VEHICLE //
    case GET_NO_ASSOCIATED_VEHICLE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_NO_ASSOCIATED_VEHICLE_SUCCESS:
      return {
        ...state,
        fetching: false,
        notAssociatedVehicles: action.notAssociatedVehicles
      };
    case GET_NO_ASSOCIATED_VEHICLE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET NO ASSOCIATED DEVICE //
    case GET_NO_ASSOCIATED_DEVICE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_NO_ASSOCIATED_DEVICE_SUCCESS:
      return {
        ...state,
        fetching: false,
        notAssociatedDevices: action.notAssociatedDevices
      };
    case GET_NO_ASSOCIATED_DEVICE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET NO ASSOCIATED SIM //
    case GET_NO_ASSOCIATED_SIM_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_NO_ASSOCIATED_SIM_SUCCESS:
      return {
        ...state,
        fetching: false,
        notAssociatedSims: action.notAssociatedSims
      };
    case GET_NO_ASSOCIATED_SIM_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? INITIAL FETCH PACKAGE //
    case INITIAL_FETCH_PACKAGE_REQUEST: {
      return {
        ...state,
        fetching: true,
        initialFetchDone: false,
        error: null
      };
    }
    case INITIAL_FETCH_PACKAGE_SUCCESS:
      return {
        ...state,
        fetching: false,
        initialFetchDone: true
      };
    case INITIAL_FETCH_PACKAGE_FAILURE:
      return {
        ...state,
        fetching: false,
        initialFetchDone: true,
        error: action.error
      };

    //? GET MODEM LIST FOR 1 CUSTOMER //
    case GET_MODEM_LIST_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_MODEM_LIST_SUCCESS:
      return { ...state, fetching: false, modemList: action.modemList };
    case GET_MODEM_LIST_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET SINGLE MODEM //
    case GET_SINGLE_MODEM_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_SINGLE_MODEM_SUCCESS:
      return { ...state, fetching: false, singleModem: action.singleModem };
    case GET_SINGLE_MODEM_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? ADD MODEM //
    case ADD_MODEM_REQUEST: {
      return {
        ...state,
        fetchingAddModem: true,
        error: null
      };
    }
    case ADD_MODEM_SUCCESS:
      return {
        ...state,
        fetchingAddModem: false,
        modemAdded: action.modemAdded
      };
    case ADD_MODEM_FAILURE:
      return { ...state, fetchingAddModem: false, error: action.error };

    //? UPDATE MODEM //
    case UPDATE_MODEM_REQUEST: {
      return {
        ...state,
        fetchUpdatingModel: true,
        error: null
      };
    }
    case UPDATE_MODEM_SUCCESS:
      return {
        ...state,
        fetchUpdatingModel: false,
        modemUpdated: action.modemUpdated
      };
    case UPDATE_MODEM_FAILURE:
      return { ...state, fetchUpdatingModel: false, error: action.error };

    //? CHECK MODEM DATA IMPORT //
    case CHECK_MODEM_DATA_IMPORT_REQUEST: {
      return {
        ...state,
        checkModemDataFetching: true,
        error: null
      };
    }
    case CHECK_MODEM_DATA_IMPORT_SUCCESS:
      return {
        ...state,
        checkModemDataFetching: false,
        checkModemData: action.checkModemData
      };
    case CHECK_MODEM_DATA_IMPORT_FAILURE:
      return {
        ...state,
        checkModemDataFetching: false,
        error: action.error
      };

    //? GET DEVICE STATUS //
    case GET_DEVICE_STATUS_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_DEVICE_STATUS_SUCCESS:
      return {
        ...state,
        fetching: false,
        deviceStatus: action.deviceStatus
      };
    case GET_DEVICE_STATUS_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET DEVICE TYPE NAME //
    case GET_DEVICE_TYPE_NAME_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_DEVICE_TYPE_NAME_SUCCESS:
      return {
        ...state,
        fetching: false,
        deviceTypeName: action.deviceTypeName
      };
    case GET_DEVICE_TYPE_NAME_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET SIM DEVICE TYPE
    case GET_SIM_DEVICE_TYPE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_SIM_DEVICE_TYPE_SUCCESS:
      return {
        ...state,
        fetching: false,
        simDeviceType: action.simDeviceType
      };
    case GET_SIM_DEVICE_TYPE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? DELETE MODEM//
    case DELETE_DEVICE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null,
        selectedDevice: action.payload
      };
    }
    case DELETE_DEVICE_SUCCESS:
      return {
        ...state,
        fetching: false,
        deletedDevice: action.deletedDevice
      };
    case DELETE_DEVICE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    default:
      return state;
  }
};
