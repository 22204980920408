import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
  select,
  take,
} from 'redux-saga/effects';
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  UPDATE_PREFERENCES_DASHBOARD_REQUEST,
  UPDATE_PREFERENCES_DASHBOARD_SUCCESS,
  UPDATE_PREFERENCES_DASHBOARD_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  SEND_CONTACT_REQUEST,
  SEND_CONTACT_SUCCESS,
  SEND_CONTACT_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_USERS_LIST_REQUEST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  CHANGE_PASSWORD_NO_TOKEN_REQUEST,
  CHANGE_PASSWORD_NO_TOKEN_SUCCESS,
  CHANGE_PASSWORD_NO_TOKEN_FAILURE,
  INITIAL_FETCH_ENUM_UPDATE_USER_REQUEST,
  INITIAL_FETCH_ENUM_UPDATE_USER_SUCCESS,
  INITIAL_FETCH_ENUM_UPDATE_USER_FAILURE,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAILURE,
  GET_CURRENCIES_ENUM_REQUEST,
  GET_CURRENCIES_ENUM_SUCCESS,
  GET_CURRENCIES_ENUM_FAILURE,
  GET_DEFAULT_PAGE_ENUM_REQUEST,
  GET_DEFAULT_PAGE_ENUM_SUCCESS,
  GET_DEFAULT_PAGE_ENUM_FAILURE,
  GET_LANGUAGES_ENUM_REQUEST,
  GET_LANGUAGES_ENUM_SUCCESS,
  GET_LANGUAGES_ENUM_FAILURE,
  GET_CUSTOMER_PROFILES_USER_REQUEST,
  GET_CUSTOMER_PROFILES_USER_SUCCESS,
  GET_CUSTOMER_PROFILES_USER_FAILURE,
  GET_CURRENCIES_REQUEST,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCIES_FAILURE,
} from 'constants/ActionTypes';
import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
} from '../firebase/firebase';
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
} from 'actions/Users';
import { API_URL } from '../constants/globalConstants';
import { showMessage } from 'actions/Global';
import axios from 'axios';

function* watcherChangePasswordRequest() {
  yield takeLatest(CHANGE_PASSWORD_REQUEST, workerChangePasswordRequest);
}

function* watcherChangePasswordNoToken() {
  yield takeLatest(
    CHANGE_PASSWORD_NO_TOKEN_REQUEST,
    workerChangePasswordNoToken
  );
}

function* watcherSendContactRequest() {
  yield takeLatest(SEND_CONTACT_REQUEST, workerSendContactRequest);
}

function* watcherForgotPasswordRequest() {
  yield takeLatest(FORGOT_PASSWORD_REQUEST, workerForgotPasswordRequest);
}

export function* watcherGetUser() {
  yield takeLatest(GET_USER_REQUEST, workerGetUser);
}

export function* watcherCreateUser() {
  yield takeLatest(CREATE_USER_REQUEST, workerCreateUser);
}

export function* watcherUpdateUser() {
  yield takeLatest(UPDATE_USER_REQUEST, workerUpdateUser);
}

export function* watcherGetUsersList() {
  yield takeLatest(GET_USERS_LIST_REQUEST, workerGetUsersList);
}

export function* watcherInitialFetchUpdateUser() {
  yield takeLatest(
    INITIAL_FETCH_ENUM_UPDATE_USER_REQUEST,
    workerInitialFetchUpdateUser
  );
}

export function* watcherGetCurrenciesEnum() {
  yield takeLatest(GET_CURRENCIES_ENUM_REQUEST, workerGetCurrenciesEnum);
}

export function* watcherGetLanguagesEnum() {
  yield takeLatest(GET_LANGUAGES_ENUM_REQUEST, workerGetLanguagesEnum);
}

export function* watcherGetDefaultPagesEnum() {
  yield takeLatest(GET_DEFAULT_PAGE_ENUM_REQUEST, workerGetDefaultPagesEnum);
}

export function* watcherGetCustomerUserProfiles() {
  yield takeLatest(
    GET_CUSTOMER_PROFILES_USER_REQUEST,
    workerGetCustomerUserProfiles
  );
}

const signInUserWithEmailPasswordRequest = async (email, password) => {
  return axios({
    method: 'post',
    url: API_URL + 'authentication',
    data: {
      login: email,
      password: password,
    },
  })
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return error.response;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return error.request;
      } else {
        // Something happened in setting up the request that triggered an Error
        return error.message;
      }
    });
};

const updateDashboardPreferences = async (token, preferences) => {
  let url = `${API_URL}drivers`;
  return axios({
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: {
      //
    },
    url: url,
  });
};

const signOutRequest = async () =>
  await auth
    .signOut()
    .then((authUser) => authUser)
    .catch((error) => error);

const createUser = async (token, user) => {
  console.log('=======SAGA=======');
  console.log(user);
  console.log('======================');
  return axios({
    method: 'post',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    data: {
      password: '123456',
      login: user.login,
      customerId: user.customer,
      languageId: user.default_language.id,
      profileId: user.profile.id,
      lastName: user.lastname,
      firstName: user.firstname,
      email: user.mail,
      active: user.user_locked,
    },
    url: `${API_URL}users`,
  });
};

const updateUser = async (token, user) => {
  let data = {};
  if (user.languageId) data.languageId = user.languageId;
  if (user.currency) data.currency = user.currency;
  if (user.defaultPage) data.defaultPage = user.defaultPage;
  if (user.measureUnit) data.measureUnit = user.measureUnit;
  if (user.profileId) data.profileId = user.profileId;
  if (user.lastName) data.lastName = user.lastName;
  if (user.firstName) data.firstName = user.firstName;
  if (user.email) data.email = user.email;
  if (user.comment) data.comment = user.comment;
  if (user.currentPreferencies) data.preferencies = user.currentPreferencies;

  console.log('---->', data);
  console.log(user);

  return axios({
    method: 'put',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    data: data,
    url: `${API_URL}users/${user.login}`,
  });
};

const getUser = async (token, userLogin) => {
  return axios({
    method: 'get',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}users/${userLogin}`,
  });
};

const getUsersList = async (token, customerId) => {
  return axios({
    method: 'get',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}users?customerId=${customerId}`,
  });
};

const getCurrenciesEnum = async (token) => {
  return axios({
    method: 'get',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}global/currencies`,
  });
};

const getLanguagesEnum = async (token) => {
  return axios({
    method: 'get',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}global/languages`,
  });
};

const getDefaultPagesEnum = async (token) => {
  return axios({
    method: 'get',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}global/default_pages`,
  });
};

const getCustomerUserProfile = async (token, customerId) => {
  console.log('====PROFILE======');
  console.log(customerId);
  console.log('======================');
  return axios({
    method: 'get',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}customers/${customerId}/profiles`,
  });
};

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    console.log('signInUserWithEmailPassword');
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      email,
      password
    );
    switch (signInUser.status) {
      case 200:
        localStorage.setItem('user', JSON.stringify(signInUser.data));
        localStorage.setItem('token', signInUser.data.token);
        localStorage.setItem('tokenExpiredAt', signInUser.data.tokenExpiredAt);
        localStorage.setItem('user_login', signInUser.data.login);

        yield put(userSignInSuccess(signInUser.data));
        break;
      case 403:
        //yield put(showAuthMessage('Wrong login or password', 'danger'));
        yield put(
          showMessage({
            title: 'Error',
            message: 'Wrong login or password',
            messageType: 'danger',
            duration: 5000,
            position: 'top-right',
          })
        );
        break;
      case 401:
        //yield put(showAuthMessage('Unauthorized access', 'danger'));
        yield put(
          showMessage({
            title: 'Error',
            message: 'Unauthorized access',
            messageType: 'danger',
            duration: 5000,
            position: 'top-right',
          })
        );
        break;
      case 500:
        //yield put(showAuthMessage('The server is not responding', 'danger'));
        yield put(
          showMessage({
            title: 'Error',
            message: 'The server is not responding',
            messageType: 'danger',
            duration: 5000,
            position: 'top-right',
          })
        );
        break;
      case 503:
        //yield put(showAuthMessage('The server is not responding', 'danger'));
        yield put(
          showMessage({
            title: 'Error',
            message: 'The server is not responding',
            messageType: 'danger',
            duration: 5000,
            position: 'top-right',
          })
        );
        break;
      default:
        console.log('Wouah !');
    }
  } catch (error) {
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'top-right',
      })
    );
  }
}

function* workerForgotPasswordRequest(action) {
  const { email } = action;

  try {
    const response = yield call(axiosForgotPassword, email);
    yield put({ type: FORGOT_PASSWORD_SUCCESS });
    yield put(
      showMessage({
        title: 'Information',
        message:
          "Thank you, you're going to receive an email with a link to change your password",
        messageType: 'success',
        duration: 5000,
        position: 'top-right',
      })
    );
  } catch (error) {
    if (error.response.data.error) {
      switch (error.response.data.error) {
        case 'EMAIL_NOT_FOUND':
          yield put({
            type: FORGOT_PASSWORD_FAILURE,
            error: error.response.data.error,
          });
          yield put(
            showMessage({
              title: 'Error',
              message: "The mail entered doesn't exist in our database",
              messageType: 'danger',
              duration: 5000,
              position: 'top-right',
            })
          );
          break;
        default:
          yield put({ type: FORGOT_PASSWORD_FAILURE });
          yield put(
            showMessage({
              title: 'Error',
              message: error.message,
              messageType: 'danger',
              duration: 5000,
              position: 'top-right',
            })
          );
          break;
      }
    }
  }
}
const axiosChangePassword = async (newpwd, token) => {
  return axios({
    method: 'put',
    url: `${API_URL}unauthenticated/reset_pwd`,
    params: {
      token: token,
      newpwd: newpwd,
    },
  });
};

function* signOut() {
  try {
    console.log('signOut');
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem('user_login');
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpiredAt');
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message), 'danger');
    }
  } catch (error) {
    yield put(showAuthMessage(error), 'danger');
  }
}

function* workerUpdatePreferencesDashboard(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      updateDashboardPreferences,
      token,
      action.payload
    );
    const preferencesDashboard = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({
      type: UPDATE_PREFERENCES_DASHBOARD_SUCCESS,
      preferencesDashboard,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: UPDATE_PREFERENCES_DASHBOARD_FAILURE, error });
  }
}

function* workerChangePasswordRequest(action) {
  const { newpwd, token } = action;
  try {
    const response = yield call(axiosChangePassword, newpwd, token);

    yield put({ type: CHANGE_PASSWORD_SUCCESS });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Your password has been changed',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center',
      })
    );
  } catch (error) {
    yield put({ type: CHANGE_PASSWORD_FAILURE });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center',
      })
    );
  }
}

function* workerChangePasswordNoToken(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(updatePasswordNoToken, token, action.payload);
    const updatedPassword = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({ type: CHANGE_PASSWORD_NO_TOKEN_SUCCESS, updatedPassword });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: CHANGE_PASSWORD_NO_TOKEN_FAILURE, error });
  }
}

const updatePasswordNoToken = (token, infos) => {
  return axios({
    method: 'put',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    url: `${API_URL}users/change_pwd`,
    params: {
      oldPwd: infos.old_password,
      newPwd: infos.new_password,
    },
  });
};

const axiosSendContact = async (message, email) => {
  return axios({
    method: 'put',
    url: `${API_URL}unauthenticated/send_contact_mail`,
    params: {
      message: message,
      email: email,
    },
  });
};

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}
export function* watcherUpdatePreferencesDashboard() {
  yield takeEvery(
    UPDATE_PREFERENCES_DASHBOARD_REQUEST,
    workerUpdatePreferencesDashboard
  );
}
const axiosForgotPassword = async (email) => {
  return axios({
    method: 'put',
    url: `${API_URL}unauthenticated/create_pwd_reset_token`,
    params: {
      email: email,
    },
  });
};

function* workerSendContactRequest(action) {
  const { message, email } = action;
  try {
    const response = yield call(axiosSendContact, message, email);
    const report = response.data;
    yield put({ type: SEND_CONTACT_SUCCESS });
    yield put(
      showMessage({
        title: 'Information',
        message:
          'Your message has been sent. You will receive an answer as soon as possible',
        messageType: 'success',
        duration: 5000,
        position: 'bottom-center',
      })
    );
  } catch (error) {
    yield put({ type: SEND_CONTACT_FAILURE });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center',
      })
    );
  }
}

function* workerGetUser(action) {
  const getToken = (state) => state.users.authUser.token;
  let token = yield select(getToken);

  if (!token && localStorage.getItem('token')) {
    token = localStorage.getItem('token');
  }
  try {
    const response = yield call(getUser, token, action.payload);
    const user = response.data;

    // dispatch a success action to the store with the new user
    yield put({ type: GET_USER_SUCCESS, user });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_USER_FAILURE, error, login: action.payload });
  }
}

function* workerGetUsersList(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getUsersList, token, action.payload);
    const usersList = response.data;
    // dispatch a success action to the store
    yield put({ type: GET_USERS_LIST_SUCCESS, usersList });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_USERS_LIST_FAILURE, error });
  }
}

function* workerInitialFetchUpdateUser() {
  try {
    yield all([
      put({ type: GET_CURRENCIES_ENUM_REQUEST }),
      put({ type: GET_LANGUAGES_ENUM_REQUEST }),
      put({ type: GET_DEFAULT_PAGE_ENUM_REQUEST }),
      put({ type: GET_CUSTOMERS_REQUEST, payload: {} }),
    ]);
    const res = yield all([
      take([GET_CURRENCIES_ENUM_SUCCESS, GET_CURRENCIES_ENUM_FAILURE]),
      take([GET_LANGUAGES_ENUM_SUCCESS, GET_LANGUAGES_ENUM_FAILURE]),
      take([GET_DEFAULT_PAGE_ENUM_SUCCESS, GET_DEFAULT_PAGE_ENUM_FAILURE]),
      take([GET_CUSTOMERS_SUCCESS, GET_CUSTOMERS_FAILURE]),
    ]);
    if (res != null) {
      yield put({ type: INITIAL_FETCH_ENUM_UPDATE_USER_SUCCESS });
    }
  } catch (error) {
    yield put({ type: INITIAL_FETCH_ENUM_UPDATE_USER_FAILURE, error });
  }
}

function* workerGetCurrenciesEnum() {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getCurrenciesEnum, token);
    const currenciesList = response.data;
    // dispatch a success action to the store
    yield put({ type: GET_CURRENCIES_ENUM_SUCCESS, currenciesList });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_CURRENCIES_ENUM_FAILURE, error });
  }
}

function* workerGetLanguagesEnum() {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getLanguagesEnum, token);
    const languagesList = response.data;
    // dispatch a success action to the store
    yield put({ type: GET_LANGUAGES_ENUM_SUCCESS, languagesList });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_LANGUAGES_ENUM_FAILURE, error });
  }
}

function* workerGetDefaultPagesEnum() {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getDefaultPagesEnum, token);
    const defaultPagesList = response.data;
    // dispatch a success action to the store
    yield put({ type: GET_DEFAULT_PAGE_ENUM_SUCCESS, defaultPagesList });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DEFAULT_PAGE_ENUM_SUCCESS, error });
  }
}

function* workerGetCustomerUserProfiles(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getCustomerUserProfile, token, action.payload);
    const profilesList = response.data;

    // dispatch a success action to the store with the new user
    yield put({ type: GET_CUSTOMER_PROFILES_USER_SUCCESS, profilesList });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_CUSTOMER_PROFILES_USER_FAILURE, error });
  }
}

function* workerCreateUser(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(createUser, token, action.payload);
    const user = response.data;

    // dispatch a success action to the store with the new user
    yield put({ type: CREATE_USER_SUCCESS, user });
    yield put(
      showMessage({
        title: 'Information',
        message: 'User created !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center',
      })
    );
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: CREATE_USER_FAILURE, error });
    let message = '';
    if (
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message === 'Existe déjà'
    ) {
      message = 'This login is already used by an another user';
    } else message = 'A problem occured during the process. Please retry';
    yield put(
      showMessage({
        title: 'Error',
        message: message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center',
      })
    );
  }
}

function* workerUpdateUser(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(updateUser, token, action.payload);
    const user = response.data;

    // dispatch a success action to the store with the new user
    yield put({ type: UPDATE_USER_SUCCESS, user });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: UPDATE_USER_FAILURE, error });
  }
}

//////! DELETE USER //////////
export function* watcherDeleteUser() {
  yield takeLatest(DELETE_USER_REQUEST, workerDeleteUser);
}

const deleteUser = async (token, user) => {
  let url = `${API_URL}users/${user}`;

  return axios({
    method: 'delete',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: url,
  });
};

function* workerDeleteUser(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(deleteUser, token, action.payload);
    const deleted = response.data;

    yield put({ type: DELETE_USER_SUCCESS, deleted });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: DELETE_USER_FAILURE, error });
  }
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(signOutUser),
    fork(watcherUpdatePreferencesDashboard),
    fork(watcherForgotPasswordRequest),
    fork(watcherSendContactRequest),
    fork(watcherChangePasswordRequest),
    fork(watcherChangePasswordNoToken),
    fork(watcherGetUser),
    fork(watcherGetUsersList),
    fork(watcherUpdateUser),
    fork(watcherDeleteUser),
    fork(watcherInitialFetchUpdateUser),
    fork(watcherGetCurrenciesEnum),
    fork(watcherGetLanguagesEnum),
    fork(watcherGetDefaultPagesEnum),
    fork(watcherCreateUser),
    fork(watcherGetCustomerUserProfiles),
  ]);
}
