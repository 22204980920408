import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import { colorStyle, bgColorStyle, designButtons } from 'util/themeColorClass';
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  changePassword,
} from 'actions/Users';
import { APP_VERSION } from '../constants/globalConstants';

const styles = (theme) => ({
  blackHover: {
    '&:hover': {
      color: `${theme.palette.primary.dark}`,
      transition: '0.3s ease-in',
    },
  },
  legalMention: {
    fontSize: '1rem',
    position: 'absolute',
    bottom: '1rem',
    left: 0,
    right: 0,
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  rootMessageInput: {
    height: 'auto',
    minHeight: '10rem',
  },
  notchedOutlineMessageInput: {
    borderRadius: 20,
  },
});

class ChangePassword extends React.Component {
  constructor() {
    super();
    this.state = {
      password: '',
      password_verify: '',
      query: null,
    };
  }

  componentDidMount() {
    if (this.props.location && this.props.location.search) {
      this.setState({ query: this.props.location.search.split('=') });
    }
  }

  render() {
    console.log(this.props.location.search);
    const classes = this.props.classes;
    const { email, message } = this.state;
    const { showMessage, loader, alertMessage } = this.props;
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link
              className="logo-lg"
              rel="keep-params"
              to="/"
              title="Alert Gasoil">
              <img
                style={{ width: '230px', padding: '10px' }}
                src={require('assets/images/logo-alert-gasoil.svg')}
                alt="Alert Gasoil logo"
                title="alert gasoil"
              />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>
                <IntlMessages id="appModule.change_password" />
                {/* <IntlMessages id="appModule.signin" /> */}
              </h1>
            </div>

            <div className="app-login-form">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  this._submit();
                }}>
                <fieldset>
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password" />}
                    fullWidth
                    onChange={(event) =>
                      this.setState({ password: event.target.value })
                    }
                    defaultValue={email}
                    margin="normal"
                    variant="outlined"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password_verify" />}
                    fullWidth
                    onChange={(event) =>
                      this.setState({ password_verify: event.target.value })
                    }
                    defaultValue={email}
                    margin="normal"
                    variant="outlined"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 mt-2 d-flex align-items-center justify-content-between">
                    <Link rel="keep-params" to="/signin">
                      <Button variant="contained" color="primary">
                        Back
                      </Button>
                    </Link>
                    <Button
                      type="submit"
                      //   onClick={this._newPassword}
                      variant="contained"
                      color="primary">
                      Send
                      {/* <IntlMessages id="appModule.signIn" /> */}
                    </Button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
  _formatQuery = () => {
    console.log(this.state.query);
    if (Array.isArray(this.state.query)) {
      if (
        this.state.query[0].includes('token') &&
        this.state.query[1] != null
      ) {
        return this.state.query[1];
      } else console.log('bad Query');
    } else console.log('No Query in Url');
  };
  _submit = () => {
    if (this.state.password != null && this.state.password_verify != null) {
      if (this.state.password.trim() == this.state.password_verify.trim()) {
        if (this._formatQuery() != null) {
          this.props.changePassword({
            token: this._formatQuery(),
            newpwd: this.state.password,
          });
        }
      } else console.log('NotSame');
    } else console.log('empty');
  };
  _sendContact = () => {
    this.props.sendContact({
      email: this.state.email,
      message: this.state.message,
    });
  };
}

const mapStateToProps = ({ users, global }) => {
  const { loader, authUser } = users;
  return { loader, authUser };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  changePassword,
})(
  withStyles(
    (theme) => ({
      ...styles(theme),
      ...colorStyle(theme),
      ...bgColorStyle(theme),
      ...designButtons(theme),
    }),
    { withTheme: true }
  )(ChangePassword)
);
