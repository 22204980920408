import {
  GET_AGSUP_ADA_SINGLE_GROUP_FAILURE,
  GET_AGSUP_ADA_SINGLE_GROUP_REQUEST,
  GET_AGSUP_ADA_SINGLE_GROUP_SUCCESS,
  GET_ADA_SINGLE_VEHICLE_BY_DATE_REQUEST,
  GET_ADA_SINGLE_VEHICLE_BY_DATE_SUCCESS,
  GET_ADA_SINGLE_VEHICLE_BY_DATE_FAILURE,
  GET_ADA_TYPE_ENUM_REQUEST,
  GET_ADA_TYPE_ENUM_SUCCESS,
  GET_ADA_TYPE_ENUM_FAILURE,
  GET_ALL_ADA_FAILURE,
  GET_ALL_ADA_REQUEST,
  GET_ALL_ADA_SUCCESS,
  UPDATE_ADA_REQUEST,
  UPDATE_ADA_SUCCESS,
  UPDATE_ADA_FAILURE,
  CREATE_ADA_REQUEST,
  CREATE_ADA_SUCCESS,
  CREATE_ADA_FAILURE,
  GET_SAV_COUNT_BY_ADA_REQUEST,
  GET_SAV_COUNT_BY_ADA_SUCCESS,
  GET_SAV_COUNT_BY_ADA_FAILURE
} from '../constants/ActionTypes';

const INIT_STATE = {
  ADAListFetching: false,
  ADAList: [],
  adaSingleGroupFetching: null,
  adaVehiclesSingleGroup: null,
  adaSingleVehicleByDateFetching: null,
  adaSingleVehicleByDate: null,
  adaTypeEnumFetching: null,
  adaTypeEnum: null,
  updateAdaFetching: null,
  adaUpdated: null,
  createAdaFetching: null,
  adaCreated: null,
  getSavByAdaFetching: null,
  savFilterByAda: null,
  error: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //? GET ALL ADA //
    case GET_ALL_ADA_REQUEST: {
      return {
        ...state,
        ADAListFetching: true,
        error: null
      };
    }
    case GET_ALL_ADA_SUCCESS:
      return {
        ...state,
        ADAListFetching: false,
        ADAList: action.ADAList
      };
    case GET_ALL_ADA_FAILURE:
      return { ...state, ADAListFetching: false, error: action.error };
    // ? GET ADA VEHICLES FOR SINGLE GROUP //
    case GET_AGSUP_ADA_SINGLE_GROUP_REQUEST: {
      return {
        ...state,
        adaSingleGroupFetching: true,
        error: null
      };
    }
    case GET_AGSUP_ADA_SINGLE_GROUP_SUCCESS:
      return {
        ...state,
        adaSingleGroupFetching: false,
        adaVehiclesSingleGroup: action.adaSingleGroup
      };
    case GET_AGSUP_ADA_SINGLE_GROUP_FAILURE:
      return {
        ...state,
        adaSingleGroupFetching: false,
        error: action.error
      };

    // ? GET ADA SINGLE VEHICLE BY DATE //
    case GET_ADA_SINGLE_VEHICLE_BY_DATE_REQUEST: {
      return {
        ...state,
        adaSingleVehicleByDateFetching: true,
        error: null
      };
    }
    case GET_ADA_SINGLE_VEHICLE_BY_DATE_SUCCESS:
      return {
        ...state,
        adaSingleVehicleByDateFetching: false,
        adaSingleVehicleByDate: action.adaSingleVehicle
      };
    case GET_ADA_SINGLE_VEHICLE_BY_DATE_FAILURE:
      return {
        ...state,
        adaSingleVehicleByDateFetching: false,
        error: action.error
      };

    // ? GET ADA TYPE ENUM //
    case GET_ADA_TYPE_ENUM_REQUEST: {
      return {
        ...state,
        adaTypeEnumFetching: true,
        error: null
      };
    }
    case GET_ADA_TYPE_ENUM_SUCCESS:
      return {
        ...state,
        adaTypeEnumFetching: false,
        adaTypeEnum: action.adaTypeEnum
      };
    case GET_ADA_TYPE_ENUM_FAILURE:
      return {
        ...state,
        adaTypeEnumFetching: false,
        error: action.error
      };

    // ? UPDATE ADA //
    case UPDATE_ADA_REQUEST: {
      return {
        ...state,
        updateAdaFetching: true,
        error: null
      };
    }
    case UPDATE_ADA_SUCCESS:
      return {
        ...state,
        updateAdaFetching: false,
        adaUpdated: action.adaUpdated
      };
    case UPDATE_ADA_FAILURE:
      return {
        ...state,
        updateAdaFetching: false,
        error: action.error
      };

    // ? CREATE ADA //
    case CREATE_ADA_REQUEST: {
      return {
        ...state,
        createAdaFetching: true,
        error: null
      };
    }
    case CREATE_ADA_SUCCESS:
      return {
        ...state,
        createAdaFetching: false,
        adaCreated: action.adaCreated
      };
    case CREATE_ADA_FAILURE:
      return {
        ...state,
        createAdaFetching: false,
        error: action.error
      };
    // ? GET SAV FILTER BY ADA //
    case GET_SAV_COUNT_BY_ADA_REQUEST: {
      return {
        ...state,
        getSavByAdaFetching: true,
        error: null
      };
    }
    case GET_SAV_COUNT_BY_ADA_SUCCESS:
      return {
        ...state,
        getSavByAdaFetching: false,
        savFilterByAda: action.savFilterByAda
      };
    case GET_SAV_COUNT_BY_ADA_FAILURE:
      return {
        ...state,
        getSavByAdaFetching: false,
        error: action.error
      };
    default:
      return state;
  }
};
