import {
  GET_ALL_THEFTS_FAILURE,
  GET_ALL_THEFTS_REQUEST,
  GET_ALL_THEFTS_SUCCESS,
  ADD_THEFT_FAILURE,
  ADD_THEFT_REQUEST,
  ADD_THEFT_SUCCESS,
  DELETE_THEFT_FAILURE,
  DELETE_THEFT_REQUEST,
  DELETE_THEFT_SUCCESS
} from '../constants/ActionTypes';

const INIT_STATE = {
  theftsListFetching: false,
  theftsList: [],
  error: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //? GET ALL THEFTS //
    case GET_ALL_THEFTS_REQUEST: {
      return {
        ...state,
        theftsListFetching: true,
        error: null
      };
    }
    case GET_ALL_THEFTS_SUCCESS:
      return {
        ...state,
        theftsListFetching: false,
        theftsList: action.theftsList
      };
    case GET_ALL_THEFTS_FAILURE:
      return { ...state, theftsListFetching: false, error: action.error };

    //? ADD THEFT//
    case ADD_THEFT_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case ADD_THEFT_SUCCESS:
      return { ...state, fetching: false, theftAdded: action.theftAdded };
    case ADD_THEFT_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? DELETE THEFT//
    case DELETE_THEFT_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case DELETE_THEFT_SUCCESS:
      return { ...state, fetching: false, theftDeleted: action.theftDeleted };
    case DELETE_THEFT_FAILURE:
      return { ...state, fetching: false, error: action.error };

    default:
      return state;
  }
};
