import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import { websocketSetToken } from 'actions/Websocket';
import { switchLanguage } from 'actions/Setting';
import { getUser, userSignOut } from 'actions/Users';
import { isIOS, isMobile } from 'react-device-detect';
import asyncComponent from 'util/asyncComponent';
import TopNav from 'components/TopNav';
import GlobalSearchVehicleModal from 'components/GlobalSearchVehicleModal/index';

import $ from 'jquery';
import * as moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment);

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      websocketConnected: false,
    };
  }

  static getDerivedStateFromProps(prevState, nextProps) {
    if (
      prevState &&
      prevState.websocketConnected &&
      prevState.websocketConnected !== nextProps.websocketConnected
    ) {
      prevState.websocketSetToken(prevState.authUser.token);
      return {
        websocketConnected: prevState.websocketConnected,
      };
    } else {
      return {
        loading: false,
      };
    }
  }

  componentDidMount() {
    let history = this.props.history;
    $('body').on('click', "a[rel~='keep-params']", function (e) {
      e.preventDefault();
      e.stopPropagation();
      console.log($(this).attr('target'));
      let offset = $(this).attr('href').indexOf('?');

      let params = window.location.search;
      if (offset > -1) params = params.split('?').join('&');

      let dest = $(this).attr('href') + params;

      if ($(this).attr('target') == '_blank') {
        let win = window.open(dest, '_blank');
        win.focus();
      } else {
        history.push(dest);
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (localStorage.getItem('tokenExpiredAt') < new Date().getTime()) {
      localStorage.removeItem('user');
      localStorage.removeItem('user_login');
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpiredAt');

      // Effacer auth user et redirect
      this.props.userSignOut();
    }
  }

  render() {
    const {
      //match,
      drawerType,
      navigationStyle,
      horizontalNavPosition,
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'fixed-drawer'
      : drawerType.includes(COLLAPSED_DRAWER)
      ? 'collapsible-drawer'
      : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height');
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        {/* <Tour /> */}

        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? 'app-header-horizontal'
                : ''
            }`}>
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route
                  path={`/home`}
                  component={asyncComponent(() => import('./routes/homePage'))}
                />
                <Route
                  path={`/my-account`}
                  component={asyncComponent(() =>
                    import('./routes/userSettings')
                  )}
                />

                {/* ///////! STATS ///////*/}
                <Route
                  path={`/customer/:customerId/vehicle-stats/:vehicleId`}
                  component={asyncComponent(() =>
                    import('./routes/vehicleStats')
                  )}
                />
                <Route
                  path={`/vehicles-stats`}
                  component={asyncComponent(() =>
                    import('./routes/vehiclesStats')
                  )}
                />
                <Route
                  path={`/customer/:customerId/driver-stats/:driverId`}
                  component={asyncComponent(() =>
                    import('./routes/driverStats')
                  )}
                />
                <Route
                  path={`/drivers-stats`}
                  component={asyncComponent(() =>
                    import('./routes/driversStats')
                  )}
                />

                {/* ///////! ECO DRIVING ///////*/}
                <Route
                  path={`/eco-driving-stats-driver`}
                  component={asyncComponent(() =>
                    import('./routes/EcoDrivingStatsDriver')
                  )}
                />
                <Route
                  path={`/eco-driving-driver`}
                  component={asyncComponent(() =>
                    import('./routes/EcoDrivingScoringDriver')
                  )}
                />
                <Route
                  path={`/eco-driving-stats-vehicle`}
                  component={asyncComponent(() =>
                    import('./routes/EcoDrivingStatsVehicle')
                  )}
                />
                <Route
                  path={`/eco-driving-vehicle`}
                  component={asyncComponent(() =>
                    import('./routes/EcoDrivingScoringVehicle')
                  )}
                />

                {/* ///////! MONITORING ///////*/}
                <Route
                  path={`/monitoring`}
                  component={asyncComponent(() =>
                    import('./routes/liveMapMonitoring')
                  )}
                />

                {/* ///////! LIVEMAP ///////*/}
                <Route
                  path={`/map`}
                  component={asyncComponent(() => import('./routes/liveMap'))}
                />

                {/* ///////! SAVINGS CENTER ///////*/}
                <Route
                  path={`/savings-center/dashboard`}
                  component={asyncComponent(() =>
                    import('./routes/savingsDashboard')
                  )}
                />
                <Route
                  path={`/savings-center/roi`}
                  component={asyncComponent(() =>
                    import('./routes/roiCalculator')
                  )}
                />
                <Route
                  path={`/savings-center/administrative-gains`}
                  component={asyncComponent(() =>
                    import('./routes/administrativeGains')
                  )}
                />
                <Route
                  path={`/savings-center/thefts`}
                  component={asyncComponent(() =>
                    import('./routes/savingsThefts')
                  )}
                />
                <Route
                  path={`/savings-center/tmava`}
                  component={asyncComponent(() =>
                    import('./routes/savingsTmava')
                  )}
                />
                <Route
                  path={`/savings-center/tmavat`}
                  component={asyncComponent(() =>
                    import('./routes/savingsTmavat')
                  )}
                />
                <Route
                  path={`/savings-center/refuels-optimisation`}
                  component={asyncComponent(() =>
                    import('./routes/refuelsOptimisation')
                  )}
                />

                {/* ///////! REPORTS CENTER ///////*/}
                <Route
                  path={`/reports-center/dashboard`}
                  component={asyncComponent(() =>
                    import('./routes/reportsDashboard')
                  )}
                />
                <Route
                  path={`/reports-center/subscription`}
                  component={asyncComponent(() =>
                    import('./routes/reportsSubscriptionsManagement')
                  )}
                />
                <Route
                  path={`/reports-center/add-subscription`}
                  component={asyncComponent(() =>
                    import('./routes/addReportSubscription')
                  )}
                />

                {/* ///////! SAV ///////*/}
                <Route
                  path={`/sav`}
                  component={asyncComponent(() =>
                    import('./routes/savManagement')
                  )}
                />

                {/* ///////! AG SUP ///////*/}
                <Route
                  exact
                  path={`/ag-sup/dashboard/group`}
                  component={asyncComponent(() =>
                    import('./routes/agsupDashboardSingleGroup')
                  )}
                />
                <Route
                  exact
                  path={`/ag-sup/treatment-requests/add-request`}
                  component={asyncComponent(() =>
                    import('./routes/addTreatmentRequest')
                  )}
                />
                <Route
                  exact
                  path={`/ag-sup/treatment-requests`}
                  component={asyncComponent(() =>
                    import('./routes/treatmentRequestManagement')
                  )}
                />
                <Route
                  exact
                  path={`/ag-sup/treatment-requests`}
                  component={asyncComponent(() =>
                    import('./routes/treatmentRequestManagement')
                  )}
                />
                <Route
                  exact
                  path={`/ag-sup/dashboard`}
                  component={asyncComponent(() =>
                    import('./routes/agsupDashboard')
                  )}
                />

                {/* ///////! MONITORING ///////*/}
                <Route
                  exact
                  path={`/ag-sup/monitoring/:customerId`}
                  component={asyncComponent(() =>
                    import('./routes/agsupMonitoring')
                  )}
                />
                <Route
                  exact
                  path={`/ag-sup/monitoring/:customerId/:vehicleId`}
                  component={asyncComponent(() =>
                    import('./routes/agsupMonitoring')
                  )}
                />
                <Route
                  exact
                  path={`/ag-sup/ada-vehicle`}
                  component={asyncComponent(() =>
                    import('./routes/agsupAdaSingleVehicle')
                  )}
                />
                <Route
                  exact
                  path={`/ag-sup/ada`}
                  component={asyncComponent(() =>
                    import('./routes/agsupAdaAllCustomer')
                  )}
                />

                <Route
                  exact
                  path={`/ag-sup/monitoring/refuel/detail/:customerId`}
                  component={asyncComponent(() =>
                    import('./routes/agsupMonitoringRefuelEvent')
                  )}
                />

                <Route
                  exact
                  path={`/ag-sup/monitoring/refuel/detail/:customerId/:vehicleId`}
                  component={asyncComponent(() =>
                    import('./routes/agsupMonitoringRefuelEvent')
                  )}
                />

                <Route
                  exact
                  path={`/ag-sup/monitoring/refuel/detail/:customerId/:vehicleId/:startDateTime/:endDateTime`}
                  component={asyncComponent(() =>
                    import('./routes/agsupMonitoringRefuelEvent')
                  )}
                />

                <Route
                  exact
                  path={`/ag-sup/monitoring/theft/detail/:customerId`}
                  component={asyncComponent(() =>
                    import('./routes/agsupMonitoringDetail')
                  )}
                />

                <Route
                  exact
                  path={`/ag-sup/monitoring/theft/detail/:customerId/:vehicleId`}
                  component={asyncComponent(() =>
                    import('./routes/agsupMonitoringDetail')
                  )}
                />

                <Route
                  exact
                  path={`/ag-sup/monitoring/theft/detail/:customerId/:vehicleId/:startDateTime/:endDateTime`}
                  component={asyncComponent(() =>
                    import('./routes/agsupMonitoringDetail')
                  )}
                />

                <Route
                  exact
                  path={`/ag-sup/monitoring-events/refuel`}
                  component={asyncComponent(() =>
                    import('./routes/agsupMonitoringRefuelEvent')
                  )}
                />
                <Route
                  exact
                  path={`/ag-sup/monitoring-events/theft`}
                  component={asyncComponent(() =>
                    import('./routes/agsupMonitoringTheftEvent')
                  )}
                />

                <Route
                  exact
                  path={`/ag-sup/monitoring-events/tankage`}
                  component={asyncComponent(() =>
                    import('./routes/agsupMonitoringTankage')
                  )}
                />

                <Route
                  exact
                  path={`/ag-sup/monitoring-detail`}
                  component={asyncComponent(() =>
                    import('./routes/agsupMonitoringDetail')
                  )}
                />

                <Route
                  exact
                  path={`/ag-sup/monitoring`}
                  component={asyncComponent(() =>
                    import('./routes/agsupMonitoring')
                  )}
                />

                {/* ////! AG PARC ///////*/}
                <Route
                  exact
                  path={`/ag-parc/users-management/edit/:userLogin`}
                  component={asyncComponent(() => import('./routes/editUser'))}
                />
                <Route
                  exact
                  path={`/ag-parc/users-management`}
                  component={asyncComponent(() =>
                    import('./routes/usersManagement')
                  )}
                />
                <Route
                  exact
                  path={`/ag-parc/add-user`}
                  component={asyncComponent(() => import('./routes/addUser'))}
                />
                <Route
                  exact
                  path={`/ag-parc/vehicles-management`}
                  component={asyncComponent(() =>
                    import('./routes/vehicleManagement')
                  )}
                />
                <Route
                  path={`/ag-parc/add-vehicle`}
                  component={asyncComponent(() =>
                    import('./routes/addVehicle')
                  )}
                />
                <Route
                  path={`/ag-parc/vehicles-management/view/:idVehicle`}
                  component={asyncComponent(() =>
                    import('./routes/singleVehicle')
                  )}
                />
                <Route
                  path={`/ag-parc/vehicles-management/edit/:idVehicle`}
                  component={asyncComponent(() =>
                    import('./routes/editVehicle')
                  )}
                />
                <Route
                  exact
                  path={`/ag-parc/customers-management`}
                  component={asyncComponent(() =>
                    import('./routes/customerManagement')
                  )}
                />
                <Route
                  path={`/ag-parc/customers-management/view/:idCustomer`}
                  component={asyncComponent(() =>
                    import('./routes/singleCustomer')
                  )}
                />
                <Route
                  path={`/ag-parc/customers-management/edit/:idCustomer`}
                  component={asyncComponent(() =>
                    import('./routes/editCustomer')
                  )}
                />
                <Route
                  path={`/ag-parc/add-driver`}
                  component={asyncComponent(() => import('./routes/addDriver'))}
                />
                <Route
                  path={`/ag-parc/drivers-management/edit/:idDriver`}
                  component={asyncComponent(() =>
                    import('./routes/editDriver')
                  )}
                />
                <Route
                  path={`/ag-parc/drivers-management/view/:idDriver`}
                  component={asyncComponent(() =>
                    import('./routes/singleDriver')
                  )}
                />
                <Route
                  path={`/ag-parc/drivers-management`}
                  component={asyncComponent(() =>
                    import('./routes/driversManagement')
                  )}
                />
                <Route
                  path={`/ag-parc/add-eco-profile-driver`}
                  component={asyncComponent(() =>
                    import('./routes/addEcoProfileDriver')
                  )}
                />
                <Route
                  path={`/ag-parc/eco-profiles-management-driver/edit/:ecoProfileId`}
                  component={asyncComponent(() =>
                    import('./routes/editEcoProfileDriver')
                  )}
                />
                <Route
                  path={`/ag-parc/eco-profiles-management-driver/view/:ecoProfileId`}
                  component={asyncComponent(() =>
                    import('./routes/singleEcoProfileDriver')
                  )}
                />
                <Route
                  path={`/ag-parc/eco-profiles-management-driver`}
                  component={asyncComponent(() =>
                    import('./routes/ecoProfilesManagementDriver')
                  )}
                />
                <Route
                  path={`/ag-parc/add-eco-profile-vehicle`}
                  component={asyncComponent(() =>
                    import('./routes/addEcoProfileVehicle')
                  )}
                />
                <Route
                  path={`/ag-parc/eco-profiles-management-vehicle/edit/:ecoProfileId`}
                  component={asyncComponent(() =>
                    import('./routes/editEcoProfileVehicle')
                  )}
                />
                <Route
                  path={`/ag-parc/eco-profiles-management-vehicle/view/:ecoProfileId`}
                  component={asyncComponent(() =>
                    import('./routes/singleEcoProfileVehicle')
                  )}
                />
                <Route
                  path={`/ag-parc/eco-profiles-management-vehicle`}
                  component={asyncComponent(() =>
                    import('./routes/ecoProfilesManagementVehicle')
                  )}
                />
                <Route
                  path={`/ag-parc/sim-management/add-sim`}
                  component={asyncComponent(() => import('./routes/addSim'))}
                />
                <Route
                  path={`/ag-parc/sim-management/view/:simId`}
                  component={asyncComponent(() => import('./routes/singleSim'))}
                />
                <Route
                  path={`/ag-parc/sim-management/edit/:simId`}
                  component={asyncComponent(() => import('./routes/editSim'))}
                />
                <Route
                  path={`/ag-parc/sim-management`}
                  component={asyncComponent(() =>
                    import('./routes/simManagement')
                  )}
                />
                <Route
                  path={`/ag-parc/add-modem`}
                  component={asyncComponent(() => import('./routes/addModem'))}
                />
                <Route
                  path={`/ag-parc/modems-management/view/:modemId`}
                  component={asyncComponent(() =>
                    import('./routes/singleModem')
                  )}
                />
                <Route
                  path={`/ag-parc/modems-management/edit/:modemId`}
                  component={asyncComponent(() => import('./routes/editModem'))}
                />
                <Route
                  path={`/ag-parc/modems-management`}
                  component={asyncComponent(() =>
                    import('./routes/modemManagement')
                  )}
                />

                <Route
                  path={`/ag-parc/package-management/add-package`}
                  component={asyncComponent(() =>
                    import('./routes/addPackage')
                  )}
                />

                <Route
                  path={`/ag-parc/package-management/edit/:packageId`}
                  component={asyncComponent(() =>
                    import('./routes/editPackage')
                  )}
                />

                <Route
                  path={`/ag-parc/package-management`}
                  component={asyncComponent(() =>
                    import('./routes/packageManagement')
                  )}
                />

                {/* ////! AG TANK REF ///////*/}
                <Route
                  exact
                  path={`/ag-tank-ref/tank-series-management`}
                  component={asyncComponent(() =>
                    import('./routes/tankSeriesManagement')
                  )}
                />
                <Route
                  path={`/ag-tank-ref/tank-series-management/view/:tankSerialId`}
                  component={asyncComponent(() =>
                    import('./routes/singleTankSerial')
                  )}
                />
                <Route
                  path={`/ag-tank-ref/add-tank-serie`}
                  component={asyncComponent(() =>
                    import('./routes/addTankSerie')
                  )}
                />
                <Route
                  exact
                  path={`/ag-tank-ref/tank-models-management`}
                  component={asyncComponent(() =>
                    import('./routes/tankModelsManagement')
                  )}
                />
                <Route
                  path={`/ag-tank-ref/tank-models-management/view/:tankModelId`}
                  component={asyncComponent(() =>
                    import('./routes/singleTankModel')
                  )}
                />
                <Route
                  path={`/ag-tank-ref/add-tank-model`}
                  component={asyncComponent(() =>
                    import('./routes/addTankModel')
                  )}
                />
                <Route
                  path={`/ag-tank-ref/tank-management/view/:tankCalibrationId`}
                  component={asyncComponent(() =>
                    import('./routes/singleTankCalibration')
                  )}
                />
                <Route
                  path={`/ag-tank-ref/tank-management`}
                  component={asyncComponent(() =>
                    import('./routes/tankCalibrationManagement')
                  )}
                />
                <Route
                  path={`/ag-tank-ref/add-tank`}
                  component={asyncComponent(() =>
                    import('./routes/addTankCalibration')
                  )}
                />

                {/* ////! SETTINGS ///////*/}
                <Route
                  path={`/settings/edit-vehicle-groups`}
                  component={asyncComponent(() =>
                    import('./routes/vehicleGroupsManagement')
                  )}
                />
                <Route
                  path={`/settings/edit-driver-groups`}
                  component={asyncComponent(() =>
                    import('./routes/driverGroupsManagement')
                  )}
                />
                <Route
                  path={`/settings/refuel-import`}
                  component={asyncComponent(() =>
                    import('./routes/refuelImport')
                  )}
                />

                {/* ////! 404 ///////*/}
                <Route
                  component={asyncComponent(() =>
                    import('components/Error404')
                  )}
                />
              </Switch>
              <GlobalSearchVehicleModal />
            </div>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, users, websocket, global }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  //const { detailVehicleOpened } = global;
  return {
    drawerType,
    navigationStyle,
    horizontalNavPosition,
    authUser: users.authUser,
    websocketConnected: websocket.connected,
    //detailVehicleOpened,
  };
};

const mapDispatchToProps = (dispatch) => ({
  websocketSetToken: (token) => dispatch(websocketSetToken(token)),
  switchLanguage: (locale) => dispatch(switchLanguage(locale)),
  getUser: (userLogin) => dispatch(getUser(userLogin)),
  userSignOut: () => dispatch(userSignOut()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
