import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Modal, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import MonitoringWidget from '../Widgets/SearchModalSection/monitoringWidget';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import { closeDetailVehicleModal } from 'actions/Global';
import CardBox from 'components/CardBox';
import SimpleBarChart from 'components/Widgets/SimpleBarChart';
import MapWidget from 'components/Widgets/SearchModalSection/MapWidget';
import { NavLink } from 'react-router-dom';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { colors } from 'containers/themes/defaultTheme';
import * as moment from 'moment';
import { history } from '../../store';

const ResponsiveGridLayout = WidthProvider(Responsive);

const styles = theme => ({
  modalBox: {
    maxWidth: 'none',
    width: 'calc(100% - 4rem)',
    margin: '2rem'
  },
  modalHeader: {
    backgroundColor: '#ECEEF1',
    borderRadius: 0,
    fontSize: '1.8rem',
    padding: '10px 20px 0px 20px'
  },
  modalContent: {
    backgroundColor: '#ECEEF1',
    borderRadius: 0,
    padding: '0px 10px 0px 10px'
  },
  headingTile: {
    marginBottom: '0.8rem'
  },
  headingTileTitle: {
    fontSize: '1.4rem !important',
    color: theme.palette.primary.main
  },
  valueLabel: {
    fontSize: '1.7rem',
    fontWeight: 'bold'
  },
  subValueLabel: {
    fontSize: '1.2rem',
    fontWeight: 'bold'
  },
  unityLabel: {
    fontSize: '0.8rem'
  },
  compareButton: {
    position: 'absolute',
    fontSize: '1.1rem',
    top: '1rem',
    right: '1rem',
    '&:hover': {
      color: colors.accent.main,
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  cardStyle: {
    border: '1px solid #CACED5',
    padding: '1.5rem',
    marginBottom: 0,
    borderRadius: 0,
    boxShadow: '0px 2px 6px #0000000A',
    width: '100%',
    height: '100%'
  },
  chartRightContainer: {
    paddingLeft: '30%',
    alignSelf: 'flex-end'
  },
  tile12: {
    padding: '1rem',
    width: '12.5%'
  },
  tile25: {
    padding: '1rem',
    width: '25%'
  },
  tile37: {
    padding: '1rem',
    width: '37.5%'
  },
  tile50: {
    padding: '1rem',
    width: '50%'
  },
  cardHeightContent: {
    height: 'calc(100% - 4rem)'
  }
});

class GlobalSearchVehicleModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const classes = this.props.classes;

    // layout is an array of objects, see the demo for more complete usage
    let layouts = {
      lg: [
        { i: '1', x: 0, y: 0, w: 4, h: 2 },
        { i: '2', x: 4, y: 0, w: 4, h: 2 },
        { i: '3', x: 0, y: 1, w: 4, h: 2 },
        { i: '4', x: 4, y: 1, w: 4, h: 2 }
      ],
      md: [
        { i: '1', x: 0, y: 0, w: 8, h: 2 },
        { i: '2', x: 0, y: 1, w: 8, h: 2 },
        { i: '3', x: 0, y: 2, w: 8, h: 2 },
        { i: '4', x: 0, y: 3, w: 8, h: 2 }
      ]
    };

    return (
      <Modal
        className={`modal-box ${classes.modalBox}`}
        isOpen={this.props.detailVehicleOpened != null}>
        <ModalHeader className={`modal-box-header ${classes.modalHeader}`}>
          {this.props.detailVehicleOpened
            ? this.props.detailVehicleOpened.plate
            : null}
          <IconButton className="" onClick={this.props.closeDetailVehicleModal}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>

        <div className={`modal-box-content ${classes.modalContent}`}>
          <ResponsiveGridLayout
            className="layout mb-3 mt-3"
            layouts={layouts}
            breakpoints={{ lg: 1200, md: 0 }}
            margin={[20, 20]}
            containerPadding={[1, 0]}
            cols={{ lg: 8, md: 8 }}
            isResizable={false}
            isDraggable={false}>
            <div key="1">
              <CardBox
                heading="Last 24 hours monitoring"
                headingTitleStyle={classes.headingTileTitle}
                childrenStyle={classes.cardHeightContent}
                cardStyle={`${classes.cardStyle}`}
                styleName={'h-100'}>
                <div className="d-flex flex-row flex-wrap h-100">
                  <MonitoringWidget vehicle={this.props.detailVehicleOpened} />
                  <span
                    onClick={() => {
                      this.props.closeDetailVehicleModal();
                      history.push(
                        `/monitoring?customerId=${this.props
                          .detailVehicleOpened &&
                          this.props.detailVehicleOpened
                            .customerId}&end=${moment().valueOf()}&start=${moment()
                          .subtract(24, 'hours')
                          .valueOf()}&vehicleId=${this.props
                          .detailVehicleOpened &&
                          this.props.detailVehicleOpened.id}`
                      );
                    }}
                    className={`${classes.compareButton}`}>
                    aller vers la carte
                  </span>
                </div>
              </CardBox>
            </div>
            <div key="2">
              <CardBox
                heading="Trips in the last 24 hours"
                headingTitleStyle={classes.headingTileTitle}
                childrenStyle={classes.cardHeightContent}
                cardStyle={`${classes.cardStyle}`}
                styleName={'h-100'}>
                <div className="d-flex flex-row flex-wrap h-100">
                  <MapWidget vehicle={this.props.detailVehicleOpened} />
                  <span
                    onClick={() => {
                      this.props.closeDetailVehicleModal();
                      history.push(
                        `/map?customerId=${this.props.detailVehicleOpened &&
                          this.props.detailVehicleOpened
                            .customerId}&mode=truckList&preselectedVehicle=${this
                          .props.detailVehicleOpened &&
                          this.props.detailVehicleOpened
                            .id}&replay=replay&preselectedStartDate=${moment()
                          .subtract(24, 'hours')
                          .valueOf()}&preselectedEndDate=${moment().valueOf()}&fromModal=true`
                      );
                    }}
                    className={`${classes.compareButton}`}>
                    aller vers la carte
                  </span>
                </div>
              </CardBox>
            </div>

            <div key="3">
              <CardBox
                heading="Net Income"
                headingTitleStyle={classes.headingTileTitle}
                childrenStyle={classes.cardHeightContent}
                cardStyle={`${classes.cardStyle}`}
                styleName={'h-100'}>
                <div className="d-flex flex-row flex-wrap h-100">
                  <div className="col-lg-9 col-md-12">
                    <SimpleBarChart legend={false} />
                  </div>
                  <div
                    className={`stack-order d-flex flex-column justify-content-center align-items-center col-lg-3 col-md-12 ${classes.paddingResponsive}`}>
                    <div className="d-flex flex-row ml-auto">
                      <span
                        className={`text-primary pl-2 ${classes.valueLabel}`}>
                        -12,54
                      </span>
                      <span
                        className={`text-primary pl-2 ${classes.unityLabel}`}>
                        %
                      </span>
                    </div>
                    <div className="d-flex flex-row align-items-baseline ml-auto">
                      <span
                        className={`text-primary pl-2 ${classes.valueLabel}`}>
                        26,76
                      </span>
                      <span
                        className={`text-primary pl-2 ${classes.unityLabel}`}>
                        L/100 km
                      </span>
                    </div>
                  </div>
                  <NavLink
                    rel="keep-params"
                    to="/"
                    className={`${classes.compareButton}`}>
                    Aller vers les statistiques
                  </NavLink>
                </div>
              </CardBox>
            </div>

            <div key="4">
              <CardBox
                heading="Net Income"
                headingTitleStyle={classes.headingTileTitle}
                childrenStyle={classes.cardHeightContent}
                cardStyle={`${classes.cardStyle}`}
                styleName={'h-100'}>
                <div className="d-flex flex-row flex-wrap h-100">
                  <div className="col-lg-9 col-md-12">
                    <SimpleBarChart legend={false} />
                  </div>
                  <div
                    className={`stack-order d-flex flex-column justify-content-center align-items-center col-lg-3 col-md-12 ${classes.paddingResponsive}`}>
                    <div className="d-flex flex-row ml-auto">
                      <span
                        className={`text-primary pl-2 ${classes.valueLabel}`}>
                        -12,54
                      </span>
                      <span
                        className={`text-primary pl-2 ${classes.unityLabel}`}>
                        %
                      </span>
                    </div>
                    <div className="d-flex flex-row align-items-baseline ml-auto">
                      <span
                        className={`text-primary pl-2 ${classes.valueLabel}`}>
                        26,76
                      </span>
                      <span
                        className={`text-primary pl-2 ${classes.unityLabel}`}>
                        L/100 km
                      </span>
                    </div>
                  </div>
                  <NavLink
                    rel="keep-params"
                    to="/"
                    className={`${classes.compareButton}`}>
                    Aller vers les statistiques
                  </NavLink>
                </div>
              </CardBox>
            </div>
          </ResponsiveGridLayout>
          {/* </div> */}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ global }) => {
  return {
    detailVehicleOpened: global.detailVehicleOpened
  };
};

const mapDispatchToProps = dispatch => ({
  closeDetailVehicleModal: () => dispatch(closeDetailVehicleModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GlobalSearchVehicleModal));
