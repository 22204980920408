import {
  GET_VEHICLE_ACTIVITY_REQUEST,
  GET_VEHICLE_ACTIVITY_DETAILS_REQUEST,
  GET_DRIVER_ACTIVITY_REQUEST
  //GET_DRIVER_ACTIVITY_DETAILS_REQUEST
} from '../constants/ActionTypes';

export const getVehicleActivities = infos => {
  return {
    type: GET_VEHICLE_ACTIVITY_REQUEST,
    payload: infos
  };
};

export const getVehicleActivityDetails = infos => {
  return {
    type: GET_VEHICLE_ACTIVITY_DETAILS_REQUEST,
    payload: infos
  };
};

export const getDriverActivities = infos => {
  return {
    type: GET_DRIVER_ACTIVITY_REQUEST,
    payload: infos
  };
};

// export const getDriverActivityDetails = infos => {
//   return {
//     type: GET_DRIVER_ACTIVITY_DETAILS_REQUEST,
//     payload: infos
//   };
// };
