import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactNotification from 'react-notifications-component';
import { injectIntl } from 'react-intl';

class MessageNotification extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      touchedSelect: {}
    };
    this.notificationDOMRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.showNotificationDate !== prevProps.showNotificationDate) {
      this.showNotification(this.props.notification);
    }
  }

  showNotification = ({
    title,
    message,
    messageType,
    duration = 2000,
    position = 'bottom-center'
  }) => {
    console.log('showNotification', {
      title,
      message,
      messageType,
      duration,
      position
    });
    this.notificationDOMRef.current.addNotification({
      title: title,
      message: message,
      type: messageType,
      insert: 'bottom',
      container: position,
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: { duration: duration },
      dismissable: { click: true }
    });
  };

  render() {
    return <ReactNotification ref={this.notificationDOMRef} />;
  }
}

const mapStateToProps = ({ global }) => {
  return {
    showNotificationDate: global.showNotificationDate,
    notification: global.notification
  };
};

export default withRouter(
  connect(mapStateToProps, {})(injectIntl(MessageNotification))
);
