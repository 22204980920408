import {
  GET_ALL_VEHICLE_DAILY_REPORTS_FAILURE,
  GET_ALL_VEHICLE_DAILY_REPORTS_REQUEST,
  GET_ALL_VEHICLE_DAILY_REPORTS_SUCCESS,
  SWITCH_REF_CONSUMPTION_REQUEST,
  SWITCH_REF_CONSUMPTION_SUCCESS,
  SWITCH_REF_CONSUMPTION_FAILURE,
} from '../constants/ActionTypes';

const INIT_STATE = {
  vehicleDailyReportsListFetching: false,
  vehicleDailyReportsList: [],
  vehicleSwitchRefConsumption: null,
  vehicleSwitchRefConsumptionFetching: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //? GET ALL VEHICLE_DAILY_REPORTS //
    case GET_ALL_VEHICLE_DAILY_REPORTS_REQUEST: {
      return {
        ...state,
        vehicleDailyReportsListFetching: true,
        error: null,
      };
    }
    case GET_ALL_VEHICLE_DAILY_REPORTS_SUCCESS:
      return {
        ...state,
        vehicleDailyReportsListFetching: false,
        vehicleDailyReportsList: action.vehicleDailyReportsList,
      };
    case GET_ALL_VEHICLE_DAILY_REPORTS_FAILURE:
      return {
        ...state,
        vehicleDailyReportsListFetching: false,
        error: action.error,
      };

    //? SWITCH REF CONSUMPTION FOR 1 VEHICLE //
    case SWITCH_REF_CONSUMPTION_REQUEST: {
      return {
        ...state,
        vehicleSwitchRefConsumptionFetching: true,
        error: null,
      };
    }
    case SWITCH_REF_CONSUMPTION_SUCCESS:
      return {
        ...state,
        vehicleSwitchRefConsumptionFetching: false,
        vehicleSwitchRefConsumption: action.vehicleSwitchRefConsumption,
      };
    case SWITCH_REF_CONSUMPTION_FAILURE:
      return {
        ...state,
        vehicleSwitchRefConsumptionFetching: false,
        error: action.error,
      };

    default:
      return state;
  }
};
