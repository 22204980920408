import { TOGGLE_TRIPS, PRESELECTIONNED_TRIP } from 'constants/ActionTypes';

const initialState = {
  show_trips: false,
  preselectionnedTrip: null,
};

const livemap = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_TRIPS:
      return {
        ...state,
        show_trips: !state.show_trips,
      };
    case PRESELECTIONNED_TRIP:
      return {
        ...state,
        preselectionnedTrip: action.payload,
      };
    default:
      return state;
  }
};

export default livemap;
