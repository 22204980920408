import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import {
  GLOBAL_SEARCH_REQUEST,
  GLOBAL_SEARCH_SUCCESS,
  GLOBAL_SEARCH_FAILURE,
  GET_CURRENCIES_REQUEST,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCIES_FAILURE,
  GET_MEASURE_UNITS_REQUEST,
  GET_MEASURE_UNITS_SUCCESS,
  GET_MEASURE_UNITS_FAILURE,
  GET_DEFAULT_PAGES_REQUEST,
  GET_DEFAULT_PAGES_SUCCESS,
  GET_DEFAULT_PAGES_FAILURE,
  GET_TREATMENT_REQUESTS_REQUEST,
  GET_TREATMENT_REQUESTS_SUCCESS,
  GET_TREATMENT_REQUESTS_FAILURE,
  GET_TREATMENT_REQUESTS_LEVEL_ENUM_REQUEST,
  GET_TREATMENT_REQUESTS_LEVEL_ENUM_SUCCESS,
  GET_TREATMENT_REQUESTS_LEVEL_ENUM_FAILURE,
  GET_TREATMENT_REQUESTS_TYPE_ENUM_REQUEST,
  GET_TREATMENT_REQUESTS_TYPE_ENUM_SUCCESS,
  GET_TREATMENT_REQUESTS_TYPE_ENUM_FAILURE,
  CREATE_TREATMENT_REQUESTS_REQUEST,
  CREATE_TREATMENT_REQUESTS_SUCCESS,
  CREATE_TREATMENT_REQUESTS_FAILURE
} from 'constants/ActionTypes';
//import { showMessage } from 'actions/Global';
import { API_URL } from '../constants/globalConstants';
import axios from 'axios';
import { showMessage } from '../actions/Global';

////////////! GLOBAL SEARCH /////////////////
export function* watcherGlobalSearch() {
  yield takeEvery(GLOBAL_SEARCH_REQUEST, workerGlobalSearch);
}

const globalSearch = async (token, { customerId, offset, limit, pattern }) => {
  let url = `${API_URL}global/search?`;

  if (customerId) url += 'customerId=' + customerId + '&';
  if (offset) url += 'offset=' + offset + '&';
  if (limit) url += 'limit=' + limit + '&';
  if (pattern) url += 'pattern=' + pattern + '&';

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

function* workerGlobalSearch(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(globalSearch, token, action.payload);
    const globalSearchList = response.data;

    yield put({ type: GLOBAL_SEARCH_SUCCESS, globalSearchList });
  } catch (error) {
    yield put({ type: GLOBAL_SEARCH_FAILURE, error });
  }
}

//////! GET CURRENCIES //////////
export function* watcherGetCurrencies() {
  yield takeLatest(GET_CURRENCIES_REQUEST, workerGetCurrencies);
}

const getCurrencies = async token => {
  let url = `${API_URL}global/currencies`;

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

function* workerGetCurrencies() {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getCurrencies, token);
    const currencies = response.data;

    yield put({ type: GET_CURRENCIES_SUCCESS, currencies });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_CURRENCIES_FAILURE, error });
  }
}

//////! GET MEASURE UNITS //////////
export function* watcherGetMeasureUnits() {
  yield takeLatest(GET_MEASURE_UNITS_REQUEST, workerGetMeasureUnits);
}

const getMeasureUnits = async token => {
  let url = `${API_URL}global/measure_units`;

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

function* workerGetMeasureUnits() {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getMeasureUnits, token);
    const measureUnits = response.data;

    yield put({ type: GET_MEASURE_UNITS_SUCCESS, measureUnits });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_MEASURE_UNITS_FAILURE, error });
  }
}

//////! GET DEFAULT PAGES //////////
export function* watcherGetDefaultPages() {
  yield takeLatest(GET_DEFAULT_PAGES_REQUEST, workerGetDefaultPages);
}

const getDefaultPages = async token => {
  let url = `${API_URL}global/default_pages`;

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

function* workerGetDefaultPages() {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getDefaultPages, token);
    const defaultPages = response.data;

    yield put({ type: GET_DEFAULT_PAGES_SUCCESS, defaultPages });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DEFAULT_PAGES_FAILURE, error });
  }
}

//////! GET TREATMENT REQUESTS //////////
export function* watcherGetTreatmentRequests() {
  yield takeLatest(GET_TREATMENT_REQUESTS_REQUEST, workerGetTreatmentRequests);
}

const getTreatmentRequests = async token => {
  let url = `${API_URL}treatment_request`;

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

function* workerGetTreatmentRequests() {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getTreatmentRequests, token);
    const treatmentRequests = response.data;

    yield put({ type: GET_TREATMENT_REQUESTS_SUCCESS, treatmentRequests });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TREATMENT_REQUESTS_FAILURE, error });
  }
}

//////! GET TREATMENT REQUESTS LEVEL ENUM //////////
export function* watcherGetTreatmentRequestsLevelEnum() {
  yield takeLatest(
    GET_TREATMENT_REQUESTS_LEVEL_ENUM_REQUEST,
    workerGetTreatmentRequestsLevelEnum
  );
}

const getTreatmentRequestsLevelEnum = async token => {
  let url = `${API_URL}global/treatment_request_level`;

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

function* workerGetTreatmentRequestsLevelEnum() {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getTreatmentRequestsLevelEnum, token);
    const treatmentRequestsLevelEnum = response.data;

    yield put({
      type: GET_TREATMENT_REQUESTS_LEVEL_ENUM_SUCCESS,
      treatmentRequestsLevelEnum
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TREATMENT_REQUESTS_LEVEL_ENUM_FAILURE, error });
  }
}

//////! GET TREATMENT REQUESTS TYPE ENUM //////////
export function* watcherGetTreatmentRequestsTypeEnum() {
  yield takeLatest(
    GET_TREATMENT_REQUESTS_TYPE_ENUM_REQUEST,
    workerGetTreatmentRequestsTypeEnum
  );
}

const getTreatmentRequestsTypeEnum = async token => {
  let url = `${API_URL}global/treatment_request_types`;

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

function* workerGetTreatmentRequestsTypeEnum() {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getTreatmentRequestsTypeEnum, token);
    const treatmentRequestsTypeEnum = response.data;

    yield put({
      type: GET_TREATMENT_REQUESTS_TYPE_ENUM_SUCCESS,
      treatmentRequestsTypeEnum
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TREATMENT_REQUESTS_TYPE_ENUM_FAILURE, error });
  }
}

//////! CREATE TREATMENT REQUEST //////////
export function* watcherCreateTreatmentRequest() {
  yield takeLatest(
    CREATE_TREATMENT_REQUESTS_REQUEST,
    workerCreateTreatmentRequest
  );
}

const createTreatmentRequest = async (token, treatmentRequest) => {
  console.log('===========SAGA===========');
  console.log({
    pipeline: treatmentRequest.process.id,
    processLevelDisplay: treatmentRequest.level.value,
    customerId: treatmentRequest.customer.id,
    geographicGroupId: treatmentRequest.geographic_group
      ? treatmentRequest.geographic_group.id
      : null,
    vehicleId: treatmentRequest.vehicle ? treatmentRequest.vehicle : null,
    dateBegin: treatmentRequest.start_date,
    dateEnd: treatmentRequest.end_date,
    applicant: treatmentRequest.applicant,
    comment: treatmentRequest.description ? treatmentRequest.description : null
  });
  console.log('======================');

  let url = `${API_URL}treatment_request`;
  return axios({
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: {
      pipeline: treatmentRequest.process.id,
      processLevelDisplay: treatmentRequest.level.value,
      customerId: treatmentRequest.customer.id,
      geographicGroupId: treatmentRequest.geographic_group
        ? treatmentRequest.geographic_group.id
        : null,
      vehicleId: treatmentRequest.vehicle ? treatmentRequest.vehicle : null,
      dateBegin: treatmentRequest.start_date,
      dateEnd: treatmentRequest.end_date,
      applicant: treatmentRequest.applicant,
      comment: treatmentRequest.description
        ? treatmentRequest.description
        : null
    },
    url: url
  });
};

function* workerCreateTreatmentRequest(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(createTreatmentRequest, token, action.payload);
    const treatmentRequestsCreated = response.data;

    yield put({
      type: CREATE_TREATMENT_REQUESTS_SUCCESS,
      treatmentRequestsCreated
    });
    //yield put(showMessage('Treatment request created !', 'success'));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: CREATE_TREATMENT_REQUESTS_FAILURE, error });
    // yield put(
    //   showMessage(
    //     'A problem occured during the process. Please retry',
    //     'danger'
    //   )
    // );
  }
}

export default function* rootSaga() {
  yield all([
    fork(watcherGlobalSearch),
    fork(watcherGetCurrencies),
    fork(watcherGetMeasureUnits),
    fork(watcherGetDefaultPages),
    fork(watcherGetTreatmentRequests),
    fork(watcherGetTreatmentRequestsLevelEnum),
    fork(watcherGetTreatmentRequestsTypeEnum),
    fork(watcherCreateTreatmentRequest)
  ]);
}
