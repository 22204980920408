import {
  GET_ALL_ZONES_REQUEST,
  GET_ALL_ZONES_SUCCESS,
  GET_ALL_ZONES_FAILURE,
  GET_ZONE_CATEGORY_REQUEST,
  GET_ZONE_CATEGORY_SUCCESS,
  GET_ZONE_CATEGORY_FAILURE,
  ADD_ZONE_REQUEST,
  ADD_ZONE_SUCCESS,
  ADD_ZONE_FAILURE,
  DELETE_ZONE_REQUEST,
  DELETE_ZONE_SUCCESS,
  DELETE_ZONE_FAILURE,
  UPDATE_ZONE_REQUEST,
  UPDATE_ZONE_SUCCESS,
  UPDATE_ZONE_FAILURE
} from '../constants/ActionTypes';

const INIT_STATE = {
  fetching: false,
  zones: [],
  categories: [],
  zoneCreated: null,
  zoneUpdated: null,
  zoneDeleted: null,
  error: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //? GET ALL ZONES //
    case GET_ALL_ZONES_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_ALL_ZONES_SUCCESS:
      return { ...state, fetching: false, zones: action.zones };
    case GET_ALL_ZONES_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? GET ZONE CATEGORIES //
    case GET_ZONE_CATEGORY_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_ZONE_CATEGORY_SUCCESS:
      return { ...state, fetching: false, categories: action.categories };
    case GET_ZONE_CATEGORY_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? CREATE NEW ZONE //
    case ADD_ZONE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case ADD_ZONE_SUCCESS:
      return { ...state, fetching: false, zoneCreated: action.zoneCreated };
    case ADD_ZONE_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? DELETE ZONE //
    case DELETE_ZONE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case DELETE_ZONE_SUCCESS:
      return { ...state, fetching: false, zoneDeleted: action.zoneDeleted };
    case DELETE_ZONE_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? UPDATE ZONE //
    case UPDATE_ZONE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case UPDATE_ZONE_SUCCESS:
      return { ...state, fetching: false, zoneUpdated: action.zoneUpdated };
    case UPDATE_ZONE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    default:
      return state;
  }
};
