import {
  ADD_GROUP_FAILURE,
  ADD_GROUP_REQUEST,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_TYPE_FAILURE,
  ADD_GROUP_TYPE_REQUEST,
  ADD_GROUP_TYPE_SUCCESS,
  ADD_GROUP_TYPE_AND_GROUP_REQUEST,
  ADD_GROUP_TYPE_AND_GROUP_SUCCESS,
  ADD_GROUP_TYPE_AND_GROUP_FAILURE,
  ADD_OPTIONAL_GROUPS_FAILURE,
  ADD_OPTIONAL_GROUPS_REQUEST,
  ADD_OPTIONAL_GROUPS_SUCCESS,
  DELETE_GROUP_FAILURE,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_TYPE_FAILURE,
  DELETE_GROUP_TYPE_REQUEST,
  DELETE_GROUP_TYPE_SUCCESS,
  DELETE_OPTIONAL_GROUPS_FAILURE,
  DELETE_OPTIONAL_GROUPS_REQUEST,
  DELETE_OPTIONAL_GROUPS_SUCCESS,
  EDIT_GROUP_FAILURE,
  EDIT_GROUP_REQUEST,
  EDIT_GROUP_SUCCESS,
  EDIT_GROUP_TYPE_FAILURE,
  EDIT_GROUP_TYPE_REQUEST,
  EDIT_GROUP_TYPE_SUCCESS,
  GET_SINGLE_GROUP_FAILURE,
  GET_SINGLE_GROUP_REQUEST,
  GET_SINGLE_GROUP_SUCCESS,
  GET_GROUP_LEVELS_FAILURE,
  GET_GROUP_LEVELS_REQUEST,
  GET_GROUP_LEVELS_SUCCESS,
  GET_GROUP_LEVELS_FROM_CUSTOMER_FAILURE,
  GET_GROUP_LEVELS_FROM_CUSTOMER_REQUEST,
  GET_GROUP_LEVELS_FROM_CUSTOMER_SUCCESS,
  GET_GROUP_TYPES_FAILURE,
  GET_GROUP_TYPES_REQUEST,
  GET_GROUP_TYPES_SUCCESS,
  GET_GROUPS_BY_LEVEL_FAILURE,
  GET_GROUPS_BY_LEVEL_REQUEST,
  GET_GROUPS_BY_LEVEL_SUCCESS,
  GET_GROUPS_FAILURE,
  GET_GROUPS_REQUEST,
  GET_GROUPS_SUCCESS,
  GET_DEEPEST_GROUPS_REQUEST,
  GET_DEEPEST_GROUPS_SUCCESS,
  GET_DEEPEST_GROUPS_FAILURE,
  GET_GEOGRAPHIC_GROUPS_FILTER_FAILURE,
  GET_GEOGRAPHIC_GROUPS_FILTER_REQUEST,
  GET_GEOGRAPHIC_GROUPS_FILTER_SUCCESS,
  GET_NO_GEOGRAPHIC_GROUPS_FILTER_FAILURE,
  GET_NO_GEOGRAPHIC_GROUPS_FILTER_REQUEST,
  GET_NO_GEOGRAPHIC_GROUPS_FILTER_SUCCESS,
  GET_OPTIONAL_GROUPS_FAILURE,
  GET_OPTIONAL_GROUPS_REQUEST,
  GET_OPTIONAL_GROUPS_SUCCESS,
  GET_TREE_AREA_GROUPS_FAILURE,
  GET_TREE_AREA_GROUPS_REQUEST,
  GET_TREE_AREA_GROUPS_SUCCESS,
  GET_PRESELECTED_GEOGRAPHIC_GROUPS_BARFILTER_FAILURE,
  GET_PRESELECTED_GEOGRAPHIC_GROUPS_BARFILTER_REQUEST,
  GET_PRESELECTED_GEOGRAPHIC_GROUPS_BARFILTER_SUCCESS,
  GET_PRESELECTED_NO_GEOGRAPHIC_GROUPS_BARFILTER_FAILURE,
  GET_PRESELECTED_NO_GEOGRAPHIC_GROUPS_BARFILTER_REQUEST,
  GET_PRESELECTED_NO_GEOGRAPHIC_GROUPS_BARFILTER_SUCCESS
} from 'constants/ActionTypes';

const INIT_STATE = {
  initialFetchDone: false,
  fetching: false,
  fetchingGroupTypesAdd: false,
  fetchingGroupTypesEdit: false,
  fetchingGroupTypesDelete: false,
  fetchingGroupAdd: false,
  addedGroup: null,
  deletedGroup: null,
  fetchingGroupTypeAndGroupAdd: false,
  fetchingGroupEdit: false,
  fetchingGroupDelete: false,
  fetchingDeepestGroups: false,
  deepestGroups: null,
  error: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    ////////? GET TREE AREA GROUPS ///////////
    case GET_TREE_AREA_GROUPS_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_TREE_AREA_GROUPS_SUCCESS: {
      return {
        ...state,
        treeAreaGroups: action.treeAreaGroups,
        fetching: false,
        error: null
      };
    }
    case GET_TREE_AREA_GROUPS_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: true
      };
    }

    ////////? GET DEEPEST GROUPS SINGLE CUSTOMER ///////////
    case GET_DEEPEST_GROUPS_REQUEST: {
      return {
        ...state,
        fetchingDeepestGroups: true,
        error: null
      };
    }
    case GET_DEEPEST_GROUPS_SUCCESS: {
      return {
        ...state,
        deepestGroups: action.deepestGroups,
        fetchingDeepestGroups: false,
        error: null
      };
    }
    case GET_DEEPEST_GROUPS_FAILURE: {
      return {
        ...state,
        fetchingDeepestGroups: false,
        error: true
      };
    }

    ////////? GET GROUP TYPE ///////////
    case GET_GROUP_TYPES_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_GROUP_TYPES_SUCCESS: {
      return {
        ...state,
        groupTypes: action.groupTypes,
        fetching: false,
        error: null
      };
    }
    case GET_GROUP_TYPES_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: true
      };
    }

    ////////? ADD GROUP TYPE ///////////
    case ADD_GROUP_TYPE_REQUEST: {
      return {
        ...state,
        fetchingGroupTypesAdd: true,
        error: null
      };
    }
    case ADD_GROUP_TYPE_SUCCESS: {
      return {
        ...state,
        fetchingGroupTypesAdd: false,
        error: null
      };
    }
    case ADD_GROUP_TYPE_FAILURE: {
      return {
        ...state,
        fetchingGroupTypesAdd: false,
        error: true
      };
    }

    ////////? EDIT GROUP TYPE ///////////
    case EDIT_GROUP_TYPE_REQUEST: {
      return {
        ...state,
        fetchingGroupTypesEdit: true,
        error: null
      };
    }
    case EDIT_GROUP_TYPE_SUCCESS: {
      return {
        ...state,
        fetchingGroupTypesEdit: false,
        error: null
      };
    }
    case EDIT_GROUP_TYPE_FAILURE: {
      return {
        ...state,
        fetchingGroupTypesEdit: false,
        error: true
      };
    }

    ////////? DELETE GROUP TYPE ///////////
    case DELETE_GROUP_TYPE_REQUEST: {
      return {
        ...state,
        fetchingGroupTypesDelete: true,
        error: null
      };
    }
    case DELETE_GROUP_TYPE_SUCCESS: {
      return {
        ...state,
        fetchingGroupTypesDelete: false,
        error: null
      };
    }
    case DELETE_GROUP_TYPE_FAILURE: {
      return {
        ...state,
        fetchingGroupTypesDelete: false,
        error: true
      };
    }

    ////////? ADD GROUP TYPE AND GROUP ///////////
    case ADD_GROUP_TYPE_AND_GROUP_REQUEST: {
      return {
        ...state,
        fetchingGroupTypeAndGroupAdd: true,
        error: null
      };
    }
    case ADD_GROUP_TYPE_AND_GROUP_SUCCESS: {
      return {
        ...state,
        fetchingGroupTypeAndGroupAdd: false,
        error: null
      };
    }
    case ADD_GROUP_TYPE_AND_GROUP_FAILURE: {
      return {
        ...state,
        fetchingGroupTypeAndGroupAdd: false,
        error: true
      };
    }

    ////////? GET GROUP LEVELS ///////////
    case GET_GROUP_LEVELS_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_GROUP_LEVELS_SUCCESS: {
      return {
        ...state,
        groupLevels: action.groupLevels,
        fetching: false,
        error: null
      };
    }
    case GET_GROUP_LEVELS_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: true
      };
    }

    ////////? GET GROUP LEVELS FROM CUSTOMER ///////////
    case GET_GROUP_LEVELS_FROM_CUSTOMER_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_GROUP_LEVELS_FROM_CUSTOMER_SUCCESS: {
      return {
        ...state,
        groupLevelsFromCustomer: action.groupLevelsFromCustomer,
        fetching: false,
        error: null
      };
    }
    case GET_GROUP_LEVELS_FROM_CUSTOMER_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: true
      };
    }

    ////////? GET GROUPS ///////////
    case GET_GROUPS_REQUEST: {
      return {
        ...state,
        fetching: true,
        isGroupsNextData: action.payload.offset > 0 ? true : false,
        error: null
      };
    }
    case GET_GROUPS_SUCCESS: {
      return {
        ...state,
        groups: action.groups,
        fetching: false,
        error: null
      };
    }
    case GET_GROUPS_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: true
      };
    }

    case GET_GEOGRAPHIC_GROUPS_FILTER_REQUEST: {
      return {
        ...state,
        geographicGroupsFilterFetching: true,
        isGeographicGroupsFilterNextData:
          action.payload.offset > 0 ? true : false,
        error: null
      };
    }
    case GET_GEOGRAPHIC_GROUPS_FILTER_SUCCESS: {
      return {
        ...state,
        geographicGroupsFilter: action.groups,
        geographicGroupsFilterFetching: false,
        error: null
      };
    }
    case GET_GEOGRAPHIC_GROUPS_FILTER_FAILURE: {
      return {
        ...state,
        geographicGroupsFilterFetching: false,
        error: true
      };
    }

    case GET_NO_GEOGRAPHIC_GROUPS_FILTER_REQUEST: {
      return {
        ...state,
        noGeographicGroupsFilterFetching: true,
        isNoGeographicGroupsFilterNextData:
          action.payload.offset > 0 ? true : false,
        error: null
      };
    }
    case GET_NO_GEOGRAPHIC_GROUPS_FILTER_SUCCESS: {
      return {
        ...state,
        noGeographicGroupsFilter: action.groups,
        noGeographicGroupsFilterFetching: false,
        error: null
      };
    }
    case GET_NO_GEOGRAPHIC_GROUPS_FILTER_FAILURE: {
      return {
        ...state,
        noGeographicGroupsFilterFetching: false,
        error: true
      };
    }

    ////////? GET GROUPS BY LEVEL ///////////
    case GET_GROUPS_BY_LEVEL_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_GROUPS_BY_LEVEL_SUCCESS: {
      return {
        ...state,
        groupsByLevel: action.groupsByLevel,
        fetching: false,
        error: null
      };
    }
    case GET_GROUPS_BY_LEVEL_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: true
      };
    }

    ////////? GET SINGLE GROUP ///////////
    case GET_SINGLE_GROUP_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_SINGLE_GROUP_SUCCESS: {
      return {
        ...state,
        group: action.group,
        fetching: false,
        error: null
      };
    }
    case GET_SINGLE_GROUP_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: true
      };
    }

    ////////? ADD GROUP ///////////
    case ADD_GROUP_REQUEST: {
      return {
        ...state,
        fetchingGroupAdd: true,
        error: null
      };
    }
    case ADD_GROUP_SUCCESS: {
      return {
        ...state,
        fetchingGroupAdd: false,
        addedGroup: action.addedGroup,
        error: null
      };
    }
    case ADD_GROUP_FAILURE: {
      return {
        ...state,
        fetchingGroupAdd: false,
        error: true
      };
    }

    ////////? EDIT GROUP ///////////
    case EDIT_GROUP_REQUEST: {
      return {
        ...state,
        fetchingGroupEdit: true,
        error: null
      };
    }
    case EDIT_GROUP_SUCCESS: {
      return {
        ...state,
        fetchingGroupEdit: false,
        error: null
      };
    }
    case EDIT_GROUP_FAILURE: {
      return {
        ...state,
        fetchingGroupEdit: false,
        error: true
      };
    }

    ////////? DELETE GROUP ///////////
    case DELETE_GROUP_REQUEST: {
      return {
        ...state,
        fetchingGroupDelete: true,
        error: null
      };
    }
    case DELETE_GROUP_SUCCESS: {
      return {
        ...state,
        fetchingGroupDelete: false,
        deletedGroup: action.deletedGroup,
        error: null
      };
    }
    case DELETE_GROUP_FAILURE: {
      return {
        ...state,
        fetchingGroupDelete: false,
        error: action.error
      };
    }

    ////////? ADD OPTIONAL GROUP ///////////
    case ADD_OPTIONAL_GROUPS_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case ADD_OPTIONAL_GROUPS_SUCCESS: {
      return {
        ...state,
        fetching: false,
        error: null
      };
    }
    case ADD_OPTIONAL_GROUPS_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: true
      };
    }

    ////////? DELETE OPTIONAL GROUP ///////////
    case DELETE_OPTIONAL_GROUPS_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case DELETE_OPTIONAL_GROUPS_SUCCESS: {
      return {
        ...state,
        fetching: false,
        error: null
      };
    }
    case DELETE_OPTIONAL_GROUPS_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: true
      };
    }

    ////////? GET OPTIONAL GROUP ///////////
    case GET_OPTIONAL_GROUPS_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_OPTIONAL_GROUPS_SUCCESS: {
      return {
        ...state,
        optionalGroups: action.gettingGroups,
        fetching: false,
        error: null
      };
    }
    case GET_OPTIONAL_GROUPS_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: true
      };
    }

    //? GET PRESELECTED GEOGRAPHIC_GROUPS IN BARFILTER //
    case GET_PRESELECTED_GEOGRAPHIC_GROUPS_BARFILTER_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_PRESELECTED_GEOGRAPHIC_GROUPS_BARFILTER_SUCCESS:
      return {
        ...state,
        fetching: false,
        preSelectedGeographicGroupsBarFilter:
          action.preSelectedGeographicGroupsBarFilter
      };
    case GET_PRESELECTED_GEOGRAPHIC_GROUPS_BARFILTER_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET PRESELECTED NO GEOGRAPHIC_GROUPS IN BARFILTER //
    case GET_PRESELECTED_NO_GEOGRAPHIC_GROUPS_BARFILTER_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_PRESELECTED_NO_GEOGRAPHIC_GROUPS_BARFILTER_SUCCESS:
      return {
        ...state,
        fetching: false,
        preSelectedNoGeographicGroupsBarFilter:
          action.preSelectedNoGeographicGroupsBarFilter
      };
    case GET_PRESELECTED_NO_GEOGRAPHIC_GROUPS_BARFILTER_FAILURE:
      return { ...state, fetching: false, error: action.error };

    default:
      return state;
  }
};
