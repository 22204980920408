//? Custom style des react-select 1
export const selectAutocompleteStyle = {
  option: (provided, state) => {
    const myColor = '#2B92A0';
    return {
      ...provided,
      backgroundColor: state.isSelected
        ? myColor
        : state.isFocused
        ? '#ECEEF1'
        : null,
      color: state.isSelected ? 'white' : '#607D8B',
      fontFamily: 'Open Sans',
      fontWeight: 'normal'
    };
  },
  control: (provided, state) => ({
    ...provided,
    minWidth: '10rem',
    height: '3.4rem',
    minHeight: '3.4rem !important',
    borderRadius: '5rem',
    color: state.isDisabled ? '#CFD8DC' : '#607D8B',
    boxShadow:
      state.isFocused || state.isSelected ? '0px 0px 0px #2B92A0' : null,
    borderWidth: '0.1rem',
    background: state.isDisabled ? '#ECEEF1' : null,
    borderColor:
      state.isFocused || state.isSelected
        ? '#2B92A0'
        : state.isDisabled
        ? '#afb0b3'
        : '#ECEEF1',
    transition: '0.25s ease',
    '&:hover': {
      borderColor: '#2B92A0',
      transition: '0.25s ease'
    }
  }),
  placeholder: (provided, state) => ({
    ...provided,
    top: '45%',
    color: state.isDisabled ? 'rgba(0, 0, 0, 0.38)' : '#607D8B',
    fontSize: '1.3rem',
    fontFamily: 'Open Sans',
    fontWeight: 'normal'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    top: '45%',
    color: state.isDisabled ? 'rgba(0, 0, 0, 0.38)' : '#607D8B',
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
    fontSize: '1.3rem',
    textOverflow: 'ellipsis'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxHeight: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'nowrap'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#ECEEF1',
    marginBottom: '0.4rem'
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: '#607D8B'
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: '#2B92A0',
    ':hover': {
      backgroundColor: '#2B92A0',
      color: 'white'
    }
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    marginTop: '0.8rem',
    marginBottom: '1rem'
  }),
  indicatorContainer: (provided, state) => ({
    ...provided,
    marginTop: '-0.2rem'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused || state.isSelected ? '#2B92A0' : 'hsl(0,0%,80%)',
    marginTop: '-0.2rem'
  }),
  base: (provided, state) => ({
    ...provided,
    zIndex: 9999999
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 9999999
  })
};

//? Custom style des react-select 2
export const selectAutocompleteStyle2 = {
  option: (provided, state) => {
    const myColor = '#454E64';
    return {
      ...provided,
      backgroundColor: state.isSelected
        ? myColor
        : state.isFocused
        ? '#ECEEF1'
        : null,
      color: state.isSelected ? 'white' : myColor,
      fontFamily: 'Open Sans',
      fontWeight: 'normal'
    };
  },
  control: (provided, state) => ({
    ...provided,
    minWidth: '10rem',
    height: '3.4rem',
    minHeight: '3.4rem !important',
    borderRadius: '5rem',
    color: '#454E64',
    boxShadow:
      state.isFocused || state.isSelected ? '0px 0px 0px #454E64' : null,
    borderWidth: '0.1rem',
    borderColor: state.isFocused || state.isSelected ? '#454E64' : '#ECEEF1',
    transition: '0.25s ease',
    '&:hover': {
      borderColor: '#454E64',
      transition: '0.3s ease'
    }
  }),
  placeholder: (provided, state) => ({
    ...provided,
    top: '45%',
    color: state.isDisabled ? 'rgba(0, 0, 0, 0.38)' : '#454E64',
    fontSize: '1.3rem',
    fontFamily: 'Open Sans',
    fontWeight: 'normal'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    top: '45%',
    color: state.isDisabled ? 'rgba(0, 0, 0, 0.38)' : '#454E64',
    fontFamily: 'Open Sans',
    fontSize: '1.3rem',
    fontWeight: 'normal'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxHeight: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'nowrap'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#ECEEF1',
    marginBottom: '0.4rem'
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: '#454E64'
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: '#454E64',
    ':hover': {
      backgroundColor: '#454E64',
      color: 'white'
    }
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    marginTop: '0.8rem',
    marginBottom: '1rem'
  }),
  indicatorContainer: (provided, state) => ({
    ...provided,
    marginTop: '-0.2rem'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused || state.isSelected ? '#454E64' : 'hsl(0,0%,80%)',
    marginTop: '-0.2rem'
  }),
  base: (provided, state) => ({
    ...provided,
    zIndex: 9999999
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 9999999
  })
};

//? Custom style des react-select pour la boite de dialogue
export const selectAutocompleteStyleModal = {
  option: (provided, state) => {
    const myColor = '#2B92A0';
    return {
      ...provided,
      backgroundColor: state.isSelected
        ? myColor
        : state.isFocused
        ? '#ECEEF1'
        : null,
      color: state.isSelected ? 'white' : '#607D8B',
      fontFamily: 'Open Sans',
      fontWeight: 'normal'
    };
  },
  control: (provided, state) => ({
    ...provided,
    minWidth: '10rem',
    height: '3.4rem',
    minHeight: '3.4rem !important',
    borderRadius: '5rem',
    color: state.isDisabled ? '#CFD8DC' : '#607D8B',
    boxShadow:
      state.isFocused || state.isSelected ? '0px 0px 0px #2B92A0' : null,
    borderWidth: '0.1rem',
    background: state.isDisabled ? '#ECEEF1' : null,
    borderColor:
      state.isFocused || state.isSelected
        ? '#2B92A0'
        : state.isDisabled
        ? '#afb0b3'
        : '#ECEEF1',
    transition: '0.25s ease',
    '&:hover': {
      borderColor: '#2B92A0',
      transition: '0.25s ease'
    }
  }),
  placeholder: (provided, state) => ({
    ...provided,
    top: '45%',
    color: state.isDisabled ? 'rgba(0, 0, 0, 0.38)' : '#607D8B',
    fontSize: '1.3rem',
    fontFamily: 'Open Sans',
    fontWeight: 'normal'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    top: '45%',
    color: state.isDisabled ? 'rgba(0, 0, 0, 0.38)' : '#607D8B',
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
    fontSize: '1.3rem',
    textOverflow: 'ellipsis'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxHeight: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'nowrap'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#ECEEF1',
    marginBottom: '0.4rem'
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: '#607D8B'
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: '#2B92A0',
    ':hover': {
      backgroundColor: '#2B92A0',
      color: 'white'
    }
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    marginTop: '0.8rem',
    marginBottom: '1rem'
  }),
  indicatorContainer: (provided, state) => ({
    ...provided,
    marginTop: '-0.2rem'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused || state.isSelected ? '#2B92A0' : 'hsl(0,0%,80%)',
    marginTop: '-0.2rem'
  }),
  base: (provided, state) => ({
    ...provided,
    zIndex: 9999
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 9999999
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: '4.5rem',
    whiteSpace: 'nowrap'
  })
};

//? Custom style des react-select pour la boite de dialogue
export const selectAutocompleteStyleError = {
  ...selectAutocompleteStyle,
  control: (provided, state) => ({
    ...provided,
    minWidth: '10rem',
    height: '3.4rem',
    minHeight: '3.4rem !important',
    borderRadius: '5rem',
    color: state.isDisabled ? '#CFD8DC' : '#607D8B',
    boxShadow:
      state.isFocused || state.isSelected ? '0px 0px 0px #CD4B46' : null,
    borderWidth: '0.1rem',
    background: state.isDisabled ? '#ECEEF1' : null,
    borderColor:
      state.isFocused || state.isSelected
        ? '#CD4B46'
        : state.isDisabled
        ? '#afb0b3'
        : '#CD4B46',
    transition: '0.25s ease',
    '&:hover': {
      borderColor: '#CD4B46',
      transition: '0.25s ease'
    }
  })
};

//? Custom style des react-select pour la boite de dialogue
export const selectEnumTable = {
  ...selectAutocompleteStyle,

  base: (provided, state) => ({
    ...provided,
    zIndex: 99999
  }),

  menu: (provided, state) => ({
    ...provided,
    zIndex: 99999
  }),

  control: (provided, state) => ({
    ...provided,
    zIndex: 99999,
    minWidth: '5rem',
    height: '3.4rem',
    minHeight: '3.4rem !important',
    borderRadius: 0,
    color: state.isDisabled ? '#CFD8DC' : '#607D8B',
    boxShadow:
      state.isFocused || state.isSelected ? '0px 0px 0px #CD4B46' : null,
    borderWidth: '0.1rem',
    background: state.isDisabled ? '#ECEEF1' : null,
    borderColor:
      state.isFocused || state.isSelected
        ? '#607D8B'
        : state.isDisabled
        ? '#afb0b3'
        : '#CFD8DC',
    transition: '0.25s ease',
    '&:hover': {
      borderColor: '#CFD8DC',
      transition: '0.25s ease'
    }
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused || state.isSelected ? '#607D8B' : 'hsl(0,0%,80%)',
    marginTop: '-0.2rem'
  })
};

//? Custom style des select pour quand on veut les fusionner avec un input text
export const selectFusionTextStyle = {
  option: (provided, state) => {
    const myColor = '#2B92A0';
    return {
      ...provided,
      backgroundColor: state.isSelected
        ? myColor
        : state.isFocused
        ? '#ECEEF1'
        : null,
      color: state.isSelected ? 'white' : '#607D8B',
      fontFamily: 'Open Sans',
      fontWeight: 'normal'
    };
  },
  control: (provided, state) => ({
    ...provided,
    minWidth: '10rem',
    height: '3.4rem',
    minHeight: '3.4rem !important',
    color: state.isDisabled ? '#CFD8DC' : '#607D8B',
    boxShadow:
      state.isFocused || state.isSelected ? '0px 0px 0px #2B92A0' : null,
    background: state.isDisabled ? '#ECEEF1' : '#ECEEF1',
    borderRight: `1px solid #ECEEF1`,
    borderTop: `1px solid #ECEEF1`,
    borderBottom: `1px solid #ECEEF1`,
    borderLeft: 0,
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    borderColor:
      state.isFocused || state.isSelected
        ? '#2B92A0'
        : state.isDisabled
        ? '#afb0b3'
        : '#ECEEF1',
    transition: '0.25s ease',
    '&:hover': {
      borderColor: '#2B92A0',
      transition: '0.25s ease'
    }
  }),
  placeholder: (provided, state) => ({
    ...provided,
    top: '45%',
    color: state.isDisabled ? 'rgba(0, 0, 0, 0.38)' : '#607D8B',
    fontSize: '1.3rem',
    fontFamily: 'Open Sans',
    fontWeight: 'normal'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    top: '45%',
    color: state.isDisabled ? 'rgba(0, 0, 0, 0.38)' : '#607D8B',
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
    fontSize: '1.3rem',
    textOverflow: 'ellipsis'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxHeight: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'nowrap'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#ECEEF1',
    marginBottom: '0.4rem'
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: '#607D8B'
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: '#2B92A0',
    ':hover': {
      backgroundColor: '#2B92A0',
      color: 'white'
    }
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    marginTop: '0.8rem',
    marginBottom: '1rem'
  }),
  indicatorContainer: (provided, state) => ({
    ...provided,
    marginTop: '-0.2rem'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused || state.isSelected ? '#2B92A0' : 'hsl(0,0%,80%)',
    marginTop: '-0.2rem'
  }),
  base: (provided, state) => ({
    ...provided,
    zIndex: 9999999
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 9999999
  })
};
