import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Settings';
import LiveMap from './LiveMap';
import Vehicles from './Vehicles';
import Customers from './Customers';
import Tank from './Tank';
import Websocket from './Websocket';
import Drivers from './Drivers';
import Zones from './Zones';
import Global from './Global';
import Trips from './Trips';
import Alerts from './Alerts';
import Reports from './Reports';
import Users from './Users';
import EcoProfiles from './EcoProfiles';
import Groups from './Groups';
import Packages from './Packages';
import Refuels from './Refuels';
import VehicleDailyReports from './VehicleDailyReports';
import Ada from './Ada';
import Thefts from './Thefts';

export default history =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    livemap: LiveMap,
    vehicles: Vehicles,
    customers: Customers,
    tanks: Tank,
    drivers: Drivers,
    websocket: Websocket,
    zones: Zones,
    global: Global,
    trips: Trips,
    alerts: Alerts,
    reports: Reports,
    users: Users,
    ecoProfiles: EcoProfiles,
    groups: Groups,
    packages: Packages,
    refuels: Refuels,
    vehicleDailyReports: VehicleDailyReports,
    ADA: Ada,
    thefts: Thefts
  });
