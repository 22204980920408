export const colors = {
  primary: {
    light: '#607D8B',
    main: '#454E64',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: '#ECEEF1',
    dark: '#CFD8DC',
    contrastText: '#222222'
  },
  accent: {
    main: '#2B92A0',
    variant: '#FFC207',
    contrastText: '#fff'
  },
  error: {
    main: '#CD4B46'
  },
  status: {
    information: '#2297FF',
    success: '#91B837',
    progress: '#ffdb6d',
    warn: '#E9A83F',
    danger: '#CD4B46',
    parking: '#2948b7',
    idling: '#6FAEA6'
  }
};

export default {
  //! Color theme
  palette: {
    primary: {
      light: colors.primary.light,
      main: colors.primary.main,
      contrastText: colors.primary.contrastText
    },
    secondary: {
      main: colors.secondary.main,
      dark: colors.secondary.dark,
      variant: colors.secondary.variant,
      contrastText: colors.secondary.contrastText
    },
    accent: {
      main: colors.accent.main,
      variant: colors.accent.variant,
      contrastText: colors.accent.contrastText
    },
    error: {
      main: colors.error.main
    },
    status: {
      information: colors.status.information,
      success: colors.status.success,
      progress: colors.status.progress,
      warn: colors.status.warn,
      danger: colors.status.danger,
      parking: colors.status.parking,
      idling: colors.status.idling
    }
  },
  status: {
    information: '#2297FF',
    success: '#91B837',
    progress: '#ffdb6d',
    warn: '#E9A83F',
    danger: '#CD4B46',
    parking: '#2948b7',
    idling: '#6FAEA6'
  },
  typography: {
    fontFamily: 'Open Sans',
    fontSize: 20,

    button: {
      fontWeight: 400,
      textAlign: 'capitalize'
    }
  },
  //! Override elements
  overrides: {
    // ? Drawer
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: 0
      }
    },
    // ? Tooltip
    MuiTooltip: {
      tooltip: {
        fontFamily: 'Open Sans',
        fontSize: '1.2rem',
        fontWeight: 400,
        color: '#FFFFFF',
        backgroundColor: colors.primary.main
      }
    },
    // ? Input
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: `1px solid ${colors.primary.light}`
        }
      }
    },
    MuiInputBase: {
      root: {
        '&$disabled': {
          borderColor: colors.secondary.dark,
          backgroundColor: colors.secondary.main,
          borderRadius: 50
        }
      }
    },
    // ? Input outlined
    MuiOutlinedInput: {
      root: {
        color: colors.primary.light,
        height: '3.4rem',
        '&$focused $notchedOutline': {
          borderColor: colors.accent.main,
          borderWidth: 1
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: colors.accent.main,
          transition: '0.2s ease'
        },
        '&$focused': {
          borderRadius: 50
        }
      },
      input: {
        fontSize: '1.3rem',
        fontFamily: 'Open Sans',
        fontWeight: 'Regular'
      },
      notchedOutline: {
        borderColor: colors.secondary.main,
        borderRadius: 50
      }
    },
    // ? Les labels des inputs
    MuiInputLabel: {
      // ? Celui spécifique aux input outlined
      outlined: {
        transform: 'translate(14px, 10px) scale(1)'
      },
      // ? Les labels à l'intérieur de FormControls
      formControl: {
        // top: '-1rem'
      }
      // shrink: {
      //   color: '#2B92A0'
      // }
    },
    // ? Les labels détachés de l'input
    MuiFormLabel: {
      root: {
        color: colors.primary.light
      }
    },
    // ? Les selects
    MuiSelect: {
      icon: {
        top: 'calc(50% - 10px)',
        right: '1rem'
      },
      select: {
        '&:focus': {
          backgroundColor: 'transparent'
        }
      }
    },
    // ? Les buttons
    MuiButton: {
      root: {
        borderRadius: 50,
        textTransform: 'none',
        '&:disabled': {
          borderColor: colors.secondary.dark,
          backgroundColor: colors.secondary.main,
          color: colors.primary.light,
          borderWeight: 2,
          '& svg': {
            '& path': {
              fill: colors.secondary.dark
            }
          }
        }
      },
      outlined: {
        textTransform: 'none',
        backgroundColor: colors.accent.main,
        color: '#FFFFFF',
        borderColor: colors.accent.main,
        borderWeight: 2,
        '&:hover': {
          color: colors.accent.main,
          backgroundColor: '#FFFFFF',
          '& svg': {
            '& path': {
              fill: colors.accent.main
            }
          }
        },
        '&:disabled': {
          borderColor: colors.secondary.dark,
          backgroundColor: colors.secondary.main,
          color: colors.primary.light,
          borderWeight: 2,
          '& svg': {
            '& path': {
              fill: colors.secondary.dark
            }
          }
        },
        '& svg': {
          '& path': {
            fill: '#FFFFFF'
          }
        }
      },
      outlinedSecondary: {
        textTransform: 'none',
        color: colors.accent.main,
        borderColor: colors.accent.main,
        backgroundColor: '#FFFFFF',
        borderWeight: 2,
        '& svg': {
          '& path': {
            fill: colors.accent.main
          }
        },
        '&:hover': {
          color: '#FFFFFF',
          backgroundColor: colors.accent.main,
          '& svg': {
            '& path': {
              fill: '#FFFFFF'
            }
          }
        }
      }
    },

    // ? Les icons de MUI
    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: 30
      }
    },
    // ? Switch
    MuiSwitch: {
      colorPrimary: {
        '&$checked': {
          color: colors.primary.light
        },
        '&$checked + $track': {
          backgroundColor: colors.primary.light
        }
      },
      colorSecondary: {
        '&$checked': {
          color: colors.accent.main
        },
        '&$checked + $track': {
          backgroundColor: colors.accent.main
        }
      }
    },
    // ? Checkbox
    MuiCheckbox: {
      colorPrimary: {
        '&$checked': {
          color: colors.accent.main
        }
      },
      colorSecondary: {
        '&$checked': {
          color: colors.accent.main
        }
      }
    },
    // ? Radio button
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: colors.accent.main
        }
      }
    },
    // ? Les Paper
    MuiPaper: {
      root: {
        borderRadius: 0
      },
      rounded: {
        borderRadius: 0
        // border: '1px solid #CFD8DC'
      }
    },
    // ? Les Card
    MuiCard: {
      root: {
        borderRadius: 0,
        boxShadow: 'none'
      },
      rounded: {
        borderRadius: 0
        // border: '1px solid #CFD8DC'
      }
    },
    // ? Design des tables
    MuiTableCell: {
      root: {
        borderBottom: '0px',
        padding: '0.5rem 0rem 0.5rem 0rem'
      },
      stickyHeader: {
        backgroundColor: '#ECEEF1',
        zIndex: 0
      },

      body: {
        color: colors.primary.main
      }
      // head: {
      //   color: colors.primary.light,
      //   fontWeight: 'bold'
      // }
    },
    MuiTableSortLabel: {
      root: {
        fontWeight: 'bold',
        color: colors.primary.light
      },
      icon: {
        fill: colors.primary.light
      }
    },
    // ? Design du table pagination des tableaux
    MuiTablePagination: {
      select: {
        textAlign: 'right',
        paddingLeft: '8px',
        paddingRight: '30px',
        textAlignLast: 'right'
      }
    },
    // ? Progress bar
    MuiLinearProgress: {
      root: { height: '0.5rem', borderRadius: 50 },

      colorPrimary: {
        backgroundColor: '#ECEEF1'
      },
      barColorPrimary: {
        backgroundColor: colors.primary.light
      }
    },
    // ? Chip
    MuiChip: {
      root: {
        backgroundColor: '#FFFFFF',
        color: colors.primary.main,
        height: '22px'
      },
      label: {
        paddingLeft: '7px',
        paddingRight: '7px'
      }
    }
  }
};
