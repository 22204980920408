import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import {
  GET_ALL_DRIVERS_REQUEST,
  GET_ALL_DRIVERS_SUCCESS,
  GET_ALL_DRIVERS_FAILURE,
  GET_SINGLE_DRIVER_REQUEST,
  GET_SINGLE_DRIVER_SUCCESS,
  GET_SINGLE_DRIVER_FAILURE,
  GET_DRIVER_GROUP_REQUEST,
  GET_DRIVER_GROUP_SUCCESS,
  GET_DRIVER_GROUP_FAILURE,
  ADD_DRIVER_REQUEST,
  ADD_DRIVER_SUCCESS,
  ADD_DRIVER_FAILURE,
  UPDATE_DRIVER_REQUEST,
  UPDATE_DRIVER_SUCCESS,
  UPDATE_DRIVER_FAILURE,
  DELETE_DRIVER_REQUEST,
  DELETE_DRIVER_SUCCESS,
  DELETE_DRIVER_FAILURE,
  INITIAL_FETCH_SINGLE_DRIVER_REQUEST,
  INITIAL_FETCH_SINGLE_DRIVER_SUCCESS,
  INITIAL_FETCH_SINGLE_DRIVER_FAILURE,
  GET_ALL_DRIVER_ECO_PROFILES_REQUEST,
  GET_ALL_DRIVER_ECO_PROFILES_SUCCESS,
  GET_ALL_DRIVER_ECO_PROFILES_FAILURE,
  GET_DRIVER_TMAVA_TOP_REPORT_REQUEST,
  GET_DRIVER_TMAVA_TOP_REPORT_SUCCESS,
  GET_DRIVER_TMAVA_TOP_REPORT_FAILURE,
  GET_DRIVER_FUEL_CONSUMPTION_REPORT_REQUEST,
  GET_DRIVER_FUEL_CONSUMPTION_REPORT_SUCCESS,
  GET_DRIVER_FUEL_CONSUMPTION_REPORT_FAILURE,
  GET_DRIVER_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST,
  GET_DRIVER_FUEL_CONSUMPTION_REPORT_MULTIPLE_SUCCESS,
  GET_DRIVER_FUEL_CONSUMPTION_REPORT_MULTIPLE_FAILURE,
  GET_DRIVER_TOTAL_FUEL_REPORT_REQUEST,
  GET_DRIVER_TOTAL_FUEL_REPORT_SUCCESS,
  GET_DRIVER_TOTAL_FUEL_REPORT_FAILURE,
  GET_DRIVER_TOTAL_DISTANCE_REPORT_REQUEST,
  GET_DRIVER_TOTAL_DISTANCE_REPORT_SUCCESS,
  GET_DRIVER_TOTAL_DISTANCE_REPORT_FAILURE,
  GET_DRIVER_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST,
  GET_DRIVER_TOTAL_DISTANCE_REPORT_MULTIPLE_SUCCESS,
  GET_DRIVER_TOTAL_DISTANCE_REPORT_MULTIPLE_FAILURE,
  GET_DRIVER_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST,
  GET_DRIVER_TOTAL_ACTIVITY_DAYS_REPORT_SUCCESS,
  GET_DRIVER_TOTAL_ACTIVITY_DAYS_REPORT_FAILURE,
  GET_DRIVER_ACTIVITY_STATS_REPORT_REQUEST,
  GET_DRIVER_ACTIVITY_STATS_REPORT_SUCCESS,
  GET_DRIVER_ACTIVITY_STATS_REPORT_FAILURE,
  GET_DRIVER_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST,
  GET_DRIVER_ACTIVITY_STATS_REPORT_MULTIPLE_SUCCESS,
  GET_DRIVER_ACTIVITY_STATS_REPORT_MULTIPLE_FAILURE,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAILURE,
  GET_GROUPS_REQUEST,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAILURE,
  GET_GROUP_TYPES_REQUEST,
  GET_GROUP_TYPES_SUCCESS,
  GET_GROUP_TYPES_FAILURE,
  GET_DRIVER_ACTIVITIES_BY_DRIVER_REQUEST,
  GET_DRIVER_ACTIVITIES_BY_DRIVER_SUCCESS,
  GET_DRIVER_ACTIVITIES_BY_DRIVER_FAILURE,
  GET_DRIVER_ACTIVITIES_REQUEST,
  GET_DRIVER_ACTIVITIES_SUCCESS,
  GET_DRIVER_ACTIVITIES_FAILURE,
  GET_PRESELECTED_DRIVER_BARFILTER_REQUEST,
  GET_PRESELECTED_DRIVERS_BARFILTER_SUCCESS,
  GET_PRESELECTED_DRIVER_BARFILTER_FAILURE,
  GET_DRIVERS_FILTER_REQUEST,
  GET_DRIVERS_FILTER_SUCCESS,
  GET_DRIVERS_FILTER_FAILURE
} from 'constants/ActionTypes';
import { showMessage } from 'actions/Global';
import { API_URL } from '../constants/globalConstants';
import axios from 'axios';

export function* watcherGetDrivers() {
  yield takeLatest(GET_ALL_DRIVERS_REQUEST, workerGetDrivers);
}

export function* watcherGetSingleDriver() {
  yield takeLatest(GET_SINGLE_DRIVER_REQUEST, workerGetSingleDriver);
}

export function* watcherInitialFetchGetSingleDriver() {
  yield takeLatest(
    INITIAL_FETCH_SINGLE_DRIVER_REQUEST,
    workerInitialFetchGetSingleDriver
  );
}

export function* watcherGetDriverGroups() {
  yield takeLatest(GET_DRIVER_GROUP_REQUEST, workerGetDriverGroups);
}

export function* watcherAddDriver() {
  yield takeLatest(ADD_DRIVER_REQUEST, workerAddDriver);
}

export function* watcherUpdateDriver() {
  yield takeLatest(UPDATE_DRIVER_REQUEST, workerUpdateDriver);
}

export function* watcherDeleteDriver() {
  yield takeLatest(DELETE_DRIVER_REQUEST, workerDeleteDriver);
}

export function* watcherGetDriverTmavaTopReport() {
  yield takeLatest(
    GET_DRIVER_TMAVA_TOP_REPORT_REQUEST,
    workerGetDriverTmavaTopReport
  );
}

export function* watcherGetDriverFuelConsumptionReport() {
  yield takeLatest(
    GET_DRIVER_FUEL_CONSUMPTION_REPORT_REQUEST,
    workerGetDriverFuelConsumptionReport
  );
}

export function* watcherGetDriverFuelConsumptionReportMultiple() {
  yield takeLatest(
    GET_DRIVER_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST,
    workerGetDriverFuelConsumptionReportMultiple
  );
}

export function* watcherGetDriverTotalFuelReport() {
  yield takeLatest(
    GET_DRIVER_TOTAL_FUEL_REPORT_REQUEST,
    workerGetDriverTotalFuelReport
  );
}

export function* watcherGetDriverTotalDistanceReport() {
  yield takeLatest(
    GET_DRIVER_TOTAL_DISTANCE_REPORT_REQUEST,
    workerGetDriverTotalDistanceReport
  );
}

export function* watcherGetDriverTotalDistanceReportMultiple() {
  yield takeLatest(
    GET_DRIVER_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST,
    workerGetDriverTotalDistanceReportMultiple
  );
}

export function* watcherGetDriverTotalActivityDaysReport() {
  yield takeLatest(
    GET_DRIVER_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST,
    workerGetDriverTotalActivityDaysReport
  );
}

export function* watcherGetDriverActivityStatsReport() {
  yield takeLatest(
    GET_DRIVER_ACTIVITY_STATS_REPORT_REQUEST,
    workerGetDriverActivityStatsReport
  );
}

export function* watcherGetDriverActivityStatsReportMultiple() {
  yield takeLatest(
    GET_DRIVER_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST,
    workerGetDriverActivityStatsReportMultiple
  );
}

export function* watcherGetDriverActivitiesByDriver() {
  yield takeLatest(
    GET_DRIVER_ACTIVITIES_BY_DRIVER_REQUEST,
    workerGetDriverActivitiesByDriver
  );
}

export function* watcherGetDriverActivities() {
  yield takeLatest(GET_DRIVER_ACTIVITIES_REQUEST, workerGetDriverActivities);
}

export function* watcherGetPreselectedDriverBarFilter() {
  yield takeLatest(
    GET_PRESELECTED_DRIVER_BARFILTER_REQUEST,
    workerGetPreselectedDriverBarFilter
  );
}

export function* watcherGetDriversFilter() {
  yield takeLatest(GET_DRIVERS_FILTER_REQUEST, workerGetDriversFilter);
}

const getDrivers = async (
  token,
  { customerId, vehicleGroupId, vehicleId, driverIds, offset, limit, pattern }
) => {
  let url = `${API_URL}drivers?`;
  if (customerId) url += 'customerId=' + customerId + '&';

  if (driverIds) {
    driverIds.map(driverId => {
      url += 'driverId=' + driverId + '&';
    });
  }

  if (offset) url += 'offset=' + offset + '&';
  if (limit) url += 'limit=' + limit + '&';
  if (pattern) url += 'pattern=' + pattern;

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

const getSingleDriver = async (token, driverId) => {
  let url = `${API_URL}drivers/${driverId}`;
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

const getDriverGroups = async (token, customerId) => {
  let url = `${API_URL}driver_groups?customerId=${customerId}`;
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

const addDriver = async (token, driver) => {
  let url = `${API_URL}drivers`;
  return axios({
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: {
      driverCode: driver.card_number,
      firstName: driver.firstname,
      lastName: driver.lastname,
      phone: driver.phone,
      email: driver.mail,
      ecoDrivingProfileId: driver.profil.id,
      activation: driver.active ? driver.active : false,
      customerId: driver.customer.id,
      optionalDriverGroupIds:
        driver.optional_groups && driver.optional_groups.length > 0
          ? driver.optional_groups
          : []
    },
    url: url
  });
};

const updateDriver = async (token, driver) => {
  let url = `${API_URL}drivers/${driver.driverId}`;
  return axios({
    method: 'put',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: {
      driverCode: driver.card_number,
      firstName: driver.firstname,
      lastName: driver.lastname,
      phone: driver.phone,
      email: driver.mail,
      ecoDrivingProfileId: driver.profil.id,
      activation: driver.active ? driver.active : false,
      customerId: driver.customer.id,
      optionalDriverGroupIds: driver.optional_groups
    },
    url: url
  });
};

const deleteDriver = async (token, driverId) => {
  let url = `${API_URL}drivers/${driverId}`;
  return axios({
    method: 'delete',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

const getDriverTmavaTopReport = async (
  token,
  { driverId, startDate, endDate }
) => {
  let url = `${API_URL}drivers/${driverId}/report/top_tmava?&period=${encodeURIComponent(
    JSON.stringify({
      start: startDate,
      end: endDate
    })
  )}`;

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

const getDriverFuelConsumptionReport = async (
  token,
  { driverId, startDate, endDate, periods }
) => {
  let url = `${API_URL}drivers/${driverId}/report/fuel_consumption?`;
  if (startDate && endDate) {
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  } else if (periods) {
    url += `period=${encodeURIComponent(JSON.stringify(periods))}`;
  }

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

const getDriverTotalFuelReport = async (
  token,
  { driverId, startDate, endDate }
) => {
  let url = `${API_URL}drivers/${driverId}/report/total_fuel?&period=${encodeURIComponent(
    JSON.stringify({
      start: startDate,
      end: endDate
    })
  )}`;

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

const getDriverTotalDistanceReport = async (
  token,
  { driverId, startDate, endDate, periods }
) => {
  let url = `${API_URL}drivers/${driverId}/report/total_distance?`;
  if (startDate && endDate) {
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  } else if (periods) {
    url += `period=${encodeURIComponent(JSON.stringify(periods))}`;
  }

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

const getDriverTotalActivityDaysReport = async (
  token,
  { driverId, startDate, endDate }
) => {
  let url = `${API_URL}drivers/${driverId}/report/total_activity_days?&period=${encodeURIComponent(
    JSON.stringify({
      start: startDate,
      end: endDate
    })
  )}`;

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

const getDriverActivityStatsReport = async (
  token,
  { driverId, startDate, endDate, periods }
) => {
  let url = `${API_URL}drivers/${driverId}/report/vehicle_stats?`;
  if (startDate && endDate) {
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  } else if (periods) {
    url += `period=${encodeURIComponent(JSON.stringify(periods))}`;
  }

  console.log('url', url);

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

const getDriverActivitiesByDriver = async (
  token,
  { driverId, startDate, endDate }
) => {
  let url = `${API_URL}drivers/${driverId}/activity/driver_activity?`;
  if (startDate && endDate)
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  console.log('url', url);
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

const getDriverActivities = async (token, { driverId, startDate, endDate }) => {
  let url = `${API_URL}drivers/activity/driver_activity?`;
  if (startDate && endDate)
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  console.log('url', url);
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

function* workerGetDrivers(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getDrivers, token, action.payload);
    const driversList = response.data;

    yield put({ type: GET_ALL_DRIVERS_SUCCESS, driversList });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_ALL_DRIVERS_FAILURE, error });
  }
}

function* workerGetSingleDriver(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getSingleDriver, token, action.payload);
    const driver = response.data;

    yield put({ type: GET_SINGLE_DRIVER_SUCCESS, driver });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_SINGLE_DRIVER_FAILURE, error });
  }
}

export function* workerInitialFetchGetSingleDriver(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    //? On get le driver à partir de son ID
    const response = yield call(
      getSingleDriver,
      token,
      action.payload.driverId
    );
    const driver = response.data;

    //? On envoi à Redux la réponse pour qu'il peuple le store
    yield put({ type: GET_SINGLE_DRIVER_SUCCESS, driver });

    //? On appel l'action qui permet de récupérer la liste des profils éco conduite dispo pour le driver et la liste de tous les customers
    yield all([
      put({
        type: GET_ALL_DRIVER_ECO_PROFILES_REQUEST,
        payload: driver.customerId
      }),
      put({ type: GET_CUSTOMERS_REQUEST, payload: {} }),
      put({
        type: GET_GROUPS_REQUEST,
        payload: { customerId: driver.customerId, groupTypeFlag: 'DRIVER' }
      }),
      put({
        type: GET_GROUP_TYPES_REQUEST,
        payload: { customerId: driver.customerId }
      })
    ]);
    const res = yield all([
      take([
        GET_ALL_DRIVER_ECO_PROFILES_SUCCESS,
        GET_ALL_DRIVER_ECO_PROFILES_FAILURE
      ]),
      take([GET_CUSTOMERS_SUCCESS, GET_CUSTOMERS_FAILURE]),
      take([GET_GROUPS_SUCCESS, GET_GROUPS_FAILURE]),
      take([GET_GROUP_TYPES_SUCCESS, GET_GROUP_TYPES_FAILURE])
    ]);

    //? On indique que notre worker est done si on recoit une réponse
    if (res !== null) {
      yield put({ type: INITIAL_FETCH_SINGLE_DRIVER_SUCCESS });
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: INITIAL_FETCH_SINGLE_DRIVER_FAILURE, error });
  }
}

function* workerGetDriverGroups(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getDriverGroups, token, action.payload);
    const driverGroups = response.data;

    yield put({ type: GET_DRIVER_GROUP_SUCCESS, driverGroups });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DRIVER_GROUP_FAILURE, error });
  }
}

function* workerAddDriver(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(addDriver, token, action.payload);
    const driverAdded = response.data;

    yield put({ type: ADD_DRIVER_SUCCESS, driverAdded });
    //yield put(showMessage('Driver added', 'success'));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ADD_DRIVER_FAILURE, error });
    //yield put(showMessage(error.message, 'danger'));
  }
}

function* workerUpdateDriver(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(updateDriver, token, action.payload);
    const driverUpdated = response.data;

    yield put({ type: UPDATE_DRIVER_SUCCESS, driverUpdated });
    //yield put(showMessage('Driver updated', 'success'));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: UPDATE_DRIVER_FAILURE, error });
    //yield put(showMessage(error.message, 'danger'));
  }
}

function* workerDeleteDriver(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(deleteDriver, token, action.payload);
    const deletedDriver = action.payload;

    yield put({ type: DELETE_DRIVER_SUCCESS, deletedDriver });
    //yield put(showMessage('Driver deleted', 'success'));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: DELETE_DRIVER_FAILURE, error });
    //yield put(showMessage(error.message, 'danger'));
  }
}

function* workerGetDriverTmavaTopReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getDriverTmavaTopReport, token, action.payload);
    const tmavaTopReport = response.data;
    // dispatch a success action to the store
    yield put({ type: GET_DRIVER_TMAVA_TOP_REPORT_SUCCESS, tmavaTopReport });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DRIVER_TMAVA_TOP_REPORT_FAILURE, error });
  }
}

function* workerGetDriverFuelConsumptionReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getDriverFuelConsumptionReport,
      token,
      action.payload
    );
    const fuelConsumptionReport = response.data;
    // dispatch a success action to the store
    yield put({
      type: GET_DRIVER_FUEL_CONSUMPTION_REPORT_SUCCESS,
      fuelConsumptionReport
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DRIVER_FUEL_CONSUMPTION_REPORT_FAILURE, error });
  }
}

function* workerGetDriverFuelConsumptionReportMultiple(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getDriverFuelConsumptionReport,
      token,
      action.payload
    );
    const fuelConsumptionReportMultiple = response.data;
    // dispatch a success action to the store
    yield put({
      type: GET_DRIVER_FUEL_CONSUMPTION_REPORT_MULTIPLE_SUCCESS,
      fuelConsumptionReportMultiple
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_DRIVER_FUEL_CONSUMPTION_REPORT_MULTIPLE_FAILURE,
      error
    });
  }
}

function* workerGetDriverTotalFuelReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getDriverTotalFuelReport,
      token,
      action.payload
    );
    const totalFuelReport = response.data;
    // dispatch a success action to the store
    yield put({ type: GET_DRIVER_TOTAL_FUEL_REPORT_SUCCESS, totalFuelReport });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DRIVER_TOTAL_FUEL_REPORT_FAILURE, error });
  }
}

function* workerGetDriverTotalDistanceReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getDriverTotalDistanceReport,
      token,
      action.payload
    );
    const totalDistanceReport = response.data;
    // dispatch a success action to the store
    yield put({
      type: GET_DRIVER_TOTAL_DISTANCE_REPORT_SUCCESS,
      totalDistanceReport
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DRIVER_TOTAL_DISTANCE_REPORT_FAILURE, error });
  }
}

function* workerGetDriverTotalDistanceReportMultiple(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getDriverTotalDistanceReport,
      token,
      action.payload
    );
    const totalDistanceReportMultiple = response.data;
    // dispatch a success action to the store
    yield put({
      type: GET_DRIVER_TOTAL_DISTANCE_REPORT_MULTIPLE_SUCCESS,
      totalDistanceReportMultiple
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_DRIVER_TOTAL_DISTANCE_REPORT_MULTIPLE_FAILURE,
      error
    });
  }
}

function* workerGetDriverTotalActivityDaysReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getDriverTotalActivityDaysReport,
      token,
      action.payload
    );
    const totalActivityDaysReport = response.data;
    // dispatch a success action to the store
    yield put({
      type: GET_DRIVER_TOTAL_ACTIVITY_DAYS_REPORT_SUCCESS,
      totalActivityDaysReport
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DRIVER_TOTAL_ACTIVITY_DAYS_REPORT_FAILURE, error });
  }
}

function* workerGetDriverActivityStatsReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getDriverActivityStatsReport,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({ type: GET_DRIVER_ACTIVITY_STATS_REPORT_SUCCESS, report });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DRIVER_ACTIVITY_STATS_REPORT_FAILURE, error });
  }
}

function* workerGetDriverActivityStatsReportMultiple(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getDriverActivityStatsReport,
      token,
      action.payload
    );
    const report = response.data;

    // dispatch a success action to the store with the new report
    yield put({
      type: GET_DRIVER_ACTIVITY_STATS_REPORT_MULTIPLE_SUCCESS,
      report
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: GET_DRIVER_ACTIVITY_STATS_REPORT_MULTIPLE_FAILURE,
      error
    });
  }
}

function* workerGetDriverActivitiesByDriver(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getDriverActivitiesByDriver,
      token,
      action.payload
    );
    const driverActivities = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({
      type: GET_DRIVER_ACTIVITIES_BY_DRIVER_SUCCESS,
      driverActivities
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DRIVER_ACTIVITIES_BY_DRIVER_FAILURE, error });
  }
}

function* workerGetDriverActivities(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getDriverActivities, token, action.payload);
    const driverActivities = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({
      type: GET_DRIVER_ACTIVITIES_SUCCESS,
      driverActivities
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DRIVER_ACTIVITIES_FAILURE, error });
  }
}

function* workerGetPreselectedDriverBarFilter(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getDrivers, token, action.payload);
    const preselectedDrivers = response.data;

    // dispatch a success action to the store with the new vehicle
    yield put({
      type: GET_PRESELECTED_DRIVERS_BARFILTER_SUCCESS,
      preselectedDrivers
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_PRESELECTED_DRIVER_BARFILTER_FAILURE, error });
  }
}

function* workerGetDriversFilter(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getDrivers, token, action.payload);
    const drivers = response.data;

    // dispatch a success action to the store with drivers
    yield put({ type: GET_DRIVERS_FILTER_SUCCESS, drivers });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DRIVERS_FILTER_FAILURE, error });
  }
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetDrivers),
    fork(watcherGetSingleDriver),
    fork(watcherInitialFetchGetSingleDriver),
    fork(watcherGetDriverGroups),
    fork(watcherUpdateDriver),
    fork(watcherDeleteDriver),
    fork(watcherAddDriver),
    fork(watcherGetDriverTmavaTopReport),
    fork(watcherGetDriverFuelConsumptionReport),
    fork(watcherGetDriverFuelConsumptionReportMultiple),
    fork(watcherGetDriverTotalFuelReport),
    fork(watcherGetDriverTotalDistanceReport),
    fork(watcherGetDriverTotalDistanceReportMultiple),
    fork(watcherGetDriverTotalActivityDaysReport),
    fork(watcherGetDriverActivityStatsReport),
    fork(watcherGetDriverActivityStatsReportMultiple),
    fork(watcherGetDriverActivitiesByDriver),
    fork(watcherGetDriverActivities),
    fork(watcherGetPreselectedDriverBarFilter),
    fork(watcherGetDriversFilter)
  ]);
}
