import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import {
  GET_ALL_ALERTS_REQUEST,
  GET_ALL_ALERTS_SUCCESS,
  GET_ALL_ALERTS_FAILURE,
  GET_ALERTS_SINGLE_VEHICLE_REQUEST,
  GET_ALERTS_SINGLE_VEHICLE_SUCCESS,
  GET_ALERTS_SINGLE_VEHICLE_FAILURE,
  GET_ALERTS_SINGLE_DRIVER_REQUEST,
  GET_ALERTS_SINGLE_DRIVER_SUCCESS,
  GET_ALERTS_SINGLE_DRIVER_FAILURE,
  GET_ALERTS_SINGLE_ZONE_REQUEST,
  GET_ALERTS_SINGLE_ZONE_SUCCESS,
  GET_ALERTS_SINGLE_ZONE_FAILURE
} from 'constants/ActionTypes';
import { API_URL } from '../constants/globalConstants';
import axios from 'axios';

export function* watcherGetAlertsSingleDriver() {
  yield takeEvery(
    GET_ALERTS_SINGLE_DRIVER_REQUEST,
    workerGetAlertsSingleDriver
  );
}

export function* watcherGetAlertsSingleVehicle() {
  yield takeEvery(
    GET_ALERTS_SINGLE_VEHICLE_REQUEST,
    workerGetAlertsSingleVehicle
  );
}

export function* watcherGetAllAlerts() {
  yield takeEvery(GET_ALL_ALERTS_REQUEST, workerGetAllAlerts);
}

export function* watcherGetAlertsSingleZone() {
  yield takeEvery(GET_ALERTS_SINGLE_ZONE_REQUEST, workerGetAlertsSingleZone);
}

const getAllAlerts = async (token, { customerId, startDate, endDate }) => {
  let url = `${API_URL}alarms/alerts?`;

  if (customerId) url += 'customerId=' + customerId + '&';
  if (startDate && endDate)
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

const getAlertsSingleDriver = async (
  token,
  { customerId, driverId, startDate, endDate, offset, limit }
) => {
  let url = `${API_URL}alarms/alerts?`;
  if (customerId) url += 'customerId=' + customerId + '&';
  if (driverId) url += 'driverId=' + driverId + '&';
  if (startDate && endDate)
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

const getAlertsSingleVehicle = async (
  token,
  { customerId, vehicleId, startDate, endDate, offset, limit }
) => {
  let url = `${API_URL}alarms/alerts?`;
  if (customerId) url += 'customerId=' + customerId + '&';
  if (vehicleId) url += 'vehicleId=' + vehicleId + '&';
  if (startDate && endDate)
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

const getAlertsSingleZone = async (
  token,
  { customerId, zoneId, startDate, endDate, offset, limit }
) => {
  let url = `${API_URL}alarms/alerts?`;
  if (customerId) url += 'customerId=' + customerId + '&';
  if (zoneId) url += 'zoneId=' + zoneId + '&';
  if (startDate && endDate)
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

function* workerGetAllAlerts(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getAllAlerts, token, action.payload);
    const alertsList = response.data;

    yield put({ type: GET_ALL_ALERTS_SUCCESS, alertsList });
  } catch (error) {
    yield put({ type: GET_ALL_ALERTS_FAILURE, error });
  }
}

function* workerGetAlertsSingleDriver(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getAlertsSingleDriver, token, action.payload);
    const alertsList = response.data;

    yield put({ type: GET_ALERTS_SINGLE_DRIVER_SUCCESS, alertsList });
  } catch (error) {
    yield put({ type: GET_ALERTS_SINGLE_DRIVER_FAILURE, error });
  }
}

function* workerGetAlertsSingleVehicle(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getAlertsSingleVehicle, token, action.payload);
    const alertsList = response.data;

    yield put({ type: GET_ALERTS_SINGLE_VEHICLE_SUCCESS, alertsList });
  } catch (error) {
    yield put({ type: GET_ALERTS_SINGLE_VEHICLE_FAILURE, error });
  }
}

function* workerGetAlertsSingleZone(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getAlertsSingleZone, token, action.payload);
    const alertsList = response.data;

    yield put({ type: GET_ALERTS_SINGLE_ZONE_SUCCESS, alertsList });
  } catch (error) {
    yield put({ type: GET_ALERTS_SINGLE_ZONE_FAILURE, error });
  }
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetAllAlerts),
    fork(watcherGetAlertsSingleVehicle),
    fork(watcherGetAlertsSingleDriver),
    fork(watcherGetAlertsSingleZone)
  ]);
}
