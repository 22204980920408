import {
  ADD_VEHICLE_REQUEST,
  GET_ALL_DATA_TO_ADD_VEHICLE_REQUEST,
  GET_ALL_DATA_TO_UPDATE_VEHICLE_REQUEST,
  GET_ALL_VEHICLE_SITES_FILTER_REQUEST,
  GET_ALL_VEHICLES_REQUEST,
  GET_DRIVER_ACTIVITIES_BY_VEHICLE_REQUEST,
  GET_ENGINE_DETECTION_TYPE_REQUEST,
  GET_ENUM_CALIB_REQUEST,
  GET_PTO_TYPE_ENUM_REQUEST,
  GET_SINGLE_VEHICLE_REQUEST,
  GET_PRESELECTED_VEHICLES_BARFILTER_REQUEST,
  GET_TACHY_TYPE_REQUEST,
  GET_RDL_TYPE_REQUEST,
  GET_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST,
  GET_VEHICLE_ACTIVITY_STATS_REPORT_REQUEST,
  GET_VEHICLE_BRAND_REQUEST,
  GET_VEHICLE_CATEGORY_REQUEST,
  GET_VEHICLE_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST,
  GET_VEHICLE_FUEL_CONSUMPTION_REPORT_REQUEST,
  GET_VEHICLE_FUEL_REPORT_REQUEST,
  GET_VEHICLE_FUEL_REPORT_BY_DRIVER_REQUEST,
  GET_VEHICLE_GROUP_TYPES_REQUEST,
  GET_VEHICLE_GROUPS_FILTER_REQUEST,
  GET_VEHICLE_MODEL_REQUEST,
  GET_VEHICLE_STATUS_REQUEST,
  GET_VEHICLE_TMAVA_TOP_REPORT_REQUEST,
  GET_VEHICLE_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST,
  GET_VEHICLE_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST,
  GET_VEHICLE_TOTAL_DISTANCE_REPORT_REQUEST,
  GET_VEHICLE_TOTAL_FUEL_REPORT_REQUEST,
  GET_VEHICLE_TYPE_REQUEST,
  GET_VEHICLES_FILTER_REQUEST,
  GET_VEHICLES_HEADER_REQUEST,
  UPDATE_VEHICLE_REQUEST,
  DELETE_VEHICLE_REQUEST,
  UPDATE_VEHICLE_FORM_MAP_REQUEST,
  GET_ENERGY_ENUM_REQUEST,
  CHECK_VEHICLE_DATA_IMPORT_REQUEST
} from '../constants/ActionTypes';

export const getAllDataToAddVehicle = () => {
  return { type: GET_ALL_DATA_TO_ADD_VEHICLE_REQUEST };
};
export const getAllDataToUpdateVehicle = ({ vehicleId }) => {
  return {
    type: GET_ALL_DATA_TO_UPDATE_VEHICLE_REQUEST,
    payload: { vehicleId }
  };
};
export const getAllVehicles = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  packageIsActive,
  calibrationType,
  offset,
  limit,
  pattern,
  lightModel
}) => {
  return {
    type: GET_ALL_VEHICLES_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      packageIsActive,
      calibrationType,
      offset,
      limit,
      pattern,
      lightModel
    }
  };
};

export const getVehiclesFilter = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds,
  offset,
  limit,
  pattern
}) => {
  return {
    type: GET_VEHICLES_FILTER_REQUEST,
    payload: {
      customerId,
      vehicleGroupIds,
      siteIds,
      vehicleIds,
      offset,
      limit,
      pattern
    }
  };
};

export const getVehiclesHeader = ({
  customerId,
  vehicleGroupId,
  vehicleId,
  offset,
  limit,
  pattern
}) => {
  return {
    type: GET_VEHICLES_HEADER_REQUEST,
    payload: { customerId, vehicleGroupId, vehicleId, offset, limit, pattern }
  };
};

export const getSingleVehicle = vehicleId => {
  return {
    type: GET_SINGLE_VEHICLE_REQUEST,
    payload: vehicleId
  };
};

export const getPreSelectedVehiclesBarFilter = ({ customerId, vehicleIds }) => {
  return {
    type: GET_PRESELECTED_VEHICLES_BARFILTER_REQUEST,
    payload: { customerId, vehicleIds }
  };
};

export const addVehicle = vehicle => {
  return {
    type: ADD_VEHICLE_REQUEST,
    payload: vehicle
  };
};

export const updateVehicle = vehicle => {
  return {
    type: UPDATE_VEHICLE_REQUEST,
    payload: vehicle
  };
};

export const deleteVehicle = vehicleId => {
  return {
    type: DELETE_VEHICLE_REQUEST,
    payload: vehicleId
  };
};

export const updateVehicleFormMap = vehicle => {
  return {
    type: UPDATE_VEHICLE_FORM_MAP_REQUEST,
    payload: vehicle
  };
};

export const getVehicleBrand = () => {
  return {
    type: GET_VEHICLE_BRAND_REQUEST
  };
};

export const getVehicleModel = brand => {
  return {
    type: GET_VEHICLE_MODEL_REQUEST,
    payload: brand
  };
};

export const getVehicleType = () => {
  return {
    type: GET_VEHICLE_TYPE_REQUEST
  };
};

export const getTachyType = () => {
  return {
    type: GET_TACHY_TYPE_REQUEST
  };
};

export const getRDLType = () => {
  return {
    type: GET_RDL_TYPE_REQUEST
  };
};

export const getVehicleCategory = () => {
  return {
    type: GET_VEHICLE_CATEGORY_REQUEST
  };
};

export const getEngineDetectionType = () => {
  return {
    type: GET_ENGINE_DETECTION_TYPE_REQUEST
  };
};

export const getEnumCalibration = () => {
  return {
    type: GET_ENUM_CALIB_REQUEST
  };
};

export const getVehicleStatus = () => {
  return {
    type: GET_VEHICLE_STATUS_REQUEST
  };
};

// export const getAllVehicleSitesFilter = ({
//   customerId,
//   offset,
//   limit,
//   pattern
// }) => {
//   return {
//     type: GET_ALL_VEHICLE_SITES_FILTER_REQUEST,
//     payload: { customerId, offset, limit, pattern }
//   };
// };

export const getVehicleGroupsFilter = ({
  customerId,
  vehicleGroupType,
  offset,
  limit,
  pattern
}) => {
  return {
    type: GET_VEHICLE_GROUPS_FILTER_REQUEST,
    payload: { customerId, vehicleGroupType, offset, limit, pattern }
  };
};

export const getVehicleGroupTypes = ({}) => {
  return {
    type: GET_VEHICLE_GROUP_TYPES_REQUEST,
    payload: {}
  };
};

export const getVehicleFuelReport = ({ vehicleId, startDate, endDate }) => {
  return {
    type: GET_VEHICLE_FUEL_REPORT_REQUEST,
    payload: { vehicleId, startDate, endDate }
  };
};

export const getVehicleFuelReportByDriver = ({
  driverId,
  startDate,
  endDate
}) => {
  return {
    type: GET_VEHICLE_FUEL_REPORT_BY_DRIVER_REQUEST,
    payload: { driverId, startDate, endDate }
  };
};

export const getVehicleTmavaTopReport = ({ vehicleId, startDate, endDate }) => {
  return {
    type: GET_VEHICLE_TMAVA_TOP_REPORT_REQUEST,
    payload: { vehicleId, startDate, endDate }
  };
};

export const getVehicleFuelConsumptionReport = ({
  vehicleId,
  startDate,
  endDate
}) => {
  return {
    type: GET_VEHICLE_FUEL_CONSUMPTION_REPORT_REQUEST,
    payload: { vehicleId, startDate, endDate }
  };
};

export const getVehicleFuelConsumptionReportMultiple = ({
  vehicleId,
  periods
}) => {
  return {
    type: GET_VEHICLE_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST,
    payload: { vehicleId, periods }
  };
};

export const getVehicleTotalFuelReport = ({
  vehicleId,
  startDate,
  endDate
}) => {
  return {
    type: GET_VEHICLE_TOTAL_FUEL_REPORT_REQUEST,
    payload: { vehicleId, startDate, endDate }
  };
};

export const getVehicleTotalDistanceReport = ({
  vehicleId,
  startDate,
  endDate
}) => {
  return {
    type: GET_VEHICLE_TOTAL_DISTANCE_REPORT_REQUEST,
    payload: { vehicleId, startDate, endDate }
  };
};

export const getVehicleTotalDistanceReportMultiple = ({
  vehicleId,
  periods
}) => {
  return {
    type: GET_VEHICLE_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST,
    payload: { vehicleId, periods }
  };
};

export const getVehicleTotalActivityDaysReport = ({
  vehicleId,
  startDate,
  endDate
}) => {
  return {
    type: GET_VEHICLE_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST,
    payload: { vehicleId, startDate, endDate }
  };
};

export const getVehicleActivityStatsReport = ({
  vehicleId,
  startDate,
  endDate
}) => {
  return {
    type: GET_VEHICLE_ACTIVITY_STATS_REPORT_REQUEST,
    payload: { vehicleId, startDate, endDate }
  };
};

export const getVehicleActivityStatsReportMultiple = ({
  vehicleId,
  periods
}) => {
  return {
    type: GET_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST,
    payload: { vehicleId, periods }
  };
};

export const getPtoType = () => {
  return {
    type: GET_PTO_TYPE_ENUM_REQUEST
  };
};

export const getEnergyVehicle = () => {
  return {
    type: GET_ENERGY_ENUM_REQUEST
  };
};

export const getDriverActivitiesByVehicle = ({
  vehicleId,
  startDate,
  endDate
}) => {
  return {
    type: GET_DRIVER_ACTIVITIES_BY_VEHICLE_REQUEST,
    payload: { vehicleId, startDate, endDate }
  };
};

export const checkVehicleDataImport = ({ customerId, vehicle }) => {
  return {
    type: CHECK_VEHICLE_DATA_IMPORT_REQUEST,
    payload: { customerId, vehicle }
  };
};
