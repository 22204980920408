import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  UPDATE_PREFERENCES_DASHBOARD_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  SEND_CONTACT_REQUEST,
  SEND_CONTACT_SUCCESS,
  SEND_CONTACT_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_NO_TOKEN_REQUEST,
  GET_USER_REQUEST,
  CREATE_USER_REQUEST,
  UPDATE_USER_REQUEST,
  DELETE_USER_REQUEST,
  GET_USERS_LIST_REQUEST,
  INITIAL_FETCH_ENUM_UPDATE_USER_REQUEST,
  GET_LANGUAGES_ENUM_REQUEST,
  GET_DEFAULT_PAGE_ENUM_REQUEST,
  GET_CURRENCIES_ENUM_REQUEST,
  GET_CUSTOMER_PROFILES_USER_REQUEST,
} from '../constants/ActionTypes';

export const changePassword = (payload) => {
  console.log(payload);
  return {
    type: CHANGE_PASSWORD_REQUEST,
    token: payload.token,
    newpwd: payload.newpwd,
  };
};

export const changePasswordNoToken = (infos) => {
  return {
    type: CHANGE_PASSWORD_NO_TOKEN_REQUEST,
    payload: infos,
  };
};

export const sendContact = (payload) => {
  console.log(payload);
  return {
    type: SEND_CONTACT_REQUEST,
    message: payload.message,
    email: payload.email,
  };
};

export const forgotPassword = (email) => {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    email: email,
  };
};
// export const forgotPasswordSuccess = () => {
//   return {
//     type: FORGOT_PASSWORD_SUCCESS
//   };
// };
// export const forgotPasswordFailure = () => {
//   return {
//     type: FORGOT_PASSWORD_FAILURE
//   };
// };
export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user,
  };
};
export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user,
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER,
  };
};
export const userSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser,
  };
};

export const userSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser,
  };
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  };
};

export const showAuthMessage = (message, errorType) => {
  return {
    type: SHOW_MESSAGE,
    message: message,
    errorType: errorType,
  };
};

export const userGoogleSignIn = () => {
  return {
    type: SIGNIN_GOOGLE_USER,
  };
};
export const userGoogleSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser,
  };
};
export const userFacebookSignIn = () => {
  return {
    type: SIGNIN_FACEBOOK_USER,
  };
};
export const userFacebookSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser,
  };
};
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};
export const userTwitterSignIn = () => {
  return {
    type: SIGNIN_TWITTER_USER,
  };
};
export const userTwitterSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser,
  };
};
export const userGithubSignIn = () => {
  return {
    type: SIGNIN_GITHUB_USER,
  };
};
export const userGithubSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser,
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

export const updatePreferencesDashboard = (infos) => {
  return {
    type: UPDATE_PREFERENCES_DASHBOARD_REQUEST,
    payload: infos,
  };
};

export const getUser = (userLogin) => {
  return {
    type: GET_USER_REQUEST,
    payload: userLogin,
  };
};

export const getUsersList = (customerId) => {
  return {
    type: GET_USERS_LIST_REQUEST,
    payload: customerId,
  };
};

export const getCurrenciesEnum = () => {
  return {
    type: GET_CURRENCIES_ENUM_REQUEST,
  };
};

export const getDefaultPagesEnum = () => {
  return {
    type: GET_DEFAULT_PAGE_ENUM_REQUEST,
  };
};

export const getLanguagesEnum = () => {
  return {
    type: GET_LANGUAGES_ENUM_REQUEST,
  };
};

export const getCustomerUserProfiles = (customerId) => {
  return {
    type: GET_CUSTOMER_PROFILES_USER_REQUEST,
    payload: customerId,
  };
};

export const initialFetchUpdateUser = () => {
  return {
    type: INITIAL_FETCH_ENUM_UPDATE_USER_REQUEST,
  };
};

export const updateUser = (user) => {
  return {
    type: UPDATE_USER_REQUEST,
    payload: user,
  };
};

export const createUser = (info) => {
  return {
    type: CREATE_USER_REQUEST,
    payload: info,
  };
};

export const deleteUser = (userId) => {
  return {
    type: DELETE_USER_REQUEST,
    payload: userId,
  };
};
