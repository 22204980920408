import React, { Component } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { getVehiclesHeader } from 'actions/Vehicles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  searchBarOutlined: {
    padding: '0 1rem 0 0.5rem !important',
  },
  searchBarUnderline: {
    paddingRight: `${0} !important`,
    marginBottom: '1rem',
  },
});

class GlobalVehicleSearchInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //
    };
    this.searchValue = null;
  }

  render() {
    const { classes } = this.props.classes;
    let icon = (
      <SearchIcon
        //className={classes.colorPrimaryLight}
        style={{
          width: '1.8rem',
          height: '1.8rem',
          color: this.props.theme.palette.primary.light,
        }}
      />
    );
    let loading = (
      <div className="loader-view">
        <CircularProgress size={20} />
      </div>
    );
    return (
      <Autocomplete
        options={this.props.vehicles ? this.props.vehicles : []}
        getOptionLabel={(option) => {
          console.log('======================');
          console.log(this.searchValue);
          console.log('======================');
          if (this.searchValue && this.searchValue.includes('-')) {
            return option.plate;
          } else if (this.searchValue && !this.searchValue.includes('-')) {
            return option.plateFormatted;
          } else return option.plate;
        }}
        renderOption={(option) => option.plate}
        noOptionsText={this.props.intl.formatMessage({
          id: 'common.noResults',
        })}
        loading={
          this.props.fetchingVehicle ? this.props.fetchingVehicle : false
        }
        loadingText={loading}
        multiple={!!this.props.multiple}
        onChange={(e, value) => {
          console.log(value);
          this.props.valueChanged(value);
        }}
        onKeyUp={(e) => {
          if (e.target.value && e.target.value.length > 1) {
            this.props.getVehiclesHeader({
              customerId: this.props.customer ? this.props.customer.id : null,
              limit: 30,
              pattern: e.target.value,
            });
          }
        }}
        renderInput={(params) => (
          <TextField
            name="vehicle"
            {...params}
            fullWidth
            variant={this.props.variant ? this.props.variant : null}
            placeholder={
              this.props.placeholder
                ? this.props.intl.formatMessage({
                    id: 'common.search',
                  })
                : null
            }
            InputProps={{
              ...params.InputProps,
              type: 'search',
              endAdornment: icon,
              classes: {
                root:
                  this.props.variant === 'outlined'
                    ? this.props.classes.searchBarOutlined
                    : this.props.classes.searchBarUnderline,
                //underline: classes.underline
              },
            }}
            onChange={(e) => {
              this.searchValue = e.target.value;
            }}
          />
        )}
      />
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    vehicles: state.vehicles.vehiclesHeader,
    fetchingVehicle: state.vehicles.fetchingVehicleHeader,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getVehiclesHeader: (params) => dispatch(getVehiclesHeader(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    (theme) => ({
      ...styles(theme),
    }),
    { withTheme: true }
  )(injectIntl(GlobalVehicleSearchInput))
);
