import {
  GET_ALL_DRIVERS_REQUEST,
  GET_ALL_DRIVERS_SUCCESS,
  GET_ALL_DRIVERS_FAILURE,
  GET_SINGLE_DRIVER_REQUEST,
  GET_SINGLE_DRIVER_SUCCESS,
  GET_SINGLE_DRIVER_FAILURE,
  GET_DRIVER_GROUP_REQUEST,
  GET_DRIVER_GROUP_SUCCESS,
  GET_DRIVER_GROUP_FAILURE,
  ADD_DRIVER_REQUEST,
  ADD_DRIVER_SUCCESS,
  ADD_DRIVER_FAILURE,
  UPDATE_DRIVER_REQUEST,
  UPDATE_DRIVER_SUCCESS,
  UPDATE_DRIVER_FAILURE,
  DELETE_DRIVER_REQUEST,
  DELETE_DRIVER_SUCCESS,
  DELETE_DRIVER_FAILURE,
  GET_DRIVER_TMAVA_TOP_REPORT_REQUEST,
  GET_DRIVER_TMAVA_TOP_REPORT_SUCCESS,
  GET_DRIVER_TMAVA_TOP_REPORT_FAILURE,
  GET_DRIVER_FUEL_CONSUMPTION_REPORT_REQUEST,
  GET_DRIVER_FUEL_CONSUMPTION_REPORT_SUCCESS,
  GET_DRIVER_FUEL_CONSUMPTION_REPORT_FAILURE,
  GET_DRIVER_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST,
  GET_DRIVER_FUEL_CONSUMPTION_REPORT_MULTIPLE_SUCCESS,
  GET_DRIVER_FUEL_CONSUMPTION_REPORT_MULTIPLE_FAILURE,
  GET_DRIVER_TOTAL_FUEL_REPORT_REQUEST,
  GET_DRIVER_TOTAL_FUEL_REPORT_SUCCESS,
  GET_DRIVER_TOTAL_FUEL_REPORT_FAILURE,
  GET_DRIVER_TOTAL_DISTANCE_REPORT_REQUEST,
  GET_DRIVER_TOTAL_DISTANCE_REPORT_SUCCESS,
  GET_DRIVER_TOTAL_DISTANCE_REPORT_FAILURE,
  GET_DRIVER_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST,
  GET_DRIVER_TOTAL_DISTANCE_REPORT_MULTIPLE_SUCCESS,
  GET_DRIVER_TOTAL_DISTANCE_REPORT_MULTIPLE_FAILURE,
  GET_DRIVER_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST,
  GET_DRIVER_TOTAL_ACTIVITY_DAYS_REPORT_SUCCESS,
  GET_DRIVER_TOTAL_ACTIVITY_DAYS_REPORT_FAILURE,
  GET_DRIVER_ACTIVITY_STATS_REPORT_REQUEST,
  GET_DRIVER_ACTIVITY_STATS_REPORT_SUCCESS,
  GET_DRIVER_ACTIVITY_STATS_REPORT_FAILURE,
  GET_DRIVER_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST,
  GET_DRIVER_ACTIVITY_STATS_REPORT_MULTIPLE_SUCCESS,
  GET_DRIVER_ACTIVITY_STATS_REPORT_MULTIPLE_FAILURE,
  INITIAL_FETCH_SINGLE_DRIVER_REQUEST,
  INITIAL_FETCH_SINGLE_DRIVER_SUCCESS,
  INITIAL_FETCH_SINGLE_DRIVER_FAILURE,
  GET_DRIVER_ACTIVITIES_BY_DRIVER_REQUEST,
  GET_DRIVER_ACTIVITIES_BY_DRIVER_SUCCESS,
  GET_DRIVER_ACTIVITIES_BY_DRIVER_FAILURE,
  GET_DRIVER_ACTIVITIES_REQUEST,
  GET_DRIVER_ACTIVITIES_SUCCESS,
  GET_DRIVER_ACTIVITIES_FAILURE,
  GET_PRESELECTED_DRIVER_BARFILTER_REQUEST,
  GET_PRESELECTED_DRIVERS_BARFILTER_SUCCESS,
  GET_PRESELECTED_DRIVER_BARFILTER_FAILURE,
  GET_DRIVERS_FILTER_FAILURE,
  GET_DRIVERS_FILTER_REQUEST,
  GET_DRIVERS_FILTER_SUCCESS
} from '../constants/ActionTypes';

const INIT_STATE = {
  fetching: false,
  drivers: [],
  driver: null,
  driverAdded: null,
  driverUpdated: null,
  deletedDriver: null,
  driverGroups: [],
  initialSingleDriverDone: false,
  driverActivitiesFetching: false,
  driverActivities: [],
  error: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //? GET ALL DRIVERS //
    case GET_ALL_DRIVERS_REQUEST: {
      return {
        ...state,
        fetching: true,
        isDriversNextData: action.payload.offset > 0 ? true : false,
        error: null
      };
    }
    case GET_ALL_DRIVERS_SUCCESS:
      return { ...state, fetching: false, drivers: action.driversList };
    case GET_ALL_DRIVERS_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? GET SINGLE DRIVER //
    case GET_SINGLE_DRIVER_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_SINGLE_DRIVER_SUCCESS:
      return { ...state, fetching: false, driver: action.driver };
    case GET_SINGLE_DRIVER_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? GET DRIVER GROUP FOR SINGLE CUSTOMER//
    case GET_DRIVER_GROUP_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_DRIVER_GROUP_SUCCESS:
      return { ...state, fetching: false, driverGroups: action.driverGroups };
    case GET_DRIVER_GROUP_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? UPDATE SINGLE DRIVER//
    case UPDATE_DRIVER_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case UPDATE_DRIVER_SUCCESS:
      return { ...state, fetching: false, driverUpdated: action.driverUpdated };
    case UPDATE_DRIVER_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? DELETE SINGLE DRIVER//
    case DELETE_DRIVER_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case DELETE_DRIVER_SUCCESS:
      return { ...state, fetching: false, deletedDriver: action.deletedDriver };
    case DELETE_DRIVER_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? ADD DRIVER//
    case ADD_DRIVER_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case ADD_DRIVER_SUCCESS:
      return { ...state, fetching: false, driverAdded: action.driverAdded };
    case ADD_DRIVER_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET DRIVER TMAVA TOP REPORT //
    case GET_DRIVER_TMAVA_TOP_REPORT_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_DRIVER_TMAVA_TOP_REPORT_SUCCESS:
      return {
        ...state,
        fetching: false,
        tmavaTopReport: action.tmavaTopReport
      };
    case GET_DRIVER_TMAVA_TOP_REPORT_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET DRIVER FUEL CONSUMPTION REPORT //
    case GET_DRIVER_FUEL_CONSUMPTION_REPORT_REQUEST: {
      return {
        ...state,
        fuelConsumptionReportFetching: true,
        error: null
      };
    }
    case GET_DRIVER_FUEL_CONSUMPTION_REPORT_SUCCESS:
      return {
        ...state,
        fuelConsumptionReportFetching: false,
        fuelConsumptionReport: action.fuelConsumptionReport
      };
    case GET_DRIVER_FUEL_CONSUMPTION_REPORT_FAILURE:
      return {
        ...state,
        fuelConsumptionReportFetching: false,
        error: action.error
      };

    //? GET DRIVER FUEL CONSUMPTION REPORT MULITPLE //
    case GET_DRIVER_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST: {
      return {
        ...state,
        fuelConsumptionReportMultipleFetching: true,
        error: null
      };
    }
    case GET_DRIVER_FUEL_CONSUMPTION_REPORT_MULTIPLE_SUCCESS:
      return {
        ...state,
        fuelConsumptionReportMultipleFetching: false,
        fuelConsumptionReportMultiple: action.fuelConsumptionReportMultiple
      };
    case GET_DRIVER_FUEL_CONSUMPTION_REPORT_MULTIPLE_FAILURE:
      return {
        ...state,
        fuelConsumptionReportMultipleFetching: false,
        error: action.error
      };

    //? GET DRIVER TOTAL FUEL REPORT //
    case GET_DRIVER_TOTAL_FUEL_REPORT_REQUEST: {
      return {
        ...state,
        totalFuelReportFetching: true,
        error: null
      };
    }
    case GET_DRIVER_TOTAL_FUEL_REPORT_SUCCESS:
      return {
        ...state,
        totalFuelReportFetching: false,
        totalFuelReport: action.totalFuelReport
      };
    case GET_DRIVER_TOTAL_FUEL_REPORT_FAILURE:
      return { ...state, totalFuelReportFetching: false, error: action.error };

    //? GET DRIVER TOTAL DISTANCE REPORT //
    case GET_DRIVER_TOTAL_DISTANCE_REPORT_REQUEST: {
      return {
        ...state,
        totalDistanceReportFetching: true,
        error: null
      };
    }
    case GET_DRIVER_TOTAL_DISTANCE_REPORT_SUCCESS:
      return {
        ...state,
        totalDistanceReportFetching: false,
        totalDistanceReport: action.totalDistanceReport
      };
    case GET_DRIVER_TOTAL_DISTANCE_REPORT_FAILURE:
      return {
        ...state,
        totalDistanceReportFetching: false,
        error: action.error
      };

    //? GET DRIVER TOTAL DISTANCE REPORT MULTIPLE //
    case GET_DRIVER_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST: {
      return {
        ...state,
        totalDistanceReportMultipleFetching: true,
        error: null
      };
    }
    case GET_DRIVER_TOTAL_DISTANCE_REPORT_MULTIPLE_SUCCESS:
      return {
        ...state,
        totalDistanceReportMultipleFetching: false,
        totalDistanceReportMultiple: action.totalDistanceReportMultiple
      };
    case GET_DRIVER_TOTAL_DISTANCE_REPORT_MULTIPLE_FAILURE:
      return {
        ...state,
        totalDistanceReportMultipleFetching: false,
        error: action.error
      };

    //? GET DRIVER TOTAL ACTIVITY DAYS REPORT //
    case GET_DRIVER_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST: {
      return {
        ...state,
        totalActivityDaysReportFetching: true,
        error: null
      };
    }
    case GET_DRIVER_TOTAL_ACTIVITY_DAYS_REPORT_SUCCESS:
      return {
        ...state,
        totalActivityDaysReportFetching: false,
        totalActivityDaysReport: action.totalActivityDaysReport
      };
    case GET_DRIVER_TOTAL_ACTIVITY_DAYS_REPORT_FAILURE:
      return {
        ...state,
        totalActivityDaysReportFetching: false,
        error: action.error
      };

    //? GET DRIVER ACTIVITY STATS REPORT //
    case GET_DRIVER_ACTIVITY_STATS_REPORT_REQUEST: {
      return {
        ...state,
        driverActivityStatsReportFetching: true,
        error: null
      };
    }
    case GET_DRIVER_ACTIVITY_STATS_REPORT_SUCCESS:
      return {
        ...state,
        driverActivityStatsReportFetching: false,
        driverActivityStatsReport: action.report
      };
    case GET_DRIVER_ACTIVITY_STATS_REPORT_FAILURE:
      return {
        ...state,
        driverActivityStatsReportFetching: false,
        error: action.error
      };

    //? GET DRIVER ACTIVITY STATS REPORT MULTIPLE //
    case GET_DRIVER_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST: {
      return {
        ...state,
        driverActivityStatsReportMultipleFetching: true,
        error: null
      };
    }
    case GET_DRIVER_ACTIVITY_STATS_REPORT_MULTIPLE_SUCCESS:
      return {
        ...state,
        driverActivityStatsReportMultipleFetching: false,
        driverActivityStatsReportMultiple: action.report
      };
    case GET_DRIVER_ACTIVITY_STATS_REPORT_MULTIPLE_FAILURE:
      return {
        ...state,
        driverActivityStatsReportMultipleFetching: false,
        error: action.error
      };

    //? INITIAL GET SINGLE DRIVER //
    case INITIAL_FETCH_SINGLE_DRIVER_REQUEST: {
      return {
        ...state,
        initialSingleDriverFetching: true,
        initialSingleDriverDone: false,
        error: null
      };
    }
    case INITIAL_FETCH_SINGLE_DRIVER_SUCCESS:
      return {
        ...state,
        initialSingleDriverFetching: false,
        initialSingleDriverDone: true
      };
    case INITIAL_FETCH_SINGLE_DRIVER_FAILURE:
      return {
        ...state,
        initialSingleDriverFetching: false,
        error: action.error
      };

    //? GET DRIVER ACTIVITIES BY DRIVER //
    case GET_DRIVER_ACTIVITIES_BY_DRIVER_REQUEST: {
      return {
        ...state,
        driverActivitiesFetching: true,
        error: null
      };
    }
    case GET_DRIVER_ACTIVITIES_BY_DRIVER_SUCCESS:
      return {
        ...state,
        driverActivitiesFetching: false,
        driverActivities: action.driverActivities
      };
    case GET_DRIVER_ACTIVITIES_BY_DRIVER_FAILURE:
      return {
        ...state,
        driverActivitiesFetching: false,
        error: action.error
      };

    //? GET DRIVER ACTIVITIES //
    case GET_DRIVER_ACTIVITIES_REQUEST: {
      return {
        ...state,
        driverActivitiesFetching: true,
        error: null
      };
    }
    case GET_DRIVER_ACTIVITIES_SUCCESS:
      return {
        ...state,
        driverActivitiesFetching: false,
        driverActivities: action.driverActivities
      };
    case GET_DRIVER_ACTIVITIES_FAILURE:
      return {
        ...state,
        driverActivitiesFetching: false,
        error: action.error
      };

    //? GET PRESELECTED DRIVER IN BARFILTER //
    case GET_PRESELECTED_DRIVER_BARFILTER_REQUEST: {
      return {
        ...state,
        driverFetching: true,
        error: null
      };
    }
    case GET_PRESELECTED_DRIVERS_BARFILTER_SUCCESS:
      return {
        ...state,
        driverFetching: false,
        preSelectedDriversBarFilter: action.preselectedDrivers
      };
    case GET_PRESELECTED_DRIVER_BARFILTER_FAILURE:
      return { ...state, driverFetching: false, error: action.error };

    //? GET ALL DRIVERS FILTER //
    case GET_DRIVERS_FILTER_REQUEST: {
      return {
        ...state,
        fetchingDriversInFilterBar: true,
        isDriversNextData: action.payload.offset > 0 ? true : false,
        error: null
      };
    }
    case GET_DRIVERS_FILTER_SUCCESS:
      return {
        ...state,
        fetchingDriversInFilterBar: false,
        driversFilter: action.drivers
      };
    case GET_DRIVERS_FILTER_FAILURE:
      return {
        ...state,
        fetchingDriversInFilterBar: false,
        error: action.error
      };

    default:
      return state;
  }
};
