import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import {
  GET_SIM_LIST_REQUEST,
  GET_SIM_LIST_SUCCESS,
  GET_SIM_LIST_FAILURE,
  GET_SINGLE_SIM_REQUEST,
  GET_SINGLE_SIM_SUCCESS,
  GET_SINGLE_SIM_FAILURE,
  ADD_SIM_REQUEST,
  ADD_SIM_SUCCESS,
  ADD_SIM_FAILURE,
  UPDATE_SIM_REQUEST,
  UPDATE_SIM_SUCCESS,
  UPDATE_SIM_FAILURE,
  DELETE_SIM_REQUEST,
  DELETE_SIM_SUCCESS,
  DELETE_SIM_FAILURE,
  CHECK_SIM_DATA_IMPORT_REQUEST,
  CHECK_SIM_DATA_IMPORT_SUCCESS,
  CHECK_SIM_DATA_IMPORT_FAILURE,
  GET_MOBILE_OPERATORS_REQUEST,
  GET_MOBILE_OPERATORS_SUCCESS,
  GET_MOBILE_OPERATORS_FAILURE,
  GET_PACKAGES_LIST_REQUEST,
  GET_PACKAGES_LIST_SUCCESS,
  GET_PACKAGES_LIST_FAILURE,
  GET_PACKAGE_HISTORIC_REQUEST,
  GET_PACKAGE_HISTORIC_SUCCESS,
  GET_PACKAGE_HISTORIC_FAILURE,
  GET_SINGLE_PACKAGE_REQUEST,
  GET_SINGLE_PACKAGE_SUCCESS,
  GET_SINGLE_PACKAGE_FAILURE,
  ADD_PACKAGE_REQUEST,
  ADD_PACKAGE_SUCCESS,
  ADD_PACKAGE_FAILURE,
  UPDATE_PACKAGE_REQUEST,
  UPDATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_FAILURE,
  DELETE_PACKAGE_REQUEST,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_FAILURE,
  GET_NO_ASSOCIATED_VEHICLE_REQUEST,
  GET_NO_ASSOCIATED_VEHICLE_SUCCESS,
  GET_NO_ASSOCIATED_VEHICLE_FAILURE,
  GET_NO_ASSOCIATED_DEVICE_REQUEST,
  GET_NO_ASSOCIATED_DEVICE_SUCCESS,
  GET_NO_ASSOCIATED_DEVICE_FAILURE,
  GET_NO_ASSOCIATED_SIM_REQUEST,
  GET_NO_ASSOCIATED_SIM_SUCCESS,
  GET_NO_ASSOCIATED_SIM_FAILURE,
  GET_MODEM_LIST_REQUEST,
  GET_MODEM_LIST_SUCCESS,
  GET_MODEM_LIST_FAILURE,
  GET_SINGLE_MODEM_REQUEST,
  GET_SINGLE_MODEM_SUCCESS,
  GET_SINGLE_MODEM_FAILURE,
  ADD_MODEM_REQUEST,
  ADD_MODEM_SUCCESS,
  ADD_MODEM_FAILURE,
  DELETE_DEVICE_REQUEST,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAILURE,
  UPDATE_MODEM_REQUEST,
  UPDATE_MODEM_SUCCESS,
  UPDATE_MODEM_FAILURE,
  CHECK_MODEM_DATA_IMPORT_REQUEST,
  CHECK_MODEM_DATA_IMPORT_SUCCESS,
  CHECK_MODEM_DATA_IMPORT_FAILURE,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAILURE,
  INITIAL_FETCH_PACKAGE_REQUEST,
  INITIAL_FETCH_PACKAGE_SUCCESS,
  INITIAL_FETCH_PACKAGE_FAILURE,
  GET_DEVICE_STATUS_REQUEST,
  GET_DEVICE_STATUS_SUCCESS,
  GET_DEVICE_STATUS_FAILURE,
  GET_DEVICE_TYPE_NAME_REQUEST,
  GET_DEVICE_TYPE_NAME_SUCCESS,
  GET_DEVICE_TYPE_NAME_FAILURE,
  GET_SIM_DEVICE_TYPE_REQUEST,
  GET_SIM_DEVICE_TYPE_SUCCESS,
  GET_SIM_DEVICE_TYPE_FAILURE,
  GET_PTO_TYPE_ENUM_REQUEST,
  GET_PTO_TYPE_ENUM_SUCCESS,
  GET_PTO_TYPE_ENUM_FAILURE
} from 'constants/ActionTypes';
import { showMessage } from 'actions/Global';
import { API_URL } from '../constants/globalConstants';
import axios from 'axios';

const getSimList = async token => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}sims`
  });
};

const getSingleSim = async (token, simId) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}sims/${simId}`
  });
};

const addSim = async (token, sim) => {
  sim.creation_date = parseInt(sim.creation_date);
  console.log('======SAGA======');
  console.log(sim);
  console.log('======================');
  return axios({
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: {
      id: sim.serial_number,
      msisdn: sim.line_number,
      mobileOperator: sim.operator,
      plan: sim.phone_plan,
      codePin: sim.pin_code,
      deviceType: sim.device_type,
      options: sim.options,
      dateCreate: sim.creation_date
    },
    url: `${API_URL}sims`
  });
};

const updateSim = async (token, sim) => {
  return axios({
    method: 'put',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: {
      msisdn: sim.line_number,
      mobileOperator: sim.operator,
      plan: sim.phone_plan,
      codePin: sim.pin_code,
      deviceType: sim.device_type,
      options: sim.options
    },
    url: `${API_URL}sims/${sim.id}`
  });
};

const deleteSim = async (token, simId) => {
  console.log(simId);
  return axios({
    method: 'delete',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}sims/${simId}`
  });
};

const checkSimDataImport = async (token, { sim }) => {
  let url = `${API_URL}sims/import_check`;
  console.log('url', url, {
    codePin: sim.codePin,
    deviceType: sim.deviceType,
    id: sim.id,
    mobileOperator: sim.mobileOperator,
    msisdn: sim.msisdn,
    options: sim.options,
    plan: sim.plan,
    dateCreate: sim.dateCreate
  });

  return axios({
    method: 'put',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    data: {
      codePin: sim.codePin,
      deviceType: sim.deviceType,
      id: sim.id,
      mobileOperator: sim.mobileOperator,
      msisdn: sim.msisdn,
      options: sim.options,
      plan: sim.plan,
      dateCreate: sim.dateCreate
    },
    url: url
  });
};

const getMobileOperators = async token => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}global/mobile_operators`
  });
};

const getPackageList = async (token, info) => {
  console.log('===========SAGA===========');
  console.log(info);
  console.log('======================');

  let url = `${API_URL}packages?`;
  if (info.customerId) {
    url += `customerId=${info.customerId}`;
  }
  if (info.vehicleId) {
    url += `&vehicleId=${info.vehicleId}`;
  }
  if (info.imei) {
    url += `&imei=${info.imei}`;
  }
  console.log('==========URL============');
  console.log(url);
  console.log('======================');
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

const getPackageHistoric = async (token, vehicleId) => {
  console.log('SAGA', vehicleId);
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}packages?vehicleId=${vehicleId}`
  });
};

const getSinglePackage = async (token, packageId) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}packages/${packageId}`
  });
};

const addPackage = async (token, infos) => {
  console.log(infos);
  return axios({
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: {
      packageIsActive: infos.package_is_active,
      dateActivation: infos.activation_package_date,
      dateDesactivation: null,
      deviceId: infos.associated_device.id,
      vehicleId: infos.associated_vehicle.id,
      simIccid: infos.associated_sim.id,
      buzzerConnectedDuringInstallation:
        infos.buzzer_connected_during_installation,
      buzzerEnteredInMatrix: infos.buzzer_entered_in_matrix,
      fmsConnectedDuringInstallation: infos.fms_connected_during_installation,
      fmsDetected: infos.fms_detected_automatically,
      fmsEnteredInMatrix: infos.fms_entered_in_matrix,
      detectedPto: infos.pto_detected,
      ptoEnteredInMatrix: infos.pto_entered_in_matrix,
      ptoConnectedDuringInstallation: infos.pto_connected_during_installation,
      comment: infos.pto_comment ? infos.pto_comment : ''
    },
    url: `${API_URL}packages`
  });
};

const updatePackage = async (token, infos) => {
  console.log('updatePackage', {
    packageIsActive: infos.package_is_active,
    dateActivation: infos.activation_package_date,
    dateDesactivation: infos.deactivation_package_date,
    deviceId: infos.associated_device.id,
    vehicleId: infos.associated_vehicle.id,
    simIccid: infos.associated_sim.id,
    buzzerConnectedDuringInstallation:
      infos.buzzer_connected_during_installation,
    buzzerEnteredInMatrix: infos.buzzer_entered_in_matrix,
    fmsConnectedDuringInstallation: infos.fms_connected_during_installation,
    fmsDetected: infos.fms_detected_automatically,
    fmsEnteredInMatrix: infos.fms_entered_in_matrix,
    detectedPto: infos.pto_detected,
    ptoEnteredInMatrix: infos.pto_entered_in_matrix,
    ptoConnectedDuringInstallation: infos.pto_connected_during_installation,
    comment: infos.pto_comment ? infos.pto_comment : ''
  });
  return axios({
    method: 'put',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: {
      packageIsActive: infos.package_is_active,
      dateActivation: infos.activation_package_date,
      dateDesactivation: infos.deactivation_package_date,
      deviceId: infos.associated_device.id,
      vehicleId: infos.associated_vehicle.id,
      simIccid: infos.associated_sim.id,
      buzzerConnectedDuringInstallation:
        infos.buzzer_connected_during_installation,
      buzzerEnteredInMatrix: infos.buzzer_entered_in_matrix,
      fmsConnectedDuringInstallation: infos.fms_connected_during_installation,
      fmsDetected: infos.fms_detected_automatically,
      fmsEnteredInMatrix: infos.fms_entered_in_matrix,
      detectedPto: infos.pto_detected,
      ptoEnteredInMatrix: infos.pto_entered_in_matrix,
      ptoConnectedDuringInstallation: infos.pto_connected_during_installation,
      comment: infos.pto_comment ? infos.pto_comment : ''
    },
    url: `${API_URL}packages/${infos.id}`
  });
};

const deletePackage = async (token, packageId) => {
  console.log(packageId);
  return axios({
    method: 'delete',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}packages/${packageId}`
  });
};

const getNoAssociatedVehicles = async (token, customerId) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}vehicles/not_associated?customerId=${customerId}`
  });
};

const getNoAssociatedDevices = async (token, options) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}devices/not_associated`
  });
};

const getNoAssociatedSims = async (token, options) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}sims/not_associated`
  });
};

const getModemList = async token => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}devices`
  });
};

const getSingleModem = async (token, modemId) => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}devices/${modemId}`
  });
};

const addModem = async (token, modem) => {
  console.log('======SAGA=======');
  console.log(modem);
  console.log('======================');
  modem.date_delivery = parseInt(modem.date_delivery);
  return axios({
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: {
      status: 'ACTIVE',
      dateDelivery: modem.date_delivery,
      versionAgx: modem.version_agx,
      versionDevice: modem.version_device,
      deviceTypeName: modem.device_type_name,
      comment: modem.comment,
      id: modem.id
    },
    url: `${API_URL}devices`
  });
};

const updateModem = async (token, modem) => {
  return axios({
    method: 'put',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: {
      status: 'ACTIVE',
      dateDelivery: modem.date_of_delivery,
      versionAgx: modem.version_agx,
      versionDevice: modem.version_device,
      deviceTypeName: modem.device_type_name,
      comment: modem.comment
    },
    url: `${API_URL}devices/${modem.id}`
  });
};

const deleteModem = async (token, deviceId) => {
  console.log(deviceId);
  return axios({
    method: 'delete',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}devices/${deviceId}`
  });
};

const checkModemDataImport = async (token, { modem }) => {
  let url = `${API_URL}devices/import_check`;
  console.log('url', url, {
    dateDelivery: modem.dateDelivery,
    deviceTypeName: modem.deviceTypeName,
    id: modem.id,
    versionAgx: modem.versionAgx,
    versionDevice: modem.versionDevice,
    comment: modem.comment
  });

  return axios({
    method: 'put',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    data: {
      dateDelivery: modem.dateDelivery,
      deviceTypeName: modem.deviceTypeName,
      id: modem.id,
      versionAgx: modem.versionAgx,
      versionDevice: modem.versionDevice,
      comment: modem.comment
    },
    url: url
  });
};

const getDevicesStatusList = async token => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}global/device_status`
  });
};

const getDevicesTypeNameList = async token => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}global/device_type_name`
  });
};

const getSimDeviceTypeEnum = async token => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}global/sim_device_type_name`
  });
};

//////! GET SIM LIST //////////
export function* watcherGetSimList() {
  yield takeLatest(GET_SIM_LIST_REQUEST, workerGetSimList);
}

function* workerGetSimList() {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getSimList, token);
    const simList = response.data;

    yield put({ type: GET_SIM_LIST_SUCCESS, simList });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_SIM_LIST_FAILURE, error });
  }
}

//////! GET SINGLE SIM //////////
export function* watcherGetSingleSim() {
  yield takeLatest(GET_SINGLE_SIM_REQUEST, workerGetSingleSim);
}

function* workerGetSingleSim(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getSingleSim, token, action.payload);
    const singleSim = response.data;

    yield put({ type: GET_SINGLE_SIM_SUCCESS, singleSim });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_SINGLE_SIM_FAILURE, error });
  }
}

//////! ADD SIM //////////
export function* watcherAddSim() {
  yield takeLatest(ADD_SIM_REQUEST, workerAddSim);
}

function* workerAddSim(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(addSim, token, action.payload);
    const simAdded = response.data;

    yield put({ type: ADD_SIM_SUCCESS, simAdded });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Sim created !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center'
      })
    );
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ADD_SIM_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center'
      })
    );
  }
}

//////! UPDATE SIM //////////
export function* watcherUpdateSim() {
  yield takeLatest(UPDATE_SIM_REQUEST, workerUpdateSim);
}

function* workerUpdateSim(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(updateSim, token, action.payload);
    const simUpdated = response.data;

    yield put({ type: UPDATE_SIM_SUCCESS, simUpdated });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Sim updated !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center'
      })
    );
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: UPDATE_SIM_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center'
      })
    );
  }
}

//////! DELETE SIM //////////
export function* watcherDeleteSim() {
  yield takeLatest(DELETE_SIM_REQUEST, workerDeleteSim);
}

function* workerDeleteSim(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(deleteSim, token, action.payload);
    const deletedSim = action.payload;

    yield put({ type: DELETE_SIM_SUCCESS, deletedSim });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Sim deleted !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center'
      })
    );
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: DELETE_SIM_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center'
      })
    );
  }
}

//////! CHECK SIM DATE EXPORT //////////
export function* watcherCheckSimDataImport() {
  yield takeLatest(CHECK_SIM_DATA_IMPORT_REQUEST, workerCheckSimDataImport);
}

function* workerCheckSimDataImport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(checkSimDataImport, token, action.payload);
    const checkSimData = response.data;

    // dispatch a success action
    yield put({
      type: CHECK_SIM_DATA_IMPORT_SUCCESS,
      checkSimData
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: CHECK_SIM_DATA_IMPORT_FAILURE, error });
  }
}

//////! GET MOBILE OPERATORS //////////
export function* watchergetMobileOperators() {
  yield takeLatest(GET_MOBILE_OPERATORS_REQUEST, workerGetMobileOperators);
}

function* workerGetMobileOperators(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getMobileOperators, token, action.payload);
    const mobileOperators = response.data;

    yield put({ type: GET_MOBILE_OPERATORS_SUCCESS, mobileOperators });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_MOBILE_OPERATORS_FAILURE, error });
  }
}

//////! GET PACKAGES LIST //////////
export function* watcherGetPackageList() {
  yield takeLatest(GET_PACKAGES_LIST_REQUEST, workerGetPackageList);
}

function* workerGetPackageList(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getPackageList, token, action.payload);
    const packageList = response.data;

    yield put({ type: GET_PACKAGES_LIST_SUCCESS, packageList });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_PACKAGES_LIST_FAILURE, error });
  }
}

//////! GET SINGLE VEHICLE PACKAGE HISTORIC //////////
export function* watcherGetPackageHistoric() {
  yield takeLatest(GET_PACKAGE_HISTORIC_REQUEST, workerGetPackageHistoric);
}

function* workerGetPackageHistoric(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getPackageHistoric, token, action.payload);
    const historicPackageList = response.data;

    yield put({ type: GET_PACKAGE_HISTORIC_SUCCESS, historicPackageList });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_PACKAGE_HISTORIC_FAILURE, error });
  }
}

//////! GET SINGLE PACKAGE //////////
export function* watcherGetSinglePackage() {
  yield takeLatest(GET_SINGLE_PACKAGE_REQUEST, workerGetSinglePackage);
}

function* workerGetSinglePackage(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getSinglePackage, token, action.payload);
    const singlePackage = response.data;

    yield put({ type: GET_SINGLE_PACKAGE_SUCCESS, singlePackage });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_SINGLE_PACKAGE_FAILURE, error });
  }
}

//////! ADD PACKAGE //////////
export function* watcherAddPackage() {
  yield takeLatest(ADD_PACKAGE_REQUEST, workerAddPackage);
}

function* workerAddPackage(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(addPackage, token, action.payload);
    const packageAdded = response.data;

    yield put({ type: ADD_PACKAGE_SUCCESS, packageAdded });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Package created !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center'
      })
    );
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ADD_PACKAGE_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center'
      })
    );
  }
}

//////! UPDATE PACKAGE //////////
export function* watcherUpdatePackage() {
  yield takeLatest(UPDATE_PACKAGE_REQUEST, workerUpdatePackage);
}

function* workerUpdatePackage(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(updatePackage, token, action.payload);
    const packageUpdated = response.data;

    yield put({ type: UPDATE_PACKAGE_SUCCESS, packageUpdated });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Package updated !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center'
      })
    );
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: UPDATE_PACKAGE_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center'
      })
    );
  }
}

//////! DELETE PACKAGE //////////
export function* watcherDeletePackage() {
  yield takeLatest(DELETE_PACKAGE_REQUEST, workerDeletePackage);
}

function* workerDeletePackage(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(deletePackage, token, action.payload);
    const deletedPackage = action.payload;

    yield put({ type: DELETE_PACKAGE_SUCCESS, deletedPackage });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Package deleted !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center'
      })
    );
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: DELETE_PACKAGE_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 2000,
        position: 'bottom-center'
      })
    );
  }
}

//////! GET MODEM LIST //////////
export function* watcherGetModemList() {
  yield takeLatest(GET_MODEM_LIST_REQUEST, workerGetModemList);
}

function* workerGetModemList(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getModemList, token, action.payload);
    const modemList = response.data;

    yield put({ type: GET_MODEM_LIST_SUCCESS, modemList });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_MODEM_LIST_FAILURE, error });
  }
}

//////! GET SINGLE MODEM //////////
export function* watcherGetSingleModem() {
  yield takeLatest(GET_SINGLE_MODEM_REQUEST, workerGetSingleModem);
}

function* workerGetSingleModem(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getSingleModem, token, action.payload);
    const singleModem = response.data;

    yield put({ type: GET_SINGLE_MODEM_SUCCESS, singleModem });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_SINGLE_MODEM_FAILURE, error });
  }
}

//////! ADD MODEM //////////
export function* watcherAddModem() {
  yield takeLatest(ADD_MODEM_REQUEST, workerAddModem);
}

function* workerAddModem(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(addModem, token, action.payload);
    console.log(response);
    const modemAdded = response.data;
    console.log(modemAdded);

    yield put({ type: ADD_MODEM_SUCCESS, modemAdded });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Modem created !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center'
      })
    );
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ADD_MODEM_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center'
      })
    );
  }
}

//////! UPDATE MODEM //////////
export function* watcherUpdateModem() {
  yield takeLatest(UPDATE_MODEM_REQUEST, workerUpdateModem);
}

function* workerUpdateModem(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(updateModem, token, action.payload);
    const modemUpdated = response.data;

    yield put({ type: UPDATE_MODEM_SUCCESS, modemUpdated });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Modem updated !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center'
      })
    );
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: UPDATE_MODEM_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center'
      })
    );
  }
}

//////! DELETE MODEM //////////
export function* watcherDeleteModem() {
  yield takeLatest(DELETE_DEVICE_REQUEST, workerDeleteModem);
}

function* workerDeleteModem(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(deleteModem, token, action.payload);
    const deletedDevice = action.payload;

    yield put({ type: DELETE_DEVICE_SUCCESS, deletedDevice });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Modem deleted !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center'
      })
    );
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: DELETE_DEVICE_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center'
      })
    );
  }
}

//////! CHECK MODEM DATE EXPORT //////////
export function* watcherCheckModemDataImport() {
  yield takeLatest(CHECK_MODEM_DATA_IMPORT_REQUEST, workerCheckModemDataImport);
}

function* workerCheckModemDataImport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(checkModemDataImport, token, action.payload);
    const checkModemData = response.data;

    // dispatch a success action
    yield put({
      type: CHECK_MODEM_DATA_IMPORT_SUCCESS,
      checkModemData
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: CHECK_MODEM_DATA_IMPORT_FAILURE, error });
  }
}

//////! GET NO ASSOCIATED VEHICLE //////////

export function* watcherGetNoAssociatedVehicles() {
  yield takeLatest(
    GET_NO_ASSOCIATED_VEHICLE_REQUEST,
    workerGetNoAssociatedVehicles
  );
}

function* workerGetNoAssociatedVehicles(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getNoAssociatedVehicles, token, action.payload);
    const notAssociatedVehicles = response.data;

    yield put({
      type: GET_NO_ASSOCIATED_VEHICLE_SUCCESS,
      notAssociatedVehicles
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_NO_ASSOCIATED_VEHICLE_FAILURE, error });
  }
}

//////! GET NO ASSOCIATED DEVICE //////////

export function* watcherGetNoAssociatedDevices() {
  yield takeLatest(
    GET_NO_ASSOCIATED_DEVICE_REQUEST,
    workerGetNoAssociatedDevices
  );
}

function* workerGetNoAssociatedDevices(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getNoAssociatedDevices, token, action.payload);
    const notAssociatedDevices = response.data;

    yield put({
      type: GET_NO_ASSOCIATED_DEVICE_SUCCESS,
      notAssociatedDevices
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_NO_ASSOCIATED_DEVICE_FAILURE, error });
  }
}

//////! GET NO ASSOCIATED SIM //////////

export function* watcherGetNoAssociatedSims() {
  yield takeLatest(GET_NO_ASSOCIATED_SIM_REQUEST, workerGetNoAssociatedSims);
}

function* workerGetNoAssociatedSims(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getNoAssociatedSims, token, action.payload);
    const notAssociatedSims = response.data;

    yield put({
      type: GET_NO_ASSOCIATED_SIM_SUCCESS,
      notAssociatedSims
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_NO_ASSOCIATED_SIM_FAILURE, error });
  }
}

//////! INITIAL FETCH PACKAGE //////////

export function* watcherInitialFetchPackage() {
  yield takeLatest(INITIAL_FETCH_PACKAGE_REQUEST, workerInitialFetchPackage);
}

export function* workerInitialFetchPackage(action) {
  console.log('workerInitialFetchPackage');
  console.log('==========HELLO WORLD============');
  console.log(action.payload);
  console.log('======================');
  yield all([
    put({
      type: GET_CUSTOMERS_REQUEST,
      payload: { offset: action.payload.offset, limit: action.payload.limit }
    }),
    put({
      type: GET_SINGLE_PACKAGE_REQUEST,
      payload: action.payload.packageId
    }),
    action.payload.packageId
      ? put({
          type: GET_SINGLE_PACKAGE_REQUEST,
          payload: action.payload.packageId
        })
      : null,
    //put({ type: GET_NO_ASSOCIATED_DEVICE_REQUEST }),
    put({ type: GET_NO_ASSOCIATED_SIM_REQUEST }),
    put({ type: GET_PTO_TYPE_ENUM_REQUEST })
  ]);
  const response = yield all([
    take([GET_CUSTOMERS_SUCCESS, GET_CUSTOMERS_FAILURE]),
    action.payload.packageId
      ? take([GET_SINGLE_PACKAGE_SUCCESS, GET_SINGLE_PACKAGE_FAILURE])
      : null,
    //take([GET_NO_ASSOCIATED_DEVICE_SUCCESS, GET_NO_ASSOCIATED_DEVICE_FAILURE]),
    take([GET_NO_ASSOCIATED_SIM_SUCCESS, GET_NO_ASSOCIATED_SIM_FAILURE]),
    take([GET_PTO_TYPE_ENUM_SUCCESS, GET_PTO_TYPE_ENUM_FAILURE])
  ]);
  console.log('all response perform');
  if (response != null) {
    yield put({ type: INITIAL_FETCH_PACKAGE_SUCCESS });
  }
}

//////! GET DEVICE STATUS //////////
export function* watcherGetDeviceStatus() {
  yield takeLatest(GET_DEVICE_STATUS_REQUEST, workerGetDeviceStatus);
}

function* workerGetDeviceStatus(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getDevicesStatusList, token, action.payload);
    const deviceStatus = response.data;

    yield put({ type: GET_DEVICE_STATUS_SUCCESS, deviceStatus });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DEVICE_STATUS_FAILURE, error });
  }
}

//////! GET DEVICE TYPE NAME //////////
export function* watcherGetDeviceTypeName() {
  yield takeLatest(GET_DEVICE_TYPE_NAME_REQUEST, workerGetDeviceTypeName);
}

function* workerGetDeviceTypeName(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getDevicesTypeNameList, token, action.payload);
    const deviceTypeName = response.data;

    yield put({ type: GET_DEVICE_TYPE_NAME_SUCCESS, deviceTypeName });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DEVICE_TYPE_NAME_FAILURE, error });
  }
}

//////! GET SIM DEVICE TYPE  //////////
export function* watcherGetSimDeviceTypeEnum() {
  yield takeLatest(GET_SIM_DEVICE_TYPE_REQUEST, workerGetSimDeviceTypeEnum);
}

function* workerGetSimDeviceTypeEnum(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getSimDeviceTypeEnum, token, action.payload);
    const simDeviceType = response.data;

    yield put({ type: GET_SIM_DEVICE_TYPE_SUCCESS, simDeviceType });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_SIM_DEVICE_TYPE_FAILURE, error });
  }
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetSimList),
    fork(watcherGetSingleSim),
    fork(watcherAddSim),
    fork(watcherUpdateSim),
    fork(watcherDeleteSim),
    fork(watcherCheckSimDataImport),
    fork(watchergetMobileOperators),
    fork(watcherGetPackageList),
    fork(watcherGetPackageHistoric),
    fork(watcherGetModemList),
    fork(watcherGetSingleModem),
    fork(watcherAddModem),
    fork(watcherUpdateModem),
    fork(watcherDeleteModem),
    fork(watcherCheckModemDataImport),
    fork(watcherGetNoAssociatedVehicles),
    fork(watcherGetNoAssociatedDevices),
    fork(watcherGetNoAssociatedSims),
    fork(watcherInitialFetchPackage),
    fork(watcherGetDeviceStatus),
    fork(watcherGetDeviceTypeName),
    fork(watcherAddPackage),
    fork(watcherUpdatePackage),
    fork(watcherDeletePackage),
    fork(watcherGetSinglePackage),
    fork(watcherGetSimDeviceTypeEnum)
  ]);
}
