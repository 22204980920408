import React, { Component } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Area,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
//import data from './data-linechart';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100
  }
];
const Aux = props => props.children;

class CustomizedDot extends React.Component {
  render() {
    const { cx, cy, stroke, payload, value } = this.props;
    const width = 4; //payload.start && payload.end ? (payload.end - payload.start)/1000/60 : 1;
    const height = this.props.heightLine ? this.props.heightLine : 10;

    if (this.props.value) {
      return (
        <rect
          width={width}
          height={height}
          x={cx - width / 2}
          y={cy - height / 2}
          fill={this.props.stroke}
        />
      );
    } else return null;
  }
}

const noLine = () => ({
  lineStart() {},
  lineEnd() {},
  point(x, y) {}
});

class SimpleTimelineChart extends Component {
  render() {
    return (
      <ResponsiveContainer
        width="100%"
        height={this.props.height ? this.props.height : '100%'}>
        <LineChart
          syncId={this.props.syncId}
          data={this.props.data ? this.props.data : data}>
          <defs>
            <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
              <stop offset="15%" stopColor="#76E6AE" stopOpacity={0.95} />
              <stop offset="100%" stopColor="#76E6AE" stopOpacity={0.3} />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
              <stop offset="15%" stopColor="#67DDFE" stopOpacity={0.95} />
              <stop offset="100%" stopColor="#67DDFE" stopOpacity={0.3} />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id="color3" x1="0" y1="0" x2="0" y2="1">
              <stop offset="15%" stopColor="#FFC81F" stopOpacity={0.95} />
              <stop offset="100%" stopColor="#FFC81F" stopOpacity={0.3} />
            </linearGradient>
          </defs>

          {this.props.customGradiantColors
            ? this.props.customGradiantColors.map(customGradiantColor => {
                return (
                  <defs>
                    <linearGradient
                      id={customGradiantColor.substr(1)}
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1">
                      <stop
                        offset="15%"
                        stopColor={customGradiantColor}
                        stopOpacity={0.95}
                      />
                      <stop
                        offset="100%"
                        stopColor={customGradiantColor}
                        stopOpacity={0.3}
                      />
                    </linearGradient>
                  </defs>
                );
              })
            : null}

          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={false} />
          <YAxis
            tickFormatter={
              this.props.formatYAxis ? this.props.formatYAxis : null
            }
            onClick={this.props.YAxisOnClick}
            tick={{ fontSize: 12 }}
            width={this.props.YAxisWidth ? this.props.YAxisWidth : 60}
            type={'category'}
          />
          {this.props.customTooTip ? (
            <Tooltip
              wrapperStyle={{ zIndex: 1000 }}
              content={this.props.customTooTip}
            />
          ) : (
            <Tooltip />
          )}
          {this.props.dataKeys
            ? this.props.dataKeys.map((dataKey, index) => {
                if (dataKey instanceof Object) {
                  return (
                    <Line
                      activeDot={false}
                      legendType="none"
                      dot={<CustomizedDot heightLine={dataKey.heightLine} />}
                      type={noLine}
                      stroke={dataKey.color}
                      heightLine={dataKey.heightLine}
                      isAnimationActive={false}
                      key={dataKey.key + index}
                      dataKey={dataKey.key}
                    />
                  );
                } else
                  return (
                    <Line
                      type="monotone"
                      key={dataKey + index}
                      dataKey={dataKey}
                      stroke="#FFC81F"
                      fill="url(#color3)"
                    />
                  );
              })
            : null}

          {this.props.legend ? (
            this.props.verticalLegend ? (
              <Legend
                iconType="square"
                layout="vertical"
                align="right"
                verticalAlign="middle"
              />
            ) : (
              <Legend
                wrapperStyle={{
                  paddingTop: '10px'
                }}
                iconType="square"
                layout="horizontal"
                align="center"
                verticalAlign="bottom"
              />
            )
          ) : null}
        </LineChart>
      </ResponsiveContainer>
    );
  }
}

export default SimpleTimelineChart;
