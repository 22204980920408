import {
  WEBSOCKET_10SEC_DATA,
  WEBSOCKET_CONNECTED,
  WEBSOCKET_NEW_ACTIVITY_DATA,
  WEBSOCKET_NEW_ADDRESS,
  WEBSOCKET_NEW_ALERT_DATA,
  WEBSOCKET_NEW_DATA,
  WEBSOCKET_NEW_DRIVER_DATA,
  WEBSOCKET_NEW_LOCATION,
  WEBSOCKET_SET_TOKEN_FAILURE,
  WEBSOCKET_SET_TOKEN_REQUEST,
  WEBSOCKET_SET_TOKEN_SUCCESS,
  WEBSOCKET_SUBSCRIBE_EVENTS_FAILURE,
  WEBSOCKET_SUBSCRIBE_EVENTS_REQUEST,
  WEBSOCKET_SUBSCRIBE_EVENTS_SUCCESS,
  WEBSOCKET_UNSUBSCRIBE_EVENTS_FAILURE,
  WEBSOCKET_UNSUBSCRIBE_EVENTS_REQUEST,
  WEBSOCKET_UNSUBSCRIBE_EVENTS_SUCCESS
} from '../constants/ActionTypes';

const INIT_STATE = {
  fetching: false,
  error: null,
  typeNewData: null,
  vehicleIdNewData: null,
  currentWebsocketLocationData: [],
  currentWebsocketAddressData: [],
  currentWebsocketVehicleData: [],
  currentWebsocketDriverActivityData: [],
  currentWebsocketVehicleActivityData: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //? SET TOKEN //
    case WEBSOCKET_SET_TOKEN_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case WEBSOCKET_SET_TOKEN_SUCCESS:
      return { ...state, fetching: false, usersenticated: true };
    case WEBSOCKET_SET_TOKEN_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? SUBSCRIBE EVENTS //
    case WEBSOCKET_SUBSCRIBE_EVENTS_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case WEBSOCKET_SUBSCRIBE_EVENTS_SUCCESS:
      return { ...state, fetching: false, subscribeEvents: true };
    case WEBSOCKET_SUBSCRIBE_EVENTS_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? UNSUBSCRIBE EVENTS //
    case WEBSOCKET_UNSUBSCRIBE_EVENTS_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case WEBSOCKET_UNSUBSCRIBE_EVENTS_SUCCESS:
      return { ...state, fetching: false, subscribeEvents: false };
    case WEBSOCKET_UNSUBSCRIBE_EVENTS_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? NEW CONNECTED EVENT //
    case WEBSOCKET_CONNECTED:
      return { ...state, fetching: false, connected: true };

    //? NEW LOCATION EVENT //
    case WEBSOCKET_NEW_LOCATION:
      return {
        ...state,
        fetching: false,
        location: action.location,
        typeNewData: 'LOCATION',
        vehicleIdNewData: action.location.vehicle
          ? action.location.vehicle.id
          : null
      };

    //? NEW ADDRESS EVENT //
    case WEBSOCKET_NEW_ADDRESS:
      return {
        ...state,
        fetching: false,
        address: action.address,
        typeNewData: 'ADDRESS',
        vehicleIdNewData: action.address.vehicle
          ? action.address.vehicle.id
          : null
      };

    //? NEW DATA EVENT //
    case WEBSOCKET_NEW_DATA:
      return {
        ...state,
        fetching: false,
        data: action.data,
        typeNewData: 'DATA',
        vehicleIdNewData: action.data.vehicle ? action.data.vehicle.id : null
      };

    //? NEW DRIVER DATA EVENT //
    case WEBSOCKET_NEW_DRIVER_DATA:
      return {
        ...state,
        fetching: false,
        data: action.data,
        typeNewData: 'DRIVER_DATA',
        vehicleIdNewData: action.data.vehicle ? action.data.vehicle.id : null
      };

    //? NEW ACTIVITY DATA EVENT //
    case WEBSOCKET_NEW_ACTIVITY_DATA:
      return {
        ...state,
        fetching: false,
        data: action.data,
        typeNewData: 'ACTIVITY_DATA',
        vehicleIdNewData: action.data.vehicle ? action.data.vehicle.id : null
      };

    //? NEW ALERT DATA EVENT //
    case WEBSOCKET_NEW_ALERT_DATA:
      return {
        ...state,
        fetching: false,
        data: action.data,
        typeNewData: 'ALERT_DATA',
        vehicleIdNewData: action.data.alert
          ? action.data.alert.vehicle.id
          : null
      };

    //? SAVE DATA EVENT //
    case WEBSOCKET_10SEC_DATA:
      return {
        ...state,
        currentWebsocketLocationData: action.currentWebsocketLocationData,
        currentWebsocketAddressData: action.currentWebsocketAddressData,
        currentWebsocketVehicleData: action.currentWebsocketVehicleData,
        currentWebsocketDriverActivityData:
          action.currentWebsocketDriverActivityData,
        currentWebsocketVehicleActivityData:
          action.currentWebsocketVehicleActivityData,
        currentWebsocketAlertData: action.currentWebsocketAlertData
      };

    default:
      return state;
  }
};
