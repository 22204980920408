import {
  GET_AGSUP_ADA_CUSTOMERS_DASHBOARD_REPORT_FAILURE,
  GET_AGSUP_ADA_CUSTOMERS_DASHBOARD_REPORT_REQUEST,
  GET_AGSUP_ADA_CUSTOMERS_DASHBOARD_REPORT_SUCCESS,
  GET_AGSUP_ADA_CUSTOMER_TREE_REQUEST,
  GET_AGSUP_ADA_CUSTOMER_TREE_SUCCESS,
  GET_AGSUP_ADA_CUSTOMER_TREE_FAILURE,
  GET_AGSUP_GENERAL_DASHBOARD_REPORT_FAILURE,
  GET_AGSUP_GENERAL_DASHBOARD_REPORT_REQUEST,
  GET_AGSUP_GENERAL_DASHBOARD_REPORT_SUCCESS,
  GET_AGSUP_GENERAL_DASHBOARD_SITES_FAILURE,
  GET_AGSUP_GENERAL_DASHBOARD_SITES_REQUEST,
  GET_AGSUP_GENERAL_DASHBOARD_SITES_SUCCESS,
  GET_AGSUP_DASHBOARD_SINGLE_SITE_REQUEST,
  GET_AGSUP_DASHBOARD_SINGLE_SITE_SUCCESS,
  GET_AGSUP_DASHBOARD_SINGLE_SITE_FAILURE,
  GET_CUSTOMER_DRIVER_ACTIVITY_STATS_REPORT_FAILURE,
  GET_CUSTOMER_DRIVER_ACTIVITY_STATS_REPORT_REQUEST,
  GET_CUSTOMER_DRIVER_ACTIVITY_STATS_REPORT_SUCCESS,
  GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_FAILURE,
  GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_FAILURE,
  GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST,
  GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_SUCCESS,
  GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_REQUEST,
  GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_SUCCESS,
  GET_FUEL_CONSUMPTION_REPORT_FAILURE,
  GET_FUEL_CONSUMPTION_REPORT_MULTIPLE_FAILURE,
  GET_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST,
  GET_FUEL_CONSUMPTION_REPORT_MULTIPLE_SUCCESS,
  GET_FUEL_CONSUMPTION_REPORT_REQUEST,
  GET_FUEL_CONSUMPTION_REPORT_SUCCESS,
  GET_PERMANENT_STOCK_RATE_REPORT_FAILURE,
  GET_PERMANENT_STOCK_RATE_REPORT_REQUEST,
  GET_PERMANENT_STOCK_RATE_REPORT_SUCCESS,
  GET_REFUEL_VEHICLES_REPORT_FAILURE,
  GET_REFUEL_VEHICLES_REPORT_REQUEST,
  GET_REFUEL_VEHICLES_REPORT_SUCCESS,
  GET_THEFT_VEHICLES_REPORT_FAILURE,
  GET_THEFT_VEHICLES_REPORT_REQUEST,
  GET_THEFT_VEHICLES_REPORT_SUCCESS,
  GET_THEFT_REPORT_FAILURE,
  GET_THEFT_REPORT_REQUEST,
  GET_THEFT_REPORT_SUCCESS,
  GET_TMAVA_REPARTITION_REPORT_FAILURE,
  GET_TMAVA_REPARTITION_REPORT_REQUEST,
  GET_TMAVA_REPARTITION_REPORT_SUCCESS,
  GET_TOP_DRIVER_TMAVA_REPORT_FAILURE,
  GET_TOP_DRIVER_TMAVA_REPORT_REQUEST,
  GET_TOP_DRIVER_TMAVA_REPORT_SUCCESS,
  GET_TOP_LOWEST_BATTERY_VEHICLES_REPORT_FAILURE,
  GET_TOP_LOWEST_BATTERY_VEHICLES_REPORT_REQUEST,
  GET_TOP_LOWEST_BATTERY_VEHICLES_REPORT_SUCCESS,
  GET_TOP_LOWEST_FUEL_VEHICLES_REPORT_FAILURE,
  GET_TOP_LOWEST_FUEL_VEHICLES_REPORT_REQUEST,
  GET_TOP_LOWEST_FUEL_VEHICLES_REPORT_SUCCESS,
  GET_TOP_OVERSPEED_VEHICLES_REPORT_FAILURE,
  GET_TOP_OVERSPEED_VEHICLES_REPORT_REQUEST,
  GET_TOP_OVERSPEED_VEHICLES_REPORT_SUCCESS,
  GET_TOP_VEHICLE_TMAVA_REPORT_FAILURE,
  GET_TOP_VEHICLE_TMAVA_REPORT_REQUEST,
  GET_TOP_VEHICLE_TMAVA_REPORT_SUCCESS,
  GET_TOTAL_ACTIVITY_DAYS_REPORT_FAILURE,
  GET_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST,
  GET_TOTAL_ACTIVITY_DAYS_REPORT_SUCCESS,
  GET_TOTAL_DISTANCE_REPORT_FAILURE,
  GET_TOTAL_DISTANCE_REPORT_MULTIPLE_FAILURE,
  GET_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST,
  GET_TOTAL_DISTANCE_REPORT_MULTIPLE_SUCCESS,
  GET_TOTAL_DISTANCE_REPORT_REQUEST,
  GET_TOTAL_DISTANCE_REPORT_SUCCESS,
  GET_TOTAL_FUEL_REPORT_FAILURE,
  GET_TOTAL_FUEL_REPORT_REQUEST,
  GET_TOTAL_FUEL_REPORT_SUCCESS,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_MULTIPLE_FAILURE,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_MULTIPLE_REQUEST,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_MULTIPLE_SUCCESS,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_FAILURE,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REQUEST,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_SUCCESS,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REFERENCE_FAILURE,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REFERENCE_REQUEST,
  GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REFERENCE_SUCCESS,
  GET_VEHICLE_DAILY_REPORT_FAILURE,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_DASHBOARD_TREND_FAILURE,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_DASHBOARD_TREND_REQUEST,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_DASHBOARD_TREND_SUCCESS,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_FAILURE,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_FOR_CUMULATIVE_TMAVA_FAILURE,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_FOR_CUMULATIVE_TMAVA_REQUEST,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_FOR_CUMULATIVE_TMAVA_SUCCESS,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_REQUEST,
  GET_VEHICLE_DAILY_REPORT_MULTIPLE_SUCCESS,
  GET_VEHICLE_DAILY_REPORT_REQUEST,
  GET_VEHICLE_DAILY_REPORT_SUCCESS,
  GET_VEHICLE_LIVE_REPORT_FAILURE,
  GET_VEHICLE_LIVE_REPORT_REQUEST,
  GET_VEHICLE_LIVE_REPORT_SUCCESS,
  GET_DRIVER_DAILY_REPORT_MULTIPLE_FAILURE,
  GET_DRIVER_DAILY_REPORT_MULTIPLE_REQUEST,
  GET_DRIVER_DAILY_REPORT_MULTIPLE_SUCCESS,
  GET_DRIVER_DAILY_REPORT_FAILURE,
  GET_DRIVER_DAILY_REPORT_REQUEST,
  GET_DRIVER_DAILY_REPORT_SUCCESS,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_FAILURE,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_REQUEST,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_SUCCESS,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_REFERENCE_FAILURE,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_REFERENCE_REQUEST,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_REFERENCE_SUCCESS,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_MULTIPLE_FAILURE,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_MULTIPLE_REQUEST,
  GET_DRIVER_DAILY_REPORT_BY_DRIVER_MULTIPLE_SUCCESS,
  GET_VEQ_TMAVA_STATS_REPORT_FAILURE,
  GET_VEQ_TMAVA_STATS_REPORT_REQUEST,
  GET_VEQ_TMAVA_STATS_REPORT_SUCCESS,
  GET_STABILITY_POINTS_REQUEST,
  GET_STABILITY_POINTS_SUCCESS,
  GET_STABILITY_POINTS_FAILURE,
  GET_TOP_FLOP_REPORT_FAILURE,
  GET_TOP_FLOP_REPORT_REQUEST,
  GET_TOP_FLOP_REPORT_SUCCESS,
  GET_REFUEL_OPTIMIZATION_REPORT_FAILURE,
  GET_REFUEL_OPTIMIZATION_REPORT_REQUEST,
  GET_REFUEL_OPTIMIZATION_REPORT_SUCCESS,
  GET_REFUEL_OPTIMIZATION_BY_VEHICLE_REPORT_FAILURE,
  GET_REFUEL_OPTIMIZATION_BY_VEHICLE_REPORT_REQUEST,
  GET_REFUEL_OPTIMIZATION_BY_VEHICLE_REPORT_SUCCESS,
  GET_ZONE_STAT_REPORT_FAILURE,
  GET_ZONE_STAT_REPORT_REQUEST,
  GET_ZONE_STAT_REPORT_SUCCESS,
  GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_FAILURE,
  GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_REQUEST,
  GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_SUCCESS,
  GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_MULTIPLE_FAILURE,
  GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_MULTIPLE_REQUEST,
  GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_MULTIPLE_SUCCESS
} from '../constants/ActionTypes';

const INIT_STATE = {
  fuelConsumptionReportFetching: false,
  fuelConsumptionReport: null,
  fuelConsumptionReportMultipleFetching: false,
  fuelConsumptionReportMultiple: null,
  totalFuelReportFetching: false,
  totalFuelReport: null,
  totalDistanceReportFetching: false,
  totalDistanceReport: null,
  totalDistanceReportMultipleFetching: false,
  totalDistanceReportMultiple: null,
  totalActivityDaysReport: null,
  totalActivityDaysReportFetching: false,
  vehicleActivityStatsReport: null,
  vehicleActivityStatsReportFetching: false,
  vehicleActivityStatsReportMultiple: null,
  vehicleActivityStatsReportMultipleFetching: false,
  driverActivityStatsReport: null,
  driverActivityStatsReportFetching: false,
  permanentStockRateReport: null,
  permanentStockRateReportFetching: false,
  topLowestFuelVehiclesReport: null,
  topLowestFuelVehiclesReportFetching: false,
  topLowestBatteryVehiclesReport: null,
  topLowestBatteryVehiclesReportFetching: false,
  topOverspeedVehiclesReport: null,
  topOverspeedVehiclesReportFetching: false,
  vehicleDailyReport: null,
  vehicleDailyReportFetching: false,
  vehicleDailyReportMultiple: null,
  vehicleDailyReportMultipleFetching: false,
  vehicleDailyReportMultipleDashboardTrend: null,
  vehicleDailyReportMultipleDashboardTrendFetching: null,
  vehicleDailyReportMultipleForCumulativeTMAVA: null,
  vehicleDailyReportMultipleForCumulativeTMAVAFetching: null,
  topVehicleTMAVAReport: null,
  topVehicleTMAVAReportFetching: null,
  topDriverTMAVAReport: null,
  topDriverTMAVAReportFetching: null,
  tmavaRepartitionReport: null,
  tmavaRepartitionReportFetching: null,
  vehicleLiveReport: null,
  vehicleLiveReportFetching: null,
  generalDashboardFetching: null,
  generalDashboardReport: null,
  generalDashboardSites: null,
  generalDashboardVehiclesFetching: null,
  generalDashboardVehicles: null,

  adaAllCustomersFetching: null,
  adaAllCustomersReport: null,
  adaCustomerTreeFetching: null,
  adaCustomerTree: null,
  refuelVehiclesReport: null,
  refuelVehiclesReportFetching: null,
  theftVehiclesReport: null,
  theftVehiclesReportFetching: null,

  driverDailyReport: null,
  driverDailyReportFetching: false,
  driverDailyReportMultiple: null,
  driverDailyReportMultipleFetching: false,
  driverDailyReportByDriver: null,
  driverDailyReportByDriverFetching: false,
  driverDailyReportByDriverMultiple: null,
  driverDailyReportByDriverMultipleFetching: false,
  veqTmavaStatsReport: null,
  veqTmavaStatsReportFetching: false,

  stabilityPointsFetching: false,
  stabilityPoints: null,

  topFlopReportFetching: false,
  topFlopReport: null,

  refuelOptimizationReportFetching: false,
  refuelOptimizationReport: null,

  refuelOptimizationByVehicleReportFetching: false,
  refuelOptimizationByVehicleReport: null,

  zoneStatReportFetching: false,
  zoneStatReport: null,

  averageInitialTankVolumePercentRefuelReportFetching: false,
  averageInitialTankVolumePercentRefuelReport: null,

  averageInitialTankVolumePercentRefuelReportMultipleFetching: false,
  averageInitialTankVolumePercentRefuelReportMultiple: null,

  error: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // ? GET FUEL CONSUMPTION REPORT //
    case GET_FUEL_CONSUMPTION_REPORT_REQUEST: {
      return {
        ...state,
        fuelConsumptionReportFetching: true,
        error: null
      };
    }
    case GET_FUEL_CONSUMPTION_REPORT_SUCCESS:
      return {
        ...state,
        fuelConsumptionReportFetching: false,
        fuelConsumptionReport: action.report
      };
    case GET_FUEL_CONSUMPTION_REPORT_FAILURE:
      return {
        ...state,
        fuelConsumptionReportFetching: false,
        error: action.error
      };

    // ? GET FUEL CONSUMPTION REPORT MULITPLE //
    case GET_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST: {
      return {
        ...state,
        fuelConsumptionReportMultipleFetching: true,
        error: null
      };
    }
    case GET_FUEL_CONSUMPTION_REPORT_MULTIPLE_SUCCESS:
      return {
        ...state,
        fuelConsumptionReportMultipleFetching: false,
        fuelConsumptionReportMultiple: action.report
      };
    case GET_FUEL_CONSUMPTION_REPORT_MULTIPLE_FAILURE:
      return {
        ...state,
        fuelConsumptionReportMultipleFetching: false,
        error: action.error
      };

    // ? GET TOTAL FUEL REPORT //
    case GET_TOTAL_FUEL_REPORT_REQUEST: {
      return {
        ...state,
        totalFuelReportFetching: true,
        error: null
      };
    }
    case GET_TOTAL_FUEL_REPORT_SUCCESS:
      return {
        ...state,
        totalFuelReportFetching: false,
        totalFuelReport: action.report
      };
    case GET_TOTAL_FUEL_REPORT_FAILURE:
      return { ...state, totalFuelReportFetching: false, error: action.error };

    // ? GET TOTAL DISTANCE REPORT //
    case GET_TOTAL_DISTANCE_REPORT_REQUEST: {
      return {
        ...state,
        totalDistanceReportFetching: true,
        error: null
      };
    }
    case GET_TOTAL_DISTANCE_REPORT_SUCCESS:
      return {
        ...state,
        totalDistanceReportFetching: false,
        totalDistanceReport: action.report
      };
    case GET_TOTAL_DISTANCE_REPORT_FAILURE:
      return {
        ...state,
        totalDistanceReportFetching: false,
        error: action.error
      };

    // ? GET TOTAL DISTANCE REPORT MULTIPLE //
    case GET_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST: {
      return {
        ...state,
        totalDistanceReportMultipleFetching: true,
        error: null
      };
    }
    case GET_TOTAL_DISTANCE_REPORT_MULTIPLE_SUCCESS:
      return {
        ...state,
        totalDistanceReportMultipleFetching: false,
        totalDistanceReportMultiple: action.report
      };
    case GET_TOTAL_DISTANCE_REPORT_MULTIPLE_FAILURE:
      return {
        ...state,
        totalDistanceReportMultipleFetching: false,
        error: action.error
      };

    // ? GET TOTAL ACTIVITY DAYS REPORT //
    case GET_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST: {
      return {
        ...state,
        totalActivityDaysReportFetching: true,
        error: null
      };
    }
    case GET_TOTAL_ACTIVITY_DAYS_REPORT_SUCCESS:
      return {
        ...state,
        totalActivityDaysReportFetching: false,
        totalActivityDaysReport: action.report
      };
    case GET_TOTAL_ACTIVITY_DAYS_REPORT_FAILURE:
      return {
        ...state,
        totalActivityDaysReportFetching: false,
        error: action.error
      };

    // ? GET VEHICLE ACTIVITY STATS REPORT //
    case GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_REQUEST: {
      return {
        ...state,
        vehicleActivityStatsReportFetching: true,
        error: null
      };
    }
    case GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_SUCCESS:
      return {
        ...state,
        vehicleActivityStatsReportFetching: false,
        vehicleActivityStatsReport: action.report
      };
    case GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_FAILURE:
      return {
        ...state,
        vehicleActivityStatsReportFetching: false,
        error: action.error
      };

    // ? GET VEHICLE ACTIVITY STATS REPORT MULTIPLE //
    case GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST: {
      return {
        ...state,
        vehicleActivityStatsReportMultipleFetching: true,
        error: null
      };
    }
    case GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_SUCCESS:
      return {
        ...state,
        vehicleActivityStatsReportMultipleFetching: false,
        vehicleActivityStatsReportMultiple: action.report
      };
    case GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_FAILURE:
      return {
        ...state,
        vehicleActivityStatsReportMultipleFetching: false,
        error: action.error
      };

    // ? GET DRIVER ACTIVITY STATS REPORT //
    case GET_CUSTOMER_DRIVER_ACTIVITY_STATS_REPORT_REQUEST: {
      return {
        ...state,
        driverActivityStatsReportFetching: true,
        error: null
      };
    }
    case GET_CUSTOMER_DRIVER_ACTIVITY_STATS_REPORT_SUCCESS:
      return {
        ...state,
        driverActivityStatsReportFetching: false,
        driverActivityStatsReport: action.report
      };
    case GET_CUSTOMER_DRIVER_ACTIVITY_STATS_REPORT_FAILURE:
      return {
        ...state,
        driverActivityStatsReportFetching: false,
        error: action.error
      };

    // ? GET PERMANENT STOCK RATE REPORT //
    case GET_PERMANENT_STOCK_RATE_REPORT_REQUEST: {
      return {
        ...state,
        permanentStockRateReportFetching: true,
        error: null
      };
    }
    case GET_PERMANENT_STOCK_RATE_REPORT_SUCCESS:
      return {
        ...state,
        permanentStockRateReportFetching: false,
        permanentStockRateReport: action.report
      };
    case GET_PERMANENT_STOCK_RATE_REPORT_FAILURE:
      return {
        ...state,
        permanentStockRateReportFetching: false,
        error: action.error
      };

    // ? GET TOP LOWEST FUEL VEHICLES REPORT //
    case GET_TOP_LOWEST_FUEL_VEHICLES_REPORT_REQUEST: {
      return {
        ...state,
        topLowestFuelVehiclesReportFetching: true,
        error: null
      };
    }
    case GET_TOP_LOWEST_FUEL_VEHICLES_REPORT_SUCCESS:
      return {
        ...state,
        topLowestFuelVehiclesReportFetching: false,
        topLowestFuelVehiclesReport: action.report
      };
    case GET_TOP_LOWEST_FUEL_VEHICLES_REPORT_FAILURE:
      return {
        ...state,
        topLowestFuelVehiclesReportFetching: false,
        error: action.error
      };

    // ? GET TOP LOWEST BATTERY VEHICLES REPORT //
    case GET_TOP_LOWEST_BATTERY_VEHICLES_REPORT_REQUEST: {
      return {
        ...state,
        topLowestBatteryVehiclesReportFetching: true,
        error: null
      };
    }
    case GET_TOP_LOWEST_BATTERY_VEHICLES_REPORT_SUCCESS:
      return {
        ...state,
        topLowestBatteryVehiclesReportFetching: false,
        topLowestBatteryVehiclesReport: action.report
      };
    case GET_TOP_LOWEST_BATTERY_VEHICLES_REPORT_FAILURE:
      return {
        ...state,
        topLowestBatteryVehiclesReportFetching: false,
        error: action.error
      };

    // ? GET TOP OVERSPEED VEHICLES REPORT //
    case GET_TOP_OVERSPEED_VEHICLES_REPORT_REQUEST: {
      return {
        ...state,
        topOverspeedVehiclesReportFetching: true,
        error: null
      };
    }
    case GET_TOP_OVERSPEED_VEHICLES_REPORT_SUCCESS:
      return {
        ...state,
        topOverspeedVehiclesReportFetching: false,
        topOverspeedVehiclesReport: action.report
      };
    case GET_TOP_OVERSPEED_VEHICLES_REPORT_FAILURE:
      return {
        ...state,
        topOverspeedVehiclesReportFetching: false,
        error: action.error
      };

    // ? GET VEHICLE DAILY REPORT //
    case GET_VEHICLE_DAILY_REPORT_REQUEST: {
      return {
        ...state,
        vehicleDailyReportFetching: true,
        error: null
      };
    }
    case GET_VEHICLE_DAILY_REPORT_SUCCESS:
      return {
        ...state,
        vehicleDailyReportFetching: false,
        vehicleDailyReport: action.report
      };
    case GET_VEHICLE_DAILY_REPORT_FAILURE:
      return {
        ...state,
        vehicleDailyReportFetching: false,
        error: action.error
      };

    // ? GET VEHICLE DAILY REPORT MULTIPLE //
    case GET_VEHICLE_DAILY_REPORT_MULTIPLE_REQUEST: {
      return {
        ...state,
        vehicleDailyReportMultipleFetching: true,
        error: null
      };
    }
    case GET_VEHICLE_DAILY_REPORT_MULTIPLE_SUCCESS:
      return {
        ...state,
        vehicleDailyReportMultipleFetching: false,
        vehicleDailyReportMultiple: action.report
      };
    case GET_VEHICLE_DAILY_REPORT_MULTIPLE_FAILURE:
      return {
        ...state,
        vehicleDailyReportMultipleFetching: false,
        error: action.error
      };

    // ? GET VEHICLE DAILY REPORT MULTIPLE DASHBOARD TREND //
    case GET_VEHICLE_DAILY_REPORT_MULTIPLE_DASHBOARD_TREND_REQUEST: {
      return {
        ...state,
        vehicleDailyReportMultipleDashboardTrendFetching: true,
        error: null
      };
    }
    case GET_VEHICLE_DAILY_REPORT_MULTIPLE_DASHBOARD_TREND_SUCCESS:
      return {
        ...state,
        vehicleDailyReportMultipleDashboardTrendFetching: false,
        vehicleDailyReportMultipleDashboardTrend: action.report
      };
    case GET_VEHICLE_DAILY_REPORT_MULTIPLE_DASHBOARD_TREND_FAILURE:
      return {
        ...state,
        vehicleDailyReportMultipleDashboardTrendFetching: false,
        error: action.error
      };

    // ? GET VEHICLE DAILY REPORT MULTIPLE CUMULATIVE TMAVA //
    case GET_VEHICLE_DAILY_REPORT_MULTIPLE_FOR_CUMULATIVE_TMAVA_REQUEST: {
      return {
        ...state,
        vehicleDailyReportMultipleForCumulativeTMAVAFetching: true,
        error: null
      };
    }
    case GET_VEHICLE_DAILY_REPORT_MULTIPLE_FOR_CUMULATIVE_TMAVA_SUCCESS:
      return {
        ...state,
        vehicleDailyReportMultipleForCumulativeTMAVAFetching: false,
        vehicleDailyReportMultipleForCumulativeTMAVA: action.report
      };
    case GET_VEHICLE_DAILY_REPORT_MULTIPLE_FOR_CUMULATIVE_TMAVA_FAILURE:
      return {
        ...state,
        vehicleDailyReportMultipleForCumulativeTMAVAFetching: false,
        error: action.error
      };

    // ? GET TOP VEHICLE TMAVA REPORT //
    case GET_TOP_VEHICLE_TMAVA_REPORT_REQUEST: {
      return {
        ...state,
        topVehicleTMAVAReportFetching: true,
        error: null
      };
    }
    case GET_TOP_VEHICLE_TMAVA_REPORT_SUCCESS:
      return {
        ...state,
        topVehicleTMAVAReportFetching: false,
        topVehicleTMAVAReport: action.report
      };
    case GET_TOP_VEHICLE_TMAVA_REPORT_FAILURE:
      return {
        ...state,
        topVehicleTMAVAReportFetching: false,
        error: action.error
      };

    // ? GET TOP DRIVER TMAVA REPORT //
    case GET_TOP_DRIVER_TMAVA_REPORT_REQUEST: {
      return {
        ...state,
        topDriverTMAVAReportFetching: true,
        error: null
      };
    }
    case GET_TOP_DRIVER_TMAVA_REPORT_SUCCESS:
      return {
        ...state,
        topDriverTMAVAReportFetching: false,
        topDriverTMAVAReport: action.report
      };
    case GET_TOP_DRIVER_TMAVA_REPORT_FAILURE:
      return {
        ...state,
        topDriverTMAVAReportFetching: false,
        error: action.error
      };

    // ? GET TMAVA REPARTITION REPORT //
    case GET_TMAVA_REPARTITION_REPORT_REQUEST: {
      return {
        ...state,
        tmavaRepartitionReportFetching: true,
        error: null
      };
    }
    case GET_TMAVA_REPARTITION_REPORT_SUCCESS:
      return {
        ...state,
        tmavaRepartitionReportFetching: false,
        tmavaRepartitionReport: action.report
      };
    case GET_TMAVA_REPARTITION_REPORT_FAILURE:
      return {
        ...state,
        tmavaRepartitionReportFetching: false,
        error: action.error
      };

    // ? GET VEHICLE DAILY REPORT BY VEHICLE //
    case GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REQUEST: {
      return {
        ...state,
        vehicleDailyReportByVehicleFetching: true,
        error: null
      };
    }
    case GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicleDailyReportByVehicleFetching: false,
        vehicleDailyReportByVehicle: action.report
      };
    case GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_FAILURE:
      return {
        ...state,
        vehicleDailyReportByVehicleFetching: false,
        error: action.error
      };

    // ? GET VEHICLE DAILY REPORT BY VEHICLE REFERENCE //
    case GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REFERENCE_REQUEST: {
      return {
        ...state,
        vehicleDailyReportByVehicleReferenceFetching: true,
        error: null
      };
    }
    case GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REFERENCE_SUCCESS:
      return {
        ...state,
        vehicleDailyReportByVehicleReferenceFetching: false,
        vehicleDailyReportByVehicleReference: action.report
      };
    case GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REFERENCE_FAILURE:
      return {
        ...state,
        vehicleDailyReportByVehicleReferenceFetching: false,
        error: action.error
      };

    // ? GET VEHICLE DAILY REPORT BY VEHICLE MULTIPLE //
    case GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_MULTIPLE_REQUEST: {
      return {
        ...state,
        vehicleDailyReportByVehicleMultipleFetching: true,
        error: null
      };
    }
    case GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_MULTIPLE_SUCCESS:
      return {
        ...state,
        vehicleDailyReportByVehicleMultipleFetching: false,
        vehicleDailyReportByVehicleMultiple: action.report
      };
    case GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_MULTIPLE_FAILURE:
      return {
        ...state,
        vehicleDailyReportByVehicleMultipleFetching: false,
        error: action.error
      };

    // ? GET VEHICLE LIVE REPORT //
    case GET_VEHICLE_LIVE_REPORT_REQUEST: {
      return {
        ...state,
        vehicleLiveReportFetching: true,
        error: null
      };
    }
    case GET_VEHICLE_LIVE_REPORT_SUCCESS:
      return {
        ...state,
        vehicleLiveReportFetching: false,
        vehicleLiveReport: action.report
      };
    case GET_VEHICLE_LIVE_REPORT_FAILURE:
      return {
        ...state,
        vehicleLiveReportFetching: false,
        error: action.error
      };

    // ? GET AGSUP GENERAL DASHBOARD REPORT //
    case GET_AGSUP_GENERAL_DASHBOARD_REPORT_REQUEST: {
      return {
        ...state,
        generalDashboardFetching: true,
        error: null
      };
    }
    case GET_AGSUP_GENERAL_DASHBOARD_REPORT_SUCCESS:
      return {
        ...state,
        generalDashboardFetching: false,
        generalDashboardReport: action.report
      };
    case GET_AGSUP_GENERAL_DASHBOARD_REPORT_FAILURE:
      return {
        ...state,
        generalDashboardFetching: false,
        error: action.error
      };

    // ? GET AGSUP GENERAL DASHBOARD SITES FOR ONE CUSTOMER//
    case GET_AGSUP_GENERAL_DASHBOARD_SITES_REQUEST: {
      return {
        ...state,
        generalDashboardSitesFetching: true,
        error: null
      };
    }
    case GET_AGSUP_GENERAL_DASHBOARD_SITES_SUCCESS:
      return {
        ...state,
        generalDashboardSitesFetching: false,
        generalDashboardSites: action.sites
      };
    case GET_AGSUP_GENERAL_DASHBOARD_SITES_FAILURE:
      return {
        ...state,
        generalDashboardSitesFetching: false,
        error: action.error
      };

    //? GET AGSUP DASHBOARD VEHICLES FOR ONE SITE (GROUP) //
    case GET_AGSUP_DASHBOARD_SINGLE_SITE_REQUEST: {
      return {
        ...state,
        generalDashboardVehiclesFetching: true,
        error: null
      };
    }
    case GET_AGSUP_DASHBOARD_SINGLE_SITE_SUCCESS:
      return {
        ...state,
        generalDashboardVehiclesFetching: false,
        generalDashboardVehicles: action.vehiclesCount
      };
    case GET_AGSUP_DASHBOARD_SINGLE_SITE_FAILURE:
      return {
        ...state,
        generalDashboardVehiclesFetching: false,
        error: action.error
      };

    // ? GET AGSUP ADA ALL DASHBOARD REPORT //
    case GET_AGSUP_ADA_CUSTOMERS_DASHBOARD_REPORT_REQUEST: {
      return {
        ...state,
        adaAllCustomersFetching: true,
        error: null
      };
    }
    case GET_AGSUP_ADA_CUSTOMERS_DASHBOARD_REPORT_SUCCESS:
      return {
        ...state,
        adaAllCustomersFetching: false,
        adaAllCustomersReport: action.report
      };
    case GET_AGSUP_ADA_CUSTOMERS_DASHBOARD_REPORT_FAILURE:
      return {
        ...state,
        adaAllCustomersFetching: false
      };

    // ? GET AGSUP ADA DASHBOARD CUSTOMER TREE //
    case GET_AGSUP_ADA_CUSTOMER_TREE_REQUEST: {
      return {
        ...state,
        adaCustomerTreeFetching: true,
        error: null
      };
    }
    case GET_AGSUP_ADA_CUSTOMER_TREE_SUCCESS:
      return {
        ...state,
        adaCustomerTreeFetching: false,
        adaCustomerTree: action.customerTree
      };
    case GET_AGSUP_ADA_CUSTOMER_TREE_FAILURE:
      return {
        ...state,
        adaCustomerTreeFetching: false,
        error: action.error
      };

    // ? GET REFUEL VEHICLES REPORT //
    case GET_REFUEL_VEHICLES_REPORT_REQUEST: {
      return {
        ...state,
        refuelVehiclesReportFetching: true,
        error: null
      };
    }
    case GET_REFUEL_VEHICLES_REPORT_SUCCESS:
      return {
        ...state,
        refuelVehiclesReportFetching: false,
        refuelVehiclesReport: action.report
      };
    case GET_REFUEL_VEHICLES_REPORT_FAILURE:
      return {
        ...state,
        refuelVehiclesReportFetching: false,
        error: action.error
      };

    // ? GET THEFT VEHICLES REPORT //
    case GET_THEFT_VEHICLES_REPORT_REQUEST: {
      return {
        ...state,
        theftVehiclesReportFetching: true,
        error: null
      };
    }
    case GET_THEFT_VEHICLES_REPORT_SUCCESS:
      return {
        ...state,
        theftVehiclesReportFetching: false,
        theftVehiclesReport: action.report
      };
    case GET_THEFT_VEHICLES_REPORT_FAILURE:
      return {
        ...state,
        theftVehiclesReportFetching: false,
        error: action.error
      };

    // ? GET THEFT REPORT //
    case GET_THEFT_REPORT_REQUEST: {
      return {
        ...state,
        theftReportFetching: true,
        error: null
      };
    }
    case GET_THEFT_REPORT_SUCCESS:
      return {
        ...state,
        theftReportFetching: false,
        theftReport: action.report
      };
    case GET_THEFT_REPORT_FAILURE:
      return {
        ...state,
        theftReportFetching: false,
        error: action.error
      };

    // ? GET DRIVER DAILY REPORT //
    case GET_DRIVER_DAILY_REPORT_REQUEST: {
      return {
        ...state,
        driverDailyReportFetching: true,
        error: null
      };
    }
    case GET_DRIVER_DAILY_REPORT_SUCCESS:
      return {
        ...state,
        driverDailyReportFetching: false,
        driverDailyReport: action.report
      };
    case GET_DRIVER_DAILY_REPORT_FAILURE:
      return {
        ...state,
        driverDailyReportFetching: false,
        error: action.error
      };

    // ? GET DRIVER DAILY REPORT MULTIPLE //
    case GET_DRIVER_DAILY_REPORT_MULTIPLE_REQUEST: {
      return {
        ...state,
        driverDailyReportMultipleFetching: true,
        error: null
      };
    }
    case GET_DRIVER_DAILY_REPORT_MULTIPLE_SUCCESS:
      return {
        ...state,
        driverDailyReportMultipleFetching: false,
        driverDailyReportMultiple: action.report
      };
    case GET_DRIVER_DAILY_REPORT_MULTIPLE_FAILURE:
      return {
        ...state,
        driverDailyReportMultipleFetching: false,
        error: action.error
      };

    // ? GET DRIVER DAILY REPORT BY DRIVER //
    case GET_DRIVER_DAILY_REPORT_BY_DRIVER_REQUEST: {
      return {
        ...state,
        driverDailyReportByDriverFetching: true,
        error: null
      };
    }
    case GET_DRIVER_DAILY_REPORT_BY_DRIVER_SUCCESS:
      return {
        ...state,
        driverDailyReportByDriverFetching: false,
        driverDailyReportByDriver: action.report
      };
    case GET_DRIVER_DAILY_REPORT_BY_DRIVER_FAILURE:
      return {
        ...state,
        driverDailyReportByDriverFetching: false,
        error: action.error
      };

    // ? GET DRIVER DAILY REPORT BY DRIVER REFERENCE //
    case GET_DRIVER_DAILY_REPORT_BY_DRIVER_REFERENCE_REQUEST: {
      return {
        ...state,
        driverDailyReportByDriverReferenceFetching: true,
        error: null
      };
    }
    case GET_DRIVER_DAILY_REPORT_BY_DRIVER_REFERENCE_SUCCESS:
      return {
        ...state,
        driverDailyReportByDriverReferenceFetching: false,
        driverDailyReportByDriverReference: action.report
      };
    case GET_DRIVER_DAILY_REPORT_BY_DRIVER_REFERENCE_FAILURE:
      return {
        ...state,
        driverDailyReportByDriverReferenceFetching: false,
        error: action.error
      };

    // ? GET DRIVER DAILY REPORT BY DRIVER MULTIPLE //
    case GET_DRIVER_DAILY_REPORT_BY_DRIVER_MULTIPLE_REQUEST: {
      return {
        ...state,
        driverDailyReportByDriverMultipleFetching: true,
        error: null
      };
    }
    case GET_DRIVER_DAILY_REPORT_BY_DRIVER_MULTIPLE_SUCCESS:
      return {
        ...state,
        driverDailyReportByDriverMultipleFetching: false,
        driverDailyReportByDriverMultiple: action.report
      };
    case GET_DRIVER_DAILY_REPORT_BY_DRIVER_MULTIPLE_FAILURE:
      return {
        ...state,
        driverDailyReportByDriverMultipleFetching: false,
        error: action.error
      };

    // ? GET VEQ TMAVA STATS REPORT //
    case GET_VEQ_TMAVA_STATS_REPORT_REQUEST: {
      return {
        ...state,
        veqTmavaStatsReportFetching: true,
        error: null
      };
    }
    case GET_VEQ_TMAVA_STATS_REPORT_SUCCESS:
      return {
        ...state,
        veqTmavaStatsReportFetching: false,
        veqTmavaStatsReport: action.report
      };
    case GET_VEQ_TMAVA_STATS_REPORT_FAILURE:
      return {
        ...state,
        veqTmavaStatsReportFetching: false,
        error: action.error
      };

    // ? GET STABILITY POINTS FOR A VEHICLE ON SELECTED PERIOD //
    case GET_STABILITY_POINTS_REQUEST: {
      return {
        ...state,
        stabilityPointsFetching: true,
        error: null
      };
    }
    case GET_STABILITY_POINTS_SUCCESS:
      return {
        ...state,
        stabilityPointsFetching: false,
        stabilityPoints: action.stabilityPoints
      };
    case GET_STABILITY_POINTS_FAILURE:
      return {
        ...state,
        stabilityPointsFetching: false,
        error: action.error
      };

    // ? GET TOP FLOP REPORT //
    case GET_TOP_FLOP_REPORT_REQUEST: {
      return {
        ...state,
        topFlopReportFetching: true,
        error: null
      };
    }
    case GET_TOP_FLOP_REPORT_SUCCESS:
      return {
        ...state,
        topFlopReportFetching: false,
        topFlopReport: action.topFlopReport
      };
    case GET_TOP_FLOP_REPORT_FAILURE:
      return {
        ...state,
        topFlopReportFetching: false,
        error: action.error
      };

    // ? GET REFUEL OPTIMIZATION REPORT //
    case GET_REFUEL_OPTIMIZATION_REPORT_REQUEST: {
      return {
        ...state,
        refuelOptimizationReportFetching: true,
        error: null
      };
    }
    case GET_REFUEL_OPTIMIZATION_REPORT_SUCCESS:
      return {
        ...state,
        refuelOptimizationReportFetching: false,
        refuelOptimizationReport: action.refuelOptimizationReport
      };
    case GET_REFUEL_OPTIMIZATION_REPORT_FAILURE:
      return {
        ...state,
        refuelOptimizationReportFetching: false,
        error: action.error
      };

    // ? GET REFUEL OPTIMIZATION BY VEHICLE REPORT //
    case GET_REFUEL_OPTIMIZATION_BY_VEHICLE_REPORT_REQUEST: {
      return {
        ...state,
        refuelOptimizationByVehicleReportFetching: true,
        error: null
      };
    }
    case GET_REFUEL_OPTIMIZATION_BY_VEHICLE_REPORT_SUCCESS:
      return {
        ...state,
        refuelOptimizationByVehicleReportFetching: false,
        refuelOptimizationByVehicleReport:
          action.refuelOptimizationByVehicleReport
      };
    case GET_REFUEL_OPTIMIZATION_BY_VEHICLE_REPORT_FAILURE:
      return {
        ...state,
        refuelOptimizationByVehicleReportFetching: false,
        error: action.error
      };

    // ? GET ZONE STAT REPORT //
    case GET_ZONE_STAT_REPORT_REQUEST: {
      return {
        ...state,
        zoneStatReportFetching: true,
        error: null
      };
    }
    case GET_ZONE_STAT_REPORT_SUCCESS:
      return {
        ...state,
        zoneStatReportFetching: false,
        zoneStatReport: action.zoneStatReport
      };
    case GET_ZONE_STAT_REPORT_FAILURE:
      return {
        ...state,
        zoneStatReportFetching: false,
        error: action.error
      };

    // ? GET AVERAGE INITIAL TANK VOLUME PERCENT REFUEL REPORT //
    case GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_REQUEST: {
      return {
        ...state,
        averageInitialTankVolumePercentRefuelReportFetching: true,
        error: null
      };
    }
    case GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_SUCCESS:
      return {
        ...state,
        averageInitialTankVolumePercentRefuelReportFetching: false,
        averageInitialTankVolumePercentRefuelReport: action.report
      };
    case GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_FAILURE:
      return {
        ...state,
        averageInitialTankVolumePercentRefuelReportFetching: false,
        error: action.error
      };

    // ? GET AVERAGE INITIAL TANK VOLUME PERCENT REFUEL REPORT MULTIPLE //
    case GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_MULTIPLE_REQUEST: {
      return {
        ...state,
        averageInitialTankVolumePercentRefuelReportMultipleFetching: true,
        error: null
      };
    }
    case GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_MULTIPLE_SUCCESS:
      return {
        ...state,
        averageInitialTankVolumePercentRefuelReportMultipleFetching: false,
        averageInitialTankVolumePercentRefuelReportMultiple: action.report
      };
    case GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_MULTIPLE_FAILURE:
      return {
        ...state,
        averageInitialTankVolumePercentRefuelReportMultipleFetching: false,
        error: action.error
      };

    default:
      return state;
  }
};
