import {
  WEBSOCKET_10SEC_DATA,
  WEBSOCKET_SET_TOKEN_REQUEST,
  WEBSOCKET_SUBSCRIBE_EVENTS_REQUEST,
  WEBSOCKET_UNSUBSCRIBE_EVENTS_REQUEST
} from '../constants/ActionTypes';

export const websocketSetToken = token => {
  return {
    type: WEBSOCKET_SET_TOKEN_REQUEST,
    payload: token
  };
};

export const websocketSubscribeEvents = ({
  customerId,
  vehicleGroupIds,
  siteIds,
  vehicleIds
}) => {
  return {
    type: WEBSOCKET_SUBSCRIBE_EVENTS_REQUEST,
    payload: { customerId, vehicleGroupIds, siteIds, vehicleIds }
  };
};

export const websocketUnsubscribeEvents = () => {
  return {
    type: WEBSOCKET_UNSUBSCRIBE_EVENTS_REQUEST
  };
};

export const websocketSaveData = () => {
  return {
    type: WEBSOCKET_10SEC_DATA
  };
};
