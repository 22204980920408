import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReactPlaceholder from 'react-placeholder';
import CircularProgress from 'components/CircularProgress/index';

import { getUser } from 'actions/Users';

class Loading extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    document.title = 'alertgasoil™ - fuel cost killer';

    if (localStorage.getItem('user_login') && !this.props.authUser) {
      this.props.getUser(localStorage.getItem('user_login'));
    }
  }

  render() {
    return (
      <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
        <div className="loader-view" style={{ height: '100%', width: '100%' }}>
          <CircularProgress />
        </div>
      </ReactPlaceholder>
    );
  }
}

const mapStateToProps = ({ users, global }) => {
  const { authUser } = users;
  return { authUser };
};

const mapDispatchToProps = dispatch => ({
  getUser: userLogin => dispatch(getUser(userLogin))
});

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
