import {
  ADD_VEHICLE_FAILURE,
  ADD_VEHICLE_REQUEST,
  ADD_VEHICLE_SUCCESS,
  GET_ALL_DATA_TO_ADD_VEHICLE_FAILURE,
  GET_ALL_DATA_TO_ADD_VEHICLE_REQUEST,
  GET_ALL_DATA_TO_ADD_VEHICLE_SUCCESS,
  GET_ALL_DATA_TO_UPDATE_VEHICLE_FAILURE,
  GET_ALL_DATA_TO_UPDATE_VEHICLE_REQUEST,
  GET_ALL_DATA_TO_UPDATE_VEHICLE_SUCCESS,
  GET_ALL_VEHICLE_SITES_FILTER_FAILURE,
  GET_ALL_VEHICLE_SITES_FILTER_REQUEST,
  GET_ALL_VEHICLE_SITES_FILTER_SUCCESS,
  GET_ALL_VEHICLES_FAILURE,
  GET_ALL_VEHICLES_REQUEST,
  GET_ALL_VEHICLES_SUCCESS,
  GET_DRIVER_ACTIVITIES_BY_VEHICLE_FAILURE,
  GET_DRIVER_ACTIVITIES_BY_VEHICLE_REQUEST,
  GET_DRIVER_ACTIVITIES_BY_VEHICLE_SUCCESS,
  GET_ENGINE_DETECTION_TYPE_FAILURE,
  GET_ENGINE_DETECTION_TYPE_REQUEST,
  GET_ENGINE_DETECTION_TYPE_SUCCESS,
  GET_ENUM_CALIB_FAILURE,
  GET_ENUM_CALIB_REQUEST,
  GET_ENUM_CALIB_SUCCESS,
  GET_PTO_TYPE_ENUM_FAILURE,
  GET_PTO_TYPE_ENUM_REQUEST,
  GET_PTO_TYPE_ENUM_SUCCESS,
  GET_SINGLE_VEHICLE_FAILURE,
  GET_SINGLE_VEHICLE_REQUEST,
  GET_SINGLE_VEHICLE_SUCCESS,
  GET_PRESELECTED_VEHICLES_BARFILTER_REQUEST,
  GET_PRESELECTED_VEHICLES_BARFILTER_SUCCESS,
  GET_PRESELECTED_VEHICLES_BARFILTER_FAILURE,
  GET_TACHY_TYPE_FAILURE,
  GET_TACHY_TYPE_REQUEST,
  GET_TACHY_TYPE_SUCCESS,
  GET_RDL_TYPE_FAILURE,
  GET_RDL_TYPE_REQUEST,
  GET_RDL_TYPE_SUCCESS,
  GET_VEHICLE_ACTIVITY_STATS_REPORT_FAILURE,
  GET_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_FAILURE,
  GET_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST,
  GET_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_SUCCESS,
  GET_VEHICLE_ACTIVITY_STATS_REPORT_REQUEST,
  GET_VEHICLE_ACTIVITY_STATS_REPORT_SUCCESS,
  GET_VEHICLE_BRAND_FAILURE,
  GET_VEHICLE_BRAND_REQUEST,
  GET_VEHICLE_BRAND_SUCCESS,
  GET_VEHICLE_CATEGORY_FAILURE,
  GET_VEHICLE_CATEGORY_REQUEST,
  GET_VEHICLE_CATEGORY_SUCCESS,
  GET_VEHICLE_FUEL_CONSUMPTION_REPORT_FAILURE,
  GET_VEHICLE_FUEL_CONSUMPTION_REPORT_MULTIPLE_FAILURE,
  GET_VEHICLE_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST,
  GET_VEHICLE_FUEL_CONSUMPTION_REPORT_MULTIPLE_SUCCESS,
  GET_VEHICLE_FUEL_CONSUMPTION_REPORT_REQUEST,
  GET_VEHICLE_FUEL_CONSUMPTION_REPORT_SUCCESS,
  GET_VEHICLE_FUEL_REPORT_FAILURE,
  GET_VEHICLE_FUEL_REPORT_REQUEST,
  GET_VEHICLE_FUEL_REPORT_SUCCESS,
  GET_VEHICLE_FUEL_REPORT_BY_DRIVER_FAILURE,
  GET_VEHICLE_FUEL_REPORT_BY_DRIVER_REQUEST,
  GET_VEHICLE_FUEL_REPORT_BY_DRIVER_SUCCESS,
  GET_VEHICLE_GROUP_TYPES_FAILURE,
  GET_VEHICLE_GROUP_TYPES_REQUEST,
  GET_VEHICLE_GROUP_TYPES_SUCCESS,
  GET_VEHICLE_GROUPS_FILTER_FAILURE,
  GET_VEHICLE_GROUPS_FILTER_REQUEST,
  GET_VEHICLE_GROUPS_FILTER_SUCCESS,
  GET_VEHICLE_MODEL_FAILURE,
  GET_VEHICLE_MODEL_REQUEST,
  GET_VEHICLE_MODEL_SUCCESS,
  GET_VEHICLE_STATUS_FAILURE,
  GET_VEHICLE_STATUS_REQUEST,
  GET_VEHICLE_STATUS_SUCCESS,
  GET_VEHICLE_TMAVA_TOP_REPORT_FAILURE,
  GET_VEHICLE_TMAVA_TOP_REPORT_REQUEST,
  GET_VEHICLE_TMAVA_TOP_REPORT_SUCCESS,
  GET_VEHICLE_TOTAL_ACTIVITY_DAYS_REPORT_FAILURE,
  GET_VEHICLE_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST,
  GET_VEHICLE_TOTAL_ACTIVITY_DAYS_REPORT_SUCCESS,
  GET_VEHICLE_TOTAL_DISTANCE_REPORT_FAILURE,
  GET_VEHICLE_TOTAL_DISTANCE_REPORT_MULTIPLE_FAILURE,
  GET_VEHICLE_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST,
  GET_VEHICLE_TOTAL_DISTANCE_REPORT_MULTIPLE_SUCCESS,
  GET_VEHICLE_TOTAL_DISTANCE_REPORT_REQUEST,
  GET_VEHICLE_TOTAL_DISTANCE_REPORT_SUCCESS,
  GET_VEHICLE_TOTAL_FUEL_REPORT_FAILURE,
  GET_VEHICLE_TOTAL_FUEL_REPORT_REQUEST,
  GET_VEHICLE_TOTAL_FUEL_REPORT_SUCCESS,
  GET_VEHICLE_TYPE_FAILURE,
  GET_VEHICLE_TYPE_REQUEST,
  GET_VEHICLE_TYPE_SUCCESS,
  GET_VEHICLES_FILTER_FAILURE,
  GET_VEHICLES_FILTER_REQUEST,
  GET_VEHICLES_FILTER_SUCCESS,
  GET_VEHICLES_HEADER_FAILURE,
  GET_VEHICLES_HEADER_REQUEST,
  GET_VEHICLES_HEADER_SUCCESS,
  UPDATE_VEHICLE_FAILURE,
  UPDATE_VEHICLE_REQUEST,
  UPDATE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAILURE,
  DELETE_VEHICLE_REQUEST,
  DELETE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FORM_MAP_REQUEST,
  UPDATE_VEHICLE_FORM_MAP_SUCCESS,
  UPDATE_VEHICLE_FORM_MAP_FAILURE,
  GET_ENERGY_ENUM_REQUEST,
  GET_ENERGY_ENUM_SUCCESS,
  GET_ENERGY_ENUM_FAILURE,
  CHECK_VEHICLE_DATA_IMPORT_REQUEST,
  CHECK_VEHICLE_DATA_IMPORT_SUCCESS,
  CHECK_VEHICLE_DATA_IMPORT_FAILURE
} from '../constants/ActionTypes';

const INIT_STATE = {
  initialFetchDone: false,
  fetching: false,

  fetchingVehicleHeader: false,
  fetchingVehiclesInFilterBar: false,
  fetchingAddVehicle: false,
  vehicleFetching: false,
  vehicle: null,
  preSelectedVehicleBarFilter: null,
  vehiclesFetching: false,
  vehicles: null,
  vehicleBrand: [],
  vehicleType: [],
  tachyType: [],
  RDLType: [],
  vehicleCategory: [],
  calibrationList: [],
  statusList: [],
  ptoType: [],
  energyEnum: null,
  engineDetectionType: [],
  fuelReportFetching: false,
  fuelReport: null,
  vehicleUpdated: null,
  vehicleDeleted: null,

  fuelConsumptionReportFetching: false,
  fuelConsumptionReport: null,
  fuelConsumptionReportMultipleFetching: false,
  fuelConsumptionReportMultiple: null,
  totalFuelReportFetching: false,
  totalFuelReport: null,
  totalDistanceReportFetching: false,
  totalDistanceReport: null,
  totalDistanceReportMultipleFetching: false,
  totalDistanceReportMultiple: null,
  permanentStockRateReportFetching: false,
  permanentStockRateReport: null,
  totalActivityDaysReport: null,
  totalActivityDaysReportFetching: false,
  vehicleActivityStatsReport: null,
  vehicleActivityStatsReportFetching: false,
  vehicleActivityStatsReportMultiple: null,
  vehicleActivityStatsReportMultipleFetching: false,
  driverActivitiesFetching: false,
  driverActivities: null,
  checkVehicleDataFetching: false,
  checkVehicleData: null,

  error: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_DATA_TO_UPDATE_VEHICLE_REQUEST: {
      return {
        ...state,
        initialFetchDone: false,
        fetching: true,
        error: null
      };
    }
    case GET_ALL_DATA_TO_UPDATE_VEHICLE_SUCCESS: {
      return {
        ...state,
        initialFetchDone: true,
        fetching: false,
        error: null
      };
    }
    case GET_ALL_DATA_TO_UPDATE_VEHICLE_FAILURE: {
      return {
        ...state,
        initialFetchDone: false,
        fetching: false,
        error: true
      };
    }
    case GET_ALL_DATA_TO_ADD_VEHICLE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_ALL_DATA_TO_ADD_VEHICLE_SUCCESS: {
      return {
        ...state,
        initialFetchDone: true,
        fetching: false,
        error: null
      };
    }
    case GET_ALL_DATA_TO_ADD_VEHICLE_FAILURE: {
      return {
        ...state,
        initialFetchDone: false,
        fetching: false,
        error: true
      };
    }
    //? GET ALL VEHICLES //
    case GET_ALL_VEHICLES_REQUEST: {
      return {
        ...state,
        vehiclesFetching: true,
        isVehiclesNextData: action.payload.offset > 0 ? true : false,
        error: null
      };
    }
    case GET_ALL_VEHICLES_SUCCESS:
      return { ...state, vehiclesFetching: false, vehicles: action.vehicles };
    case GET_ALL_VEHICLES_FAILURE:
      return { ...state, vehiclesFetching: false, error: action.error };

    //? GET ALL VEHICLES FILTER //
    case GET_VEHICLES_FILTER_REQUEST: {
      return {
        ...state,
        fetchingVehiclesInFilterBar: true,
        isVehiclesNextData: action.payload.offset > 0 ? true : false,
        error: null
      };
    }
    case GET_VEHICLES_FILTER_SUCCESS:
      return {
        ...state,
        fetchingVehiclesInFilterBar: false,
        vehiclesFilter: action.vehicles
      };
    case GET_VEHICLES_FILTER_FAILURE:
      return {
        ...state,
        fetchingVehiclesInFilterBar: false,
        error: action.error
      };

    //? GET ALL VEHICLES HEADER //
    case GET_VEHICLES_HEADER_REQUEST: {
      return {
        ...state,
        fetchingVehicleHeader: true,
        error: null
      };
    }
    case GET_VEHICLES_HEADER_SUCCESS:
      return {
        ...state,
        fetchingVehicleHeader: false,
        vehiclesHeader: action.vehicles
      };
    case GET_VEHICLES_HEADER_FAILURE:
      return { ...state, fetchingVehicleHeader: false, error: action.error };

    //? GET SINGLE VEHICLE //
    case GET_SINGLE_VEHICLE_REQUEST: {
      return {
        ...state,
        vehicleFetching: true,
        error: null
      };
    }
    case GET_SINGLE_VEHICLE_SUCCESS:
      return { ...state, vehicleFetching: false, vehicle: action.vehicle };
    case GET_SINGLE_VEHICLE_FAILURE:
      return { ...state, vehicleFetching: false, error: action.error };

    //? GET PRESELECTED VEHICLES IN BARFILTER //
    case GET_PRESELECTED_VEHICLES_BARFILTER_REQUEST: {
      return {
        ...state,
        vehicleFetching: true,
        error: null
      };
    }
    case GET_PRESELECTED_VEHICLES_BARFILTER_SUCCESS:
      return {
        ...state,
        vehicleFetching: false,
        preSelectedVehiclesBarFilter: action.preselectedVehicles
      };
    case GET_PRESELECTED_VEHICLES_BARFILTER_FAILURE:
      return { ...state, vehicleFetching: false, error: action.error };

    //? ADD VEHICLE //
    case ADD_VEHICLE_REQUEST: {
      return {
        ...state,
        fetchingAddVehicle: true,
        error: null
      };
    }
    case ADD_VEHICLE_SUCCESS:
      return { ...state, fetchingAddVehicle: false, vehicle: action };
    case ADD_VEHICLE_FAILURE:
      return {
        ...state,
        fetchingAddVehicle: false,
        vehicle: null,
        error: action.error
      };

    //? UPDATE VEHICLE //
    case UPDATE_VEHICLE_REQUEST: {
      return {
        ...state,
        fetching_update_vehicle: true,
        error: null
      };
    }
    case UPDATE_VEHICLE_SUCCESS:
      return {
        ...state,
        fetching_update_vehicle: false,
        vehicleUpdated: action.vehicle
      };
    case UPDATE_VEHICLE_FAILURE:
      return {
        ...state,
        fetching_update_vehicle: false,
        vehicleUpdated: null,
        error: action.error
      };

    //? DELETE VEHICLE //
    case DELETE_VEHICLE_REQUEST: {
      return {
        ...state,
        fetching_delete_vehicle: true,
        error: null
      };
    }
    case DELETE_VEHICLE_SUCCESS:
      return {
        ...state,
        fetching_delete_vehicle: false,
        vehicleDeleted: action.deletedVehicle
      };
    case DELETE_VEHICLE_FAILURE:
      return {
        ...state,
        fetching_delete_vehicle: false,
        vehicleDeleted: null,
        error: action.error
      };

    //? UPDATE VEHICLE FORM MAP //
    case UPDATE_VEHICLE_FORM_MAP_REQUEST: {
      return {
        ...state,
        fetching_update_vehicle: true,
        error: null
      };
    }
    case UPDATE_VEHICLE_FORM_MAP_SUCCESS:
      return {
        ...state,
        fetching_update_vehicle: false,
        vehicleUpdated: action.vehicle
      };
    case UPDATE_VEHICLE_FORM_MAP_FAILURE:
      return {
        ...state,
        fetching_update_vehicle: false,
        vehicleUpdated: null,
        error: action.error
      };

    //? GET VEHICLE BRAND //
    case GET_VEHICLE_BRAND_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_VEHICLE_BRAND_SUCCESS:
      return { ...state, fetching: false, vehicleBrand: action.vehicleBrand };
    case GET_VEHICLE_BRAND_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? GET VEHICLE MODEL //
    case GET_VEHICLE_MODEL_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_VEHICLE_MODEL_SUCCESS:
      return { ...state, fetching: false, vehicleModel: action.vehicleModel };
    case GET_VEHICLE_MODEL_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? GET VEHICLE TYPE //
    case GET_VEHICLE_TYPE_REQUEST: {
      return {
        ...state,
        fetching_get_vehicle_type: true,
        error: null
      };
    }
    case GET_VEHICLE_TYPE_SUCCESS:
      return {
        ...state,
        fetching_get_vehicle_type: false,
        vehicleType: action.vehicleType
      };
    case GET_VEHICLE_TYPE_FAILURE:
      return {
        ...state,
        fetching_get_vehicle_type: false,
        error: action.error
      };

    //? GET TACHY TYPE //
    case GET_TACHY_TYPE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_TACHY_TYPE_SUCCESS:
      return { ...state, fetching: false, tachyType: action.tachyType };
    case GET_TACHY_TYPE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET RDL TYPE //
    case GET_RDL_TYPE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_RDL_TYPE_SUCCESS:
      return { ...state, fetching: false, RDLType: action.RDLType };
    case GET_RDL_TYPE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET ENGINE DETECTION TYPE //
    case GET_ENGINE_DETECTION_TYPE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_ENGINE_DETECTION_TYPE_SUCCESS:
      return {
        ...state,
        fetching: false,
        engineDetectionType: action.engineDetectionType
      };
    case GET_ENGINE_DETECTION_TYPE_FAILURE:
      return { ...state, fetching: false, error: action.error };
    //? GET VEHICLE CATEGORY //
    case GET_VEHICLE_CATEGORY_REQUEST: {
      return {
        ...state,
        fetching_get_vehicle_category: true,
        error: null
      };
    }
    case GET_VEHICLE_CATEGORY_SUCCESS:
      return {
        ...state,
        fetching_get_vehicle_category: false,
        vehicleCategory: action.vehicleCategory
      };
    case GET_VEHICLE_CATEGORY_FAILURE:
      return {
        ...state,
        fetching_get_vehicle_category: false,
        error: action.error
      };

    //? GET ENUM CALIBRATION //
    case GET_ENUM_CALIB_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_ENUM_CALIB_SUCCESS:
      return {
        ...state,
        fetching: false,
        calibrationList: action.calibrationList
      };
    case GET_ENUM_CALIB_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET VEHICLE STATUS ENUM //
    case GET_VEHICLE_STATUS_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_VEHICLE_STATUS_SUCCESS:
      return {
        ...state,
        fetching: false,
        statusList: action.statusList
      };
    case GET_VEHICLE_STATUS_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET ALL VEHICLE SITES FILTER //
    case GET_ALL_VEHICLE_SITES_FILTER_REQUEST: {
      return {
        ...state,
        fetchingVehiclesInFilterBar: true,
        isSitesNextData: action.payload.offset > 0 ? true : false,
        error: null
      };
    }
    case GET_ALL_VEHICLE_SITES_FILTER_SUCCESS:
      return {
        ...state,
        fetchingVehiclesInFilterBar: false,
        sitesFilter: action.sitesFilter
      };
    case GET_ALL_VEHICLE_SITES_FILTER_FAILURE:
      return {
        ...state,
        fetchingVehiclesInFilterBar: false,
        error: action.error
      };

    //? GET VEHICLE GROUPS FILTER //
    case GET_VEHICLE_GROUPS_FILTER_REQUEST: {
      return {
        ...state,
        fetchingVehiclesInFilterBar: true,
        isVehicleGroupsNextData: action.payload.offset > 0 ? true : false,
        error: null
      };
    }
    case GET_VEHICLE_GROUPS_FILTER_SUCCESS:
      return {
        ...state,
        fetchingVehiclesInFilterBar: false,
        groupsFilter: action.groupsFilter
      };
    case GET_VEHICLE_GROUPS_FILTER_FAILURE:
      return {
        ...state,
        fetchingVehiclesInFilterBar: false,
        error: action.error
      };

    //? GET VEHICLE GROUP TYPES //
    case GET_VEHICLE_GROUP_TYPES_REQUEST: {
      return {
        ...state,
        fetchingVehiclesInFilterBar: true,
        error: null
      };
    }
    case GET_VEHICLE_GROUP_TYPES_SUCCESS:
      return {
        ...state,
        fetchingVehiclesInFilterBar: false,
        groupTypes: action.groupTypes
      };
    case GET_VEHICLE_GROUP_TYPES_FAILURE:
      return {
        ...state,
        fetchingVehiclesInFilterBar: false,
        error: action.error
      };

    //? GET VEHICLE FUEL REPORT //
    case GET_VEHICLE_FUEL_REPORT_REQUEST: {
      return {
        ...state,
        fuelReportFetching: true,
        error: null
      };
    }
    case GET_VEHICLE_FUEL_REPORT_SUCCESS:
      return {
        ...state,
        fuelReportFetching: false,
        fuelReport: action.fuelReport
      };
    case GET_VEHICLE_FUEL_REPORT_FAILURE:
      return { ...state, fuelReportFetching: false, error: action.error };

    //? GET VEHICLE FUEL REPORT BY DRIVER //
    case GET_VEHICLE_FUEL_REPORT_BY_DRIVER_REQUEST: {
      return {
        ...state,
        fuelReportFetching: true,
        error: null
      };
    }
    case GET_VEHICLE_FUEL_REPORT_BY_DRIVER_SUCCESS:
      return {
        ...state,
        fuelReportFetching: false,
        fuelReport: action.fuelReport
      };
    case GET_VEHICLE_FUEL_REPORT_BY_DRIVER_FAILURE:
      return { ...state, fuelReportFetching: false, error: action.error };

    //? GET VEHICLE TMAVA TOP REPORT //
    case GET_VEHICLE_TMAVA_TOP_REPORT_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_VEHICLE_TMAVA_TOP_REPORT_SUCCESS:
      return {
        ...state,
        fetching: false,
        tmavaTopReport: action.tmavaTopReport
      };
    case GET_VEHICLE_TMAVA_TOP_REPORT_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET VEHICLE FUEL CONSUMPTION REPORT //
    case GET_VEHICLE_FUEL_CONSUMPTION_REPORT_REQUEST: {
      return {
        ...state,
        fuelConsumptionReportFetching: true,
        error: null
      };
    }
    case GET_VEHICLE_FUEL_CONSUMPTION_REPORT_SUCCESS:
      return {
        ...state,
        fuelConsumptionReportFetching: false,
        fuelConsumptionReport: action.fuelConsumptionReport
      };
    case GET_VEHICLE_FUEL_CONSUMPTION_REPORT_FAILURE:
      return {
        ...state,
        fuelConsumptionReportFetching: false,
        error: action.error
      };

    //? GET VEHICLE FUEL CONSUMPTION REPORT MULITPLE //
    case GET_VEHICLE_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST: {
      return {
        ...state,
        fuelConsumptionReportMultipleFetching: true,
        error: null
      };
    }
    case GET_VEHICLE_FUEL_CONSUMPTION_REPORT_MULTIPLE_SUCCESS:
      return {
        ...state,
        fuelConsumptionReportMultipleFetching: false,
        fuelConsumptionReportMultiple: action.fuelConsumptionReportMultiple
      };
    case GET_VEHICLE_FUEL_CONSUMPTION_REPORT_MULTIPLE_FAILURE:
      return {
        ...state,
        fuelConsumptionReportMultipleFetching: false,
        error: action.error
      };

    //? GET VEHICLE TOTAL FUEL REPORT //
    case GET_VEHICLE_TOTAL_FUEL_REPORT_REQUEST: {
      return {
        ...state,
        totalFuelReportFetching: true,
        error: null
      };
    }
    case GET_VEHICLE_TOTAL_FUEL_REPORT_SUCCESS:
      return {
        ...state,
        totalFuelReportFetching: false,
        totalFuelReport: action.totalFuelReport
      };
    case GET_VEHICLE_TOTAL_FUEL_REPORT_FAILURE:
      return { ...state, totalFuelReportFetching: false, error: action.error };

    //? GET VEHICLE TOTAL DISTANCE REPORT //
    case GET_VEHICLE_TOTAL_DISTANCE_REPORT_REQUEST: {
      return {
        ...state,
        totalDistanceReportFetching: true,
        error: null
      };
    }
    case GET_VEHICLE_TOTAL_DISTANCE_REPORT_SUCCESS:
      return {
        ...state,
        totalDistanceReportFetching: false,
        totalDistanceReport: action.totalDistanceReport
      };
    case GET_VEHICLE_TOTAL_DISTANCE_REPORT_FAILURE:
      return {
        ...state,
        totalDistanceReportFetching: false,
        error: action.error
      };

    //? GET VEHICLE TOTAL DISTANCE REPORT MULTIPLE //
    case GET_VEHICLE_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST: {
      return {
        ...state,
        totalDistanceReportMultipleFetching: true,
        error: null
      };
    }
    case GET_VEHICLE_TOTAL_DISTANCE_REPORT_MULTIPLE_SUCCESS:
      return {
        ...state,
        totalDistanceReportMultipleFetching: false,
        totalDistanceReportMultiple: action.totalDistanceReportMultiple
      };
    case GET_VEHICLE_TOTAL_DISTANCE_REPORT_MULTIPLE_FAILURE:
      return {
        ...state,
        totalDistanceReportMultipleFetching: false,
        error: action.error
      };

    //? GET VEHICLE TOTAL ACTIVITY DAYS REPORT //
    case GET_VEHICLE_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST: {
      return {
        ...state,
        totalActivityDaysReportFetching: true,
        error: null
      };
    }
    case GET_VEHICLE_TOTAL_ACTIVITY_DAYS_REPORT_SUCCESS:
      return {
        ...state,
        totalActivityDaysReportFetching: false,
        totalActivityDaysReport: action.totalActivityDaysReport
      };
    case GET_VEHICLE_TOTAL_ACTIVITY_DAYS_REPORT_FAILURE:
      return {
        ...state,
        totalActivityDaysReportFetching: false,
        error: action.error
      };

    //? GET VEHICLE ACTIVITY STATS REPORT //
    case GET_VEHICLE_ACTIVITY_STATS_REPORT_REQUEST: {
      return {
        ...state,
        vehicleActivityStatsReportFetching: true,
        error: null
      };
    }
    case GET_VEHICLE_ACTIVITY_STATS_REPORT_SUCCESS:
      return {
        ...state,
        vehicleActivityStatsReportFetching: false,
        vehicleActivityStatsReport: action.report
      };
    case GET_VEHICLE_ACTIVITY_STATS_REPORT_FAILURE:
      return {
        ...state,
        vehicleActivityStatsReportFetching: false,
        error: action.error
      };

    //? GET VEHICLE ACTIVITY STATS REPORT MULTIPLE //
    case GET_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST: {
      return {
        ...state,
        vehicleActivityStatsReportMultipleFetching: true,
        error: null
      };
    }
    case GET_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_SUCCESS:
      return {
        ...state,
        vehicleActivityStatsReportMultipleFetching: false,
        vehicleActivityStatsReportMultiple: action.report
      };
    case GET_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_FAILURE:
      return {
        ...state,
        vehicleActivityStatsReportMultipleFetching: false,
        error: action.error
      };

    //? GET PTO TYPE ENUM //
    case GET_PTO_TYPE_ENUM_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_PTO_TYPE_ENUM_SUCCESS:
      return {
        ...state,
        fetching: false,
        ptoType: action.ptoTypeList
      };
    case GET_PTO_TYPE_ENUM_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };

    //? GET ENERGY ENUM //
    case GET_ENERGY_ENUM_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_ENERGY_ENUM_SUCCESS:
      return {
        ...state,
        fetching: false,
        energyEnum: action.energyList
      };
    case GET_ENERGY_ENUM_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };

    //? GET DRIVER ACTIVITIES BY VEHICLE //
    case GET_DRIVER_ACTIVITIES_BY_VEHICLE_REQUEST: {
      return {
        ...state,
        driverActivitiesFetching: true,
        error: null
      };
    }
    case GET_DRIVER_ACTIVITIES_BY_VEHICLE_SUCCESS:
      return {
        ...state,
        driverActivitiesFetching: false,
        driverActivities: action.driverActivities
      };
    case GET_DRIVER_ACTIVITIES_BY_VEHICLE_FAILURE:
      return {
        ...state,
        driverActivitiesFetching: false,
        error: action.error
      };

    //? CHECK VEHICLE DATA IMPORT //
    case CHECK_VEHICLE_DATA_IMPORT_REQUEST: {
      return {
        ...state,
        checkVehicleDataFetching: true,
        error: null
      };
    }
    case CHECK_VEHICLE_DATA_IMPORT_SUCCESS:
      return {
        ...state,
        checkVehicleDataFetching: false,
        checkVehicleData: action.checkVehicleData
      };
    case CHECK_VEHICLE_DATA_IMPORT_FAILURE:
      return {
        ...state,
        checkVehicleDataFetching: false,
        error: action.error
      };

    default:
      return state;
  }
};
