import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import {
  GET_ALL_ZONES_REQUEST,
  GET_ALL_ZONES_SUCCESS,
  GET_ALL_ZONES_FAILURE,
  GET_ZONE_CATEGORY_REQUEST,
  GET_ZONE_CATEGORY_SUCCESS,
  GET_ZONE_CATEGORY_FAILURE,
  ADD_ZONE_REQUEST,
  ADD_ZONE_SUCCESS,
  ADD_ZONE_FAILURE,
  DELETE_ZONE_REQUEST,
  DELETE_ZONE_SUCCESS,
  DELETE_ZONE_FAILURE,
  UPDATE_ZONE_REQUEST,
  UPDATE_ZONE_SUCCESS,
  UPDATE_ZONE_FAILURE
} from 'constants/ActionTypes';
import { showMessage } from 'actions/Global';
import { API_URL } from '../constants/globalConstants';
import axios from 'axios';

export function* watcherGetAllZones() {
  yield takeEvery(GET_ALL_ZONES_REQUEST, workerGetAllZones);
}

export function* watcherGetZoneCategories() {
  yield takeEvery(GET_ZONE_CATEGORY_REQUEST, workerGetZoneCategories);
}

export function* watcherAddZone() {
  yield takeEvery(ADD_ZONE_REQUEST, workerAddZone);
}

export function* watcherDeleteZone() {
  yield takeEvery(DELETE_ZONE_REQUEST, workerDeleteZone);
}

export function* watcherUpdateZone() {
  yield takeEvery(UPDATE_ZONE_REQUEST, workerUpdateZone);
}

const getAllZones = async (token, idCustomer) => {
  let url = `${API_URL}zones?`;
  if (idCustomer) url += 'customerId=' + idCustomer + '&';
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

const getZoneCategories = async token => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}global/zone_categories`
  });
};

const addZone = async (token, zone) => {
  console.log('======================');
  console.log(zone);
  console.log('======================');
  if (zone.formZone === 'CIRCLE') {
    return axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        customerId: zone.customerId,
        type: zone.formZone,
        name: zone.zoneInfos.zone_name,
        description: zone.zoneInfos.description,
        category: zone.zoneInfos.zone_category,
        center: zone.detailsJson.center,
        radius: zone.detailsJson.radius,
        points: null
        //zone_status: 'ENABLED'
      },
      url: `${API_URL}zones`
    });
  } else if (zone.formZone === 'POLYGON' || zone.formZone === 'RECTANGLE') {
    return axios({
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        customerId: zone.customerId,
        type: zone.formZone,
        name: zone.zoneInfos.zone_name,
        description: zone.zoneInfos.description,
        category: zone.zoneInfos.zone_category,
        points: zone.detailsJson.points,
        center: null,
        radius: null
        //zone_status: 'ENABLED'
      },
      url: `${API_URL}zones`
    });
  }
};

const deleteZone = async (token, idZone) => {
  return axios({
    method: 'delete',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}zones/` + idZone
  });
};

const updateZone = async (token, zone) => {
  if (zone.formZone === 'CIRCLE') {
    return axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        customerId: zone.customerId,
        type: zone.formZone,
        name: zone.zoneInfos.zone_name,
        description: zone.zoneInfos.description,
        category: zone.zoneInfos.zone_category,
        center: zone.detailsJson.center,
        radius: zone.detailsJson.radius,
        points: null
        //zone_status: 'ENABLED'
      },
      url: `${API_URL}zones/` + zone.zoneId
    });
  } else if (zone.formZone === 'POLYGON' || zone.formZone === 'RECTANGLE') {
    return axios({
      method: 'put',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        customerId: zone.customerId,
        type: zone.formZone,
        name: zone.zoneInfos.zone_name,
        description: zone.zoneInfos.description,
        category: zone.zoneInfos.zone_category,
        points: zone.detailsJson.points,
        center: null,
        radius: null
        //zone_status: 'ENABLED'
      },
      url: `${API_URL}zones/` + zone.zoneId
    });
  }
};

function* workerGetAllZones(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getAllZones, token, action.payload);
    const zones = response.data;

    yield put({ type: GET_ALL_ZONES_SUCCESS, zones });
  } catch (error) {
    yield put({ type: GET_ALL_ZONES_FAILURE, error });
  }
}

function* workerAddZone(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(addZone, token, action.payload);
    const zoneCreated = response.data;
    yield put({ type: ADD_ZONE_SUCCESS, zoneCreated });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Zone created !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center'
      })
    );
  } catch (error) {
    yield put({ type: ADD_ZONE_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center'
      })
    );
  }
}

function* workerDeleteZone(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(deleteZone, token, action.payload);
    const zoneDeleted = action.payload;

    yield put({ type: DELETE_ZONE_SUCCESS, zoneDeleted });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Zone deleted !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center'
      })
    );
  } catch (error) {
    yield put({ type: DELETE_ZONE_FAILURE, error });
    yield put(showMessage(error.message, 'danger'));
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center'
      })
    );
  }
}

function* workerUpdateZone(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(updateZone, token, action.payload);
    const zoneUpdated = response.data;
    yield put({ type: UPDATE_ZONE_SUCCESS, zoneUpdated });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Zone updated !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center'
      })
    );
  } catch (error) {
    yield put({ type: UPDATE_ZONE_FAILURE, error });
    yield put(showMessage(error.message, 'danger'));
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center'
      })
    );
  }
}

function* workerGetZoneCategories() {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getZoneCategories, token);
    const categories = response.data;
    yield put({ type: GET_ZONE_CATEGORY_SUCCESS, categories });
  } catch (error) {
    yield put({ type: GET_ZONE_CATEGORY_FAILURE, error });
  }
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetAllZones),
    fork(watcherAddZone),
    fork(watcherDeleteZone),
    fork(watcherUpdateZone),
    fork(watcherGetZoneCategories)
  ]);
}
