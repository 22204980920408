import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  GET_ALL_VEHICLE_DAILY_REPORTS_REQUEST,
  GET_ALL_VEHICLE_DAILY_REPORTS_SUCCESS,
  GET_ALL_VEHICLE_DAILY_REPORTS_FAILURE,
  SWITCH_REF_CONSUMPTION_REQUEST,
  SWITCH_REF_CONSUMPTION_SUCCESS,
  SWITCH_REF_CONSUMPTION_FAILURE,
} from 'constants/ActionTypes';
import { showMessage } from 'actions/Global';
import { API_URL } from '../constants/globalConstants';
import axios from 'axios';

//////////////////! GET ALL VEHICLES DAILY REPORT ///////////////////////
export function* watcherGetAllVehicleDailyReports() {
  yield takeEvery(
    GET_ALL_VEHICLE_DAILY_REPORTS_REQUEST,
    workerGetAllVehicleDailyReports
  );
}

const getAllVehicleDailyReports = async (
  token,
  { vehicleId, startDate, endDate }
) => {
  let url = `${API_URL}vehicle_daily_reports?`;
  if (vehicleId) url += 'vehicleId=' + vehicleId + '&';
  if (startDate && endDate)
    url += `period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate,
      })
    )}`;

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: url,
  });
};

function* workerGetAllVehicleDailyReports(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      getAllVehicleDailyReports,
      token,
      action.payload
    );
    const vehicleDailyReportsList = response.data;

    yield put({
      type: GET_ALL_VEHICLE_DAILY_REPORTS_SUCCESS,
      vehicleDailyReportsList,
    });
  } catch (error) {
    yield put({ type: GET_ALL_VEHICLE_DAILY_REPORTS_FAILURE, error });
  }
}

//////////////////! SWITCH REF CONSUMPTION FOR 1 VEHICLE ///////////////////////
export function* watcherSwitchRefConsumptionVehicle() {
  yield takeEvery(
    SWITCH_REF_CONSUMPTION_REQUEST,
    workerSwitchRefConsumptionVehicle
  );
}

const switchRefConsumptionVehicle = async (
  token,
  { vehicleId, day, value }
) => {
  let url = `${API_URL}vehicle_daily_reports/`;
  if (vehicleId) url += vehicleId + '/';
  if (day) url += `${day}/`;
  if (value) url += `${value}`;

  return axios({
    method: 'put',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    url: url,
  });
};

function* workerSwitchRefConsumptionVehicle(action) {
  const getToken = (state) => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(
      switchRefConsumptionVehicle,
      token,
      action.payload
    );
    const vehicleSwitchRefConsumption = response.data;

    yield put({
      type: SWITCH_REF_CONSUMPTION_SUCCESS,
      vehicleSwitchRefConsumption,
    });
  } catch (error) {
    yield put({ type: SWITCH_REF_CONSUMPTION_FAILURE, error });
  }
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetAllVehicleDailyReports),
    fork(watcherSwitchRefConsumptionVehicle),
  ]);
}
