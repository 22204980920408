import {
  GET_ALL_ALERTS_REQUEST,
  GET_ALERTS_SINGLE_VEHICLE_REQUEST,
  GET_ALERTS_SINGLE_DRIVER_REQUEST,
  GET_ALERTS_SINGLE_ZONE_REQUEST
} from '../constants/ActionTypes';

export const getAllAlerts = infos => {
  return {
    type: GET_ALL_ALERTS_REQUEST,
    payload: infos
  };
};

export const getAlertSingleVehicle = infos => {
  return {
    type: GET_ALERTS_SINGLE_VEHICLE_REQUEST,
    payload: infos
  };
};

export const getAlertSingleDriver = infos => {
  return {
    type: GET_ALERTS_SINGLE_DRIVER_REQUEST,
    payload: infos
  };
};

export const getAlertSingleZone = infos => {
  return {
    type: GET_ALERTS_SINGLE_ZONE_REQUEST,
    payload: infos
  };
};
