import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import {
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAILURE,
  GET_SINGLE_CUSTOMER_REQUEST,
  GET_SINGLE_CUSTOMER_SUCCESS,
  GET_SINGLE_CUSTOMER_FAILURE,
  GET_PRESELECTED_CUSTOMER_BARFILTER_REQUEST,
  GET_PRESELECTED_CUSTOMER_BARFILTER_SUCCESS,
  GET_PRESELECTED_CUSTOMER_BARFILTER_FAILURE,
  GET_CUSTOMERS_FILTER_REQUEST,
  GET_CUSTOMERS_FILTER_SUCCESS,
  GET_CUSTOMERS_FILTER_FAILURE,
  GET_CUSTOMER_STATUS_LIST_REQUEST,
  GET_CUSTOMER_STATUS_LIST_SUCCESS,
  GET_CUSTOMER_STATUS_LIST_FAILURE,
  GET_CUSTOMER_STATS_REPORT_REQUEST,
  GET_CUSTOMER_STATS_REPORT_SUCCESS,
  GET_CUSTOMER_STATS_REPORT_FAILURE,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE
} from 'constants/ActionTypes';
import { API_URL } from '../constants/globalConstants';
import { showMessage } from 'actions/Global';
import axios from 'axios';

export function* watcherGetCustomers() {
  yield takeEvery(GET_CUSTOMERS_REQUEST, workerGetCustomers);
}

export function* watcherUpdateCustomer() {
  yield takeEvery(UPDATE_CUSTOMER_REQUEST, workerUpdateCustomer);
}

export function* watcherGetSingleCustomer() {
  yield takeEvery(GET_SINGLE_CUSTOMER_REQUEST, workerGetSingleCustomer);
}

export function* watcherGetPreSelectedCustomerBarFilter() {
  yield takeEvery(
    GET_PRESELECTED_CUSTOMER_BARFILTER_REQUEST,
    workerGetPreSelectedCustomerBarFilter
  );
}

export function* watcherGetCustomersFilter() {
  yield takeEvery(GET_CUSTOMERS_FILTER_REQUEST, workerGetCustomersFilter);
}

export function* watcherGetCustomerStatusList() {
  yield takeEvery(
    GET_CUSTOMER_STATUS_LIST_REQUEST,
    workerGetCustomerStatusList
  );
}

export function* watcherGetCustomerStatsReport() {
  yield takeEvery(
    GET_CUSTOMER_STATS_REPORT_REQUEST,
    workerGetCustomerStatsReport
  );
}

const getCustomers = async (token, { offset, limit, pattern }) => {
  let url = `${API_URL}customers?`;
  if (offset) url += 'offset=' + offset + '&';
  if (limit) url += 'limit=' + limit + '&';
  if (pattern) url += 'pattern=' + pattern;
  // console.log('url', url);
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

const updateCustomer = async (token, customer) => {
  console.log('========SAGA=========');
  console.log(customer);
  console.log('======================');

  console.log('settings', {
    name: customer.customer_name,
    active: customer.enable_customer,
    status: customer.customer_status.name,
    // reseller: false, //! A DELETE
    // resellerId: 0, //! A DELETE
    agProAppName: customer.agpro_app_name,
    salesForceId: customer.salesforce_id,
    settings: {
      SAV_PRIORITY_GAUGE: customer.priority_gauge,
      SAV_SIM_DYSFUNCTION: customer.sim_dysfunction,
      SAV_PERMANENT_PLUS_CONNECTION: customer.permanent_plus_connection,
      SAV_DISCONNECTED_CASE_UNDER_BATTERY_CUT:
        customer.disconnected_case_under_battery_cut,
      SAV_PACKAGE_WITHOUT_DATA_FRAME: customer.package_without_dataframe,
      SAV_RELAY_FUSE: customer.relay_fuse,
      SAV_DISCONNECT_CASE: customer.disconnected_case,
      SAV_PTO: customer.pto,
      SAV_D8_ACTIVATION: customer.d8_activation,
      SAV_TACOGRAPH: customer.tacograph,
      SAV_BUZZER: customer.buzzer,
      SAV_SRE_STONERIDGE_PASSAGE: customer.sre_stoneridge_passage,
      SAV_FMS_CONNECTION: customer.fms_connection,
      SAV_AGTACH: customer.ag_tach,
      SAV_MANO_CONTACT_CONNECTION: customer.mano_contact_connection,
      SAV_GPS_LOST: customer.gps_lost,
      SAV_ALTERNATOR_BATTERY: customer.alternator_battery,
      SAV_TANKS_DIMENSIONS_PLUG: customer.tanks_dimensions_plug,
      SAV_GAUGE_RMA: customer.gauge_sav,
      SAV_TACHY_RMA_LOSS: customer.loss_or_rma_tachy,
      SAV_RMA_BOX: customer.case_rma,
      SAV_GPS_LOSS_RMA: customer.gps_loss_or_rma,
      SAV_DISCONNECTED_CASE_ON_OFF: customer.disconnected_case_off,
      SAV_DISCONNECTED_CASE_ON_ON: customer.disconnected_case_on,

      ALERT_TMAVA_BEGIN_HOUR: customer.tmava_begin_hour,
      ALERT_TMAVA_END_HOUR: customer.tmava_end_hour,
      ALERT_TMAVA_TRIGGER_THRESHOLD: customer.tmava_display_threshold,
      ALERT_TMAVA_DAYS:
        typeof customer.tmava_days === 'object'
          ? JSON.stringify(customer.tmava_days).replace(/[\\"]+/g, '"')
          : customer.tmava_days,
      ALERT_TMAVAT_BEGIN_HOUR: customer.tmavat_begin_hour,
      ALERT_TMAVAT_END_HOUR: customer.tmavat_end_hour,
      ALERT_TMAVAT_TRIGGER_THRESHOLD: customer.tmavat_display_threshold,
      ALERT_TMAVAT_DAYS:
        typeof customer.tmavat_days === 'object'
          ? JSON.stringify(customer.tmavat_days).replace(/[\\"]+/g, '"')
          : customer.tmavat_days,
      ALERT_SERVER_THEFT_BEGIN_HOUR: customer.server_theft_begin_hour,
      ALERT_SERVER_THEFT_END_HOUR: customer.server_theft_end_hour,
      ALERT_SERVER_THEFT_TRIGGER_THRESHOLD:
        customer.server_theft_display_threshold,
      ALERT_SERVER_THEFT_DAYS:
        typeof customer.server_theft_days === 'object'
          ? JSON.stringify(customer.server_theft_days).replace(/[\\"]+/g, '"')
          : customer.server_theft_days,
      ALERT_EMBEDDED_THEFT_BEGIN_HOUR: customer.embedded_theft_begin_hour,
      ALERT_EMBEDDED_THEFT_END_HOUR: customer.embedded_theft_end_hour,
      ALERT_EMBEDDED_THEFT_TRIGGER_THRESHOLD:
        customer.embedded_theft_display_threshold,
      ALERT_EMBEDDED_THEFT_DAYS:
        typeof customer.embedded_theft_days === 'object'
          ? JSON.stringify(customer.embedded_theft_days).replace(/[\\"]+/g, '"')
          : customer.embedded_theft_days,
      ALERT_LOW_BATTERY_BEGIN_HOUR: customer.low_battery_begin_hour,
      ALERT_LOW_BATTERY_END_HOUR: customer.low_battery_end_hour,
      ALERT_LOW_BATTERY_TRIGGER_THRESHOLD:
        customer.low_battery_display_threshold,
      ALERT_LOW_BATTERY_DAYS:
        typeof customer.low_battery_days === 'object'
          ? JSON.stringify(customer.low_battery_days).replace(/[\\"]+/g, '"')
          : customer.low_battery_days,
      EVENT_LOW_TANK_STOCK_BEGIN_HOUR:
        customer.almost_low_tank_stock_begin_hour,
      EVENT_LOW_TANK_STOCK_END_HOUR: customer.almost_low_tank_stock_end_hour,
      EVENT_LOW_TANK_STOCK_TRIGGER_THRESHOLD:
        customer.almost_low_tank_stock_display_threshold,
      EVENT_LOW_TANK_STOCK_DAYS:
        typeof customer.almost_low_tank_stock_days === 'object'
          ? JSON.stringify(customer.almost_low_tank_stock_days).replace(
              /[\\"]+/g,
              '"'
            )
          : customer.almost_low_tank_stock_days,
      EVENT_VERY_LOW_TANK_STOCK_BEGIN_HOUR: customer.low_tank_stock_begin_hour,
      EVENT_VERY_LOW_TANK_STOCK_END_HOUR: customer.low_tank_stock_end_hour,
      EVENT_VERY_LOW_TANK_STOCK_TRIGGER_THRESHOLD:
        customer.low_tank_stock_display_threshold,
      EVENT_VERY_LOW_TANK_STOCK_DAYS:
        typeof customer.low_tank_stock_days === 'object'
          ? JSON.stringify(customer.low_tank_stock_days).replace(/[\\"]+/g, '"')
          : customer.low_tank_stock_days,
      ALERT_ZONE_IN_LIST_ZONE_ENABLED:
        customer.zones_alerts.zone_entry.length > 0,
      ALERT_ZONE_IN_LIST_ZONE:
        customer.zones_alerts.zone_entry.length > 0
          ? customer.zones_alerts.zone_entry.join(';')
          : '',
      ALERT_ZONE_OUT_LIST_ZONE_ENABLED:
        customer.zones_alerts.zone_exit.length > 0,
      ALERT_ZONE_OUT_LIST_ZONE:
        customer.zones_alerts.zone_exit.length > 0
          ? customer.zones_alerts.zone_exit.join(';')
          : '',
      ALERT_ZONE_ATTENDANCE_LIST_ZONE_ENABLED:
        customer.zones_alerts.zone_presence_time.length > 0,
      ALERT_ZONE_ATTENDANCE_LIST_ZONE:
        customer.zones_alerts.zone_presence_time.length > 0
          ? customer.zones_alerts.zone_presence_time.join(';')
          : '',
      ALERT_ZONE_SPEED_ENABLED: customer.zones_alerts.zone_speed.length > 0,
      ALERT_ZONE_SPEED_LIST_ZONE:
        customer.zones_alerts.zone_speed.length > 0
          ? customer.zones_alerts.zone_speed.join(';')
          : '',
      TMAVA_COEFF: customer.tmava_coef,
      TMAVAT_COEFF: customer.tmavat_coef,
      VEQ_TMAVA_DAYS_PER_YEAR: customer.tmava_veq,
      TEQ_COMBUSTION: customer.combustion_teq,
      FULL_TANK_THRESHOLD: customer.full_tank_threshold,
      REFERENCE_THRESHOLD_CONSUMPTION: customer.reference_threshold_consumption,
      OVERSPEED: customer.overspeed,
      MINIMUM_DISTANCE_PER_MONTH: customer.minimum_distance
    }
  });

  return axios({
    method: 'put',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: {
      name: customer.customer_name,
      active: customer.enable_customer,
      status: customer.customer_status.name,
      // reseller: false, //! A DELETE
      // resellerId: 0, //! A DELETE
      agProAppName: customer.agpro_app_name,
      salesForceId: customer.salesforce_id,
      settings: {
        SAV_PRIORITY_GAUGE: customer.priority_gauge,
        SAV_SIM_DYSFUNCTION: customer.sim_dysfunction,
        SAV_PERMANENT_PLUS_CONNECTION: customer.permanent_plus_connection,
        SAV_DISCONNECTED_CASE_UNDER_BATTERY_CUT:
          customer.disconnected_case_under_battery_cut,
        SAV_PACKAGE_WITHOUT_DATA_FRAME: customer.package_without_dataframe,
        SAV_RELAY_FUSE: customer.relay_fuse,
        SAV_DISCONNECT_CASE: customer.disconnected_case,
        SAV_PTO: customer.pto,
        SAV_D8_ACTIVATION: customer.d8_activation,
        SAV_TACOGRAPH: customer.tacograph,
        SAV_BUZZER: customer.buzzer,
        SAV_SRE_STONERIDGE_PASSAGE: customer.sre_stoneridge_passage,
        SAV_FMS_CONNECTION: customer.fms_connection,
        SAV_AGTACH: customer.ag_tach,
        SAV_MANO_CONTACT_CONNECTION: customer.mano_contact_connection,
        SAV_GPS_LOST: customer.gps_lost,
        SAV_ALTERNATOR_BATTERY: customer.alternator_battery,
        SAV_TANKS_DIMENSIONS_PLUG: customer.tanks_dimensions_plug,
        SAV_GAUGE_RMA: customer.gauge_sav,
        SAV_TACHY_RMA_LOSS: customer.loss_or_rma_tachy,
        SAV_RMA_BOX: customer.case_rma,
        SAV_GPS_LOSS_RMA: customer.gps_loss_or_rma,
        SAV_DISCONNECTED_CASE_ON_OFF: customer.disconnected_case_off,
        SAV_DISCONNECTED_CASE_ON_ON: customer.disconnected_case_on,

        ALERT_TMAVA_ENABLED: customer.alert_tmava,
        ALERT_TMAVA_BEGIN_HOUR: customer.tmava_begin_hour,
        ALERT_TMAVA_END_HOUR: customer.tmava_end_hour,
        ALERT_TMAVA_TRIGGER_THRESHOLD: customer.tmava_display_threshold,
        ALERT_TMAVA_DAYS:
          typeof customer.tmava_days === 'object'
            ? JSON.stringify(customer.tmava_days).replace(/[\\"]+/g, '"')
            : customer.tmava_days,

        ALERT_TMAVAT_ENABLED: customer.alert_tmavat,
        ALERT_TMAVAT_BEGIN_HOUR: customer.tmavat_begin_hour,
        ALERT_TMAVAT_END_HOUR: customer.tmavat_end_hour,
        ALERT_TMAVAT_TRIGGER_THRESHOLD: customer.tmavat_display_threshold,
        ALERT_TMAVAT_DAYS:
          typeof customer.tmavat_days === 'object'
            ? JSON.stringify(customer.tmavat_days).replace(/[\\"]+/g, '"')
            : customer.tmavat_days,

        ALERT_SERVER_THEFT_ENABLED: customer.alert_server_theft,
        ALERT_SERVER_THEFT_BEGIN_HOUR: customer.server_theft_begin_hour,
        ALERT_SERVER_THEFT_END_HOUR: customer.server_theft_end_hour,
        ALERT_SERVER_THEFT_TRIGGER_THRESHOLD:
          customer.server_theft_display_threshold,
        ALERT_SERVER_THEFT_DAYS:
          typeof customer.server_theft_days === 'object'
            ? JSON.stringify(customer.server_theft_days).replace(/[\\"]+/g, '"')
            : customer.server_theft_days,

        ALERT_EMBEDDED_THEFT_ENABLED: customer.alert_embedded_theft,
        ALERT_EMBEDDED_THEFT_BEGIN_HOUR: customer.embedded_theft_begin_hour,
        ALERT_EMBEDDED_THEFT_END_HOUR: customer.embedded_theft_end_hour,
        ALERT_EMBEDDED_THEFT_TRIGGER_THRESHOLD:
          customer.embedded_theft_display_threshold,
        ALERT_EMBEDDED_THEFT_DAYS:
          typeof customer.embedded_theft_days === 'object'
            ? JSON.stringify(customer.embedded_theft_days).replace(
                /[\\"]+/g,
                '"'
              )
            : customer.embedded_theft_days,

        ALERT_LOW_BATTERY_ENABLED: customer.alert_low_battery,
        ALERT_LOW_BATTERY_BEGIN_HOUR: customer.low_battery_begin_hour,
        ALERT_LOW_BATTERY_END_HOUR: customer.low_battery_end_hour,
        ALERT_LOW_BATTERY_TRIGGER_THRESHOLD:
          customer.low_battery_display_threshold,
        ALERT_LOW_BATTERY_DAYS:
          typeof customer.low_battery_days === 'object'
            ? JSON.stringify(customer.low_battery_days).replace(/[\\"]+/g, '"')
            : customer.low_battery_days,

        ALERT_LOW_TANK_STOCK_ENABLED: customer.alert_almost_low_tank_stock,
        EVENT_LOW_TANK_STOCK_BEGIN_HOUR:
          customer.almost_low_tank_stock_begin_hour,
        EVENT_LOW_TANK_STOCK_END_HOUR: customer.almost_low_tank_stock_end_hour,
        EVENT_LOW_TANK_STOCK_TRIGGER_THRESHOLD:
          customer.almost_low_tank_stock_threshold,
        EVENT_LOW_TANK_STOCK_DAYS:
          typeof customer.almost_low_tank_stock_days === 'object'
            ? JSON.stringify(customer.almost_low_tank_stock_days).replace(
                /[\\"]+/g,
                '"'
              )
            : customer.almost_low_tank_stock_days,

        ALERT_VERY_LOW_TANK_STOCK_ENABLED: customer.alert_low_tank_stock,
        EVENT_VERY_LOW_TANK_STOCK_BEGIN_HOUR:
          customer.low_tank_stock_begin_hour,
        EVENT_VERY_LOW_TANK_STOCK_END_HOUR: customer.low_tank_stock_end_hour,
        EVENT_VERY_LOW_TANK_STOCK_TRIGGER_THRESHOLD:
          customer.low_tank_stock_threshold,
        EVENT_VERY_LOW_TANK_STOCK_DAYS:
          typeof customer.low_tank_stock_days === 'object'
            ? JSON.stringify(customer.low_tank_stock_days).replace(
                /[\\"]+/g,
                '"'
              )
            : customer.low_tank_stock_days,
        ALERT_ZONE_IN_LIST_ZONE_ENABLED:
          customer.zones_alerts.zone_entry.length > 0,
        ALERT_ZONE_IN_LIST_ZONE:
          customer.zones_alerts.zone_entry.length > 0
            ? customer.zones_alerts.zone_entry.join(';')
            : '',
        ALERT_ZONE_OUT_LIST_ZONE_ENABLED:
          customer.zones_alerts.zone_exit.length > 0,
        ALERT_ZONE_OUT_LIST_ZONE:
          customer.zones_alerts.zone_exit.length > 0
            ? customer.zones_alerts.zone_exit.join(';')
            : '',
        ALERT_ZONE_ATTENDANCE_LIST_ZONE_ENABLED:
          customer.zones_alerts.zone_presence_time.length > 0,
        ALERT_ZONE_ATTENDANCE_LIST_ZONE:
          customer.zones_alerts.zone_presence_time.length > 0
            ? customer.zones_alerts.zone_presence_time.join(';')
            : '',
        ALERT_ZONE_SPEED_ENABLED: customer.zones_alerts.zone_speed.length > 0,
        ALERT_ZONE_SPEED_LIST_ZONE:
          customer.zones_alerts.zone_speed.length > 0
            ? customer.zones_alerts.zone_speed.join(';')
            : '',
        TMAVA_COEFF: customer.tmava_coef,
        TMAVAT_COEFF: customer.tmavat_coef,
        VEQ_TMAVA_DAYS_PER_YEAR: customer.tmava_veq,
        TEQ_COMBUSTION: customer.combustion_teq,
        FULL_TANK_THRESHOLD: customer.full_tank_threshold,
        REFERENCE_THRESHOLD_CONSUMPTION:
          customer.reference_threshold_consumption,
        OVERSPEED: customer.overspeed,
        MINIMUM_DISTANCE_PER_MONTH: customer.minimum_distance,
        FUEL_PRICE_BY_LITER: customer.petrol_price,
        THRESHOLD_MINIMUM_DAY_ACTIVITY:
          customer.activity_days_minimum_threshold,
        TRAINED_CLIENT: customer.trained_customer,
        PAGE_ACCESS_DEPLOYMENT_MONITORING: customer.follow_deployment_access
      }
    },
    url: `${API_URL}customers/${customer.id}`
  });
};

const getSingleCustomer = async (token, idCustomer) => {
  console.log('======CUSTOMER SAGA =======');
  console.log(idCustomer);
  console.log('======================');
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}customers/${idCustomer}`
  });
};

const getCustomerStatusList = async token => {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: `${API_URL}global/customer_status`
  });
};

const getCustomerStatsReport = async (
  token,
  { customerId, vehicleGroupIds, siteIds, vehicleIds, startDate, endDate }
) => {
  let url = `${API_URL}customer/${customerId}/report/customer_stats?`;
  if (vehicleGroupIds) {
    vehicleGroupIds.map(vehicleGroupId => {
      url += 'vehicleGroupId=' + vehicleGroupId + '&';
    });
  }
  if (siteIds) {
    siteIds.map(siteId => {
      url += 'vehicleGroupId=' + siteId + '&';
    });
  }
  if (vehicleIds) {
    vehicleIds.map(vehicleId => {
      url += 'vehicleId=' + vehicleId + '&';
    });
  }
  if (startDate && endDate) {
    url += `&period=${encodeURIComponent(
      JSON.stringify({
        start: startDate,
        end: endDate
      })
    )}`;
  }

  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    },
    url: url
  });
};

function* workerGetCustomers(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getCustomers, token, action.payload);
    const customers = response.data;

    yield put({ type: GET_CUSTOMERS_SUCCESS, customers });
  } catch (error) {
    yield put({ type: GET_CUSTOMERS_FAILURE, error });
  }
}

function* workerUpdateCustomer(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(updateCustomer, token, action.payload);
    const customer = response.data;

    yield put({ type: UPDATE_CUSTOMER_SUCCESS, customer });
    yield put(
      showMessage({
        title: 'Information',
        message: 'Customer updated !',
        messageType: 'success',
        duration: 2000,
        position: 'bottom-center'
      })
    );
  } catch (error) {
    yield put({ type: UPDATE_CUSTOMER_FAILURE, error });
    yield put(
      showMessage({
        title: 'Error',
        message: error.message,
        messageType: 'danger',
        duration: 5000,
        position: 'bottom-center'
      })
    );
  }
}

function* workerGetCustomersFilter(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getCustomers, token, action.payload);
    const customers = response.data;

    yield put({ type: GET_CUSTOMERS_FILTER_SUCCESS, customers });
  } catch (error) {
    yield put({ type: GET_CUSTOMERS_FILTER_FAILURE, error });
  }
}

function* workerGetSingleCustomer(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getSingleCustomer, token, action.payload);
    const customer = response.data;

    yield put({ type: GET_SINGLE_CUSTOMER_SUCCESS, customer });
  } catch (error) {
    yield put({ type: GET_SINGLE_CUSTOMER_FAILURE, error });
  }
}

function* workerGetPreSelectedCustomerBarFilter(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getSingleCustomer, token, action.payload);
    const preSelectedCustomer = response.data;

    yield put({
      type: GET_PRESELECTED_CUSTOMER_BARFILTER_SUCCESS,
      preSelectedCustomer
    });
  } catch (error) {
    yield put({ type: GET_PRESELECTED_CUSTOMER_BARFILTER_FAILURE, error });
  }
}

function* workerGetCustomerStatusList(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getCustomerStatusList, token);
    const customerStatusList = response.data;
    yield put({
      type: GET_CUSTOMER_STATUS_LIST_SUCCESS,
      customerStatusList
    });
  } catch (error) {
    yield put({ type: GET_CUSTOMER_STATUS_LIST_FAILURE, error });
  }
}

function* workerGetCustomerStatsReport(action) {
  const getToken = state => state.users.authUser.token;
  const token = yield select(getToken);
  try {
    const response = yield call(getCustomerStatsReport, token, action.payload);
    const customerStatsReport = response.data;
    yield put({
      type: GET_CUSTOMER_STATS_REPORT_SUCCESS,
      customerStatsReport
    });
  } catch (error) {
    yield put({ type: GET_CUSTOMER_STATS_REPORT_FAILURE, error });
  }
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetCustomers),
    fork(watcherUpdateCustomer),
    fork(watcherGetSingleCustomer),
    fork(watcherGetPreSelectedCustomerBarFilter),
    fork(watcherGetCustomersFilter),
    fork(watcherGetCustomerStatusList),
    fork(watcherGetCustomerStatsReport)
  ]);
}
