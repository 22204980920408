import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Footer from 'components/Footer';
import Button from '@material-ui/core/Button';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import * as Yup from 'yup';
import {
  forgotPassword,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn,
} from 'actions/Users';
import { APP_VERSION } from '../constants/globalConstants';

const styles = (theme) => ({
  blackHover: {
    '&:hover': {
      color: `${theme.palette.primary.dark}`,
      transition: '0.3s ease-in',
    },
  },
  legalMention: {
    fontSize: '1rem',
    position: 'absolute',
    bottom: '1rem',
    left: 0,
    right: 0,
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //
    };
  }

  validatorSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        this.props
          ? this.props.intl.formatMessage({
              id: 'form.control.email.format',
            })
          : 'The format is incorrect'
      )
      .required(
        this.props
          ? this.props.intl.formatMessage({
              id: 'form.control.email.required',
            })
          : 'The email is required'
      ),
  });

  render() {
    console.log('====================================');
    console.log(this.props);
    console.log('====================================');

    const classes = this.props.classes;
    const { showMessage, loader, alertMessage } = this.props;
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link
              className="logo-lg"
              rel="keep-params"
              to="/"
              title="Alert Gasoil">
              <img
                style={{ width: '230px', padding: '10px' }}
                src={require('assets/images/logo-alert-gasoil.svg')}
                alt="Alert Gasoil logo"
                title="alert gasoil"
              />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>
                <IntlMessages id="forgotPassword.title" />
              </h1>
            </div>
            {/* {this.props.error_code &&
            this.props.error_code === 'EMAIL_NOT_FOUND' ? (
              <p
                style={{
                  fontSize: '1.1rem',
                  color: this.props.theme.palette.status.danger
                }}>
                <IntlMessages id="forgotPassword.mailDoesntExist" />
              </p>
            ) : null} */}
            <p>
              <IntlMessages id="forgotPassword.description" />
            </p>
            <div className="app-login-form">
              <Formik
                initialValues={{
                  email: '',
                }}
                enableReinitialize
                validationSchema={this.validatorSchema}
                onSubmit={(values, actions) => {
                  console.log(values);
                  this.props.forgotPassword(values.email);
                  actions.setSubmitting(false);
                  actions.resetForm();
                }}
                render={({
                  errors,
                  status,
                  touched,
                  setFieldValue,
                  submitForm,
                  isSubmitting,
                  values,
                  isValid,
                  handleChange,
                  handleSubmit,
                }) => (
                  <Form>
                    <FormControl component="fieldset" className={` w-100`}>
                      <FormLabel component="legend">
                        <IntlMessages id="appModule.email" />
                      </FormLabel>
                      <Field
                        name="email"
                        type="text"
                        component={TextField}
                        onChange={handleChange}
                        value={values.email}
                        className="mt-2"
                        fullWidth
                        variant="outlined"
                      />
                    </FormControl>
                    <div className="mt-4 d-flex align-items-center justify-content-between">
                      <Link rel="keep-params" to="/signin">
                        <button className="signin-back-button">
                          <IntlMessages id="appModule.back" />
                        </button>
                      </Link>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!isValid}
                        onClick={handleSubmit}>
                        <IntlMessages id="forgotPassword.sendButton" />
                      </Button>
                    </div>
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({ users, global }) => {
  const { loader, authUser, error_code } = users;
  return {
    loader,
    authUser,
    error_code,
  };
};

export default connect(mapStateToProps, {
  forgotPassword,
  userSignIn,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn,
})(
  withStyles(
    (theme) => ({
      ...styles(theme),
    }),
    { withTheme: true }
  )(injectIntl(ForgotPassword))
);
