import {
  ADD_ECO_PROFILE_FAILURE,
  ADD_ECO_PROFILE_REQUEST,
  ADD_ECO_PROFILE_SUCCESS,
  ADD_VEHICLE_ECO_PROFILE_FAILURE,
  ADD_VEHICLE_ECO_PROFILE_REQUEST,
  ADD_VEHICLE_ECO_PROFILE_SUCCESS,
  GET_ALL_DRIVER_ECO_PROFILES_FAILURE,
  GET_ALL_DRIVER_ECO_PROFILES_REQUEST,
  GET_ALL_DRIVER_ECO_PROFILES_SUCCESS,
  GET_ALL_VEHICLE_ECO_PROFILES_FAILURE,
  GET_ALL_VEHICLE_ECO_PROFILES_REQUEST,
  GET_ALL_VEHICLE_ECO_PROFILES_SUCCESS,
  GET_SINGLE_ECO_PROFILE_FAILURE,
  GET_SINGLE_ECO_PROFILE_REQUEST,
  GET_SINGLE_ECO_PROFILE_SUCCESS,
  GET_SINGLE_VEHICLE_ECO_PROFILE_FAILURE,
  GET_SINGLE_VEHICLE_ECO_PROFILE_REQUEST,
  GET_SINGLE_VEHICLE_ECO_PROFILE_SUCCESS,
  UPDATE_ECO_PROFILE_FAILURE,
  UPDATE_ECO_PROFILE_REQUEST,
  UPDATE_ECO_PROFILE_SUCCESS,
  UPDATE_VEHICLE_ECO_PROFILE_FAILURE,
  UPDATE_VEHICLE_ECO_PROFILE_REQUEST,
  UPDATE_VEHICLE_ECO_PROFILE_SUCCESS,
  GET_ECO_PROFILE_SCORES_REQUEST,
  GET_ECO_PROFILE_SCORES_SUCCESS,
  GET_ECO_PROFILE_SCORES_FAILURE,
  GET_VEHICLE_ECO_PROFILE_SCORES_REQUEST,
  GET_VEHICLE_ECO_PROFILE_SCORES_SUCCESS,
  GET_VEHICLE_ECO_PROFILE_SCORES_FAILURE
} from '../constants/ActionTypes';

const INIT_STATE = {
  fetching: false,
  ecoProfiles: [],
  vehicleEcoProfiles: [],
  ecoProfile: null,
  ecoProfileAdded: null,
  ecoProfileUpdated: null,
  error: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //? GET ALL DRIVER ECO PROFILES //
    case GET_ALL_DRIVER_ECO_PROFILES_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_ALL_DRIVER_ECO_PROFILES_SUCCESS:
      console.log('humm SUCCESS');
      return { ...state, fetching: false, ecoProfiles: action.ecoProfilesList };
    case GET_ALL_DRIVER_ECO_PROFILES_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET ALL VEHICLE ECO PROFILES //
    case GET_ALL_VEHICLE_ECO_PROFILES_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_ALL_VEHICLE_ECO_PROFILES_SUCCESS:
      console.log('SUCCESS');
      return {
        ...state,
        fetching: false,
        vehicleEcoProfiles: action.ecoProfilesList
      };
    case GET_ALL_VEHICLE_ECO_PROFILES_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET SINGLE ECO PROFILE //
    case GET_SINGLE_ECO_PROFILE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_SINGLE_ECO_PROFILE_SUCCESS:
      return { ...state, fetching: false, ecoProfile: action.ecoProfile };
    case GET_SINGLE_ECO_PROFILE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET SINGLE VEHICLE ECO PROFILE //
    case GET_SINGLE_VEHICLE_ECO_PROFILE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_SINGLE_VEHICLE_ECO_PROFILE_SUCCESS:
      return {
        ...state,
        fetching: false,
        vehicleEcoProfile: action.ecoProfile
      };
    case GET_SINGLE_VEHICLE_ECO_PROFILE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? ADD ECO PROFILE//
    case ADD_ECO_PROFILE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case ADD_ECO_PROFILE_SUCCESS:
      return {
        ...state,
        fetching: false,
        ecoProfileAdded: action.ecoProfileAdded
      };
    case ADD_ECO_PROFILE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? ADD VEHICLE ECO PROFILE//
    case ADD_VEHICLE_ECO_PROFILE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case ADD_VEHICLE_ECO_PROFILE_SUCCESS:
      return {
        ...state,
        fetching: false,
        vehicleEcoProfileAdded: action.ecoProfileAdded
      };
    case ADD_VEHICLE_ECO_PROFILE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? UPDATE ECO PROFILE//
    case UPDATE_ECO_PROFILE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case UPDATE_ECO_PROFILE_SUCCESS:
      return {
        ...state,
        fetching: false,
        ecoProfileUpdated: action.ecoProfileUpdated
      };
    case UPDATE_ECO_PROFILE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? UPDATE VEHICLE ECO PROFILE//
    case UPDATE_VEHICLE_ECO_PROFILE_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case UPDATE_VEHICLE_ECO_PROFILE_SUCCESS:
      return {
        ...state,
        fetching: false,
        vehicleEcoProfileUpdated: action.ecoProfileUpdated
      };
    case UPDATE_VEHICLE_ECO_PROFILE_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET ECO PROFILE SCORES//
    case GET_ECO_PROFILE_SCORES_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_ECO_PROFILE_SCORES_SUCCESS:
      return {
        ...state,
        fetching: false,
        ecoProfileScores: action.ecoProfileScores
      };
    case GET_ECO_PROFILE_SCORES_FAILURE:
      return { ...state, fetching: false, error: action.error };

    //? GET VEHICLE ECO PROFILE SCORES//
    case GET_VEHICLE_ECO_PROFILE_SCORES_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null
      };
    }
    case GET_VEHICLE_ECO_PROFILE_SCORES_SUCCESS:
      return {
        ...state,
        fetching: false,
        vehicleEcoProfileScores: action.ecoProfileScores
      };
    case GET_VEHICLE_ECO_PROFILE_SCORES_FAILURE:
      return { ...state, fetching: false, error: action.error };

    default:
      return state;
  }
};
