import { shouldPolyfill } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import enLang from './entries/en-EN';
import zhLang from './entries/zh-Hans-CN';
import arLang from './entries/ar_SA';
import itLang from './entries/it_IT';
import esLang from './entries/es_ES';
import frLang from './entries/fr_FR';
import nlLang from './entries/nl_NL';
//import { addLocaleData } from 'react-intl';   // It was necessary for react-intl v2

const AppLocale = {
  en: enLang,
  zh: zhLang,
  ar: arLang,
  it: itLang,
  es: esLang,
  fr: frLang,
  nl: nlLang
};

if (shouldPolyfill()) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-getcanonicallocales/polyfill');
}

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/dist/locale-data/en');
  require('@formatjs/intl-pluralrules/dist/locale-data/fr');
  require('@formatjs/intl-pluralrules/dist/locale-data/es');
  require('@formatjs/intl-pluralrules/dist/locale-data/it');
  require('@formatjs/intl-pluralrules/dist/locale-data/nl');
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/fr');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/es');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/it');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/nl');
}

// addLocaleData(AppLocale.en.data);   // It was necessary for react-intl v2
// addLocaleData(AppLocale.zh.data);
// addLocaleData(AppLocale.ar.data);
// addLocaleData(AppLocale.it.data);
// addLocaleData(AppLocale.es.data);
// addLocaleData(AppLocale.fr.data);
// addLocaleData(AppLocale.nl.data);

export default AppLocale;
